import { forward, sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { requestActualSurvey } from '../../../survey/model'
import {
  $targetDocumentId,
  approveForm,
  closeModal,
  sendEstimateToApproveFx,
} from './private'
import { openSendApproveModal } from './public'
import { updateDocumentInfo } from '../../shared-model'

$targetDocumentId
  .on(openSendApproveModal, (_, id) => id)
  .reset(closeModal)

sample({
  clock: approveForm.formValidated,
  source: $targetDocumentId,
  filter: Boolean,
  fn: (id, { comment }) => ({
    id,
    comment,
  }),
  target: sendEstimateToApproveFx,
})

forward({
  from: sendEstimateToApproveFx.doneData,
  to: updateDocumentInfo,
})

forward({
  from: sendEstimateToApproveFx.doneData,
  to: [closeModal, requestActualSurvey],
})

forward({
  from: closeModal,
  to: approveForm.reset,
})

createToast({
  effect: sendEstimateToApproveFx,
  doneText: 'Отправлено на согласование',
})
