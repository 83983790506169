import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { DialogSheetWrapper } from '../DialogSheetWrapper'
import { TextMLight } from '../../typography'
import { palette } from '../../palette'
import { NewCheckbox } from '../NewCheckbox'
import { NewButton } from '../../buttons'
import { TooltipProps } from '../../types'

type Props = TooltipProps & {
  onAcceptClick: () => void
  onToggle: (value: any) => void
  onClear: () => void

  testId: string
  items: {
    id: any
    label: string
  }[]
  selectedItems: any[]
}

export const MultiSelectList = ({
  items,
  onAcceptClick,
  onToggle,
  onClear,
  selectedItems,
  testId,

  floatingStyles,
  getFloatingProps,
  refs,
}: Props) => {
  return (
    <List
      ref={refs.setFloating}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="275px">
        {items.map((item) => (
          <ListItem onClick={() => onToggle(item.id)} key={item.id}>
            <NewCheckbox
              label={item.label}
              checkPosition="left"
              onChange={() => onToggle(item.id)}
              value={item.id}
              isChecked={selectedItems.includes(item.id)}
            />
          </ListItem>
        ))}
      </Scrollbars>
      <Buttons>
        <NewButton
          dataTestId={`${testId}-accept`}
          label="Применить"
          buttonType="secondary"
          size="S"
          onClick={onAcceptClick}
        />
        <NewButton
          label="Сбросить"
          dataTestId={`${testId}-reset`}
          buttonType="line-grey"
          prefixIcon="refresh"
          size="S"
          onClick={onClear}
        />
      </Buttons>
    </List>
  )
}

const List = styled(DialogSheetWrapper)`
  padding: 8px 0;
  ${TextMLight}
  z-index: 11;
`

const ListItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey40};
    }
  }
`

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  padding: 12px 12px 8px;
`
