import {
  AppealItemType,
  SupportAppealTypeAlias,
  cancelAppealItemReqFx,
  getAppealInfoReqFx,
  getCommentsReqFx,
  putAppealMessageReqFx,
} from '@/dal'
import { createAppealPageModel } from '@/features/create-appeals-list/model'
import { d } from './domain'
import { AppealType } from './types'

export const $appealTypes = d.store<AppealType[]>([
  {
    name: 'Проблемы с согласованием',
    alias: SupportAppealTypeAlias.PROBLEMS_WITH_APPROVAL,
  },
  {
    name: 'Добавить пользователя в ЛКП',
    alias: SupportAppealTypeAlias.ADD_NEW_USER,
  },
  {
    name: 'Отключить пользователя от ЛКП',
    alias: SupportAppealTypeAlias.DISABLE_USER,
  },
  {
    name: 'Ошибки в ЛКП',
    alias: SupportAppealTypeAlias.LKP_ERRORS,
  },
  {
    name: 'Консультация по работе системы ЛКП',
    alias: SupportAppealTypeAlias.CONSULTATION,
  },
  {
    name: 'Предложить улучшение',
    alias: SupportAppealTypeAlias.SUGGEST_AN_IMPROVEMENT,
  },
])

export const {
  filterModel,
  listModel,
  commentsModel,
} = createAppealPageModel<AppealItemType>({
  domain: d,
  getCommentsFx: getCommentsReqFx,
  $filterItems: $appealTypes,
  getItemsFx: getAppealInfoReqFx,
  cancelItemFx: cancelAppealItemReqFx,
  postCommentFx: putAppealMessageReqFx,
})
