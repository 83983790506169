import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Icon2 } from '../../icons'
import { TextL } from '../../typography'
import { palette } from '../../palette'


type Props = {
  label: string
  path: string
}

export const LinkTab = ({ label, path }: Props) => (
  <TextLink to={path} replace exact>
    <Wrapper>
      <Text>
        {label}
      </Text>
      <IconWrapper>
        <Icon2 icon="arrowRight" />
      </IconWrapper>
    </Wrapper>
  </TextLink>
)

const IconWrapper = styled.div`
  color: inherit;
  display: inline-flex;
  opacity: 0;
  transition: opacity 0.15s linear;
`

const Wrapper = styled.div`
  padding: 13px 16px;
  transition: 0.15s linear;
  transition-property: color background-color;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 20px;
  color: ${palette.grey60};

  @media (hover: hover){
    &:hover {
      color: ${palette.grey90};

      & ${IconWrapper} {
        opacity: 1;
      }
    }
  }

  &:active {
    background-color: ${palette.grey10};
    color: ${palette.grey90};
  }
`


const TextLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;

  &.active {
    ${Wrapper} {
      background-color: ${palette.grey20};
      color: ${palette.grey90};
      & ${IconWrapper} {
        opacity: 1;
      }
    }
  }
`

const Text = styled.div`
  ${TextL}
  color: inherit;
`
