import {
  DefaultErrorResponse,
  DefaultHandledError,
  defaultErrorHandler,
} from '../error-handler'
import { CheckInnNotFoundError, NeedChangeTableError } from './errors'
import {
  PaymentError,
  CheckInnErrorPayload,
  PaymentErrorResponse,
  NeedChangeTableErrorPayload,
} from './types'

type PaymentErrorHandler =
  CheckInnNotFoundError
  | NeedChangeTableError
  | DefaultHandledError

export const paymentErrorHandler = (
  error: PaymentErrorResponse | undefined,
): PaymentErrorHandler => {
  switch (error?.code) {
    case PaymentError.Not_found: {
      return new CheckInnNotFoundError(error as CheckInnErrorPayload)
    }
    case PaymentError.NeedChangeTable: {
      return new NeedChangeTableError(error as NeedChangeTableErrorPayload)
    }
    default: {
      return defaultErrorHandler(error as DefaultErrorResponse)
    }
  }
}
