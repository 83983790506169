import React from 'react'
import { useGate, useUnit } from 'effector-react'
import styled from 'styled-components'

import { PillowSwiper, NewTextButton } from '@/ui'
import { ProjectItemType } from '@/dal'
import { EditCreateContactModal } from '@/features/my-company/create-edit-contact/view/entries'

import {
  $projects,
  ContactsGate,
  onAddContact,
  selectProject,
} from '../../model/private'
import { DEFAULT_ID } from '../../model/const'
import { DeleteContactConfirm, RoleList } from '../containers'
import { $selectedProjectId } from '../../model'

export const Contacts = React.memo(() => {
  const projects = useUnit($projects)
  const selectedTab = useUnit($selectedProjectId)

  const items = React.useMemo(() => {
    if (!projects) return null
    return [
      { label: 'Все', id: DEFAULT_ID },
      ...projects.map((item) => ({
        label: item.name,
        id: item.id,
      })),
    ]
  }, [projects])

  useGate(ContactsGate)

  return (
    <>
      <Wrapper>
        <PillowSwiper
          activeId={selectedTab}
          items={items ?? []}
          onItemClick={(id) => {
            selectProject(id as ProjectItemType['id'] | null)
          }}
        />
        <ListWrapper>
          <NewTextButton
            dataTestId="open-create-contact-modal"
            label="Добавить контакт"
            iconSize={16}
            prefixIcon="plusButton"
            onClick={onAddContact}
          />

          <RoleList />
        </ListWrapper>
      </Wrapper>
      <EditCreateContactModal />
      <DeleteContactConfirm />
    </>
  )
})

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  gap: 12px;
`
