import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import {
  Table,
  TableFilter,
  WorksHeader,
} from '../containers'
import { AutoCompleteAlert } from '../parts'
import { ActWorkGate } from '../../model/private'
import { $documentFeatures } from '../../../model'

export const Works = React.memo(() => {
  const features = useUnit($documentFeatures)

  useGate(ActWorkGate)

  return (
    <Container>
      <WorksHeader />
      {features?.has_negative_completions_counts && <AutoCompleteAlert />}
      <div>
        <TableFilter />
        <Table />
      </div>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
