import {
  HeadM,
  Icon2,
  IconName2,
  TextLLight,
  palette,
} from '@/ui'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

type Props = {
  title: string
  text: string
  link: string
  icon: IconName2
  type?: 'yellow' | 'white'
}

export const LinkCard = ({
  icon,
  link,
  text,
  title,
  // @TODO возможно, надо будет удалить type (зависит от дизайна)
  type = 'yellow',
}: Props) => (
  <Link to={link}>
    <Card type={type}>
      <CardTop>
        <Icon2 color={type === 'white' ? 'lightBlue' : 'accent100'} size={32} icon={icon} />
        <TextWrapper>
          <TopText>
            {title}
          </TopText>
          <IconWrapper>
            <Icon2 icon="arrowRight" />
          </IconWrapper>
        </TextWrapper>
      </CardTop>
      <CardBody>
        {text}
      </CardBody>
    </Card>
  </Link>
)

const CardTop = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const IconWrapper = styled.div`
  color: ${palette.grey60};
  display: inline-flex;
  transition: color 0.15s linear;
`

const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`

const TopText = styled.div`
  ${HeadM}
  transition: color 0.15s linear;
  flex-grow: 1;
`

const CardBody = styled.div`
  ${TextLLight}
`

const YellowCardStyle = css`
  transition: background-color 0.15s linear;
  background-color: ${palette.yellow10};
  @media (hover: hover) {
    &:hover {
      background-color: ${palette.yellow20};

      ${TopText}, ${IconWrapper} {
        color: ${palette.accent100};
      }
    }
  }
  &:active {
    background-color: ${palette.yellow30};
  }
`
const WhiteCardStyle = css`
  background-color: ${palette.white};
  border: 1px solid ${palette.grey20};
  transition: border-color 0.15s linear;

  @media (hover: hover) {
    &:hover {
      border-color: ${palette.grey50};

      ${TopText}, ${IconWrapper} {
        color: ${palette.accent100};
      }
    }
  }
  &:active {
    border-color: ${palette.grey60};
  }
`

const Card = styled.div<{type: 'yellow' | 'white'}>`
  height: 100%;
  padding: 28px 28px 32px 28px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  ${({ type }) => type === 'yellow' && YellowCardStyle}
  ${({ type }) => type === 'white' && WhiteCardStyle}
`
