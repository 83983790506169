import React from 'react'

import { EditableValue } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  Col,
  Value,
} from './sharedCSS'

type Props = {
  type?: 'currency' | 'percent'
  isEdited?: boolean
  isBold?: boolean
  value: string
  currentValue?: string
  deltaValue: string | null
  isChangeable?: boolean
  isGrey?: boolean
  onSubmit?: (val: string) => void
}

export const CurrencyCol = ({
  type = 'currency',
  value,
  deltaValue,
  isBold,
  isChangeable,
  isEdited,
  currentValue,
  isGrey,
  onSubmit,
}: Props) => {
  const isNegativeDelta = React.useMemo(() => {
    if (!currentValue) return null
    return parseFloat(currentValue) > parseFloat(value)
  }, [currentValue, value])

  const formatString = (string: string) => (
    type === 'currency' ? numberWithSpaces(string.toString()) : `${string} %`
  )

  return (
    <Col isEdited={isEdited}>
      {isChangeable && onSubmit ? (
        <EditableValue
          onSubmit={onSubmit}
          value={value}
          ceilType="currency"
          placeholder="Введите цену"
        />
      ) : (
        <Value isBold={isBold} isGrey={isGrey}>
          {formatString(value)}
        </Value>
      )}

      {deltaValue !== null && (
        <Value isBold={isBold} isGrey={isGrey} isDiff>
          {isNegativeDelta ? '-' : '+'}
          {' '}
          {formatString(deltaValue)}
        </Value>
      )}
    </Col>
  )
}
