import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx } from '../request'
import {
  GetAppealInfoParams,
  GetAppealInfoResponse,
  PutAppealMessageReqParams,
  PutAppealMessageReqResponse,
  AppealItemType,
  AppealComment,
  GetJiraDocumentsByType,
  JiraDocument,
  CreateProblemWithApproval,
  AddUserParams,
  MyUsersInfo,
  PostOtherProblemParams,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import { AbortableRequest } from '../interfaces'

export const getAppealInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, ...query }: AbortableRequest<GetAppealInfoParams>) => ({
    method: Method.get,
    url: '/jira/user_requests/',
    query: {
      ...query,
      status: query.status?.join(','),
      request_type: query.request_type?.join(','),
    },
    signal,
  }),
  mapResult: ({
    result,
  }: {
    result: AxiosResponse<GetAppealInfoResponse>},
  ) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const cancelAppealItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: AppealItemType['id']) => ({
    method: Method.put,
    url: `/jira/reject_request/${id}/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<AppealItemType>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getCommentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{id: AppealItemType['id']}>) => ({
    method: Method.get,
    url: `/jira/comments/${id}/`,
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<AppealComment[]>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const putAppealMessageReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PutAppealMessageReqParams) => ({
    method: Method.put,
    url: `/jira/comments/${id}/`,
    body: prepareFormData(body),
  }),
  mapResult: ({ result }: {result: AxiosResponse<PutAppealMessageReqResponse>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getJiraDocumentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ type, signal }: AbortableRequest<GetJiraDocumentsByType>) => ({
    method: Method.get,
    url: `/jira/doc_by_type/${type}/`,
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<JiraDocument[]>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postProblemsWithApprovalReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: CreateProblemWithApproval) => ({
    method: Method.post,
    url: '/jira/new/troubles/',
    body: prepareFormData(body),
  }),
  mapResult: ({ result }: {result: AxiosResponse<void>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const addUserReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: AddUserParams) => ({
    method: Method.post,
    url: '/jira/new/user/',
    body,
  }),
  mapResult: ({ result }: {result: AxiosResponse<void>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getMyUsersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/jira/get_created_users/',
  }),
  mapResult: ({ result }: {result: AxiosResponse<MyUsersInfo[]>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const blockUserReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: MyUsersInfo['id']) => ({
    method: Method.put,
    url: `/jira/block_user/${id}/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<void>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postOtherProblemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ alias, ...params }: PostOtherProblemParams) => ({
    method: Method.post,
    url: `/jira/new/other/${alias}/`,
    body: prepareFormData(params),
  }),
  mapResult: ({ result }: {result: AxiosResponse<void>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
