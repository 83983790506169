import { DocumentAlias, DocumentStatuses, FileDTO } from '../interfaces'

export enum AdministrationAppealStatus {
  All = 'all',
  Unread = 'unread',
  WithoutResponse = 'without_response',
  Completed = 'completed',
}

type RequestStatuses = {
  name: string
  count: number
  value: AdministrationAppealStatus
}

type RequestAttachment = {
  name: string
  url: string
}

export type AdministrativeAppealComment = {
  id: number
  author: string
  date_time: string
  body: string
  user_lkp: boolean
  files?: FileDTO[]
}

export enum AdministrationAppealTypeAlias {
  ProjectDir = 'project_dir',
  ConstructionDir = 'construction_dir',
  ProjectCor = 'project_cor',
  ActReq = 'actreq',
  Act = 'act',
  Contract = 'contract',
  Estimate = 'estimate',
  Contragent = 'contragent',
}

export type AdministrationAppealFilterItem = {
  value: AdministrationAppealTypeAlias | 'all'
  label: string
}

export enum AdministrationAppealDocType {
  Contract = 'contract',
  Payment = 'znr',
  Act = 'act',
  Budget = 'plan',
  Estimate = 'estimate',
}

export type AdministrativeRequestType = {
  alias: string
  name: AdministrationAppealTypeAlias
}

export type AdministrationAppealDocument = {
  name: string
  type: AdministrationAppealDocType
  id: number
  status: DocumentStatuses
}

export type AdministrativeAppealItemType = {
  id: number
  request_number: string
  created_date: string // isoDate
  expiration_date: string // isoDate
  start_date: string // isoDate
  end_date: string // isoDate
  status: string
  task_type: AdministrationAppealTypeAlias
  attachments: RequestAttachment[]

  description: string
  executor: string
  completed: boolean

  is_read: boolean
  is_found_answer: boolean
  can_send_message: boolean
} & ({
  related_document_id: number
  related_document_name: string
  related_document_project_name: string
  related_document_sum: number
  related_document_type: DocumentAlias
} | {
  related_document_id: null
  related_document_name: null
  related_document_project_name: null
  related_document_sum: null
  related_document_type: null
})

export type GetAdministrativeAppealInfoParams = {
  request_type?: string[]
  from?: string
  to?: string
  status?: AdministrationAppealStatus[]
}

export type GetDocumentListParams = {
  task_type: string
  search_by_name?: string
}

export type GetDocumentListResponse = AdministrationAppealDocument[]

export type GetAdministrativeRequestTypesResponse = AdministrativeRequestType[]

export type GetAdministrativeAppealInfoResponse = {
  items: AdministrativeAppealItemType[]
  request_status: RequestStatuses[]
}

export type PostAppealFormParams = {
  target_document_id: number
  task_type?: AdministrationAppealTypeAlias
  subject: string
  description: string
  files?: File[]
  doc_type: AdministrationAppealDocType
}

export type CompleteTaskPayload = {
  id: number
  result: string
  files?: File[]
}

export type PostAppealFormResponse = AdministrativeAppealItemType
