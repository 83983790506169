import React from 'react'
import styled, { css } from 'styled-components'

import { TextMLight } from '../typography'
import { palette } from '../palette'

type Props = {
  label: string
  value: string | number
  name?: string
  isChecked?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  dataTestId: string
  onChange: (value: Props['value']) => void
}

export const Radio = React.forwardRef(({
  label,
  value,
  name,
  isChecked,
  isDisabled,
  isInvalid,
  dataTestId,
  onChange,
}: Props, ref?: React.ForwardedRef<HTMLLabelElement>) => (
  <Wrapper isDisabled={isDisabled} ref={ref}>
    <HiddenRadio
      value={value}
      name={name}
      disabled={isDisabled}
      checked={Boolean(isChecked)}
      data-testid={dataTestId}
      onChange={() => onChange(value)}
    />
    <CustomRadio isChecked={isChecked} isInvalid={isInvalid} />
    <Text isDisabled={Boolean(isDisabled)}>
      {label}
    </Text>
  </Wrapper>
))

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const Text = styled.div<Pick<Props, 'isDisabled'>>`
  ${TextMLight}
  padding-top: 2px;

  ${({ isDisabled }) => isDisabled
    && css`
      color: ${palette.grey70};
    `}
`

const CustomRadio = styled.div<Pick<Props, 'isChecked' | 'isInvalid'>>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 18px;
  height: 18px;
  border: 1px solid ${palette.grey50};
  background-color: ${palette.white};
  border-radius: 50%;
  transition: 0.15s linear;
  transition-property: background-color, border-color, border-width;

  ${HiddenRadio}:checked ~ & {
    border-color: ${palette.accent100};
    border-width: 5px;
  }

  ${HiddenRadio}:focus-visible ~ &::before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border: 2px solid ${palette.grey90};
    border-radius: 50%;
  }

  ${HiddenRadio}:disabled ~ & {
    border-color: ${palette.grey40};
    background-color: ${palette.grey30};
  }

  ${HiddenRadio}:checked:disabled ~ & {
    border-color: ${palette.grey30};
    background-color: ${palette.grey60};
  }

  ${({ isInvalid }) => isInvalid
    && css`
      border-color: ${palette.red100} !important;
    `}
`

const Wrapper = styled.label<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;

  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
  `}

  @media (hover: hover) {
    &:hover {
      ${HiddenRadio}:not(:disabled) ~ ${CustomRadio} {
        border-color: ${palette.grey60};
      }

      ${HiddenRadio}:checked:not(:disabled) ~ ${CustomRadio} {
        border-color: ${palette.accent90};
      }
    }
  }

  &:active {
    ${HiddenRadio}:not(:checked):not(:disabled) ~ ${CustomRadio} {
      border-color: ${palette.grey60};
      background-color: ${palette.grey20};
    }

    ${HiddenRadio}:checked:not(:disabled) ~ ${CustomRadio} {
      border-color: ${palette.accent80};
    }
  }
`
