import React from 'react'
import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react'
import { useStore } from 'effector-react'

import { NewIconButton, palette } from '@/ui'

import { $articlesList, getArticlesFx, openArticle } from '../../model/private'
import { ArticleCard, ArticleSkeleton } from '../parts'

const $ariclesForSwiper = $articlesList
  .map((list) => list?.slice(0, 9) ?? null)

export const ArticlesSlider = React.memo(() => {
  const swiperRef = React.useRef<SwiperClass>(null)
  const articles = useStore($ariclesForSwiper)
  const isListLoading = useStore(getArticlesFx.pending)

  const [index, setIndex] = React.useState(0)
  const handleSliderNext = () => {
    swiperRef.current?.slideNext()
  }

  const handleSliderPrev = () => {
    swiperRef.current?.slidePrev()
  }

  return (
    <SwiperContainer>
      {index !== 0 && !isListLoading && (
        <IconWrapper>
          <NewIconButton
            onClick={handleSliderPrev}
            dataTestId="prev-slide"
            backgroundColor="white"
            hoverBackgroundColor="white"
            color="grey60"
            hoverColor="grey90"
            borderRadius="50%"
            padding="12px"
            icon="arrowLeft"
            size={16}
          />
        </IconWrapper>
      )}
      {isListLoading
        ? <ArticleSkeleton />
        : (
          <Swiper
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
            slidesPerView={3}
            slidesPerGroup={3}
            spaceBetween={16}
            onSlideChange={(swiper) => {
              setIndex(swiper.activeIndex)
            }}
            onSwiper={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              swiperRef.current = swiper
            }}
          >
            {
              articles?.map(({
                title,
                description,
                created_at,
                publication_type,
                id,
                is_read,
              }) => (
                <SwiperSlide key={id}>
                  <ArticleCard
                    id={id}
                    onCardClick={openArticle}
                    date={created_at}
                    description={description}
                    title={title}
                    type={publication_type}
                    isRead={is_read}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
        )}

      {/* Зависит от количества отображаемых элементов */}
      {index !== 6 && !isListLoading && (
        <IconWrapper isRight onClick={handleSliderNext}>
          <NewIconButton
            dataTestId="next-slide"
            backgroundColor="white"
            hoverBackgroundColor="white"
            color="grey60"
            hoverColor="grey90"
            borderRadius="50%"
            padding="12px"
            icon="arrowRight"
            size={16}
          />
        </IconWrapper>
      )}
    </SwiperContainer>
  )
})

const SwiperContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  user-select: none;

  .swiper {
    width: 100%;
    height: 100%;
  }
`

const IconWrapper = styled.div<{isRight?: boolean}>`
  position: absolute;
  z-index: 2;
  transform: translate(-50%, 25%);
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0px ${palette.shadow};

  ${({ isRight }) => isRight && css`
    right: 0;
    transform: translate(50%, 25%);
  `}
`

