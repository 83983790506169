import React from 'react'
import styled from 'styled-components'

import { PaletteColor, palette } from '../../palette'
import { HeadL, TextM, TextS } from '../../typography'
import { LabelSize } from './types'

type Props = {
  text: string
  colorText: PaletteColor
  bgColor: PaletteColor
  borderColor?: PaletteColor
  size?: LabelSize
}

export const Label = ({
  bgColor, text, colorText, borderColor, size = 'M',
}: Props) => (
  <Wrapper size={size} bgColor={bgColor} colorText={colorText} borderColor={borderColor}>
    {text}
  </Wrapper>
)

const textSizes = {
  S: TextS,
  M: TextM,
  L: HeadL,
}

const Wrapper = styled.div<Omit<Props, 'text'>>`
  ${({ size }) => textSizes[size as LabelSize]};
  background-color: ${({ bgColor }) => palette[bgColor]};
  color: ${({ colorText }) => palette[colorText]};
  border: 1px solid;
  border-color: ${({ borderColor }) => (borderColor ? palette[borderColor] : 'transparent')};
  padding: ${({ size }) => (size !== 'S' ? '2px 7px' : '0 1px')};
  border-radius: ${({ size }) => (size !== 'S' ? '4px' : '2px')};
  transition: 0.15s linear;
  transition-property: color, background-color, border-color;
  width: fit-content;
`
