import React from 'react'

import Copy from '@/ui/icons/copy.svg'
import Success from '@/ui/icons/success.svg'
import Close from '@/ui/icons/close.svg'
import Burger from '@/ui/icons/burger.svg'
import Logout from '@/ui/icons/logout.svg'
import EmptyList from '@/ui/icons/empty-list.svg'
import Search from '@/ui/icons/search.svg'
import ArrowDown from '@/ui/icons/arrow-down.svg'
import Clear from '@/ui/icons/clear.svg'
import File from '@/ui/icons/file.svg'
import Calendar from '@/ui/icons/calendar.svg'
import ArrowLeft from '@/ui/icons/arrow-left.svg'
import Edit from '@/ui/icons/edit.svg'
import SaveSuccess from '@/ui/icons/save-success.svg'
import Download from '@/ui/icons/download.svg'
import ArrowRight from '@/ui/icons/arrow-right.svg'
import ArrowUp from '@/ui/icons/arrow-up.svg'
import Tree from '@/ui/icons/tree.svg'
import Folder from '@/ui/icons/folder.svg'
import Minus from '@/ui/icons/minus.svg'
import Plus from '@/ui/icons/plus.svg'
import CloseDrawer from '@/ui/icons/close-drawer.svg'
import Back from '@/ui/icons/back.svg'
import Cross from '@/ui/icons/cross.svg'
import ClearFilter from '@/ui/icons/clear-filter.svg'
import ActionSearch from '@/ui/icons/action-search.svg'
import ExpandList from '@/ui/icons/expand-list.svg'
import AcceptVolume from '@/ui/icons/accept-volume.svg'
import AcceptVolume16 from '@/ui/icons/accept-volume-16.svg'
import Notification from '@/ui/icons/notification.svg'
import Send from '@/ui/icons/send.svg'
import Dialog from '@/ui/icons/dialog.svg'
import DialogWithLabel from '@/ui/icons/dialog-with-label.svg'
import Attachment from '@/ui/icons/attachment.svg'
import Message from '@/ui/icons/message.svg'
import Basket from '@/ui/icons/basket.svg'
import MicroMessage from '@/ui/icons/micro-message.svg'
import Attention from '@/ui/icons/attention.svg'
import TelegramIcon from '@/ui/icons/telegram-icon.svg'
import ActConstructor from '@/ui/icons/act-constructor-icon.svg'
import RefreshIcon from '@/ui/icons/refresh-icon.svg'
import BellIcon from '@/ui/icons/bell-icon.svg'

import System1 from '@/ui/icons/computer.svg'
import System2 from '@/ui/icons/system2.svg'
import System3 from '@/ui/icons/system3.svg'
import System4 from '@/ui/icons/system4.svg'
import System5 from '@/ui/icons/system5.svg'
import System6 from '@/ui/icons/system6.svg'

import Subsystem from '@/ui/icons/subsystem-icon.svg'
import SubsystemLocked from '@/ui/icons/subsystem-locked.svg'

import ActDelete from '@/ui/icons/act-delete.svg'
import ActDialog from '@/ui/icons/act-dialog.svg'
import TooltipUnderConstruction from '@/ui/icons/tooltip-under-construction.svg'

import CheckboxChecked from '@/ui/icons/checkbox-checked.svg'
import ActInform from '@/ui/icons/act-inform.svg'
import PhysicalGood from '@/ui/icons/fo-good.svg'
import PhysicalBad from '@/ui/icons/fo-bad.svg'
import PhysicalCopy from '@/ui/icons/fo-copy.svg'
import PhysicalHelp from '@/ui/icons/fo-help.svg'
import Reload from '@/ui/icons/reload.svg'
import Additional from '@/ui/icons/additional-request.svg'
import PaymentModal from '@/ui/icons/payment-modal.svg'

import AdministrativeExample from '@/ui/icons/administrative-example.svg'
import AdministrativeAttention from '@/ui/icons/contracts/contract-attention.svg'
import SwitchOn from '@/ui/icons/switch-on.svg'
import SwitchOff from '@/ui/icons/switch-off.svg'
import SavePeopleVolume from '@/ui/icons/uv/save.svg'

import DocumentFlowSystemIcon from '@/ui/icons/document-flow-system-icon.svg'
import FundingSystemIcon from '@/ui/icons/funding-system-icon.svg'
import HelpSystemIcon from '@/ui/icons/help-system-icon.svg'
import PaymentSystemIcon from '@/ui/icons/payment-system-icon.svg'
import PhysicalSystemIcon from '@/ui/icons/physical-system-icon.svg'
import ReportingSystemIcon from '@/ui/icons/reporting-system-icon.svg'
import SettingSystemIcon from '@/ui/icons/setting-system-icon.svg'
import QuestionIcon from '@/ui/icons/question-icon.svg'

// план для бюджета
import PdbInformation from '@/ui/icons/pdb/information.svg'

// ув
import Comment from '@/ui/icons/uv/comment.svg'
import CommentHover from '@/ui/icons/uv/comment-hover.svg'
import CommentPlus from '@/ui/icons/uv/comment-plus.svg'
import CommentPlusHover from '@/ui/icons/uv/comment-plus-hover.svg'
import ReloadFilter from '@/ui/icons/uv/reload.svg'

import HelpIcon from '@/ui/icons/help-icon.svg'
import AccordionArrow from '@/ui/icons/accordion-arrow.svg'

import CommentIcon from '@/ui/icons/comment-icon.svg'
import AnswerIcon from '@/ui/icons/answer-icon.svg'
// выполнение
import TitleHelp from '@/ui/icons/act/title-help.svg'
import TemplateUpload from '@/ui/icons/act/template-upload.svg'
import TemplateDownLoad from '@/ui/icons/act/template-download.svg'
import ResponsibleNames from '@/ui/icons/act/responsible-names.svg'
import AbuseManager from '@/ui/icons/act/abuse-manager.svg'
import AbuseSecurity from '@/ui/icons/act/abuse-security.svg'
import ActDeductionAdd from '@/ui/icons/act/deduction-add.svg'
import ActDeductionRemove from '@/ui/icons/act/deduction-remove.svg'

import HomeIcon from '@/ui/icons/home-icon.svg'
import EditSmeta from '@/ui/icons/edit-smeta.svg'

// список договоров
import ContractIcon1 from '@/ui/icons/contract-list-icon1.svg'
import ContractIcon2 from '@/ui/icons/contract-list-icon2.svg'
import ContractIcon3 from '@/ui/icons/contract-list-icon3.svg'
import ContractIcon4 from '@/ui/icons/contract-list-icon4.svg'
import FilterIcon from '@/ui/icons/contracts/contract-filter.svg'

import PdfFileIcon from '@/ui/icons/pdf-file-icon.svg'
import FixedDownloadIcon from '@/ui/icons/fixed-donwload.svg'
import FixedClose from '@/ui/icons/fixed-close.svg'
import FolderIcon from '@/ui/icons/folder-icon.svg'

import ClearInputIcon from '@/ui/icons/clear-input-icon.svg'
import SliderPrevArrow from '@/ui/icons/slider-prev-arrow.svg'
import SliderNextArrow from '@/ui/icons/slider-next-arrow.svg'
import TableExpandIcon from '@/ui/icons/table-expand-icon.svg'

import CheckIcon from '@/ui/icons/check-icon.svg'
import DownloadFileIcon from '@/ui/icons/download-icon.svg'

import AdministrativeTaskIcon from '@/ui/icons/administrative-task-icon.svg'
import ExtraJobIcom from '@/ui/icons/extra-job.svg'
import ChangeConditions from '@/ui/icons/change-conditions.svg'
import EnvelopeIcon from '@/ui/icons/envelope-icon.svg'

import HelpEmail from '@/ui/icons/help/email.svg'
import HelpPhone from '@/ui/icons/help/phone.svg'
import HelpPhoneBold from '@/ui/icons/help/phone-bold.svg'
import HelpWhatsapp from '@/ui/icons/help/whatsapp.svg'
import HelpTelegram from '@/ui/icons/help/telegram.svg'
import InputWarningIcon from '@/ui/icons/input-warning-icon.svg'
import ErrorNotificationIcon from '@/ui/icons/error-notification-icon.svg'
import ApproveActIcon from '@/ui/icons/approve-act-icon.svg'
import NotFinancialIcon from '@/ui/icons/not-financial.svg'
import SignInDiadoc from '@/ui/icons/sign-in-diadoc.svg'
import NewArticleTag from '@/ui/icons/new_article_tag.svg'
import LikeIcon from '@/ui/icons/like-icon.svg'
import DislikeIcon from '@/ui/icons/dislike-icon.svg'
import EyeIcon from '@/ui/icons/eye-icon.svg'

import LeftArticleArrow from '@/ui/icons/left-article-arrow.svg'
import RightArticleArrow from '@/ui/icons/right-article-arrow.svg'

import ActiveTaskCount from '@/ui/icons/mainpage/unread-news.svg'

export const Icons = {
  'title-help': TitleHelp,
  remove: Basket,
  'micro-message': MicroMessage,
  'expand-list': ExpandList,
  attachment: Attachment,
  success: Success,
  dialog: Dialog,
  'dialog-With-Label': DialogWithLabel,
  send: Send,
  copy: Copy,
  notification: Notification,
  close: Close,
  logout: Logout,
  burger: Burger,
  search: Search,
  message: Message,
  'empty-list': EmptyList,
  'arrow-down': ArrowDown,
  clear: Clear,
  file: File,
  calendar: Calendar,
  'arrow-left': ArrowLeft,
  'save-success': SaveSuccess,
  download: Download,
  tree: Tree,
  'accept-volume': AcceptVolume,
  'accept-volume-16': AcceptVolume16,
  edit: Edit,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  folder: Folder,
  minus: Minus,
  plus: Plus,
  'close-drawer': CloseDrawer,
  back: Back,
  'action-search': ActionSearch,
  'clear-filter': ClearFilter,
  cross: Cross,
  attention: Attention,

  system1: System1,
  system2: System2,
  system3: System3,
  system4: System4,
  system5: System5,
  system6: System6,

  'subsystem-icon': Subsystem,
  'subsystem-locked': SubsystemLocked,

  'act-delete': ActDelete,
  'act-dialog': ActDialog,
  'tooltip-under-construction': TooltipUnderConstruction,

  'checkbox-checked': CheckboxChecked,
  'act-inform': ActInform,

  'fo-good': PhysicalGood,
  'fo-bad': PhysicalBad,
  'fo-copy': PhysicalCopy,
  'fo-help': PhysicalHelp,

  reload: Reload,
  additional: Additional,

  'payment-modal': PaymentModal,

  'administrative-example': AdministrativeExample,
  'administrative-attention': AdministrativeAttention,
  'switch-on': SwitchOn,
  'switch-off': SwitchOff,

  'pdb-information': PdbInformation,
  'put-people-volume': SavePeopleVolume,
  comment: Comment,
  'comment-hover': CommentHover,
  'comment-plus': CommentPlus,
  'comment-plus-hover': CommentPlusHover,
  'reload-filter': ReloadFilter,

  'template-upload': TemplateUpload,
  'template-download': TemplateDownLoad,
  'responsible-names': ResponsibleNames,
  'help-icon': HelpIcon,
  'telegram-icon': TelegramIcon,
  'document-flow-system-icon': DocumentFlowSystemIcon,
  'funding-system-icon': FundingSystemIcon,
  'help-system-icon': HelpSystemIcon,
  'payment-system-icon': PaymentSystemIcon,
  'physical-system-icon': PhysicalSystemIcon,
  'reporting-system-icon': ReportingSystemIcon,
  'setting-system-icon': SettingSystemIcon,

  'contract-list-answer': ContractIcon1,
  'contract-list-send-numbers': ContractIcon2,
  'contract-list-decision': ContractIcon3,
  'contract-list-sign': ContractIcon4,
  'act-constructor-icon': ActConstructor,
  'question-icon': QuestionIcon,
  'accordion-arrow': AccordionArrow,
  'comment-icon': CommentIcon,
  'answer-icon': AnswerIcon,
  'home-icon': HomeIcon,
  'edit-smeta': EditSmeta,
  'refresh-icon': RefreshIcon,
  'pdf-file-icon': PdfFileIcon,
  'fixed-donwload': FixedDownloadIcon,
  'fixed-close': FixedClose,
  'folder-icon': FolderIcon,
  'clear-input-icon': ClearInputIcon,
  'slider-prev-arrow': SliderPrevArrow,
  'slider-next-arrow': SliderNextArrow,
  'contract-filter': FilterIcon,
  'abuse-manager': AbuseManager,
  'abuse-security': AbuseSecurity,
  'check-icon': CheckIcon,
  'download-icon': DownloadFileIcon,
  'table-expand-icon': TableExpandIcon,
  'administrative-task-icon': AdministrativeTaskIcon,
  'deduction-add': ActDeductionAdd,
  'deduction-remove': ActDeductionRemove,
  'extra-job': ExtraJobIcom,
  'change-conditions': ChangeConditions,

  'help-email': HelpEmail,
  'help-phone': HelpPhone,
  'help-phone-bold': HelpPhoneBold,
  'help-whatsapp': HelpWhatsapp,
  'help-telegram': HelpTelegram,

  envelope: EnvelopeIcon,
  'input-warning-icon': InputWarningIcon,
  'notification-error': ErrorNotificationIcon,
  'approve-act-icon': ApproveActIcon,
  'not-financial': NotFinancialIcon,
  'bell-icon': BellIcon,
  'sign-in-diadoc': SignInDiadoc,
  'new-article-tag': NewArticleTag,
  'like-icon': LikeIcon,
  'dislike-icon': DislikeIcon,
  'eye-icon': EyeIcon,
  'left-article-arrow': LeftArticleArrow,
  'right-article-arrow': RightArticleArrow,
  'active-task-count': ActiveTaskCount,
}

type Props = {
  icon: IconName
  size?: number
  onClick?: () => void
}

export type IconName = keyof typeof Icons

export const Icon = ({
  icon,
  size,
  onClick,
}: Props) => {
  const Component = Icons[icon]
  return (
    <Component
      size={size}
      onClick={onClick}
    /* onon */
    />
  )
}
