import React from 'react'
import { useGate, useStore } from 'effector-react'

import { DetailsInfo, EditDetailsForm } from '../containers'
import { $isEditing, DetailsGate } from '../../model/private'

export const Details = React.memo(() => {
  const isEditing = useStore($isEditing)

  useGate(DetailsGate)

  return (
    <>
      {isEditing ? <EditDetailsForm /> : <DetailsInfo />}
    </>
  )
})
