import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { AdministrationAppealTypeAlias, DocumentStatuses, RejectionReason } from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { useTooltip } from '@/lib/hooks'

import { PaletteColor, palette } from '../../palette'
import { Icon2, IconName2 } from '../../icons'
import { ExpandableText } from '../ExpandableText'
import { TextMLight } from '../../typography'
import { ToolTipV2 } from '../tooltip'

type Props = {
  status: DocumentStatuses
  rejectionReason?: RejectionReason
  cancelComment?: {
    description: string
    help_description: string
  } | null
}

export const RejectionInfo = ({ status, rejectionReason, cancelComment }: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip()

  const Link = React.useCallback(({ children, to }) => (
    <NavLink
      ref={refs.setReference}
      {...getReferenceProps()}
      to={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </NavLink>
  ), [refs, getReferenceProps])

  // в документах большая мешанина из разных полей с бэка, которые рендерятся почти в одно и то же
  const info = React.useMemo(() => {
    if (cancelComment) {
      return {
        icon: 'alert' as IconName2,
        iconColor: 'red100' as PaletteColor,
        text: cancelComment.description,
        additionalText: cancelComment.help_description,
      }
    }
    if (rejectionReason) {
      let text = ''

      if (rejectionReason === 'string') {
        text = rejectionReason
      } else if (typeof rejectionReason !== 'string') {
        if (rejectionReason.author) {
          text = `${rejectionReason.message.replace(/\.$/, '')}. Автор — ${rejectionReason.author}.`
        } else {
          text = rejectionReason.message
        }
      }

      return {
        icon: status === DocumentStatuses.Declined ? 'alert' : 'warning' as IconName2,
        iconColor: status === DocumentStatuses.Declined ? 'red100' : 'accent100' as PaletteColor,
        text,
        additionalText: (
          <>
            Если у вас есть вопросы по документу,
            {' '}
            <Link to={`${RouterPaths.NewAdministrationAppeal}/${AdministrationAppealTypeAlias.Contract}`}>
              обратитесь к ответственному по договору
            </Link>
          </>
        ),
        tooltip: 'Создать запрос на ответственного по договору',
      }
    }
  }, [status, cancelComment, rejectionReason, Link])

  if (!info) return null

  return (
    <ReasonWrapper>
      <Reason>
        <Icon2
          icon={info.icon}
          size={16}
          color={info.iconColor}
        />
        <ExpandableText
          text={info.text}
          lineCount={5}
        />
      </Reason>

      {info.additionalText && (
        <AdditionalText>
          {info.additionalText}
        </AdditionalText>
      )}

      {info.tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          isOpen={isOpen}
          refs={refs}
          getFloatingProps={getFloatingProps}
          size="M"
        >
          {info.tooltip}
        </ToolTipV2>
      )}
    </ReasonWrapper>
  )
}

const ReasonWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

const Reason = styled.div`
  display: flex;
  gap: 4px;
`

const AdditionalText = styled.div`
  ${TextMLight}
  color: ${palette.grey70};

  @media (hover: hover) {
    a:hover {
      text-decoration: underline;
    }
  }
`
