import { Domain, Effect, sample } from 'effector'

import { root } from '@/root-domain'

type Params<P extends { items: any[] }, R extends { items: any[] }, E = Error> = {
  fetchEffect: Effect<P, R, E>
  itemsPerRequest?: number
  domain?: Domain
}

// TODO доработать, пофиксить типы
export const createChunkRequest = <
  P extends { items: any[] },
  R extends { items: any[] },
  E = Error
>({
    fetchEffect,
    itemsPerRequest = 20,
    domain,
  }: Params<P, R, E>) => {
  const d = domain || root.domain()

  const effectFx = d.effect<P, R, E>()

  const $failedItemCount = d.store(0)
  const chunkFailed = d.event<number>()
  const requestEndedWithErrors = d.event<{ failed: number; total: number }>()

  const $counter = d.store(0)
  const incrementCounter = d.event<number>()

  const $totalItems = d.store(0)
  const setTotalItems = d.event<number>()

  $counter
    .on(incrementCounter, (counter, count) => counter + count)
    .reset(effectFx)

  $failedItemCount
    .on(chunkFailed, (counter, count) => counter + count)
    .reset(effectFx, requestEndedWithErrors)

  $totalItems
    .on(setTotalItems, (_, count) => count)
    .reset(effectFx)

  sample({
    clock: effectFx.finally,
    source: {
      failed: $failedItemCount,
      total: $totalItems,
    },
    filter: ({ failed }) => Boolean(failed),
    target: requestEndedWithErrors,
  })

  // @ts-ignore
  effectFx.use(async (payload) => {
    const { items } = payload
    const requestAmount = Math.ceil(items.length / itemsPerRequest)
    const updatedItems: any[] = []
    let data = {}

    setTotalItems(items.length)

    for (let i = 0; i < requestAmount; i += 1) {
      const chunk = items.slice(itemsPerRequest * i, itemsPerRequest * (i + 1))

      await fetchEffect({ ...payload, items: chunk })
      // eslint-disable-next-line no-loop-func
        .then((res) => {
          const { items, ...otherData } = res
          updatedItems.push(...items)
          data = otherData
          incrementCounter(res.items.length)
        })
        .catch(() => chunkFailed(chunk.length))
    }
    return {
      ...data,
      items: updatedItems,
    }
  })

  return {
    effectFx,
    chunkFailed,
    requestEndedWithErrors,
    $counter,
    $totalItems,
  }
}
