import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { $appealTypes } from '@/features/support/shared-model'
import { RouterPaths } from '@/features/app/model'
import { NewButton } from '@/ui'

import { TypeItem } from '../parts/TypeItem'
import { cancelButtonClicked } from '../../model/private'
import { Title } from '../parts'

export const SelectAppealType = React.memo(() => {
  const types = useStore($appealTypes)

  return (
    <Content>
      <Title
        step={1}
        title="Выберите тему обращения"
      />
      <TypeList>
        {types.map(({ alias, name }) => (
          <TypeItem
            link={`${RouterPaths.CreateSupportAppeal}/${alias}`}
            key={alias}
            alias={alias}
            text={name}
          />
        ))}
      </TypeList>
      <ButtonRow>
        <NewButton
          dataTestId="go-back-button"
          label="Отменить"
          buttonType="grey"
          onClick={cancelButtonClicked}
        />
      </ButtonRow>
    </Content>
  )
})

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TypeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`
