import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { SelectItemButton } from '@/ui'

import { $paymentInfo } from '../../../model/private'

export const DocsForPayment = React.memo(() => {
  const info = useUnit($paymentInfo)

  if (!info) return null

  return (
    <Link href={info.printable_form_link} data-testid="download-docs-for-payment-link" download>
      <SelectItemButton
        text="Документы для оплаты"
        icon="download"
        buttonStyle="secondary"
        hasBorder={false}
        dataTestId="download-docs-for-payment-btn"
      />
    </Link>
  )
})

const Link = styled.a`
  display: block;
`
