import { sample, split } from 'effector'

import {
  DocumentAlias,
  SupportAppealTypeAlias,
  isApproveError,
  isCreateActConflictError,
  isCreateActReqieredFieldError,
  isTextError,
} from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { getDocumentNameByType } from '@/lib/documentResolvers'

import {
  $actModalError,
  onActTextError,
  onAproveActError,
  onCreateActReqieredFieldError,
  onCreateConflictError,
  setActError,
} from './private'
import { clearActError, onActError } from './public'

$actModalError
  .on(setActError, (_, error) => error)
  .reset(clearActError)

sample({
  clock: onActTextError,
  fn: ({ data }) => ({
    title: data.detail,
  }),
  target: setActError,
})

sample({
  clock: onAproveActError,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.ActPage}/${data.id}`,
      text: `${getDocumentNameByType(DocumentAlias.ACT)} ${data.number}`,
    }],
    title: 'Отправка на согласование невозможна',
    body: 'Запуск документа на согласование не возможен. Дождитесь результата согласования',
  }),
  target: setActError,
})

sample({
  clock: onCreateConflictError,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.ActPage}/${data.id}`,
      text: `${getDocumentNameByType(DocumentAlias.ACT)} ${data.number}`,
    }],
    body: data.detail,
  }),
  target: setActError,
})

sample({
  clock: onCreateActReqieredFieldError,
  filter: Boolean,
  fn: ({ data }) => ({
    link: [{
      href: `${RouterPaths.MyCompany}?id=${data.detail.contragent_id}`,
      text: 'Моя компания',
    }, {
      href: `${RouterPaths.CreateSupportAppeal}/${SupportAppealTypeAlias.LKP_ERRORS}`,
      text: 'техническую поддержку',
    }],
    body: data.detail.message,
    title: 'Создать выполнение невозможно',
  }),
  target: setActError,
})

const cases = {
  onAproveActError,
  onActTextError,
  onCreateConflictError,
  onCreateActReqieredFieldError,
  __: setActError.prepend(() => ({
    body: 'Обратитесь в тех.поддержку',
    title: 'Неизвестная ошибка',
  })),
}

split({
  source: onActError,
  match: {
    onAproveActError: isApproveError,
    onActTextError: isTextError,
    onCreateConflictError: isCreateActConflictError,
    onCreateActReqieredFieldError: isCreateActReqieredFieldError,
  },
  cases,
})
