import { forward } from 'effector'

import { fetchAuthDataFx } from '@/features/login/model'
import { createToast } from '@/features/toast-service/model'

import {
  $checkedRequest,
  $isSubscribe,
  getSubscribe,
  postMaintenanceFx,
  resetCheckedRequest,
  setCheckedRequest,
} from './private'

$isSubscribe.on(getSubscribe, () => true)

$checkedRequest
  .on(setCheckedRequest, () => true)
  .reset(resetCheckedRequest)

forward({
  from: getSubscribe,
  to: postMaintenanceFx,
})

forward({
  from: fetchAuthDataFx.done,
  to: setCheckedRequest,
})

createToast({
  effect: postMaintenanceFx,
})
