import { forward, sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { EstimateDoc, EstimateTableRow } from '@/dal'
import {
  $splitTarget,
  $splitTargetId,
  closeModal,
  splitVolumeForm,
  splitVolumeFx,
} from './split-volume.private'
import { openSplitVolumeModal } from './split-volume.public'
import { onSplitItemVolume } from './public'
import { $documentId, onUpdateSummary } from '../../shared-model'
import { $isTreeTable } from './private'

$splitTargetId
  .on(openSplitVolumeModal, (_, id) => id)
  .reset(closeModal)

forward({
  from: [
    splitVolumeForm.fields.new_material.$value,
    splitVolumeForm.fields.new_work.$value,
  ],
  to: [
    splitVolumeForm.fields.new_material.resetErrors,
    splitVolumeForm.fields.new_work.resetErrors,
  ],
})

sample({
  clock: openSplitVolumeModal,
  source: $splitTarget,
  filter: Boolean,
  fn: (item) => ({
    old_count_in_estimate: item.count_in_estimate?.value_current ?? '',
    // TODO поменять на новую работу с файлами, когда будет готов бэк
    old_files: [] as File[],
    old_justification_of_changes_current: item.justification_of_changes?.value ?? '',
  }),
  target: splitVolumeForm.set,
})

sample({
  clock: closeModal,
  target: splitVolumeForm.reset,
})

sample({
  clock: splitVolumeForm.formValidated,
  source: {
    itemId: $splitTargetId,
    documentId: $documentId,
    is_tree: $isTreeTable,
  },
  filter: ({ itemId, documentId }) => Boolean(itemId && documentId),
  fn: ({ documentId, is_tree, itemId }, form) => ({
    ...form,
    documentId: documentId as number,
    itemId: itemId as EstimateTableRow['id'],
    is_tree,
  }),
  target: splitVolumeFx,
})

sample({
  clock: splitVolumeFx.done,
  fn: ({ params, result }) => ({
    ...result,
    documentId: params.documentId,
  }),
  target: [onSplitItemVolume, closeModal],
})

sample({
  clock: splitVolumeFx.done,
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({ docId: params.documentId, summary: result.summary as EstimateDoc['summary_table'] }),
  target: onUpdateSummary,
})

createToast({
  effect: splitVolumeFx,
  doneText: 'Объемы разделены',
})
