import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../palette'
import { TextLLight } from '../typography'

type Props = {
  items: {
    value: any,
    label: string
  }[]
  checkedVal: string
  onChange: (val: any) => void
  radioSetName: string
  disabled?: boolean
}

export const RadioButtons = ({
  checkedVal,
  items,
  onChange,
  radioSetName,
  disabled,
}: Props) => {
  return (
    <Switches>
      {items.map((item, i) => {
        const isPrevActive = items[i - 1]?.value === checkedVal
        const isNextActive = items[i + 1]?.value === checkedVal
        const isChecked = item.value === checkedVal

        return (
          <SwitchWrapper
            isNextActive={isNextActive}
            isPrevActive={isPrevActive}
            key={item.value}
            isChecked={isChecked}
            disabled={disabled}
          >
            <SwitchLabel>
              {item.label}
            </SwitchLabel>
            <SwitchInput
              value={item.value}
              onChange={(e) => onChange(e.target.value)}
              checked={isChecked}
              name={radioSetName}
              disabled={disabled}
            />
          </SwitchWrapper>
        )
      })}
    </Switches>
  )
}


const SwitchInput = styled.input.attrs({
  type: 'radio',
})`
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    `

const Switches = styled.div`
    display: flex;
    width: 100%;
    background-color: ${palette.white};
    `

const SwitchWrapper = styled.label<{
  isChecked: boolean,
  isNextActive: boolean,
  isPrevActive: boolean,
  disabled?: boolean
}>`
    position: relative;
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${palette.grey50};
    border-left: 0px;
    cursor: pointer;
    transition: 0.15s linear;
    transition-delay: background-color border-color;
    flex-grow: 1;

    ${({ disabled }) => disabled && css`
      cursor: not-allowed;
    `}

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid;
      border-color: ${palette.grey50};
    }

    &:last-child {
      border-color: ${palette.grey50};
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:nth-child(2):last-child {
      border-color: ${palette.grey50};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media (hover: hover) {
      &:hover {
        border-color: ${palette.accent30} !important;
        background-color: ${palette.accent10} !important;
      }
    }

    ${({ isPrevActive }) => isPrevActive && css`
      border-left: 0px !important;
    `}

    ${({ isNextActive }) => isNextActive && css`
      border-right: 0px !important;
    `}

    ${({ isChecked }) => isChecked && css`
    background-color: ${palette.accent10} !important;
    border: 1px solid ${palette.accent80} !important;

    @media (hover: hover) {
      &:hover {
        background-color: ${palette.accent10} !important;
        border: 1px solid ${palette.accent80} !important;
      }
    }
  `}
    `

const SwitchLabel = styled.div`
    ${TextLLight}
    `
