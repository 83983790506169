import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { themeVar } from '@/ui/theming'
import { numberWithSpaces } from '@/lib/number-represent'
import { MediumTextCSS, PopperToolTip, SimpleLoader } from '@/ui'

import { statusResolve } from '@/lib/status-resolver'
import { $popupData, getPopupDataFx } from '../../model/private'

type Props = {
  setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  getTooltipProps: (params?: any) => any
  getArrowProps: (params?: any) => any
  visible: boolean
}

export const PrepaidPopup = React.memo((props: Props) => {
  const popupData = useStore($popupData)
  const isPending = useStore(getPopupDataFx.pending)

  return (
    <PopperToolTip
      {...props}
      tooltip={(
        <Wrapper>
          <Header>Детализация прогноза</Header>
          {isPending
            ? (
              <LoaderWrapper>
                <SimpleLoader sizePx={30} />
              </LoaderWrapper>
            )
            : Boolean(popupData.length) && (
              <List>
                {popupData.map((item, index) => (
                  <Item key={index}>
                    <div>{statusResolve(item.status)}</div>
                    <Price>{numberWithSpaces(item.count)}</Price>
                  </Item>
                ))}
              </List>
            )}
        </Wrapper>
      )}
    />
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`

const LoaderWrapper = styled.div`
  margin: 0 auto;
`

const Header = styled.div`
  ${MediumTextCSS}
  padding: 8px 0;
`

const List = styled.div`
  margin-top: 8px;
`

const Item = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  ${MediumTextCSS}
  color: ${themeVar('textGray')};

  &:nth-child(odd) {
    background-color: ${themeVar('mainBackground')};
  }
`

const Price = styled.div`
  ${MediumTextCSS}
`
