import React from 'react'
import styled from 'styled-components'
import { DateRange } from 'react-day-picker'

import { formatDateForBackend, getDayJsObject } from '@/lib/date'

import { DateInputCommonProps } from '../types'
import { DateInputWrapper } from './DateInputWrapper'
import { NewButton } from '../../buttons'

const getDateByString = (date?: string) => (
  date && getDayJsObject(date).isValid() ? new Date(date) : undefined
)

const formatDateRange = (value: Props['inputValue']) => ({
  from: getDateByString(value.from),
  to: getDateByString(value.to),
})

type Props = DateInputCommonProps & {
  inputValue: {
    from: string
    to: string
  }
  onChangeFrom: (e: string) => void
  onChangeTo: (e: string) => void
}

export const DateRangeInput = ({
  inputValue,
  onClear,
  onChangeFrom,
  onChangeTo,
  ...props
}: Props) => {
  const [hasToClose, setHasToClose] = React.useState(false)
  const [dateRange, setDateRange] = React.useState<DateRange>(formatDateRange(inputValue))

  const formattedValue = React.useMemo(() => {
    const { from, to } = inputValue
    if (!(from && to)) return ''

    const start = getDayJsObject(from).format('DD/MM/YYYY')
    const end = getDayJsObject(to).format('DD/MM/YYYY')
    return `от ${start} до ${end}`
  }, [inputValue])

  const handleDateRangeSelect = (_, newDate: Date) => {
    if (dateRange.to || !dateRange.from) {
      setDateRange({ from: newDate })
    } else if (dateRange.from > newDate) {
      setDateRange({ from: newDate, to: dateRange.from })
    } else {
      setDateRange({ ...dateRange, to: newDate })
    }
  }

  const handleOpen = () => {
    setDateRange(formatDateRange(inputValue))
  }

  const handleClose = () => {
    setDateRange({ from: undefined })
    setHasToClose(false)
  }

  const handleClear = React.useCallback(() => {
    onChangeFrom('')
    onChangeTo('')
    onClear?.()
    setHasToClose(true)
  }, [onChangeFrom, onChangeTo, onClear, setHasToClose])

  const handleSubmit = React.useCallback(() => {
    if (dateRange.from && dateRange.to) {
      onChangeFrom(formatDateForBackend(dateRange.from))
      onChangeTo(formatDateForBackend(dateRange.to))
      setHasToClose(true)
    }
  }, [dateRange, onChangeFrom, onChangeTo, setHasToClose])

  const Footer = React.useCallback(() => (
    <ButtonsRow>
      <NewButton
        label="Применить"
        isDisabled={!(dateRange.from && dateRange.to)}
        dataTestId="save-date-range-btn"
        size="S"
        isFill
        onClick={handleSubmit}
      />
      <NewButton
        label="Сбросить"
        buttonType="grey"
        dataTestId="reset-date-range-btn"
        size="S"
        isFill
        onClick={handleClear}
      />
    </ButtonsRow>
  ), [dateRange, handleSubmit, handleClear])

  return (
    <DateInputWrapper
      {...props}
      mode="range"
      formattedValue={formattedValue}
      inputValue={inputValue}
      selected={dateRange}
      noOpenOnClear
      onRangeSelect={handleDateRangeSelect}
      footerSlot={<Footer />}
      hasToClose={hasToClose}
      onClear={handleClear}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}

const ButtonsRow = styled.div`
  display: flex;
  gap: 12px;
  margin-top: -12px;
  padding: 0 24px 20px;
`
