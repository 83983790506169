import React from 'react'

import { SwiperWrapper } from '../SwiperWrapper'
import { PillowButton } from './PillowButton'

export type PillowSwiperItem = {
  label: string
  id: number | string | null
}

type Props = {
  items: PillowSwiperItem[]
  activeId: PillowSwiperItem['id']
  onItemClick: (id: PillowSwiperItem['id']) => void
  disableArrow?: boolean
}

export const PillowSwiper = ({
  activeId,
  items,
  onItemClick,
  disableArrow,
}: Props) => {
  const renderItemFunc = React.useCallback((item: PillowSwiperItem) => (
    <PillowButton
      isActive={activeId === item.id}
      label={item.label}
      id={item.id}
      onClick={onItemClick}
    />
  ), [activeId, items, onItemClick])

  return (
    <SwiperWrapper
      items={items}
      disableArrow={disableArrow}
      itemRenderFn={renderItemFunc}
    />
  )
}
