import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import {
  Icon2,
  NewIconButton,
  TextM,
  palette,
} from '@/ui'

import { $openedArticle, openShareModal, reactionButtonClicked } from '../../model/private'
import { DISLIKE_REACTION, LIKE_REACTION } from '../../model/const'
import { ReadButton } from './ReadButton'
import { ShareButton } from '../parts'

const $articleInfo = $openedArticle.map((item) => {
  if (!item) return null
  return {
    reactions: item.reactions,
    total_views: item.total_views,
  }
})

export const ArticleFooter = React.memo(() => {
  const articleInfo = useStore($articleInfo)

  if (!articleInfo) return null
  const {
    reactions,
    total_views,
  } = articleInfo

  const imLike = reactions.my === LIKE_REACTION
  const imNotLike = reactions.my === DISLIKE_REACTION

  return (
    <Wrapper>
      <LeftCol>
        <Info>
          <IconWrapper>
            <NewIconButton
              dataTestId="like-button"
              icon="likeIcon"
              color={imLike ? 'accent100' : 'grey50'}
              hoverColor={imLike ? 'accent100' : 'accent90'}
              onClick={() => reactionButtonClicked(true)}
              size={20}
            />
            <IconText isActive={imLike}>{reactions.summary[LIKE_REACTION]}</IconText>
          </IconWrapper>
          <IconWrapper>
            <NewIconButton
              dataTestId="dislike-button"
              color={imNotLike ? 'accent100' : 'grey50'}
              hoverColor={imNotLike ? 'accent100' : 'accent90'}
              icon="dislikeIcon"
              onClick={() => reactionButtonClicked(false)}
              size={20}
            />
            <IconText isActive={imNotLike}>{reactions.summary[DISLIKE_REACTION]}</IconText>
          </IconWrapper>
          <IconWrapper>
            <Icon2
              icon="openEye"
              color="grey50"
              size={20}
            />
            <IconText isActive={false}>{total_views}</IconText>
          </IconWrapper>
        </Info>
        <ShareButton onClick={openShareModal} />
      </LeftCol>

      <ReadButton />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 8px 32px;
  background-color: ${palette.white};
  border-top: 1px solid ${palette.grey50};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

const LeftCol = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
`

const Info = styled.div`
  display: flex;
  gap: 20px;
`

const IconWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const IconText = styled.div<{isActive: boolean}>`
  ${TextM}
  height: min-content;
  transition: color 0.15s linear;
  color: ${({ isActive }) => (isActive ? palette.accent100 : palette.grey60)};
`

