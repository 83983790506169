import React from 'react'
import { useStore } from 'effector-react'

import {
  NotificationItem,
  NotificationType,
} from '@/ui'
import { $hasError } from '../../model/private'

export const ErrorNotification = React.memo(() => {
  const hasError = useStore($hasError)

  if (!hasError) return null

  return (
    <NotificationItem
      type={NotificationType.Alert}
      title="Ошибка в табличной части"
      body="В отредактированной смете есть ошибки, вернитесь к таблице и исправьте их. Обратите внимание, что уже примерен фильтр «Ошибка в строке»."
    />
  )
})


