import { attach } from 'effector'

import {
  getActInfoReqFx,
  getActFeaturesReqFx,
  ActDTO,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { ActGate } from './public'
import { d } from './domain'

export const {
  requestFx: getActInfoFx,
} = createSingleEffect(getActInfoReqFx, { abortEvents: [ActGate.close] })

export const updateFeaturesFx = attach({ effect: getActFeaturesReqFx })

export const updateDocumentInfo = d.event<ActDTO>()
