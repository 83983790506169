import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import {
  Button,
  Divider,
  H1TitleCSS,
  IconButton,
  MediumTextCSS,
  MonthInput,
  themeVar,
} from '@/ui'

import { $openedFundPlan } from '@/features/funding-schedule/plan-page/plan-info/model'
import { $availableMonths } from '@/features/funding-schedule/shared-model'
import {
  planEditPeriodForm,
  closePlanEditPeriodModal,
  editPlanPeriodFx,
} from '../../model/private'

export const PlanEditPeriodForm = () => {
  const openedFundPlan = useStore($openedFundPlan)
  const loading = useStore(editPlanPeriodFx.pending)
  const availableMonths = useStore($availableMonths)
  const { fields, submit } = useForm(planEditPeriodForm)

  const planMonth = new Date(openedFundPlan?.date_start ?? '')
  const includeDates = availableMonths ? [planMonth, ...availableMonths] : [planMonth]

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  if (!openedFundPlan) {
    return null
  }

  return (
    <Container>
      <Header>

        <Title>
          Обновить месяц планирования
        </Title>

        <IconButton
          dataTestId="modal-cross-close"
          icon="cross"
          size={30}
          onClick={() => closePlanEditPeriodModal()}
        />

      </Header>

      <ContractName>{openedFundPlan.contract.contract_name}</ContractName>

      <Divider />

      <HelpHeader>Укажите период для бюджета</HelpHeader>
      <form onSubmit={handleSubmit}>
        <Row>
          <MonthInput
            dataTestId="edit-plan-input"
            label="Месяц и год"
            fullWidth
            inputValue={fields.date.value}
            onChange={fields.date.onChange}
            errorText={fields.date.errorText()}
            placeholder="Выберите месяц"
            includeDates={includeDates}
          />
        </Row>

        <ButtonRow>

          <Button
            styleButton="third"
            dataTestId="close-cancel-agreed-contractor-modal"
            size="L"
            onClick={closePlanEditPeriodModal}
          >
            Отмена
          </Button>

          <Button
            styleButton="primary"
            size="L"
            dataTestId="sumbit-cancel-agreed-contractor"
            type="submit"
            isLoading={loading}
          >
            Обновить план
          </Button>

        </ButtonRow>

      </form>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Title = styled.div`
  ${H1TitleCSS}
`

const ContractName = styled.div`
  ${MediumTextCSS}
  color: ${themeVar('textGray')};
`

const HelpHeader = styled.div`
  ${MediumTextCSS}
  margin-bottom: 16px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 32px;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;;
`

