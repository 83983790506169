import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import {
  ModalLayoutV2, palette,
} from '@/ui'
import {
  $isModalOpen, $openedService, closeModal,
} from '../../model/private'
import { AllServices } from './AllServices'
import { ServiceLinks } from './ServiceLinks'

export const ServicesModal = React.memo(() => {
  const isOpen = useStore($isModalOpen)
  const openedService = useStore($openedService)

  if (!isOpen) return null

  return (
    <ModalLayoutV2 closeModal={closeModal}>
      <Wrapper>
        {openedService ? <ServiceLinks /> : <AllServices /> }
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100vw;
  max-width: 700px;
  min-height: 350px;
  max-height: 750px;
  padding: 32px;
  background-color: ${palette.grey20};
  border-radius: 20px;
`
