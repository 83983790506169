import { ProjectItemType } from '../contract-filters'
import {
  DefaultUpdateFileParams,
  DocumentActions,
  DocumentAlias,
  DocumentProgress,
  DocumentStatuses,
  DocumentTracking,
  ExistingDoc,
  FileDTO,
  RejectionReason,
  SystemAlias,
} from '../interfaces'

export enum ContractVisibility {
  visible = 'visible',
  hidden = 'hidden'
}

export type ContractDataPayload = {
  id: number
  visibility?: ContractVisibility
}

export type GetCompanyContractsParams = {
  id: number,
  visibility?: ContractVisibility
  name?: string
  company_id?: number | null
  status?: number | null
  project_id?: number | null
}

export enum ContractType {
  NotFinance = 3,
  ExtraWork = 2,
  ChangeConditions = 1,
  Default = 4
}

export type FeatureReason = {
  info: string
}

export type FeatureReasonWithLink = {
  info: string
  name: string
} & ({
  doc_type: DocumentAlias
  id: string | number
} | {
  link: string
})

export type FeaturesForDocument = {
  create: {
    can: false
    reason: FeatureReason | FeatureReasonWithLink
  } | {
    can: true
    reason: null
  }
}

export type ContractAnalystItem = {
  cost_item: string
  avans: string
  guarante: string
  sum: string
  const_obj: string
}

export type ContractAnalyst = {
  table: ContractAnalystItem[]
  total_avans: string
  total_guarante: string
  total_sum: string
}

export type ContractOtherDeduction = {
  name: string
  percent: string
}

export enum ClosurePercentageStatus {
  Hidden = 'hidden',
  InProgress = 'in_progress',
  CanCloseContract = 'can_close_contract',
  WorkCompleted = 'work_completed',
  CanGetGuaranteeRetention = 'can_get_guarantee_retention'
}

export type ClosureProgressItem = {
  percentage: string,
  value: string
}

export type ClosurePercentage = {
  status: ClosurePercentageStatus
  has_closing_documents: boolean
  docs_link: string
  guaranteed_months: number // moths counts
  closure_data_update_at: string // ISODate
  progress?: null | {
    act: {
      completed: ClosureProgressItem
      remaining: ClosureProgressItem
      total_percent: string
      total_sum: string
    }
    paid_advance: {
      paid: ClosureProgressItem | null
      remaining_paid: ClosureProgressItem | null
      total_percent: string
      total_sum: string
    } | null
    advance_credited: {
      passed: ClosureProgressItem
      remaining_passed: ClosureProgressItem | null
      total_percent: string
      total_sum: string
    } | null
    sum_guarantee: {
      withholding: ClosureProgressItem
      remaining_withholding: ClosureProgressItem
      paid: ClosureProgressItem | null
      total_percent: string
      total_sum: string
    }
  }
}

export enum ContractDirection {
  SMR = 'smr',
  PDRD = 'pd_rd',
  BANK_WARRANTY = 'bank_warranty',
  CONTRACT_GUARANTEE = 'contract_guarantee',
}

export type ContractDirectionItem = {
  type: ContractDirection
  name: string // СМР, ПД+РД и т. д.
  description: string | null
}

export type ContractItem = {
  id: number,
  name: string,
  company_fsk: string,
  status: DocumentStatuses,
  visibility: ContractVisibility
  child: ContractItem[] | null
  project: ProjectItemType
  responsible_progress: DocumentProgress | null
  analyst: ContractAnalyst
  parent: {
    id: ContractItem['id'],
    name: ContractItem['name']
  } | null
  type: {
    map_id: ContractType,
    name: string
  }
  action: DocumentActions | null
  other_deductions: ContractOtherDeduction[]
  closure_percentage: ClosurePercentage
  features: {
    act: FeaturesForDocument,
    kor_doc: FeaturesForDocument,
    budget: FeaturesForDocument
    payment_document: FeaturesForDocument
    can_create_visa: boolean
    can_open_visa_chat: boolean
    is_answer_required: boolean
    can_generate_report: boolean
    is_advance_possible: boolean
    can_close_contract: boolean
    can_get_sum_guarantee: boolean
    can_change_visibility: boolean
    can_get_all_documents: boolean
    can_add_attachments: boolean
  }
  attachments: FileDTO[]
  contract_documents: string | null
  reports: FileDTO[] | null
  contract_all_reports: string | null
  additional_data: {
    sum: string
    nds: string
    date: string | null
    date_end: string | null
    guaranteed_date: string | null // not date
    guaranteed_percentage: string | null
    avans_percentage: string | null
    subject: string | null
    estimate: FileDTO | null
    company_fsk: string
    other_deductions: ContractOtherDeduction[]
    date_start_fact: string | null
    date_end_fact: string | null
  }
  conflicting_draft_kor_doc: ExistingDoc
  canceled_or_not_relevant_kor_doc: ExistingDoc
  direction: ContractDirection
  document_tracking: DocumentTracking | null
  company: {
    name: string // поручитель
  } | null
  period: { // срок обеспечения
    date_start: string // YYYY-MM-DD
    date_end: string // YYYY-MM-DD
  } | null
  bank: string | null
  sum_of_security: string | null // сумма обеспечения
  reject_comment: RejectionReason
}

export type ChildContractItem = Omit<ContractItem, 'child'>[]

export type AnalystItem = {
  cost_item: string // статья оборотов
  avans: string // В т.ч. сумма аванса
  guarante: string // В т.ч. сумма ГУ
  sum: string // Сумма
  const_obj: string // Объект строительства
}

export type PostReconcilationReportParams = {
  endDate: string // YYYY-MM-DD
  message: string
  startDate: string // YYYY-MM-DD
  contractId: ContractItem['id']
  buh_fio: string
  buh_mail: string
  buh_number: string
  created_from_place: SystemAlias
}

export type PostContractAttachmentsParams = {
  id: ContractItem['id']
} & DefaultUpdateFileParams

export type ClosureDocument = {
  id: number,
  name: string
}

export type GetClosureDocumentResponse = {
  link_closing_documents: string
  docs: ClosureDocument[]
}
