import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
} from '@/ui'
import { noop } from '@/lib/noop'

import {
  $editingPaymentOrders,
  $isPaymentOrdersModalOpen,
  addNewPaymentOrder,
  closePaymentOrdersModal,
  editPaymentOrder,
  markPaymentOrderToDelete,
  onUpdatePaymentOrders,
  postPaymentOrdersFx,
} from '../../../model/payment-orders.private'
import { EditPaymentOrder } from '../parts'
import { $canDeleteLastPaymentOrder, $canEditPaymentOrders } from '../../../model'

const $paymentOrdersToShow = $editingPaymentOrders.map((orders) => (
  orders.filter(({ isMarkedToDelete }) => !isMarkedToDelete)
))

const $canSubmit = $editingPaymentOrders.map((orders) => (
  !orders.some(({ number, date }) => !number || !date)
))

export const PaymentOrdersModal = React.memo(() => {
  const isOpen = useUnit($isPaymentOrdersModalOpen)
  const paymentOrders = useUnit($paymentOrdersToShow)
  const canSubmit = useUnit($canSubmit)
  const isPending = useUnit(postPaymentOrdersFx.pending)
  const [canEdit, canDeleteLast] = useUnit([$canEditPaymentOrders, $canDeleteLastPaymentOrder])

  const canDelete = canEdit && (paymentOrders.length > 1 || canDeleteLast)

  if (!isOpen) return null

  return (
    <ModalLayoutV2 closeModal={isPending ? noop : closePaymentOrdersModal}>
      <Wrapper>
        <Header>
          Данные платежных поручений
        </Header>

        <Scrollbars autoHeight autoHeightMax={520}>
          <PaymentOrderList>
            {paymentOrders.map(({
              id, number, date,
            }) => (
              <EditPaymentOrder
                key={id}
                id={id}
                numberValue={number}
                dateValue={date}
                canDelete={canDelete}
                onChange={editPaymentOrder}
                onDelete={() => markPaymentOrderToDelete(id)}
              />
            ))}
            <ButtonWrapper>
              <NewTextButton
                label="Добавить платежное поручение"
                prefixIcon="plusButton"
                textStyle="L"
                iconSize={16}
                dataTestId="add-payment-order-btn"
                onClick={addNewPaymentOrder}
              />
            </ButtonWrapper>
          </PaymentOrderList>
        </Scrollbars>


        <ButtonsRow>
          <NewButton
            label="Сохранить"
            isDisabled={!canSubmit || isPending}
            dataTestId="save-payment-orders-btn"
            isFill
            onClick={onUpdatePaymentOrders}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isPending}
            dataTestId="close-payment-orders-btn"
            isFill
            onClick={closePaymentOrdersModal}
          />
        </ButtonsRow>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 550px;
  max-width: 100%;
  padding: 32px 32px 24px;
`

const Header = styled.header`
  ${HeadXL}
`

const PaymentOrderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ButtonWrapper = styled.div`
  padding: 7px 0;
`

const ButtonsRow = styled.div`
  display: flex;
  gap: 12px;
`
