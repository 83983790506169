import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import {
  NewButton,
  NewTextArea,
  SimpleLoader,
  TextM,
} from '@/ui'
import { CommentsModel } from '../createCommentsModel'

type Params = {
  form: CommentsModel['form']
  getCommentsFx: CommentsModel['getCommentsFx']
  $totalCommentsCount: CommentsModel['$totalCommentsCount']
}

export const createAddComment = (params: Params) => React.memo(() => {
  const commentsCount = useStore(params.$totalCommentsCount)
  const isLoading = useStore(params.getCommentsFx.pending)

  const {
    fields,
    error,
    submit,
  } = useForm(params.form)

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }
  return (

    <Form onSubmit={onFormSubmit}>
      <Title>
        Комментарий
        {' '}
        {isLoading ? <SimpleLoader sizePx={20} /> : `(${commentsCount}):`}
      </Title>

      <NewTextArea
        error={error('comment')?.errorText}
        dataTestId="add-comment-textarea"
        value={fields.comment.value}
        height={100}
        onChange={fields.comment.onChange}
        placeholder="Написать комментарий...."
        autoGrow
      />

      <NewButton
        size="M"
        buttonType="primary"
        label="Отправить"
        dataTestId="add-comment-submit-button"
        type="submit"
        isDisabled={isLoading || Boolean(error('comment')?.errorText)}
      />

    </Form>
  )
})

const Title = styled.div`
  ${TextM}
  text-transform: uppercase;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px
`
