import React from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import styled from 'styled-components'
import { useGate, useStore } from 'effector-react'

import { isAppealTaskItem } from '@/dal'
import {
  HeadM,
  Icon2,
  Loader,
  PaginationItem,
  SimpleLoader,
  palette,
} from '@/ui'

import { TaskItem } from './TaskItem'
import {
  $hasMore,
  $tasksList,
  ActiveTaskGate,
  getMoreTasksFx,
  initTaskListFx,
  loadMoreTasks,
  $selectedTaskType,
  $taskTypeList,
  selectTaskType,
} from '../../model/private'
import { Tabs } from '../parts'
import { AppealTaskItem } from './AppealTaskItem'

export const ActiveTasks = React.memo(() => {
  const tasks = useStore($tasksList)
  const hasMoreTasks = useStore($hasMore)
  const isLoadingList = useStore(initTaskListFx.pending)
  const isMoreTasksLoading = useStore(getMoreTasksFx.pending)

  const taskList = useStore($taskTypeList)
  const selectedId = useStore($selectedTaskType)
  const taskItems = React.useMemo(() => taskList
    .map((item) => ({ id: item.value, label: item.label })), [taskList])

  const onItemClick = (id) => selectTaskType(id?.toString() || '')

  useGate(ActiveTaskGate)

  return (
    <Wrapper>
      <Tabs items={taskItems} selectedId={selectedId} onItemClick={onItemClick} />

      <TasksList>
        {isLoadingList
          ? <Loader />
          : (
            <>
              {tasks?.length ? (
                <Scrollbar>

                  {tasks.map((item) => (
                    <React.Fragment key={`${item.document_type}-${item.document_id}`}>
                      {isAppealTaskItem(item) ? (
                        <AppealTaskItem {...item} />
                      ) : (
                        <TaskItem {...item} />
                      )}
                    </React.Fragment>
                  ))}

                  {hasMoreTasks && (
                    <PaginationItem
                      onLoadMore={loadMoreTasks}
                      isLoading={isMoreTasksLoading}
                    >
                      <SimpleLoader sizePx={25} />
                    </PaginationItem>
                  )}
                </Scrollbar>
              )
                : (
                  <Emptylist>
                    <Icon2 size={170} icon="emptyTasks" />
                    У вас нет активных задач
                  </Emptylist>
                )}
            </>
          )}

      </TasksList>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
`

const TasksList = styled.div`
  flex-grow: 1;
`

const Emptylist = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 20px;
  ${HeadM};
  color: ${palette.grey60};
`
