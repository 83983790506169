import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { DefaultButton, SelectItemButton } from '@/ui'
import { noop } from '@/lib/noop'

import { getDocumentFiles } from '../../model/private'
import { DocumentFilesModal } from '../containers'

type Props = {
  id: number
  docType: DocumentAlias
  buttonLabel?: string
  downloadLink?: string
  status?: DocumentStatuses
  onClick?: () => void
}

// TODO сверить статусы и само условие
const statusesWithModal = [
  DocumentStatuses.Signed,
  DocumentStatuses.Declined,
  DocumentStatuses.Completed,
]

export const DocumentFilesButton = React.memo(({
  id,
  docType,
  buttonLabel = 'Документы для согласования',
  downloadLink,
  status,
  onClick,
}: Props) => {
  const isDraft = status !== DocumentStatuses.Draft

  // ToDo если примут вариант отображения по статусам
  // const hasModal = useMemo(() => status && statusesWithModal.includes(status), [status])

  // const hasModal = useMemo(() => status !== DocumentStatuses.Draft, [status])

  // 09,10,2024 С осипенко олей решили временно отключить модалку
  const hasModal = false

  const Wrapper = useCallback(({ children }) => {
    if (!hasModal && downloadLink) {
      return (
        <a href={downloadLink} download>
          {children}
        </a>
      )
    }
    if (!hasModal && onClick) {
      return (
        <Button onClick={onClick}>
          {children}
        </Button>
      )
    }
    return <>{children}</>
  }, [hasModal, downloadLink])


  return (
    <Wrapper>
      <SelectItemButton
        text={buttonLabel}
        icon="download"
        buttonStyle="secondary"
        hasBorder={false}
        dataTestId="open-doc-files-modal-btn"
        onClick={hasModal ? () => getDocumentFiles({ id, docType }) : noop}
      />
      <DocumentFilesModal isDraft={isDraft} />
    </Wrapper>
  )
})

const Button = styled(DefaultButton)`
  width: 100%;
`
