export function prepareFormData<T extends { files?: File[] | null }>({ files, ...payload }: T) {
  const formData = new FormData()

  for (const key in payload) {
    if (payload[key] === undefined || payload[key] === null) continue
    if (Array.isArray(payload[key])) {
      payload[key].forEach((element) => {
        formData.append(key, element)
      })
    } else {
      formData.append(key, payload[key] as unknown as string | Blob)
    }
  }
  // deprecated
  if (files) {
    for (const file of files) {
      // @TODO rename key to file
      formData.append('files[]', file)
    }
  }
  return formData
}
