import { forward, sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { RouterPaths, redirectOnFail } from '@/features/app/model'
import { requestActualSurvey } from '@/features/survey/model'
import { onAdditionalRequestAnswerRead, onAdditionalRequestUpdated } from '@/features/additional-request/model'

import {
  EstimateCorrectionGate,
  $allDocumentInfo,
  updateDocumentInfo,
  $unitList,
  $groupList,
  $projectList,
  $documentId,
  updateEstimateDirectories,
  refetchEstimateDocument,
  $isDocumentPending,
  onUpdateSummary,
  updateFeatures,
  updateAttachments,
  getUpdatedFeatures,
} from './public'
import {
  abortGetDocumentFx,
  getEstimateInfoByIdFx,
  getKorDocDirectoriesFx,
  updateFeaturesInfoFx,
  updateSummary,
} from './private'

$isDocumentPending
  .on(getEstimateInfoByIdFx, () => true)
  .reset(getEstimateInfoByIdFx.finally)

$allDocumentInfo
  .on(getEstimateInfoByIdFx.doneData, (_, info) => info)
  .on(updateDocumentInfo, (_, info) => info)
  .on(updateSummary, (info, summary) => (!info ? null : {
    ...info,
    summary_table: summary,
  }))
  .on(updateAttachments, (info, attachments) => (!info ? null : {
    ...info,
    attachments,
  }))
  .on(updateFeatures, (info, features) => (!info ? null : {
    ...info,
    features,
  }))
  .on(onAdditionalRequestAnswerRead, (info) => (!info ? null : {
    ...info,
    features: {
      ...info.features,
      has_unread_answer: false,
    },
  }))
  .reset(EstimateCorrectionGate.close)

$unitList
  .on(getKorDocDirectoriesFx.doneData, (_, { units }) => units)
  .reset(EstimateCorrectionGate.close)

$groupList
  .on(getKorDocDirectoriesFx.doneData, (_, { groups }) => groups)
  .reset(EstimateCorrectionGate.close)

$projectList
  .on(getKorDocDirectoriesFx.doneData, (_, { projects }) => projects)
  .reset(EstimateCorrectionGate.close)


sample({
  clock: [EstimateCorrectionGate.open, EstimateCorrectionGate.state],
  source: {
    isOpen: EstimateCorrectionGate.status,
    id: EstimateCorrectionGate.state,
  },
  filter: ({ id, isOpen }) => isOpen && Boolean(id) && !Number.isNaN(id),
  target: [getEstimateInfoByIdFx, getKorDocDirectoriesFx, requestActualSurvey],
})

sample({
  clock: [refetchEstimateDocument, onAdditionalRequestUpdated],
  source: {
    isOpen: EstimateCorrectionGate.status,
    id: EstimateCorrectionGate.state,
  },
  filter: ({ id, isOpen }) => isOpen && Boolean(id) && !Number.isNaN(id),
  target: getEstimateInfoByIdFx,
})

forward({
  from: EstimateCorrectionGate.close,
  to: abortGetDocumentFx,
})

sample({
  clock: updateEstimateDirectories,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getKorDocDirectoriesFx,
})

sample({
  clock: getUpdatedFeatures,
  source: $documentId,
  filter: Boolean,
  target: updateFeaturesInfoFx,
})

sample({
  clock: updateFeaturesInfoFx.doneData,
  target: updateFeatures,
})

sample({
  clock: onUpdateSummary,
  source: $documentId,
  filter: (id, { docId }) => id === docId,
  fn: (_, { summary }) => summary,
  target: updateSummary,
})

redirectOnFail({
  effect: getEstimateInfoByIdFx,
  route: RouterPaths.Administrative,
})

createToast({
  effect: getEstimateInfoByIdFx,
  errorText: 'Не удалось загрузить смету',
})
