import React from 'react'

import { EditableValue } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { Col, Value } from './sharedCSS'

type Props = {
  value: string
  isEdited?: boolean
  currencyValue?: string | null
  isChangeable?: boolean
  isApproved?: boolean
  isGrey?: boolean
  onSubmit?: (val: string) => void
}

export const AvansCol = ({
  currencyValue,
  value,
  isApproved,
  isChangeable,
  isEdited,
  isGrey,
  onSubmit,
}: Props) => {
  const formatString = (string: string) => (
    numberWithSpaces(string.toString())
  )

  return (
    <Col isEdited={isApproved && isEdited}>
      <Value isDiff={!isApproved && isEdited} isGrey={isGrey}>
        {formatString(value)}
        {' %'}
      </Value>

      {Boolean(currencyValue) && (isChangeable && onSubmit ? (
        <EditableValue
          onSubmit={onSubmit}
          value={currencyValue as string}
          ceilType="currency"
          placeholder="Введите цену"
        />
      ) : (
        <Value isDiff={isEdited} isGrey={isGrey}>
          {formatString(currencyValue as string)}
        </Value>
      ))}
    </Col>
  )
}
