import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import {
  DefaultButton,
  Icon2,
  palette,
} from '@/ui'
import { $authorizedUserData } from '@/features/login/model'
import { UserProfilePopup } from '../parts/UserProfilePopup'
import { onLogout } from '../../model/private'


export const UserProfileBlock = () => {
  const authorizedUserData = useStore($authorizedUserData)
  const userName = authorizedUserData
    ? `${authorizedUserData?.first_name} ${authorizedUserData?.last_name}`
    : ''

  return (
    <UserProfileWrapper>
      <UserButton>
        <IconWrapper>
          <Icon2 icon="user" color="white" size={32} />
        </IconWrapper>
      </UserButton>

      <PopupWrapper>
        <UserProfilePopup
          email={authorizedUserData?.email || ''}
          onLogout={onLogout}
          userName={userName}
        />
      </PopupWrapper>
    </UserProfileWrapper>
  )
}

const PopupWrapper = styled.div`
  position: absolute;
  inset: -4px;
  padding-top: 4px;
  left: -244px;
  z-index: 3;
  top: 100%;
  visibility: hidden;
  right: 4px;
`

const IconWrapper = styled.div`
  padding: 8px;
  border-radius: 14px;
  display: inline-flex;
  border: 2px solid ${palette.white};
  background-color: ${palette.grey50};
  transition: background-color 0.15s linear;
`

const UserProfileWrapper = styled.div`
  position: relative;
  display: inline-flex;
  color: ${palette.grey40};
  transition: color 0.15s linear;

  @media (hover: hover) {
    &:hover {
      color: ${palette.grey60};

      ${IconWrapper} {
        background-color: transparent;
      }

      ${PopupWrapper} {
        visibility: visible;
      }
    }
  }
`

const UserButton = styled(DefaultButton)`
  background: radial-gradient(rgba(232, 78, 14, 1), rgba(255, 138, 30, 1));
  border-radius: 14px;
  padding: 2px;
`
