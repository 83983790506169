import React from 'react'

import { ContractItem } from '@/dal'
import { newGetContractNameAndIconByType } from '@/lib/documentResolvers'
import { Icon2, IconName2 } from '@/ui'

type Props = {
  hasChildren: boolean
  isOpen?: boolean
  isSubItem?: boolean
  type: ContractItem['type']['map_id']
}

export const ContractIcon = ({
  hasChildren,
  type,
  isOpen,
  isSubItem,
}: Props) => {
  const iconInfo = React.useMemo(() => {
    if (!isSubItem) return null
    return newGetContractNameAndIconByType(type)
  }, [type, isSubItem])

  return (
    <>
      {Boolean(hasChildren && !isSubItem) && (
        <Icon2 icon={isOpen ? 'minusWithCircle' : 'plusWithCircle'} size={16} />
      )}
      {iconInfo && (
        <Icon2
          icon={iconInfo.icon as IconName2}
          color="accent100"
          size={16}
          tooltipText={iconInfo.name}
        />
      )}
    </>
  )
}
