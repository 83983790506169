import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'

import {
  HeadL, Icon2, NewButton, palette, AppealItem,
} from '@/ui'
import { RouterPaths, pushNavigate } from '@/features/app/model'
import { createAppealsPage } from '@/features/create-appeals-list/view/factories/createAppealsPage'
import { RenderFunc } from '@/features/create-appeals-list/types'
import { AdministrativeAppealItemType } from '@/dal'
import { getAppealDocInfo } from '@/lib/getAppealDocInfo'

import {
  $appealTypes,
  $responsibleRoles,
  commentsModel,
  filterModel,
  listModel,
} from '../../../shared-model'

import { markAppealAsRead } from '../../model/private'

const PageView = createAppealsPage({
  ...commentsModel,
  ...filterModel,
  ...listModel,
  $isCommentsPending: commentsModel.$isPending,
  $filterItems: $appealTypes,
  filtersLabel: 'Кому был адресован запрос',
})

export const RequestHistory = React.memo(() => {
  const responsibleRoles = useStore($responsibleRoles)
  const memoRenderFunc = React.useCallback<RenderFunc<AdministrativeAppealItemType>>((
    {
      item,
      isSelect,
      selectItem,
    }) => {
    const role = responsibleRoles.find(({ alias }) => alias === item.task_type)
    const toWhom = role?.dativeName ?? ''
    const docInfo = getAppealDocInfo(item)

    return (
      <AppealItem
        attachmentsCount={item.attachments.length}
        dateTime={item.created_date}
        id={item.id}
        isSelected={isSelect}
        docInfo={docInfo}
        responsible={item.executor}
        onMarkAsRead={markAppealAsRead}
        onSelect={selectItem}
        title={item.request_number}
        description={item.description}
        isRead={item.is_read}
        hasAnswer={item.is_found_answer}
        toWhom={toWhom}
      />
    )
  },
  [responsibleRoles],
  )

  return (
    <>
      <Header>
        <BackLink to={RouterPaths.Help}>
          <Icon2 icon="arrowLeft" size={24} color="grey80" />
          <Title>Обращение в администрацию проекта</Title>
        </BackLink>
        <NewButton
          label="Создать новое обращение"
          prefixIcon="plusButton"
          dataTestId="new-appeal-btn"
          size="S"
          onClick={() => pushNavigate(RouterPaths.NewAdministrationAppeal)}
        />
      </Header>
      <PageView renderFunc={memoRenderFunc} />
    </>
  )
})

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 14px 20px;
  margin-bottom: 16px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Title = styled.h3`
  ${HeadL}
`
