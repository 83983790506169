import React from 'react'

import { DocumentProgress, DocumentStatuses } from '@/dal'
import { statusResolve } from '@/lib/status-resolver'
import { Label } from './Label'
import { PaletteColor } from '../../palette'
import { LabelSize } from './types'

type Props = {
  status: DocumentStatuses
  progress?: Pick<DocumentProgress, 'agreed_by_default' | 'total_visas_default'> | null
  size?: LabelSize
  progressPosition?: 'left' | 'right'
  isShort?: boolean
}

type StatusColors = {
  text: PaletteColor
  bgColor: PaletteColor
  borderColor?: PaletteColor
}

export const DocumentStatusLabel = ({
  status, progress, size, progressPosition = 'left', isShort,
}: Props) => {
  const statusText = statusResolve(status, isShort)
  const { bgColor, text, borderColor }: StatusColors = React.useMemo(() => {
    if (progress) {
      return {
        text: 'accent100',
        bgColor: 'accent10',
      }
    }

    switch (status) {
      case DocumentStatuses.Draft: {
        return {
          text: 'grey90',
          bgColor: 'grey10',
          borderColor: 'grey30',
        }
      }
      case DocumentStatuses.DocumentsWaiting:
      case DocumentStatuses.ToBeAgreed:
      case DocumentStatuses.AgreedTechSupervisor:
      case DocumentStatuses.AgreedContractor: {
        return {
          text: 'accent100',
          bgColor: 'accent10',
        }
      }
      case DocumentStatuses.Signed:
      case DocumentStatuses.SignInDiadoc:
      case DocumentStatuses.SignActInDiadoc:
      case DocumentStatuses.SignInClient:
      case DocumentStatuses.SignInSFDiadoc: {
        return {
          text: 'violet100',
          bgColor: 'violet10',
        }
      }
      case DocumentStatuses.Approved:
      case DocumentStatuses.Completed:
      case DocumentStatuses.FinalApproved: {
        return {
          text: 'green',
          bgColor: 'lightGreen10',
        }
      }
      case DocumentStatuses.Declined: {
        return {
          text: 'red100',
          bgColor: 'red10',
        }
      }
      default: {
        return {
          text: 'grey90',
          bgColor: 'grey10',
        }
      }
    }
  }, [status])

  const progressText = progress ? `${progress.agreed_by_default}/${progress.total_visas_default}` : null

  const labelText = progress
    ? progressPosition === 'left'
      ? `${progressText} ${statusText}`
      : `${statusText} ${progressText}`
    : statusText

  return (
    <Label
      text={labelText}
      size={size}
      bgColor={bgColor}
      colorText={text}
      borderColor={borderColor}
    />
  )
}
