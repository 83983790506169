import React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'

import { palette } from '@/ui'
import { DataSetType } from '@/dal'

import { Chart } from '../parts'

import { $visibleDataSets } from '../../model/private'

export const Charts = () => {
  const data = useStore($visibleDataSets)

  const maxValue = React.useMemo(() => {
    if (!data) return null
    const values = data.reduce((acc, { data }) => (
      [
        ...acc,
        data?.days_to_approving || 0,
        data?.days_to_paying || 0,
      ]
    ), [] as number[])
    return Math.max(...values)
  }, [data])

  if (!data) return null

  return (
    <ChartsWrapper>
      {data.map(({ data, type }, index) => (
        <Item key={index} isActive={DataSetType.Current === type}>
          {data.days_to_approving && (
            <Chart
              days={data.days_to_approving || 0}
              maxDataValue={maxValue || 0}
              contracts={data.approving_documents}
              isApproving
            />
          )}
          {data.days_to_paying && (
            <Chart
              days={data.days_to_paying || 0}
              maxDataValue={maxValue || 0}
              contracts={data.paying_documents}
            />
          )}
        </Item>
      ))}
    </ChartsWrapper>
  )
}

const Item = styled.div<{isActive?: boolean}>`
  padding: 0 16px;
  display: flex;
  gap: 4px;
  align-items: flex-end;

  ${({ isActive }) => isActive && css`
    background-color: rgba(255, 241, 219, 0.40);
    border-radius: 8px 8px 0px 0px;
    border: 1px solid ${palette.accent30};
  `}
`

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
`
