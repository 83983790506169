import React from 'react'
import styled from 'styled-components'

import { usePopup } from '../../hooks'
import { MultiSelectList } from './MultiSelectList'

type Props = {
  onAcceptClick: (items: any[]) => void
  triggerComponent: ({ isOpen }: { isOpen: boolean }) => React.ReactNode

  disabled?: boolean,
  items: {
    id: any
    label: string
  }[]
  selectedIds: any[]
  testId: string
  hasAcceptOnClear?: boolean
}

export const MultiSelectPopup = ({
  onAcceptClick,
  triggerComponent,

  disabled,
  testId,
  items,
  selectedIds,
  hasAcceptOnClear,
}: Props) => {
  const [selectedItems, setSelectedItems] = React.useState<any[]>([])

  React.useEffect(() => {
    setSelectedItems(selectedIds)
  }, [selectedIds])

  const handleToggle = (value: any) => {
    setSelectedItems((items) => (
      items.includes(value)
        ? items.filter((currValue) => currValue !== value)
        : [...items, value]
    ))
  }

  const handleClose = () => {
    setSelectedItems(selectedIds)
  }

  const {
    floatingStyles,
    refs,
    isOpen,
    getFloatingProps,
    close: closeDropDown,
    getReferenceProps,
  } = usePopup({ onClose: handleClose, isDisable: disabled })

  const handleAcceptClick = () => {
    closeDropDown()
    onAcceptClick(selectedItems)
  }

  const handleClear = () => {
    setSelectedItems([])
    if (hasAcceptOnClear) {
      closeDropDown()
      onAcceptClick([])
    }
  }

  return (
    <>
      <ButtonWrapper
        ref={refs.setReference}
        {...getReferenceProps()}
        data-testid={`${testId}-open`}
      >
        {triggerComponent({ isOpen })}
      </ButtonWrapper>
      {isOpen && (
        <MultiSelectList
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          items={items}
          onAcceptClick={handleAcceptClick}
          onClear={handleClear}
          onToggle={handleToggle}
          refs={refs}
          testId={testId}
          selectedItems={selectedItems}
        />
      )}
    </>
  )
}

const ButtonWrapper = styled.div``
