import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { NewInput } from '@/ui'

import { FilterDropdown, ResetRowsControls } from '../container'
import { $isResetRowsPending } from '../../../model'
import { $searchInputValue, changeSearch, acceptSearch } from '../../../model/filters.private'

export const TableFilter = React.memo(() => {
  const value = useStore($searchInputValue)
  const isBlocked = useStore($isResetRowsPending)

  return (
    <Wrapper>
      <InputWrapper>
        <NewInput
          dataTestId="search-work-in-table-input"
          onChange={changeSearch}
          value={value}
          placeholder="Поиск по наименованию услуг и ID"
          postfixIcon="search"
          disabled={isBlocked}
          onEnterPress={acceptSearch}
          onPostfixIconClick={acceptSearch}
        />
      </InputWrapper>
      <FilterDropdown isDisabled={isBlocked} />
      <ResetRowsControls />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const InputWrapper = styled.div`
  width: 320px;
`
