import {
  Domain,
  Event,
  Store,
} from 'effector'

import { root } from '@/root-domain'

export type SetFilterParams<T> = {
    key: keyof T
    val: any
}

export type RemoveFilterParams<T> = {
    key: keyof T
    val?: any
}

export type FactoryParams = {
    domain?: Domain
}

export type CreateFiltersReturnType<T> = {
    setFilter: Event<SetFilterParams<T>>
    removeFilter: Event<RemoveFilterParams<T>>
    $filters: Store<T>
    clearFilter: Event<void>
    setFilters: Event<T>
}

export const createFilters = <Fields extends Record<string, any>>(
  initialState: Fields,
  domain?: Domain,
)
: CreateFiltersReturnType<Fields> => {
  const d = domain || root.domain()
  const $filters = d.store<Fields>(initialState as Fields)

  const clearFilter = d.event<void>()

  const setFilter = d.event<SetFilterParams<Fields>>()
  const removeFilter = d.event<RemoveFilterParams<Fields>>()
  const setFilters = d.event<Fields>()

  $filters
    .on(setFilter, (filters, { key, val }) => ({ ...filters, [key]: val }))
    .on(setFilters, (_, filters) => ({ ...initialState, ...filters }))
    .on(removeFilter, (filters, { key, val }) => {
      if (val && Array.isArray(filters[key])) {
        return {
          ...filters,
          [key]: filters[key].filter((el) => el !== val),
        }
      }

      return {
        ...filters,
        [key]: undefined,
      }
    })
    .reset(clearFilter)

  return {
    setFilter,
    $filters,
    removeFilter,
    clearFilter,
    setFilters,
  }
}
