import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { NewButton } from '@/ui'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import { openExitSpecialModeModal } from '../../../model/private'
import { $isResetModeEnabled } from '../../../model/reset-multiple-items.private'
import {
  $canSendEditedRows,
  $isMultipleEditModeEnabled,
  enableMultipleEditMode,
  updateMultipleKorDocItems,
  updateMultipleKorDocItemsFx,
} from '../../../model/edit-multiple-items.private'

export const MultipleEditControls = React.memo(() => {
  const [status, isMultipleEditMode, isResetMode, canSubmit, isPending] = useUnit([
    $documentStatus,
    $isMultipleEditModeEnabled,
    $isResetModeEnabled,
    $canSendEditedRows,
    updateMultipleKorDocItemsFx.pending,
  ])

  if (status !== DocumentStatuses.Draft || isResetMode) return null

  return (
    <Wrapper>
      {!isMultipleEditMode ? (
        <NewButton
          label="Массовое редактирование"
          prefixIcon="editStroke"
          size="S"
          dataTestId="enable-multiple-edit-mode-btn"
          onClick={enableMultipleEditMode}
        />
      ) : (
        <>
          <NewButton
            label="Отменить"
            buttonType="grey"
            size="S"
            isDisabled={isPending}
            dataTestId="close-multiple-edit-mode-btn"
            onClick={openExitSpecialModeModal}
          />
          <NewButton
            label="Сохранить изменения"
            size="S"
            isDisabled={!canSubmit}
            isPending={isPending}
            dataTestId="save-multiple-rows-btn"
            onClick={updateMultipleKorDocItems}
          />
        </>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`
