import React from 'react'
import styled, { css } from 'styled-components'

import {
  NewIconButton,
  TextM,
  TextMLight,
  palette,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { TABLE_BORDER_STYLE } from '../../../model/const'

type Props = {
  label: string
  isHiddenCell?: boolean
  totalCost?: string
  groupNumber?: number
  isExpanded?: boolean
  hasRightDivider?: boolean
  expandButtonZIndex?: number
  onExpandButton?: (groupNumber: number) => void
}

export const HeaderCell = ({
  label,
  isHiddenCell,
  totalCost,
  groupNumber,
  isExpanded,
  hasRightDivider,
  expandButtonZIndex,
  onExpandButton,
}: Props) => (
  <Cell
    isHiddenCell={Boolean(isHiddenCell)}
    hasSubLabel={Boolean(totalCost)}
    hasRightDivider={Boolean(hasRightDivider)}
  >
    {groupNumber !== undefined && onExpandButton && (
      <ButtonWrapper isExpanded={Boolean(isExpanded)} zIndex={expandButtonZIndex}>
        <NewIconButton
          padding="4px"
          dataTestId={`expand-group-${groupNumber}`}
          icon={isExpanded ? 'arrowLeft' : 'arrowRight'}
          size={12}
          borderRadius="50%"
          color="white"
          backgroundColor={isExpanded ? 'grey80' : 'grey50'}
          hoverBackgroundColor={isExpanded ? 'grey80' : 'grey50'}
          onClick={() => onExpandButton(groupNumber)}
        />
      </ButtonWrapper>
    )}
    {label}
    {totalCost && (
      <SabLabel>
        {numberWithSpaces(totalCost)}
      </SabLabel>
    )}
  </Cell>
)

const Cell = styled.div<{isHiddenCell: boolean, hasSubLabel: boolean, hasRightDivider: boolean}>`
  position: relative;
  padding: 8px;
  display: flex;
  text-align: ${({ hasSubLabel }) => (hasSubLabel ? 'right' : 'center')};
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ isHiddenCell }) => (isHiddenCell ? palette.grey30 : palette.grey10)};
  ${TextM};

  &:not(:last-child) {
    border-right: ${TABLE_BORDER_STYLE};
    ${({ hasRightDivider }) => hasRightDivider && css`
      border-width: 4px;
    `}
  }
`

const SabLabel = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const ButtonWrapper = styled.div<{ isExpanded: boolean, zIndex?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  transform: ${({ isExpanded }) => (isExpanded ? 'translateX(-50%)' : 'translateX(calc(-50% - 2px))')};
  align-items: center;

  ${({ zIndex }) => zIndex && css`
    z-index: ${zIndex};
  `}
`
