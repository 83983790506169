import {
  attach,
  combine,
  sample,
} from 'effector'
import { createGate } from 'effector-react'

import { root } from '@/root-domain'

import { AdministrationAppealStatus } from '@/dal'
import { createToast } from '@/features/toast-service/model'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createFiltersModel } from './createFiltersModel'
import {
  CreateAppealsListModel,
  RequiredAppealItemFields,
  ItemStatus,
} from '../types'

export const createAppealsListModel = <T extends RequiredAppealItemFields, S = string>({
  domain,
  getItemsFx,
  cancelItemFx,
  $filterItems,
}: CreateAppealsListModel<T, S>) => {
  const d = domain || root.domain()
  const ListGate = createGate()

  const $appealTypes = d.store<ItemStatus<S>[]>([])

  const $selectedTabId = d.store<ItemStatus<S>['value'] | null>(null)
  const selectTab = d.event<ItemStatus<S>['value']>()
  const updateAppealTypes = d.event<ItemStatus<S>[]>()

  const $listItems = d.store<T[] | null>(null)
  const $selectedItemId = d.store<T['id'] | null>(null)
  const selectItem = d.event<T['id']>()
  const resetSelectedItem = d.event<void>()

  const $cancelAppealInfo = d.store<T | null>(null)
  const onOpenCancelAppeal = d.event<T['id']>()
  const removeItem = d.event<T['id']>()
  const updateItem = d.event<T>()
  const openCancelConfirm = d.event<T>()
  const onConfirm = d.event<void>()
  const onReject = d.event<void>()
  const onMarkAsRead = d.event<T>()

  const innerCancelItemFx = attach({
    effect: cancelItemFx || d.effect(),
  })

  const { requestFx: singleGetItemsFx } = createSingleEffect(getItemsFx)
  const { requestFx: updateItemsFx } = createSingleEffect(getItemsFx)

  $cancelAppealInfo
    .on(openCancelConfirm, (_, info) => info)
    .reset(ListGate.close, onReject, innerCancelItemFx.doneData)

  $appealTypes
    .on(
      [
        singleGetItemsFx.doneData,
        updateItemsFx.doneData,
      ],
      (_, { request_status }) => request_status,
    )
    .on(updateAppealTypes, ((_, appealTypes) => appealTypes))
    .reset(ListGate.close)

  $listItems
    .on(
      [
        singleGetItemsFx.doneData,
        updateItemsFx.doneData,
      ],
      (_, { items }) => items,
    )
    .on(removeItem, (items, id) => items?.filter((item) => item.id !== id))
    .on(updateItem, (items, updatedItem) => items?.map(
      (item) => (item.id === updatedItem.id ? updatedItem : item)) ?? null,
    )
    .reset(ListGate.close, singleGetItemsFx)

  $selectedItemId
    .on(selectItem, (_, id) => id)
    .reset(ListGate.close, singleGetItemsFx, resetSelectedItem)

  $selectedTabId
    .on(singleGetItemsFx.doneData, (val, { request_status }) => val || request_status[0].value)
    .on(selectTab, (_, id) => id)
    .reset(ListGate.close)

  const $selectedItem = combine([
    $selectedItemId,
    $listItems,
  ], ([id, items]) => items?.find((item) => item.id === id) ?? null)

  const filterModel = createFiltersModel({
    $filterItems,
    $isPending: singleGetItemsFx.pending,
    domain: d,
  })

  const {
    onFilters,
    onFiltersRefresh,
    filterForm,
  } = filterModel

  sample({
    clock: onOpenCancelAppeal,
    source: $listItems,
    filter: (items, id) => Boolean(items?.some((item) => item.id === id)),
    fn: (items, id) => items?.find((item) => item.id === id) as T,
    target: openCancelConfirm,
  })

  createToast({
    effect: innerCancelItemFx,
    doneText: 'Обращение отменено',
  })

  sample({
    clock: onMarkAsRead,
    target: updateItem,
  })

  sample({
    clock: onMarkAsRead,
    source: $appealTypes,
    filter: (appealTypes) => appealTypes.some(
      ({ value }) => value === AdministrationAppealStatus.Unread,
    ),
    fn: (appealTypes) => appealTypes.map((item) => {
      switch (item.value) {
        case AdministrationAppealStatus.Unread:
          return { ...item, count: item.count - 1 }
        case AdministrationAppealStatus.Completed:
          return { ...item, count: item.count + 1 }
        default:
          return item
      }
    }),
    target: updateAppealTypes,
  })

  sample({
    clock: onConfirm,
    source: $cancelAppealInfo,
    filter: Boolean,
    fn: (item) => item?.id,
    target: innerCancelItemFx,
  })

  sample({
    clock: [onFilters, selectTab],
    source: {
      filters: filterForm.$values,
      tab: $selectedTabId,
    },
    fn: ({ filters, tab }) => ({
      date_from: filters.from,
      date_to: filters.to,
      request_type: filters.types,
      status: tab ? [tab] : undefined,
    }),
    target: singleGetItemsFx,
  })

  sample({
    clock: innerCancelItemFx.doneData,
    source: {
      filters: filterForm.$values,
      tab: $selectedTabId,
    },
    fn: ({ filters, tab }) => ({
      date_from: filters.from,
      date_to: filters.to,
      request_type: filters.types,
      status: tab ? [tab] : undefined,
    }),
    target: updateItemsFx,
  })

  sample({
    clock: ListGate.open,
    fn: () => ({}),
    target: singleGetItemsFx,
  })

  sample({
    clock: onFiltersRefresh,
    source: $selectedTabId,
    fn: (tabId) => ({
      status: tabId ? [tabId] : undefined,
    }),
    target: singleGetItemsFx,
  })

  return {
    $selectedTabId,
    $listItems,
    $filterItems,
    $appealTypes,
    $selectedItem,
    $selectedItemId,
    gate: ListGate,
    selectItem,
    selectTab,
    onFiltersRefresh,
    onFilters,
    onOpenCancelAppeal,
    $isPending: singleGetItemsFx.pending,
    updateItem,
    updateAppealTypes,
    filterModel,
    onConfirm,
    onReject,
    onMarkAsRead,
    $isCancelPending: innerCancelItemFx.pending,
    $cancelAppealInfo,
    resetSelectedItem,
  }
}
