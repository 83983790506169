import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../palette'
import { TextMLight } from '../typography'
import { Icon2 } from '../icons'

type Props = {
  value: string
  placeholder?: string
  isInvalid?: boolean
  height?: number
  maxLength?: number
  disabled?: boolean
  dataTestId: string
  autoGrow?: boolean
  error?: string
  maxHeight?: number
  showMaxLengthError?: boolean
  onChange: (val: string) => void
  onChangeLengthError?: (value: boolean) => void
}

export const NewTextArea = ({
  onChange,
  onChangeLengthError,
  dataTestId,
  placeholder,
  value,
  isInvalid,
  height,
  maxLength,
  disabled,
  autoGrow,
  error,
  maxHeight,
  showMaxLengthError,
}: Props) => {
  const [inputError, setInputError] = React.useState('')
  const [autoHeight, setAutoHeight] = React.useState<number|null>(height ?? null)
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(() => {
    if (!value) return setAutoHeight(height ?? null)
  }, [value])

  const calcHeight = () => {
    if (!autoGrow || !inputRef.current) return
    // TODO пофиксить проблему, когда с autoGrow не скукоживается обратно
    const baseHeight = (height || 0)
    const newHeight = inputRef.current.scrollHeight > baseHeight
      ? inputRef.current.scrollHeight + 1
      : baseHeight
    if (maxHeight) {
      setAutoHeight(newHeight > maxHeight ? maxHeight : newHeight)
    } else {
      setAutoHeight(newHeight)
    }
  }

  const resetLengthError = () => {
    setInputError('')
    onChangeLengthError?.(false)
  }

  const handleChange = (value: string) => {
    if (maxLength && value.length > maxLength) {
      setInputError(`Введено максимальное кол-во символов – ${maxLength}`)
      onChangeLengthError?.(true)
      onChange(value.slice(0, maxLength))
    } else {
      resetLengthError()
      onChange(value)
    }
  }

  React.useLayoutEffect(() => {
    calcHeight()
  }, [value])


  return (
    <Wrapper>
      <TextArea
        data-testid={dataTestId}
        disabled={disabled}
        maxLength={maxLength && showMaxLengthError ? maxLength + 1 : maxLength}
        height={autoGrow ? autoHeight : height}
        value={value}
        ref={inputRef}
        placeholder={placeholder}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        onBlur={resetLengthError}
        isInvalid={Boolean(isInvalid || error || inputError)}
      />
      {(inputError || error) && (
        <ErrorWrapper>
          <Icon2 icon="alert" color="red100" />
          <Error>
            {inputError || error}
          </Error>
        </ErrorWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
`

const TextArea = styled.textarea<{ isInvalid: boolean, height?: number | null }>`
  display: block;
  padding: 12px 16px;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  min-height: 43px;
  width: 100%;
  ${TextMLight}
  resize: none;
  border: 1px solid ${palette.grey40};
  transition: border-color 0.15s linear;
  border-radius: 8px;

  &:not(:disabled)::placeholder {
    color: ${palette.grey60};
  }

  &:disabled::placeholder {
    color: ${palette.grey70};
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      border-color: ${palette.grey60};
    }
  }

  &:not(:disabled):focus {
    outline: none;
    border-color: ${palette.accent80};
  }

  &:disabled {
    background-color: ${palette.grey10};
    border-color: ${palette.grey40};
    color: ${palette.grey70};
    cursor: not-allowed;
  }

  ${({ isInvalid }) => isInvalid && css`
    border-color: ${palette.red100} !important;
  `}
`
const ErrorWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  gap: 4px;
`

const Error = styled.div`
  ${TextMLight}
  color: ${palette.red100};
`
