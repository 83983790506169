import React from 'react'
import styled, { css } from 'styled-components'

import { noop } from '@/lib/noop'

import { NewIconButton } from '../buttons'
import { palette } from '../palette'

type Props = {
  closeModal: () => void
  isCloseByClickOnOverlay?: boolean
  children: React.ReactNode
  dataTestId?: string
  zIndex?: number
  isHidden?: boolean
  hasCloseButton?: boolean
}

export const ModalLayoutV2 = ({
  children,
  closeModal,
  isCloseByClickOnOverlay = true,
  dataTestId,
  zIndex,
  isHidden,
  hasCloseButton = true,
}: Props) => (
  <ModalOverlay
    zIndex={zIndex}
    data-testid={dataTestId}
    onMouseDown={isCloseByClickOnOverlay ? closeModal : noop}
    isHidden={isHidden}
  >
    <ModalWrapper onMouseDown={(e) => e.stopPropagation()}>
      {children}
      {hasCloseButton && (
        <CloseButtonWrapper>
          <NewIconButton
            borderRadius="50%"
            backgroundColor="white"
            icon="cross"
            color="grey60"
            hoverColor="grey80"
            hoverBackgroundColor="grey20"
            onClick={closeModal}
            size={20}
            dataTestId="close-modal"
            padding="6px"
          />
        </CloseButtonWrapper>
      )}
    </ModalWrapper>
  </ModalOverlay>
)

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
`

const ModalOverlay = styled.div<Pick<Props, 'zIndex' | 'isHidden'>>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: ${({ zIndex }) => zIndex || 10};

  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`

const ModalWrapper = styled.div<{ isVisible?: boolean }>`
  max-width: 100%;
  border-radius: 20px;
  position: relative;
  background-color: ${palette.white};
`
