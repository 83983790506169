import {
  combine,
  forward,
  guard,
  sample,
} from 'effector'

import {
  DocumentAlias,
} from '@/dal'
import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import {
  $selectedGroupId,
  loadGroupTree,
  onSelectedGroupIdChanged,
  resetTree,
} from '@/features/group-tree/model'

import {
  $analystArticleItems,
  $isShowNoteCell,
  $planSearchText,
  $planWorkList,
  $popupData,
  $sortPlanWorkListCriteria,
  changePlanWorkListSort,
  clearPlanFilter,
  filterWorkForm,
  getAnalystFx,
  getPopupData,
  getPopupDataFx,
  loadPlanWorksFx,
  onPaginate,
  onWorkInputError,
  paginatePlanWorksFx,
  planSearchWorks,
  resetPopupData,
  resetSearchText,
  setPlanWorkListSearchText,
  setPlanWorkVolume,
  setPlanWorkVolumeFx,
  toggleShowNoteCell,
} from './private'
import { mapWorkParams } from './mappers'
import {
  $openedFundPlan,
  $openedFundPlanId,
  clearPlan,
  loadPlan,
  loadPlanFx,
  updateSums,
} from '../../plan-info/model'

$isShowNoteCell
  .on(toggleShowNoteCell, (state) => !state)
  .reset(clearPlan)

$planWorkList
  .on(loadPlanWorksFx.doneData, (_, d) => d.data)
  .on(paginatePlanWorksFx.doneData, (prev, nextPage) => [...prev, ...nextPage.data])
  .on(setPlanWorkVolumeFx.doneData, (prevWorks, payload) => prevWorks.map((item) => {
    if (item.id === payload.item.id) {
      return payload.item
    }
    return item
  }),
  )
  .reset(clearPlan)

$planSearchText
  .on(setPlanWorkListSearchText, (_, v) => v)
  .reset([clearPlan, clearPlanFilter, onSelectedGroupIdChanged, resetSearchText])

$sortPlanWorkListCriteria
  .on(changePlanWorkListSort, ({ direction, column }, newColumn) => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc'
    if (column === newColumn) {
      return {
        column,
        direction: newDirection,
      }
    }
    return {
      column: newColumn,
      direction: 'asc',
    }
  })
  .reset(clearPlan)

$analystArticleItems
  .on(getAnalystFx.doneData, (_, options) => options)
  .reset(clearPlan)

$popupData
  .on(getPopupDataFx.doneData, (_, f) => f)
  .reset(resetPopupData, getPopupDataFx)

sample({
  clock: setPlanWorkVolumeFx.doneData,
  fn: ({ item, ...sums }) => sums,
  target: updateSums,
})

sample({
  clock: loadPlan,
  fn: (id) => ({
    documentId: id,
    documentType: DocumentAlias.BUDGET,
  }),
  target: loadGroupTree,
})

guard({
  clock: [
    resetSearchText,
    onSelectedGroupIdChanged,
    loadPlanFx.doneData,
    filterWorkForm.$values.updates,
  ],
  source: $openedFundPlan,
  filter: Boolean,
  target: planSearchWorks,
})

guard({
  clock: changePlanWorkListSort,
  source: $openedFundPlan,
  filter: Boolean,
  target: planSearchWorks,
})

const $workFilters = combine({
  plan: $openedFundPlan,
  name: $planSearchText,
  sort: $sortPlanWorkListCriteria,
  group: $selectedGroupId,
  filter: filterWorkForm.$values,
})

sample({
  clock: planSearchWorks,
  source: $workFilters,
  fn: (params) => mapWorkParams({ ...params, offset: 0 }),
  target: loadPlanWorksFx,
})

sample({
  clock: onPaginate,
  source: $workFilters,
  fn: mapWorkParams,
  target: paginatePlanWorksFx,
})

forward({
  from: clearPlan,
  to: clearPlanFilter,
})

forward({
  from: clearPlanFilter,
  to: [resetTree, filterWorkForm.reset],
})

forward({
  from: setPlanWorkVolume,
  to: setPlanWorkVolumeFx,
})

sample({
  clock: getPopupData,
  fn: (work_id) => ({
    work_id,
  }),
  target: getPopupDataFx,
})

sample({
  clock: $openedFundPlanId,
  filter: Boolean,
  target: getAnalystFx,
})

sample({
  clock: onWorkInputError,
  fn: () => ({
    content: 'Нельзя указывать объём работы больший, чем остаток к выполнению',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

createToast({
  effect: setPlanWorkVolumeFx,
  doneText: 'Изменения сохранены',
})
