import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { Loader, NewDivider, palette } from '@/ui'

import {
  CurrencyCol,
  GridWrapper,
  Label,
} from '../parts'
import { Avans, DateRow, SummaryBody } from '../containers'
import { $summary, sendSummaryInfoFx } from '../../model/private'


export const SummaryTable = React.memo(() => {
  const summary = useStore($summary)
  const isPending = useStore(sendSummaryInfoFx.pending)

  if (!summary) return null

  return (
    <Wrapper>
      <GridWrapper>
        <Label>Стоимость, руб</Label>
        <Label>Текущая, руб</Label>
        <Label>Скорректированная, руб</Label>
        <Label>Принято, руб</Label>
      </GridWrapper>
      <NewDivider />

      <SummaryBody />
      <NewDivider />
      <Avans />

      <GridWrapper>
        <Label>Выплаченный аванс</Label>
        <CurrencyCol
          value={summary.avans.paid_advance || '0.00'}
          deltaValue={null}
        />
      </GridWrapper>

      <NewDivider />

      <DateRow />

      {isPending && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
`

const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  opacity: 0.8;
  border-radius: inherit;
`
