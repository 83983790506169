import React from 'react'
import styled, { css } from 'styled-components'

import { ActionButton } from './ActionButton'

type Props = {
  availableActions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  canDownload: boolean
  isEndAlign?: boolean
  isAnswerRequired: boolean | string
  isDownloading: boolean
  onAdditionalRequestClicked: () => void
  onCopy: () => void
  onDownload: () => void
  onDelete: () => void
}

export const DocumentActionButtons = ({
  availableActions,
  canDownload,
  isEndAlign,
  isAnswerRequired,
  isDownloading,
  onAdditionalRequestClicked,
  onCopy,
  onDownload,
  onDelete,
}: Props) => {
  const canCopy = availableActions.can_copy
  const canDelete = availableActions.can_delete
  const canOpenAdditionalRequest = availableActions.additional_request
  const hasBadge = Boolean(canOpenAdditionalRequest && isAnswerRequired)

  return (
    <Wrapper isEndAlign={isEndAlign}>
      <ActionButton
        icon={hasBadge ? 'mailWithDot' : 'mail'}
        color={hasBadge ? 'accent100' : 'grey60'}
        isDisabled={!canOpenAdditionalRequest}
        // TODO неплохо бы поменять тут data-test-id
        dataTestId="button-document-dialog"
        onClick={onAdditionalRequestClicked}
      />
      <ActionButton
        icon="copyDoc"
        isDisabled={!canCopy}
        dataTestId="button-document-copy"
        onClick={onCopy}
      />
      <ActionButton
        icon="download"
        isDisabled={!canDownload || isDownloading}
        dataTestId="button-document-download"
        onClick={onDownload}
      />
      <ActionButton
        icon="delete"
        isDisabled={!canDelete}
        dataTestId="button-document-delete"
        onClick={onDelete}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isEndAlign?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;

  ${({ isEndAlign }) => isEndAlign && css`
    justify-content: flex-end;
  `}
`
