import React from 'react'

import { numberWithSpaces } from '@/lib/number-represent'

import { CellNumberInput } from './CellNumberInput'
import { CellDateInput } from './CellDateInput'
import { PaletteColor } from '../../palette'

type Props = {
  value: string
  onSubmit: (val: string) => void
  onSanitazeChange?: (val: string) => string
  defaultSubmitValue?: string
  suffix?: string
  ceilType?: 'date' | 'currency'
  placeholder?: string
  color?: PaletteColor
  hasAbsoluteIcon?: boolean
}

export const EditableValue = ({
  value,
  onSubmit,
  onSanitazeChange,
  defaultSubmitValue,
  suffix,
  ceilType = 'currency',
  placeholder,
  color,
  hasAbsoluteIcon,
}: Props) => {
  const [inputVal, setInputVal] = React.useState(value)

  const handleInputChange = (newVal: string) => {
    let value = newVal
    if (onSanitazeChange) {
      value = onSanitazeChange(newVal)
    }
    setInputVal(value)
    if (ceilType === 'date') {
      onSubmit(newVal)
    }
  }

  const handleBlur = () => {
    if (inputVal === value) return
    if (!inputVal && defaultSubmitValue) return onSubmit(defaultSubmitValue)
    onSubmit(inputVal)
  }

  const handleFocus = () => {
    setInputVal(value)
  }

  return (
    <>
      {
        ceilType !== 'date' && (
          <CellNumberInput
            onFocus={handleFocus}
            onBlur={handleBlur}
            displayValue={numberWithSpaces(value)}
            onChange={handleInputChange}
            inputValue={inputVal}
            suffix={suffix}
            textAlign="right"
            placeholder={placeholder}
            decimalScale={2}
            decimalSeparator="."
            hasAbsoluteIcon={hasAbsoluteIcon}
            color={color}
            isCentered
          />
        )
      }
      {
        ceilType === 'date' && (
          <CellDateInput
            dataTestId="summary-date-input"
            onChange={handleInputChange}
            inputValue={inputVal}
            textAlign="right"
          />
        )
      }
    </>
  )
}
