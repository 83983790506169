import { DocFlowDocSubtype } from '@/dal'

export const getDocSubtypeNameByAlias = (subtype: DocFlowDocSubtype) => {
  const aliasMap = {
    [DocFlowDocSubtype.SMR]: 'СМР',
    [DocFlowDocSubtype.PD_RD]: 'ПД+РД',
    [DocFlowDocSubtype.BANK_WARRANTY]: 'Банковская гарантия',
    [DocFlowDocSubtype.CONTRACT_GUARANTEE]: 'Договор поручительства',
    [DocFlowDocSubtype.ADDITIONAL_WORK]: 'Дополнительные работы',
    [DocFlowDocSubtype.CHANGE_CONDITION]: 'Изменение условий',
    [DocFlowDocSubtype.TYPE_ACT]: 'Постоплата',
    [DocFlowDocSubtype.TYPE_SMR]: 'Аванс на СМР',
    [DocFlowDocSubtype.TYPE_THIRD_PARTIES]: 'Аванс на материалы',
  }

  return aliasMap[subtype]
}
