import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { combine } from 'effector'

import { noop } from '@/lib/noop'
import {
  ModalLayoutV2,
  SimpleLoader,
  palette,
} from '@/ui'

import { CreateForm, ModalTitle } from '../containers'
import {
  $isOpen,
  closeModal,
  createEstimateFolderFx,
  createEstimateRowFx,
} from '../../../model/create-table-item.private'

const $showLoader = combine([
  createEstimateRowFx.pending,
  createEstimateFolderFx.pending,
], ([a, b]) => a || b)

export const CreateTableItem = React.memo(() => {
  const isOpen = useStore($isOpen)
  const showLoader = useStore($showLoader)
  if (!isOpen) return null

  return (
    <ModalLayoutV2
      closeModal={showLoader ? noop : closeModal}
      isCloseByClickOnOverlay={false}
    >
      <Wrapper>
        {showLoader
          ? <LoaderWrapper><SimpleLoader /></LoaderWrapper>
          : (
            <>
              <ModalTitle />
              <CreateForm />
            </>
          )}
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px 0 20px;
  position: relative;
  width: 550px;
`

const LoaderWrapper = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`
