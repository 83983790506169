import { createGlobalStyle } from 'styled-components'
import { TextMLight, TextSLight, palette } from '../v2'

export default createGlobalStyle`
  body {
    font-family: FuturaFuturis;
    background-color: ${palette.grey10};
    color: #fff;
    margin: 0;
    padding: 0;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;
  }
  html {
    height: 100%;
  }
  #root {
    height: 100%;
    display: flex;
  }


  h1, h2, h3, h4, h5, p {
    margin: 0;
    font-weight: unset;
  }

  a {
    color: ${palette.accent100};
    transition: color 0.15s linear;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: ${palette.accent80};
      }
    }
  }

  ::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #A3946B;
  }

  ::selection {
    color: #fff;
    background: #A3946B;
  }

  .ScrollbarsCustom-TrackY {
    width: 4px !important;
  }

  // react-day-picker styles

  .rdp {
    --rdp-accent-color: ${palette.accent100};
    --rdp-outline-selected: none;
    --rdp-cell-size: 36px;
    --rdp-background-color: transparent;

    margin: 20px 24px;
  }

  .rdp-head {
    height: 48px;
  }
  
  .rdp-head_cell {
    ${TextSLight}
  }

  .rdp-cell {
    position: relative;
  }

  .rdp-row:not(:last-child) .rdp-cell {
    padding-bottom: 2px;
  }

  .rdp-day {
    ${TextMLight}
    border: 1px solid transparent;
    color: ${palette.grey90};
    overflow: unset;
    border-radius: 100px !important;
    transition: 0.15s linear;
    transition-property: background-color, border-color, color;

    &[disabled] {
      color: ${palette.grey60} !important;
      border-color: transparent !important;
      opacity: 1 !important;
    }

    &:focus-visible:not([disabled]) {
      border: 1px solid ${palette.grey90};
      outline: unset;
      z-index: unset;
    }
  }

  .rdp-day:focus,
  .rdp-day:hover,
  .rdp-day:active {
    @media (hover: hover) {
      &:hover {
        border-color: ${palette.grey90};
        color: unset;
      }
    }
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: 300;
    text-decoration: underline;
  }

  .rdp-day:focus-visible:not([disabled]).rdp-day_selected:not(.rdp-day_range_middle) {
    background-color: ${palette.accent100};
    color: ${palette.white};
  }

  .rdp-day_selected {
    &:not(.rdp-day_range_middle) {
      background-color: ${palette.accent100};
      color: ${palette.white} !important;
    }

    &.rdp-day_range_middle {
      background-color: transparent;
    }
  }

  .rdp-day_range_start,
  .rdp-day_range_middle,
  .rdp-day_range_end {
    &::before {
      content: '';
      position: absolute;
      inset: -1px;
      z-index: -1;
      background-color: ${palette.accent10};
    }
  }

  .rdp-day_range_start::before {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .rdp-day_range_end::before {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .rdp-cell:first-child .rdp-day_range_middle::before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rdp-cell:last-child .rdp-day_range_middle::before {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

@keyframes table-cell-loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
`
