import { AxiosError } from 'axios'
import { attachWrapper } from '@42px/effector-extra'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx } from '../request'
import {
  AdministrationAppealFilterItem,
  AdministrativeAppealComment,
  AdministrativeAppealItemType,
  CompleteTaskPayload,
  GetAdministrativeAppealInfoParams,
  GetAdministrativeAppealInfoResponse,
  GetAdministrativeRequestTypesResponse,
  GetDocumentListParams,
  GetDocumentListResponse,
  PostAppealFormParams,
  PostAppealFormResponse,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import { AbortableRequest, Response } from '../interfaces'

export const getAdministrativeAppealInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    request_type,
    status,
    ...query
  }: AbortableRequest<GetAdministrativeAppealInfoParams>) => ({
    method: Method.get,
    url: '/responsible-tasks/tasks/',
    query: {
      ...query,
      status: status?.join(','),
      request_type: request_type?.join(','),
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetAdministrativeAppealInfoResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAdministrativeRequestTypesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest<void>) => ({
    method: Method.get,
    url: '/responsible-tasks/types/',
    signal,
  }),
  mapResult: ({ result }: Response<GetAdministrativeRequestTypesResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAppealDocumentListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, task_type, ...query }: AbortableRequest<GetDocumentListParams>) => ({
    method: Method.get,
    url: `/responsible-tasks/get-documents/${task_type}/`,
    query,
    signal,
  }),
  mapResult: ({ result }: Response<GetDocumentListResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationCommentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: AdministrativeAppealItemType['id'] }>) => ({
    method: Method.get,
    url: `/responsible-tasks/details/${id}/`,
    signal,
  }),
  mapResult: ({ result }: Response<AdministrativeAppealComment[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postAdministrativeAppealReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, ...params }: AbortableRequest<PostAppealFormParams>) => ({
    method: Method.post,
    url: '/responsible-tasks/create/',
    body: prepareFormData(params),
    signal,
  }),
  mapResult: ({ result }: Response<PostAppealFormResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const putMarkAppealAsReadReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: number }>) => ({
    method: Method.put,
    url: `/responsible-tasks/mark_as_read/${id}/`,
    signal,
  }),
  mapResult: ({ result }: Response<AdministrativeAppealItemType>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationAppealsFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest) => ({
    method: Method.get,
    url: '/responsible-tasks/tasks/filters/',
    signal,
  }),
  mapResult: ({ result }: Response<AdministrationAppealFilterItem[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAdministrationAppealsCountReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status }: AbortableRequest<{ status?: string[] }>) => ({
    method: Method.get,
    url: '/responsible-tasks/tasks/count/',
    signal,
    query: {
      status: status?.join(','),
    },
  }),
  mapResult: ({ result }: Response<{ count: number }>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const completeTaskReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: CompleteTaskPayload) => ({
    method: Method.put,
    url: `/responsible-tasks/complete_task/${id}/`,
    body: prepareFormData(body),
  }),
  mapResult: ({ result }: Response<AdministrativeAppealItemType>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
