import { useGate, useStore } from 'effector-react'
import React, { FormEvent } from 'react'
import { useForm } from 'effector-forms'
import styled from 'styled-components'

import { InputField, NewDropDownField } from '@/ui'
import { noop } from '@/lib/noop'
import {
  $selectedUser,
  $usersOptions,
  BlockUserGate,
  blockUserForm,
  blockUserFx,
} from '../../model/private'
import { ButtonRow, Title } from '../parts'

export const BlockUserForm = React.memo(() => {
  useGate(BlockUserGate)
  const selectedUser = useStore($selectedUser)
  const options = useStore($usersOptions)

  const { fields, isValid, submit } = useForm(blockUserForm)
  const isPending = useStore(blockUserFx.pending)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title
        step={2}
        title="Заполните данные формы – отключить пользователя от ЛКП"
      />
      <Content>
        <NewDropDownField
          dataTestId="user-select"
          options={options}
          placeholder="Выберите пользователя из списка"
          label="Пользователь"
          required
          onOptionClick={fields.userId.onChange}
          selectedId={fields.userId.value}
          error={fields.userId.firstError?.errorText}
          isDisabled={isPending}
        />
        <InputField
          disabled
          dataTestId="user-email-input"
          label="E-mail"
          value={selectedUser?.email || ''}
          placeholder="Почта пользователя"
          onChange={noop}
        />
      </Content>
      <ButtonRow
        isDisabled={!isValid || isPending}
        isPending={isPending}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Content = styled.div`
  display: flex;
  gap: 16px;
`
