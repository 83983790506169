import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'

import { SupportAppealTypeAlias } from '@/dal'
import { DropArea, NewFileItem, TextAreaField } from '@/ui'

import { ButtonRow, Title } from '../parts'
import { $selectedAppealType, otherProblemForm, postOtherProblemFx } from '../../model/private'

const titleMap = {
  [SupportAppealTypeAlias.LKP_ERRORS]: 'Заполните данные формы – ошибки в ЛКП',
  [SupportAppealTypeAlias.CONSULTATION]: 'Заполните данные формы – консультация по работе системы ЛКП',
  [SupportAppealTypeAlias.SUGGEST_AN_IMPROVEMENT]: 'Заполните данные формы – предложить улучшение',
}

export const OtherProblemForm = React.memo(() => {
  const type = useStore($selectedAppealType)
  const { fields, isValid, submit } = useForm(otherProblemForm)
  const isPending = useStore(postOtherProblemFx.pending)

  const handleFileChange = (files: File[]) => {
    const newVal = [
      ...fields.files.value,
      ...Array.from(files)
        .filter((item) => !fields.files.value.some((cur) => cur.name === item.name)),
    ]
    fields.files.onChange(newVal)
  }

  const handleDeleteFile = (fileName: File['name']) => {
    const newVal = [
      ...fields.files.value.filter((item) => item.name !== fileName),
    ]
    fields.files.onChange(newVal)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title
        step={2}
        title={type ? titleMap[type?.alias] : ''}
      />
      <Content>
        <TextAreaField
          dataTestId="message-input"
          label="Сообщение"
          onChange={fields.description.onChange}
          value={fields.description.value}
          placeholder="Задайте ваш вопрос или направьте обращение..."
          autoGrow
          height={100}
        />
        <DropArea
          onFiles={handleFileChange}
          height={92}
          isLoading={isPending}
        />
        {fields.files.value.length && (
          <FileList>
            {fields.files.value.map((file) => (
              <NewFileItem
                key={file.name}
                name={file.name}
                onDelete={isPending ? undefined : handleDeleteFile}
              />
            ))}
          </FileList>
        )}

      </Content>
      <ButtonRow
        isDisabled={!isValid || isPending}
        isPending={isPending}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const FileList = styled.div`
  
`
