import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import {
  H1TitleCSS,
  ModalLayout,
  themeVar,
  IconButton,
  Button,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import {
  $showPlanAgreedContractorAccept,
  agreedPlanContractorAcceptFx,
  closePlanAgreedContractorAccept,
  sendPlanAgreedContractorAccept,
} from '../../model/private'

export const AcceptAgreedContractor = () => {
  const showPlanAgreedContractorAccept = useStore($showPlanAgreedContractorAccept)
  const loading = useStore(agreedPlanContractorAcceptFx.pending)

  useOnChangeLocation(closePlanAgreedContractorAccept)

  if (!showPlanAgreedContractorAccept) return null

  return (
    <ModalLayout
      closeModal={closePlanAgreedContractorAccept}
      dataTestId="fs-agreed-to-accept-modal"
    >
      <Container>
        <Header>
          <div>Вы действительно хотите согласовать план?</div>
          <IconButton
            size={30}
            dataTestId="modal-cross-close"
            icon="cross"
            onClick={() => closePlanAgreedContractorAccept()}
            isDisable={loading}
          />
        </Header>

        <ButtonRow>

          <Button
            styleButton="third"
            dataTestId="close-agreed-contractor-modal"
            size="L"
            onClick={closePlanAgreedContractorAccept}
          >
            Отмена
          </Button>

          <Button
            styleButton="primary"
            size="L"
            dataTestId="sumbit-agreed-contractor"
            onClick={sendPlanAgreedContractorAccept}
            isLoading={loading}
          >
            Согласовать
          </Button>

        </ButtonRow>
      </Container>
    </ModalLayout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeVar('lightBackground')};
  padding: 24px;
  max-width: 680px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${H1TitleCSS}
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`

