import React from 'react'
import { useStore } from 'effector-react'

import { NotificationItem, NotificationType } from '@/ui'
import { $selectedCompany } from '@/features/my-company/model'
import { ServiceAlias, serviceLinks } from '@/features/services/model'
import { getNoun } from '@/lib/text-noun'

const $daysUntilCompletion = $selectedCompany.map(
  (company) => company?.count_days_until_completion || 0,
)

const link = serviceLinks[ServiceAlias.Accreditation].url ?? ''

export const DaysUntilExpiration = React.memo(() => {
  const days = useStore($daysUntilCompletion)

  let type: NotificationType

  if (days > 30) {
    type = NotificationType.Success
  } else if (days > 0) {
    type = NotificationType.Warning
  } else {
    type = NotificationType.Alert
  }

  const notificationBody = days > 0
    ? (
      <>
        {type === NotificationType.Success ? 'Аккредитация активна еще' : 'До окончания аккредитации осталось'}
        {' '}
        {days}
        {' '}
        {getNoun(days, 'день', 'дня', 'дней')}
      </>
    )
    : (
      <>
        Аккредитация просрочена! Необходимо заново
        {' '}
        <a href={link} target="_blank" rel="noopener noreferrer">
          подать документы на проверку
        </a>
      </>
    )

  return (
    <NotificationItem
      type={type}
      body={notificationBody}
    />
  )
})
