import {
  Domain,
  Event,
  Store,
  createDomain,
  sample,
} from 'effector'

export type SectionTabsModelParams<T> = {
  domain: Domain | undefined
  clearErrorTime?: number // ms
  clearEvent: Event<any>
  defaultValue: T
}

type SectionTabsModel<T> = {
  $activeTabId: Store<T>
  changeActiveTab: Event<T>
  resetActiveTab: Event<void>
  $invalidActiveTabId: Store<T | null>
  setInvalidActiveTab: Event<T>
}

export function createSectionTabsModel<T>({
  clearErrorTime,
  defaultValue,
  domain,
  clearEvent,
}: SectionTabsModelParams<T>): SectionTabsModel<T> {
  const d = domain || createDomain()
  const $timeoutId = d.store<NodeJS.Timeout|null>(null)
  const $activeTabId = d.store<T>(defaultValue)
  const $invalidActiveTabId = d.store<T | null>(null)
  const changeActiveTab = d.event<T>()
  const setInvalidActiveTab = d.event<T>()
  const resetActiveTab = d.event<void>()
  const resetInvalidActiveTab = d.event<void>()

  const setTimeoutFx = d.effect<void, NodeJS.Timeout, Error>(() => {
    return setTimeout(() => {
      resetInvalidActiveTab()
    }, clearErrorTime || 0)
  })
  const clearTimeoutFx = d.effect<NodeJS.Timeout, void, Error>((id) => {
    clearTimeout(id)
  })

  $timeoutId
    .on(setTimeoutFx.doneData, (_, id) => id)
    .reset(clearTimeoutFx.done, resetInvalidActiveTab)

  $activeTabId
    .on(changeActiveTab, (_, newTab) => newTab)
    .reset(resetActiveTab, clearEvent)

  $invalidActiveTabId
    .on(setInvalidActiveTab, (_, invalidTab) => invalidTab)
    .reset(resetInvalidActiveTab, clearEvent, changeActiveTab)

  sample({
    clock: setInvalidActiveTab,
    source: $timeoutId,
    filter: (id) => !id,
    target: setTimeoutFx,
  })

  sample({
    clock: [changeActiveTab, clearEvent],
    source: $timeoutId,
    filter: Boolean,
    target: clearTimeoutFx,
  })

  return {
    $activeTabId,
    $invalidActiveTabId,
    changeActiveTab,
    resetActiveTab,
    setInvalidActiveTab,
  }
}
