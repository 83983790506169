import { mergeRefs } from '@/lib/mergeRefs'
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactElement
  inputName?: string
  accept: string
} & ({
  multiple?: false
  onFile: (file: File) => void
} | {
  multiple?: true
  onFile: (file: File[]) => void
})

export const FileInput = React.forwardRef(({
  children,
  onFile,
  inputName,
  accept,
  multiple,
}: Props, ref: React.Ref<HTMLInputElement>) => {
  const innerRef = React.useRef<HTMLInputElement>(null)
  const handleFile = (value: File[] | File) => {
    onFile(!multiple ? value[0] : value)
    if (innerRef.current) {
      innerRef.current.value = ''
    }
  }
  return (
    <Wrap>
      <Label>
        <InputFile
          multiple={multiple}
          accept={accept}
          name={inputName}
          ref={mergeRefs([ref, innerRef])}
          onInput={(e: any) => handleFile(e.target.files)}
          type="file"
        />
        {children}
      </Label>
    </Wrap>
  )
})

const Wrap = styled.div`
  position: relative;
  display: inline-block;
`

const Label = styled.label`
  display: block;
  height: 100%;
`

const InputFile = styled.input.attrs({
  type: 'file',
})`
  width: 1px;
  height: 1px;
  position: absolute;
`
