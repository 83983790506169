import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { ServiceLink } from '@/ui'

import { Service } from '../../model/types'
import { $serviceLinks, openServiceLinks } from '../../model/private'

type Props = Service

export const ServiceItem = React.memo(({ alias, name, icon }: Props) => {
  const serviceLinks = useStore($serviceLinks)

  const references = serviceLinks[alias]
  const singleLink = references.url

  const handleClick = () => {
    if (!singleLink) {
      openServiceLinks(alias)
    }
  }

  const button = (
    <ServiceLink
      text={name}
      icon={icon}
      onClick={handleClick}
      dataTestId={`service-link-${alias}`}
    />
  )

  return singleLink ? (
    <Link href={singleLink} target="_blank" rel="noopener noreferrer">
      {button}
    </Link>
  ) : (
    <>
      {button}
    </>
  )
})

const Link = styled.a`
  text-decoration: none;
`
