import {
  Domain,
  Effect,
  forward,
  sample,
} from 'effector'
import { createForm } from 'effector-forms'

import {
  CommentItem,
  CommentResponse,
  DefaultHandledError,
  GetCommentsParams,
  SendCommentParams,
} from '@/dal'
import { root } from '@/root-domain'
import { createToast } from '@/features/toast-service/model'
import { rules } from '@/lib/form-validators'

export type Params = {
  domain?: Domain
  getCommentsFx: Effect<GetCommentsParams, CommentResponse, DefaultHandledError>
  postCommentsFx: Effect<SendCommentParams, CommentItem, any>
}

export const createCommentsModel = ({ domain, getCommentsFx, postCommentsFx }: Params) => {
  const d = domain || root.domain()

  const $comments = d.store<CommentItem[] | null>(null)
  const resetComments = d.event<void>()
  const addNewComment = d.event<CommentItem>()
  const newCommentAdded = d.event<CommentItem>()

  const $entityId = d.store<number|null>(null)
  const setId = d.event<number>()
  const clearId = d.event<void>()

  const getComments = d.event<void>()

  const $totalCommentsCount = d.store<null|number>(null)

  const form = createForm({
    domain: d,
    fields: {
      comment: {
        init: '',
        rules: [rules.required()],
      },
    },
  })

  $entityId
    .on(setId, (_, id) => id)
    .reset(clearId)

  $comments
    .on(getCommentsFx.doneData, (_, { results }) => results)
    .on(addNewComment, (comments, comment) => (comments ? [comment, ...comments] : null))
    .reset(resetComments)

  $totalCommentsCount
    .on(getCommentsFx.doneData, (_, { count }) => count)
    .reset(resetComments)

  forward({
    from: $entityId.updates,
    to: getComments,
  })

  forward({
    from: $entityId.updates,
    to: resetComments,
  })

  forward({
    from: addNewComment,
    to: newCommentAdded,
  })

  sample({
    clock: postCommentsFx.doneData,
    source: {
      id: $entityId,
    },
    fn: (_, comment) => comment,
    target: addNewComment,
  })

  sample({
    clock: form.formValidated,
    source: $entityId,
    filter: (id: number | null): id is number => Boolean(id),
    fn: (id, { comment }): SendCommentParams => ({
      id,
      text: comment,
    }),
    target: [postCommentsFx, form.reset],
  })

  sample({
    clock: getComments,
    source: {
      id: $entityId,
    },
    filter: (
      params: { id: number | null},
    ): params is { id: number } => Boolean(params.id),
    fn: ({ id }) => ({
      id,
    }),
    target: getCommentsFx,
  })

  createToast({
    effect: postCommentsFx,
    doneText: 'Комментарий отправлен',
  })

  return {
    setId,
    clearId,
    form,
    $comments,
    getCommentsFx,
    newCommentAdded,
    $totalCommentsCount,
  }
}

export type CommentsModel = ReturnType<typeof createCommentsModel>
