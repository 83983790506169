import { CreateAppealsPageModel, RequiredAppealItemFields } from '../types'
import { createAppealComments } from './createAppealComments'
import { createAppealsListModel } from './createAppealsListModel'

export const createAppealPageModel = <T extends RequiredAppealItemFields, S = string>({
  domain,
  getItemsFx,
  cancelItemFx,
  getCommentsFx,
  postCommentFx,
  $filterItems,
}: CreateAppealsPageModel<T, S>) => {
  const listModel = createAppealsListModel<T, S>({
    domain,
    getItemsFx,
    cancelItemFx,
    $filterItems,
  })

  const commentsModel = createAppealComments({
    domain,
    $selectedItemId: listModel.$selectedItemId,
    $selectedItem: listModel.$selectedItem,
    getCommentsFx,
    postCommentFx,
    updateAppealItem: listModel.updateItem,
    resetSelectedItem: listModel.resetSelectedItem,
  })

  return {
    listModel,
    filterModel: listModel.filterModel,
    commentsModel,
  }
}
