import { Effect, attach } from 'effector'
import { createGate } from 'effector-react'

import {
  TaskItem,
  getActiveTaskCountReqFx,
  getActiveTasksReqFx,
  getTaskFiltersReqFx,
  AdministrationAppealFilterItem,
  AdministrativeAppealItemType,
  getAdministrationAppealsFiltersReqFx,
  getAdministrativeAppealInfoReqFx,
  getAdministrationAppealsCountReqFx,
  AdministrationAppealStatus,
  AppealTaskItem,
} from '@/dal'
import { createPagination } from '@/features/shared/createPagination'

import { createSingleEffect } from '@/lib/createSingleEffect'

import { createPollingEffect } from '@/features/factories'
import { d } from './domain'
import { TabItem, TasksAndResponsesTabId } from './types'

export const TasksAndResponsesGate = createGate('TasksAndResponsesGate')
export const ActiveTaskGate = createGate('active-task')
export const AppealResponsesGate = createGate('AppealResponsesGate')

export const $selectedTabId = d.store<TasksAndResponsesTabId>(TasksAndResponsesTabId.ActiveTasks)
export const selectTab = d.event<TasksAndResponsesTabId>()

export const $taskTypeList = d.store<TabItem[]>([{ value: 'all', label: 'Все' }])
export const $selectedTaskType = d.store<TabItem['value']>('all')
export const selectTaskType = d.event<TabItem['value']>()

export const $tasksList = d.store<(TaskItem | AppealTaskItem)[] | null>(null)

export const loadMoreTasks = d.event<void>()

export const $appealResponsesCount = d.store<number | null>(null)
export const $appealItems = d.store<AdministrativeAppealItemType[]>([])

export const $appealsFilters = d.store<AdministrationAppealFilterItem[]>([])
export const $selectedAppealFilter = d.store<AdministrationAppealFilterItem['value']>('all')
export const selectAppealFilter = d.event<AdministrationAppealFilterItem['value']>()

export const {
  requestFx: singleGetActiveTasksFx,
} = createSingleEffect(
  getActiveTasksReqFx,
  {
    abortEvents: [ActiveTaskGate.close],
  },
)

type Item = TaskItem | AppealTaskItem

export const {
  $hasMore,
  $offset,
  $total,
  paginationEffect: getMoreTasksFx,
  initEffect: initTaskListFx,
} = createPagination<{type: TabItem['value']}, Item[]>({
  fetchEffect: singleGetActiveTasksFx,
  domain: d,
  limit: 20,
})

export const {
  effect: getActiveTaskCountFx,
  startPolling,
  stopPolling,
} = createPollingEffect({
  domain: d,
  effect: getActiveTaskCountReqFx as Effect<void, any, any>,
  ms: 30000,
})

const getAdministrationAppealResponsesCountFx = attach({
  effect: getAdministrationAppealsCountReqFx,
  mapParams: (params) => ({
    status: [AdministrationAppealStatus.Completed, AdministrationAppealStatus.Unread],
    ...params,
  }),
})

export const {
  requestFx: getResponsesCountFx,
} = createSingleEffect(
  getAdministrationAppealResponsesCountFx,
  {
    abortEvents: [TasksAndResponsesGate.close, AppealResponsesGate.close],
  },
)

export const {
  requestFx: getTaskFiltersFx,
} = createSingleEffect(getTaskFiltersReqFx, { abortEvents: [ActiveTaskGate.close] })

const getAdministrationAppealResponsesFx = attach({
  effect: getAdministrativeAppealInfoReqFx,
  mapParams: (params) => ({
    status: [AdministrationAppealStatus.Completed, AdministrationAppealStatus.Unread],
    ...params,
  }),
})

export const {
  requestFx: getAppealResponsesFx,
} = createSingleEffect(
  getAdministrationAppealResponsesFx,
  { abortEvents: [AppealResponsesGate.close] },
)

export const {
  requestFx: getAppealsFiltersFx,
} = createSingleEffect(
  getAdministrationAppealsFiltersReqFx, {
    abortEvents: [AppealResponsesGate.close],
  },
)
