import React from 'react'
import styled, { css } from 'styled-components'
import {
  DefaultButton,
  Icon2,
  TextL,
  palette,
  usePopup,
} from '@/ui'

import { RouterPaths, SubSystemId } from '@/features/app/model'

import { TooltipMenu } from './NavLinkComponents/TooltipMenu'
import { onClickNavItem } from '../../model'

const Links = [
  {
    name: 'В администрацию проекта',
    link: RouterPaths.NewAdministrationAppeal,
  },
  {
    name: 'В техническую поддержку',
    link: RouterPaths.CreateSupportAppeal,
  },
]

export const CreateRequestButton = () => {
  const {
    floatingStyles,
    getFloatingProps,
    close,
    getReferenceProps,
    isOpen,
    refs,
  } = usePopup({
    placement: 'bottom-start',
    sameComponentWidth: true,
  })

  const handleClick = () => {
    onClickNavItem({ subsystemId: SubSystemId.Help })
    close()
  }

  return (
    <Button isOpen={isOpen} ref={refs.setReference} {...getReferenceProps()}>
      <Icon2 icon="messages" />
      Создать обращение
      <TooltipMenu
        childLinks={Links}
        floatingStyles={floatingStyles}
        refs={refs}
        getFloatingProps={getFloatingProps}
        isOpen={isOpen}
        onLinkClick={handleClick}
      />
    </Button>
  )
}

const Button = styled(DefaultButton) <{ isOpen: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: rgba(${palette.rgbGrey}, 0.25);
  border: 1px solid rgba(${palette.rgbGrey}, 0.1);
  border-radius: 8px;
  ${TextL}
  color: ${palette.white};
  padding: 6px 20px;

  ${({ isOpen }) => isOpen && css`
    background-color: rgba(${palette.rgbGrey}, 0.4);
  `}
`
