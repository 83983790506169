import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

import { CreateSupportAppeal } from '@/features/support/create-appeal'
import { ContentContainer } from '@/ui'


export const CreateSupportAppealPage = () => (
  <Scrollbars autoHeight autoHeightMax="100%">
    <ContentContainer>
      <CreateSupportAppeal />
    </ContentContainer>
  </Scrollbars>
)
