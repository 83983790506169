import {useForm} from 'effector-forms'
import {useUnit} from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {getDateRange} from '@/lib/getDateRange'
import {InputField, NewDateInput, TextAreaField} from '@/ui'

import {editPaymentForm} from '../../../model/edit-private'
import {
  $isBlockChanges,
  $isThirdPerson,
  $paymentInfo,
  $paymentInfoFiles,
  deleteFile,
  postPaymentFilesFx,
  sendFiles,
} from '../../../model/private'
import {GridSpan} from '../../../shared-components'
import {FileField} from '../parts'
import {ThirdPersonFields} from '../containers'
import {PaymentDocsNotification} from '../../../payment-info/view/parts'


export const PaymentForm = React.memo(() => {
  // TODO оцифровка, актуализировать весь файл
  const info = useUnit($paymentInfo)
  const {fields} = useForm(editPaymentForm)
  const areFilesPending = useUnit(postPaymentFilesFx.pending)
  const files = useUnit($paymentInfoFiles)
  const isThirdPerson = useUnit($isThirdPerson)
  const isBlockChanges = useUnit($isBlockChanges)

  const {
    avans_date,
    comment,
    payment_date,
    number,
  } = fields

  const ranges = React.useMemo(() => {
    return getDateRange(payment_date.value, avans_date.value)
  }, [avans_date.value, payment_date.value])

  const printableFormLink = info?.printable_form_link

  return (
    <Wrapper>
      {isThirdPerson && (
        <>
          <GridSpan span={2}>
            <InputField
              dataTestId="invoice-number-field"
              label="№ Счета на оплату"
              onChange={number.onChange}
              value={number.value}
              onClear={number.reset}
              placeholder="Заполните поле"
              disabled={isBlockChanges}
              size="L"
              isRequired
              isInvalid={!number.isValid}
            />
          </GridSpan>

          <NewDateInput
            label="Дата счета на оплату"
            dataTestId="invoice-date-field"
            required
            size="L"
            onChange={payment_date.onChange}
            inputValue={payment_date.value}
            activeDateRange={ranges.startRange}
            disabled={isBlockChanges}
            isInvalid={!payment_date.isValid}
          />
        </>
      )}

      <GridSpan span={isThirdPerson ? 1 : 2}>
        <AdvanceDateWrapper>
          <NewDateInput
            required
            label="Дата зачета аванса"
            dataTestId="avans-date-field"
            size="L"
            onChange={avans_date.onChange}
            inputValue={avans_date.value}
            activeDateRange={ranges.endRange}
            disabled={isBlockChanges}
            isInvalid={!avans_date.isValid}
          />
        </AdvanceDateWrapper>
      </GridSpan>


      {isThirdPerson && <ThirdPersonFields/>}


      <GridSpan span={4}>
        <TextAreaField
          dataTestId="comment-field"
          label="Комментарий"
          onChange={comment.onChange}
          placeholder="Введите ваш комментарий..."
          value={comment.value}
          currentSymbolCount={comment.value.length}
          maxSymbolCount={1000}
          height={92}
          autoGrow
          showMaxLengthError
          disabled={isBlockChanges}
        />
      </GridSpan>

      {isThirdPerson && (
        <FileFieldWrapper span={4}>
          {printableFormLink && (
            <PaymentDocsNotification templateLink={printableFormLink} isThirdParties/>
          )}
          <FileField
            currValue={[]}
            onChange={sendFiles}
            isLoading={areFilesPending}
            markUploadedFileToDelete={deleteFile}
            uploadedFiles={files}
            disabled={isBlockChanges}
          />
        </FileFieldWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
`


const AdvanceDateWrapper = styled.div`
    max-width: 325px;
`

const FileFieldWrapper = styled(GridSpan)`
    display: flex;
    flex-direction: column;
    gap: 12px;
`