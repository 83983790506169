import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { SimpleLoader, TextL, TextLLight } from '@/ui'

import { $counter, $totalItems, updateMultipleKorDocItemsFx } from '../../../model/edit-multiple-items.private'

export const PendingOverlay = React.memo(() => {
  const [isShown, successfulItemCount, totalItems] = useUnit([
    updateMultipleKorDocItemsFx.pending,
    $counter,
    $totalItems,
  ])

  if (!isShown) return null

  return (
    <Wrapper>
      <SimpleLoader />

      <Container>
        <StatusWrapper>
          <SimpleLoader sizePx={16} borderWidth={2} />
          <StatusText>
            {successfulItemCount}
            /
            {totalItems}
            {' '}
            строк сохранены
          </StatusText>
        </StatusWrapper>

        <BottomText>
          Сохранение изменений может занять несколько минут.
          <br />
          Не закрывайте страницу.
        </BottomText>
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: absolute;
  inset: 1px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  // отсутствует в дизайн-системе (естественно)
  background-color: #EDEDED1A;
  backdrop-filter: blur(4px);
  border-radius: 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StatusText = styled.p`
  ${TextLLight}
  text-align: center;
`

const BottomText = styled.p`
  ${TextL}
  text-align: center;
`
