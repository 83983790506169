import { forward, sample, split } from 'effector'

import {
  DocumentAlias,
  DocumentStatuses,
  EstimateCorrectionType,
  EstimateDoc,
  isInvalidTableItems,
  sendEstimateToApproveReqFx,
} from '@/dal'

import { copyDocument, deleteDocument, onKorDocDeleted } from '@/features/document-manager/model'
import { RouterPaths, replaceNavigate } from '@/features/app/model'

import { createToast } from '@/features/toast-service/model'
import { downloadBlobFileFx } from '@/features/download-files'
import { openSendApproveModal } from '../../send-to-approve/model'
import {
  onApproveButton,
  openApproveModal,
  openSendToApproveModal,
  onDeleteButton,
  onDownloadComercialOffer,
  getComercialOfferFx,
  ckeckValidationBeforeApproveFx,
  onCancelApprove,
  cancelApproveFx,
  onCopy,
  onSecondApproving,
  onReject,
} from './private'
import {
  $allDocumentInfo,
  $documentId,
  $documentInfo,
  EstimateCorrectionGate,
  updateDocumentInfo,
} from '../../shared-model'
import { openApproveDocumentModal, openSecondApprovingModal } from '../../approve-document-modal/model'
import { getTableData, setRowType } from '../../estimate-table/model'
import { openRejectAprovingModal } from '../../reject-approving'

const cases = {
  openSendToApproveModal,
  approve: openApproveModal,
  isSecondApproving: onSecondApproving,
}

split({
  source: sample({
    clock: ckeckValidationBeforeApproveFx.doneData,
    source: $documentInfo,
    filter: Boolean,
    fn: (info) => info.features,
  }),
  match: {
    openSendToApproveModal: (features: EstimateDoc['features']) => Boolean(features.can_send_to_approving),
    approve: (features: EstimateDoc['features']) => Boolean(features.can_approve && !features.is_second_approving),
    isSecondApproving: (features: EstimateDoc['features']) => Boolean(features.can_approve && features.is_second_approving),
  },
  cases,
})

sample({
  clock: openSendToApproveModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openSendApproveModal,
})

sample({
  clock: onSecondApproving,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openSecondApprovingModal,
})

sample({
  clock: openApproveModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: openApproveDocumentModal,
})

sample({
  clock: onKorDocDeleted,
  source: {
    docId: $documentId,
    gateStatus: EstimateCorrectionGate.status,
  },
  filter: ({ docId, gateStatus }, { id }) => (gateStatus && docId === id),
  fn: () => RouterPaths.Administrative,
  target: replaceNavigate,
})

sample({
  clock: onDeleteButton,
  source: $allDocumentInfo,
  filter: (
    info: EstimateDoc | null,
  ): info is EstimateDoc => Boolean(info && info.features.can_delete),
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.KOR_DOC,
    documentName: info.name,
  }),
  target: deleteDocument,
})

sample({
  clock: onDownloadComercialOffer,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: getComercialOfferFx,
})

sample({
  clock: getComercialOfferFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'template.xlsx',
  }),
  target: downloadBlobFileFx,
})

createToast({
  effect: getComercialOfferFx,
})

sample({
  clock: onApproveButton,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => ({ id: info.id }),
  target: ckeckValidationBeforeApproveFx,
})

sample({
  clock: onCancelApprove,
  source: $documentInfo,
  filter: Boolean,
  fn: ({ id }) => ({ id }),
  target: cancelApproveFx,
})

forward({
  from: cancelApproveFx.doneData,
  to: [getTableData, updateDocumentInfo] as any,
})

sample({
  clock: [ckeckValidationBeforeApproveFx.failData, sendEstimateToApproveReqFx.failData],
  filter: (error) => isInvalidTableItems(error as any),
  fn: () => {
    return [EstimateCorrectionType.WithError]
  },
  target: setRowType,
})

createToast({
  effect: ckeckValidationBeforeApproveFx,
})

sample({
  clock: onCopy,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => {
    return {
      id: info.id,
      type: info.type,
      draft: info.conflicting_draft_kor_doc,
      documentName: info.name,
    }
  },
  target: copyDocument,
})

sample({
  clock: onReject,
  source: $documentInfo,
  filter: Boolean,
  fn: ({ id, status }) => ({ id, status }),
  target: openRejectAprovingModal,
})
