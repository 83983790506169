import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  HeadXL,
  ModalLayoutV2,
  NotificationItem,
  NotificationType,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import { $closingContractId, closeModal } from '../../model/private'
import { ButtonsRow, DocumentList, Steps } from '../containers'

export const CloseContractModal = React.memo(() => {
  const id = useUnit($closingContractId)

  useOnChangeLocation(closeModal)

  if (!id) return null

  return (
    <ModalLayoutV2
      closeModal={closeModal}
    >
      <Wrapper>
        <Title>
          Подписание закрывающих документов
        </Title>

        <MarginWrapper>
          <Steps />
        </MarginWrapper>

        <MarginWrapper>
          <NotificationItem
            type={NotificationType.Warning}
            body={(
              <>
                Прежде чем отправить закрывающие документы на подпись, ознакомьтесь с ними.
                Если вы нашли ошибку, обратитесь в
                {' '}
                <Link to={RouterPaths.NewAdministrationAppeal}>администрацию проекта</Link>
                .
              </>
            )}
          />
        </MarginWrapper>
        <DocumentList />
        <ButtonsRow />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  max-width: 450px;
  border-radius: 20px;
  padding: 32px 32px 20px;
`

const Title = styled.div`
  ${HeadXL}
  margin-bottom: 20px;
`

const MarginWrapper = styled.div`
  margin-bottom: 16px;
`

