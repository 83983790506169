import React from 'react'
import styled from 'styled-components'
import { useGate, useStore } from 'effector-react'

import {
  TabSwiper,
  palette,
} from '@/ui'

import {
  $selectedTabId, TasksAndResponsesGate, selectTab, $appealResponsesCount,
} from '../../model/private'
import { TasksAndResponsesTabId } from '../../model/types'
import { $activeTaskCount } from '../../model'
import { ActiveTasks, AppealMessages, AppealResponses } from '../containers'

export const TasksAndResponses = React.memo(() => {
  const selectedTabId = useStore($selectedTabId)
  const activeTasksCount = useStore($activeTaskCount)
  const appealResponsesCount = useStore($appealResponsesCount)

  const tabs = React.useMemo(() => ([
    {
      id: TasksAndResponsesTabId.ActiveTasks,
      label: 'Активные задачи',
      count: activeTasksCount,
    },
    // TODO временно убираем вкладку
    // {
    //   id: TasksAndResponsesTabId.AppealResponses,
    //   label: 'Ответы на обращения',
    //   count: appealResponsesCount,
    // },
  ]), [activeTasksCount, appealResponsesCount])

  useGate(TasksAndResponsesGate)

  return (
    <Wrapper>
      <Head>
        <TabSwiper activeId={selectedTabId} onItemClick={selectTab} items={tabs} size="L" />
      </Head>
      {selectedTabId === TasksAndResponsesTabId.ActiveTasks ? <ActiveTasks /> : <AppealResponses />}
      <AppealMessages />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 28px 0 0;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
`

const Head = styled.div`
  padding: 0 32px;
`
