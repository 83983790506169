import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { useForm } from 'effector-forms'
import { Link, useRouteMatch } from 'react-router-dom'

import {
  DropArea,
  InputField,
  NewButton,
  NewDropDownField,
  NewFileItem,
  TextAreaField,
  TextMLight,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { AdministrationAppealTypeAlias } from '@/dal'

import {
  $documentOptions,
  $selectedResponsibleRole,
  NewAppealFormGate,
  getDocumentListFx,
  sendFormFx,
} from '../../model/private'
import { appealForm } from '../../model/forms'
import { StepHeader, ButtonsContainer } from '../parts'

export const SecondStep = React.memo(() => {
  const router = useRouteMatch<{ alias: AdministrationAppealTypeAlias }>()

  const selectedRole = useUnit($selectedResponsibleRole)
  const documentOptions = useUnit($documentOptions)
  const isSending = useUnit(sendFormFx.pending)
  const areDocumentsLoading = useUnit(getDocumentListFx.pending)

  const { fields, isValid, submit } = useForm(appealForm)

  useGate(NewAppealFormGate, router.params.alias)

  if (!selectedRole) return null

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!isSending) submit()
  }

  const onFiles = (files: File[]) => {
    fields.files.onChange([...fields.files.value, ...files])
  }

  const onDeleteFile = (fileName: string) => {
    const files = fields.files.value.filter(({ name }) => fileName !== name)
    fields.files.onChange(files)
  }

  return (
    <Container>
      <StepHeader step={2} title="Заполните данные формы" />
      <Form onSubmit={onSubmit}>
        <FormContent>
          <div>
            <ResponsibleRole>
              {'Обращение '}
              {selectedRole.dativeName.toLowerCase()}
            </ResponsibleRole>

            <ResponsibleDescription>
              {selectedRole.description}
            </ResponsibleDescription>
          </div>

          <NewDropDownField
            label="Документ"
            selectedId={fields.document.value}
            error={fields.document.firstError?.errorText}
            onOptionClick={(id) => fields.document.onChange(id)}
            options={documentOptions}
            placeholder="Выберите документ из списка"
            dataTestId="new-administrative-appeal-document-dropdown"
            isDisabled={isSending}
            isLoading={areDocumentsLoading}
            required
            hasSearch
          />

          <InputField
            value={fields.subject.value}
            onChange={fields.subject.onChange}
            onClear={fields.subject.reset}
            error={fields.subject.firstError?.errorText}
            label="Тема"
            placeholder="Введите тему обращения"
            dataTestId="new-administrative-appeal-topic-input"
            disabled={isSending}
            isRequired
          />

          <TextAreaField
            value={fields.description.value}
            onChange={fields.description.onChange}
            error={fields.description.firstError?.errorText}
            label="Текст обращения"
            placeholder="Введите текст..."
            dataTestId="new-administrative-appeal-text-textarea"
            disabled={isSending}
            required
          />

          <FilesContainer>
            <DropArea isDisabled={isSending} onFiles={onFiles} />
            {fields.files.value.length ? (
              <FileList>
                {/* @TODO мб что-то получше name в качестве ключа */}
                {fields.files.value.map(({ name }) => (
                  <NewFileItem key={name} size="M" name={name} onDelete={onDeleteFile} isDense />
                ))}
              </FileList>
            ) : null}
          </FilesContainer>
        </FormContent>

        <ButtonsContainer>
          <BackButtonWrapper>
            <Link to={RouterPaths.NewAdministrationAppeal}>
              <NewButton
                label="Назад"
                buttonType="line-grey"
                dataTestId="new-administrative-appeal-back-btn"
              />
            </Link>
          </BackButtonWrapper>
          <Link to={RouterPaths.AdministrationAppeals}>
            <NewButton
              label="Отменить"
              buttonType="grey"
              dataTestId="new-administrative-appeal-cancel-btn"
              isDisabled={isSending}
            />
          </Link>
          <NewButton
            label="Отправить"
            type="submit"
            isDisabled={!isValid}
            isPending={isSending}
            dataTestId="new-administrative-appeal-submit-btn"
          />
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ResponsibleRole = styled.div`
  ${TextMLight}
  margin-bottom: 4px;
`

const ResponsibleDescription = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const BackButtonWrapper = styled.div`
  margin-right: auto;
`
