import React from 'react'
import { useStore } from 'effector-react'
import {
  useHistory, useLocation, useParams, useRouteMatch,
} from 'react-router-dom'

import { Loader } from '@/ui'
import { $userAuthorised, setRedirectPathAfterLogin, tokenApply } from '@/features/login/model'
import {
  pushNavigate,
  replaceNavigate,
  goBackNavigate,
  $appLoaded,
  setCurrentSubsystem,
  RouterPaths,
  setPreviousRoute,
  $currentRoute,
  setCurrentRoute,
} from '@/features/app/model'
import { subSystem } from '@/features/app/model/const'

type RouteWrapperProps = {
  subsystemId?: number
  isPrivate?: boolean
  isForceAuth?: boolean
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  children,
  isPrivate = false,
  subsystemId = subSystem[0].subsystemId,
  isForceAuth = false,
}) => {
  const route = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const appLoaded = useStore($appLoaded)
  const userAuthorised = useStore($userAuthorised)
  const currentRoute = useStore($currentRoute)
  const { token } = useParams<{ token: string }>()

  React.useEffect(() => {
    if (isForceAuth) {
      tokenApply(token)
      return
    }

    if (!userAuthorised && isPrivate && appLoaded) {
      history.replace(RouterPaths.Login)
      setRedirectPathAfterLogin(route.url)
    }
  }, [userAuthorised, appLoaded, isPrivate, history, isForceAuth, token])

  React.useEffect(() => {
    const unwatchPush = pushNavigate.watch((url) => history.push(url))
    const unwatchReplace = replaceNavigate.watch((url) => history.replace(url))
    const unwatchGoBack = goBackNavigate.watch(() => history.goBack())

    return () => {
      unwatchPush()
      unwatchGoBack()
      unwatchReplace()
    }
  }, [history])

  React.useEffect(() => {
    if (currentRoute) {
      setPreviousRoute(currentRoute)
    }
    setCurrentRoute(location.pathname)
  }, [location])

  React.useEffect(() => {
    setCurrentSubsystem(subsystemId)
  }, [subsystemId])

  if (!userAuthorised && !appLoaded) {
    return <Loader />
  }

  return <>{children}</>
}
