import { AxiosError } from 'axios'
import { RequestCancelError } from '../interfaces'
import { TextError, UserNotFound } from './errors'
import {
  DefaultErrorEnum,
  DefaultErrorResponse,
  DefaultTextResponseError,
  UserNotFoundResponseError,
} from './types'

export type DefaultHandledError =
  RequestCancelError
  | TextError
  | UserNotFound
  | AxiosError

export const defaultErrorHandler = (
  error: DefaultErrorResponse | undefined,
): DefaultHandledError => {
  if (!error) return error as unknown as RequestCancelError

  switch (error.code) {
    case DefaultErrorEnum.TextError: {
      return new TextError(error as DefaultTextResponseError)
    }
    case DefaultErrorEnum.UserNotFound: {
      return new UserNotFound(error as UserNotFoundResponseError)
    }
    default: {
      return error as AxiosError
    }
  }
}
