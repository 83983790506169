import React from 'react'
import { useStore } from 'effector-react'

import styled from 'styled-components'

import { MessageItem } from '@/ui'
import { $commentsList } from '../../../model/comments-item.private'

export const CommentsList = React.memo(() => {
  const comments = useStore($commentsList)

  return (
    <Wrapper>
      {comments.map((comment, i) => (
        <MessageItem
          key={comment.id}
          {...comment}
          prevMessageDate={comments[i - 1]?.date_time}
          isFill
          isRight={!comment.is_new}
        />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 0 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
