import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import { ArticleType } from '@/dal'
import {
  TextM,
  TextSLight,
  palette,
} from '@/ui'
import { getFullTime } from '@/lib/date'

type Props = {
  type?: ArticleType
  title: string
  description: string
  date: string
  isLoading?: boolean
  id: number
  isRead?: boolean
  onCardClick: (id: number) => void
}

export const ArticleCard = ({
  date,
  description,
  title,
  type,
  isLoading,
  id,
  isRead = true,
  onCardClick,
}: Props) => {
  const time = getFullTime(date)
  const isImportant = type === ArticleType.Important
  return (
    <Wrapper
      onClick={() => onCardClick(id)}
      isImportant={isImportant}
      isRead={isRead}
    >
      <Row>
        <Title>
          {isLoading ? <Skeleton /> : title}
        </Title>
        <Description>
          {isLoading ? <Skeleton count={3} /> : description}
        </Description>
      </Row>
      <Date dateTime={date}>
        {isLoading ? <Skeleton /> : time}
      </Date>
      {isImportant && (
        <Label>
          Важно
        </Label>
      )}
    </Wrapper>
  )
}

const Title = styled.h5`
 display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
  transition: color 0.15s linear;
  ${TextM}
`

const MARGIN_TOP = '20px'

const Wrapper = styled.div<{
  isImportant: boolean,
  isRead: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-top: ${MARGIN_TOP};
  background-color: ${palette.white};
  border-radius: 20px;
  padding: 16px;
  width: 100%;
  height: calc(100% - ${MARGIN_TOP});
  cursor: pointer;
  border: 1px solid transparent;
  transition: border-color 0.15s linear;

  ${({ isRead }) => !isRead && css`
    border-color: ${palette.accent100};
  `}

  @media (hover: hover) {
    &:hover {
      border-color: ${palette.accent100};

      ${Title} {
        color: ${palette.accent100};
      }
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Description = styled.p`
   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  ${TextSLight}
`

const Date = styled.time`
  ${TextSLight}
  color: ${palette.grey70};
`

const Label = styled.div`
  position: absolute;
  top: -20px;
  left: 7px;
  color: ${palette.accent100};
  border-radius: 15px;
  background-color: ${palette.accent10};
  padding: 5px 9px;
  border: 1px solid ${palette.accent100};
`
