import React from 'react'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { DocumentAlias } from '@/dal'
import {
  $documentInfo,
  $isDocumentPending,
  ActGate,
  ActInfo,
  ActDataBlock,
  ActHeader,
  ActReportingDatesModal,
  PaymentOrdersModal,
  Works,
  CancelApprovingModal,
  $isEditBlocking,
  ActTab,
  ActAttachments,
  $activeTabId,
  KS3Data,
  $isPIRAct,
  PirActSums,
  SmrActSums,
} from '@/features/act-constructor'
import { DocumentFilesButton } from '@/features/document-files'
import {
  ContentContainer, Loader, LoaderOverlay, RejectReasonNotification, WINDOW_WIDTH,
} from '@/ui'

export const ActPage = () => {
  const router = useRouteMatch<{ id: string }>()
  const ref = React.useRef<Scrollbars>(null)

  const [isDocumentPending, info, isPir, isBlocked, activeTab] = useUnit([
    $isDocumentPending,
    $documentInfo,
    $isPIRAct,
    $isEditBlocking,
    $activeTabId,
  ])

  const id = parseInt(router.params.id, 10)

  useGate(ActGate, id)

  if (!info && isDocumentPending) return <Loader />

  return (
    <>
      <Scrollbars ref={ref}>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <ActHeader />

            <RejectReasonNotification
              rejectReason={info?.reject_comment || info?.cancel_comment}
            />

            <Content isBlocked={isBlocked} isHidden={activeTab !== ActTab.Info}>
              <Row>
                <Col>
                  <ActInfo />
                  <ButtonsRow>
                    <ActAttachments />
                    <DocumentFilesButton
                      id={id}
                      docType={DocumentAlias.ACT}
                      status={info?.status}
                      downloadLink={info?.documents_link}
                    />
                  </ButtonsRow>
                  {isPir ? <PirActSums /> : <SmrActSums />}
                </Col>

                <Col>
                  <ActDataBlock />
                </Col>
              </Row>
            </Content>

            <Content isBlocked={isBlocked} isHidden={activeTab !== ActTab.Works}>
              <WorksWrapper>
                <Works />
              </WorksWrapper>
            </Content>

            <Content isBlocked={isBlocked} isHidden={activeTab !== ActTab.Deductions}>
              <KS3Data />
            </Content>
          </Wrapper>
        </ContentContainer>
      </Scrollbars>

      <ActReportingDatesModal />
      <PaymentOrdersModal />
      <CancelApprovingModal />
      <LoaderOverlay show={isDocumentPending} position="fixed" />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Content = styled.div<{ isBlocked: boolean, isHidden: boolean }>`
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}

  ${({ isBlocked }) => isBlocked && css`
    position: relative;
    opacity: 0.4;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  `}
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 0;
`

const WorksWrapper = styled.div`
  padding-top: 8px;
`

const ButtonsRow = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    flex-direction: column;
  }

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    & > * {
      flex: 0 1 50%;
    }
  }
`
