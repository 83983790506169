import React from 'react'
import { useGate, useStore } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { DocumentAlias } from '@/dal'
import {
  $documentInfo,
  $isDocumentPending,
  ApproveDocumentModal,
  CommercialOffer,
  ErrorNotification,
  EstimateAttachments,
  EstimateCorrectionGate,
  EstimateHeader,
  EstimateInfo,
  EstimateTable,
  SendToApproveModal,
  SummaryTable,
  RejectApprovingModal,
  KorDocTab,
} from '@/features/estimate-correction'
import { $activeTabId } from '@/features/estimate-correction/estimate-header'
import { SurveyModal } from '@/features/survey'
import { DocumentFilesButton } from '@/features/document-files'
import { downloadApprovingDocuments } from '@/features/estimate-correction/estimate-info/model'
import {
  ContentContainer,
  LayoutGrid,
  Loader,
  LoaderOverlay,
  RejectReasonNotification,
  WINDOW_WIDTH,
} from '@/ui'

export const EstimateCorrectionPage = () => {
  const router = useRouteMatch<{id: string }>()
  const ref = React.useRef<Scrollbars>(null)
  const isDocumentPending = useStore($isDocumentPending)
  const info = useStore($documentInfo)
  const activeTab = useStore($activeTabId)

  const id = parseInt(router.params.id, 10)

  useGate(EstimateCorrectionGate, id)

  if (!info && isDocumentPending) return <Loader />

  return (
    <>
      <Scrollbars ref={ref}>
        <ContentContainer isAutoHeight>
          <Wrapper>

            <Content>
              <EstimateHeader />
              <RejectReasonNotification
                rejectReason={info?.reject_comment || info?.cancel_comment}
              />
              <CommercialOffer />
              <ErrorNotification />
              <TabWrapper isActive={activeTab === KorDocTab.Info}>
                <LayoutGrid>

                  <LeftCol>
                    <EstimateInfo />
                    <ButtonRow>
                      <EstimateAttachments />
                      <DocumentFilesButton
                        id={id}
                        docType={DocumentAlias.KOR_DOC}
                        status={info?.status}
                        onClick={downloadApprovingDocuments}
                      />
                    </ButtonRow>
                  </LeftCol>

                  <RightCol>
                    <SummaryTable />
                  </RightCol>
                </LayoutGrid>
              </TabWrapper>
            </Content>

            <TabWrapper isActive={activeTab === KorDocTab.Works}>
              <EstimateTable scrollRef={ref} />
            </TabWrapper>
          </Wrapper>
        </ContentContainer>
      </Scrollbars>

      <SendToApproveModal />
      <RejectApprovingModal />
      <ApproveDocumentModal />
      <SurveyModal />
      <LoaderOverlay show={isDocumentPending} position="fixed" />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`

const TabWrapper = styled.div<{ isActive: boolean }>`
  flex-grow: 1;

  ${({ isActive }) => !isActive && css`
    display: none;
  `}
`

const Content = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 5;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    flex-direction: column;
  }

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    & > * {
      flex: 1 0;
    }
  }
`

const RightCol = styled.div`
  grid-column: span 7;
`
