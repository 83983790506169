import { AxiosError, AxiosResponse } from 'axios'
import { attachWrapper } from '@42px/effector-extra'
import { attach } from 'effector'
import { createGate } from 'effector-react'

import {
  Contract,
  DropdownItem,
  FetchFilterPayload,
  GetDocFlowFiltersResponse,
  downloadDocFlowReportReqFx,
  getDocumentFlowFiltersReqFx,
  getDocumentFlowStatusReqFx,
} from '@/dal'
import { authRequestFx, Method } from '@/dal/request'
import { createFilters } from '@/features/shared/filterFactory/createFilters'
import { statusResolve } from '@/lib/status-resolver'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'
import { ContractInfo } from './types'

export const $filterOptions = d.store<GetDocFlowFiltersResponse | null>(null)

export const $docNames = $filterOptions.map((info) => (info?.available_names ?? [])
  .map((label) => ({
    label,
    id: label,
  })))

export const $statuses = $filterOptions.map((info) => (info?.available_statuses ?? [])
  .map((id) => ({
    id,
    label: statusResolve(id),
  })))

export const $organizations = $filterOptions.map((info) => (info?.available_organizations ?? [])
  .map((label) => ({
    label,
    id: label,
  })))

export const $projects = $filterOptions.map((info) => (info?.available_projects ?? [])
  .map((label) => ({
    label,
    id: label,
  })))

// TODO посмотреть, актуален ли вообще этот эндпойнт сейчас
export const loadContractsFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/contract/',
  }),
  mapResult: ({ result }: { result: AxiosResponse<Contract[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const goBackSubsystem = d.event<string>()
export const DocumentFlowGate = createGate<ContractInfo>()
export const onClearFilter = d.event<void>()

export const onClickRefreshDocs = d.event<void>()

export const onLoadMore = d.event<void>()

// TODO вероятно, надо удалить позже
export const $statusList = d.store<DropdownItem[] | null>(null)

export const getStatusFx = attach({
  effect: getDocumentFlowStatusReqFx,
})

export const {
  requestFx: getFiltersFx,
  abortFx,
} = createSingleEffect(getDocumentFlowFiltersReqFx)

export const downloadDocFlowReport = d.event()

export const downloadDocFlowReportFx = attach({
  effect: downloadDocFlowReportReqFx,
})

export const {
  $filters,
  removeFilter,
  setFilter,
  setFilters,
  clearFilter,
} = createFilters<Omit<FetchFilterPayload, 'limit' | 'offset'>>({ status: [] }, d)
