import { DocumentProgress, DocumentStatuses } from '@/dal'

import { statusResolve } from './status-resolver'
import { getNoun } from './text-noun'

export const getProgressData = (progress: DocumentProgress | null, responsible: string | null) => {
  const result: {
    responsible: string | null
    legend: string
    progresses: [number, number]
  } = {
    responsible,
    legend: 'Нет доп. запросов',
    progresses: [0, 0],
  }

  if (progress) {
    const {
      // прогресс согласования 3 из 11
      agreed_by_default: agreed, // 3
      total_visas_default: total, // 11

      // доп. запросы 2 из 5
      agreed_additional_visas: agreedVisa, // 2
      total_additional_visas: totalVisa, // 5
    } = progress

    const totalAdditionText = totalVisa > 0 ? ` из ${totalVisa}` : ''
    result.legend = `${agreedVisa} ${getNoun(
      agreedVisa,
      'доп. запрос',
      'доп. запроса',
      'доп. запросов',
    )}${totalAdditionText}`

    // проверки заполненности бара согласования и бара доп. запросов
    const isEmptyTotalBar = total === 0
    const isEmptyVisasBar = totalVisa === 0

    // общий бар
    const totalBar = isEmptyTotalBar ? 0 : 100 * (agreed / total)

    // бар с доп. запросами
    const additionVisasBar = isEmptyVisasBar
      ? 0 : (totalBar / 2) * ((totalVisa - agreedVisa) / totalVisa)

    // бар с согласованием
    const mainVisasBar = totalBar - additionVisasBar

    result.progresses = [mainVisasBar, additionVisasBar]
  }

  return result
}

export const getActStatus = (
  status: DocumentStatuses,
  progress: DocumentProgress | null,
) => {
  const statusText = statusResolve(status)

  if (
    [
      DocumentStatuses.Declined,
      DocumentStatuses.Draft,
    ].includes(status) === false
    && progress
  ) {
    const { agreed_by_default, total_visas_default } = progress

    return `${agreed_by_default} / ${total_visas_default} ${statusText}`
  }

  return statusText
}
