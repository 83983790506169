import {
  sample,
} from 'effector'
import { condition, debounce } from 'patronum'

import {
  ActDTO,
  ActTableRow,
  ContractDirection,
  DocumentAlias,
  GetFolderContentParams,
  SendWorkVolumeFxPayload,
} from '@/dal'
import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import {
  $selectedGroupId,
  loadGroupTree,
  onSelectedGroupIdChanged,
  resetTree,
} from '@/features/group-tree/model'

import {
  sendActWorkVolumeFx,
  onWorkInputError,
  ActWorkGate,
  onFillByRemaining,
  $hiddenCellGroup,
  toggleHiddenGroup,
  fillByRemainingFx,
  $tableRoot,
  initFlatTableFx,
  paginateFlatTableFx,
  setRootUpdate,
  $tableTree,
  getActWorkFolderContentFx,
  setMapUpdate,
  closeFolders,
  $pendingFolderIds,
  getTreeTableActWorkFx,
  $tableInfo,
  getTableData,
  $isTree,
  toggleIsTree,
  uploadTemplateFx,
  templateSelected,
  updateItemsFx,
  openFolder,
  onLoadMore,
  onCloseFolder,
  setItemToFocus,
  $itemToFocus,
  resetItemToFocus,
  onEnterSubmitItem,
  $flatItems,
  $tableWorkTotal,
  onChangeSMRActWork,
  onChangePIRActWork,
} from './private'

import './filters.init'
import { $searchName, filters, resetFilters } from './filters.private'
import { TreeType } from './types'
import { mapFilters } from './helper'
import {
  $actType,
  $documentId,
  ActGate,
  updateFeatures,
  updateKSData,
} from '../../model'
import { cancelActApproveFx, sendActToApproveFx } from '../../model/header.private'

$isTree
  .on(toggleIsTree, (val) => !val)
  .reset(ActWorkGate.close)

$tableWorkTotal
  .on([initFlatTableFx.doneData, getTreeTableActWorkFx.doneData], (_, { total }) => total)
  .reset(ActWorkGate.close)

$hiddenCellGroup
  .on(toggleHiddenGroup, (isHidden) => !isHidden)
  .reset(ActWorkGate.close)

$tableRoot
  .on(getTreeTableActWorkFx.doneData, (_, { root }) => root)
  .on(setRootUpdate, (_, root) => root)
  .on(initFlatTableFx.doneData, (_, { data }) => data.items)
  .on(paginateFlatTableFx.doneData,
    (prevData, { data }) => (prevData ? [...prevData, ...data.items] : data.items))
  .reset(ActWorkGate.close)

$tableTree
  .on(getActWorkFolderContentFx.done, (map, { params, result }) => ({
    ...map,
    [params.groupId]: result,
  }))
  .on(setMapUpdate, (map, update) => ({
    ...map,
    ...update,
  }))
  .on(closeFolders, (_, map) => map)
  .reset(ActWorkGate.close, getTreeTableActWorkFx, initFlatTableFx)

$pendingFolderIds
  .on(getActWorkFolderContentFx, (arr, params) => [...arr, params.groupId])
  .on(
    getActWorkFolderContentFx.finally,
    (arr, { params }) => arr.filter((id) => id !== params.groupId),
  )
  .reset(ActWorkGate.close, getTreeTableActWorkFx, initFlatTableFx)

$tableInfo
  .on(getTreeTableActWorkFx.doneData, (_, { root, ...data }) => data)
  .on(initFlatTableFx.doneData, (_, { data: { items, ...other } }) => other)
  .on(sendActWorkVolumeFx.doneData, (_, {
    root,
    target,
    tree,
    ks2,
    ks3,
    ...other
  }) => other)
  .reset(ActWorkGate.close)

$itemToFocus
  .on(setItemToFocus, (_, id) => id)
  .reset(resetItemToFocus, ActGate.close)

sample({
  clock: onEnterSubmitItem,
  source: $flatItems,
  filter: Boolean,
  fn: (items, id) => {
    const index = items.findIndex((item) => item.id === id)
    const hasNextItem = index !== -1 && index < items.length - 1
    return hasNextItem ? items[index + 1]?.id : null
  },
  target: setItemToFocus,
})

sample({
  clock: ActWorkGate.close,
  target: resetTree,
})

sample({
  clock: ActWorkGate.open,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({
    documentType: DocumentAlias.ACT,
    documentId: id,
  }),
  target: [loadGroupTree, getTableData],
})

sample({
  clock: sendActWorkVolumeFx.doneData,
  source: {
    currentRoot: $tableRoot,
    currentTree: $tableTree,
  },
  fn: (tableData, { target, root, tree }) => ({
    target, root, tree, ...tableData,
  }),
  target: updateItemsFx,
})

sample({
  clock: sendActWorkVolumeFx.doneData,
  fn: ({ ks2, ks3, fill_by_remaining }) => ({
    ks2,
    ks3,
    is_fill_by_remaining: fill_by_remaining,
  }),
  target: updateKSData,
})

sample({
  clock: sendActWorkVolumeFx.done,
  fn: ({ params }) => params.actId,
  target: updateFeatures,
})

sample({
  clock: onWorkInputError,
  fn: () => ({
    content: 'Нельзя указывать объём работы больший, чем остаток к выполнению',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: [onChangeSMRActWork, onChangePIRActWork],
  source: {
    form: filters.$values,
    name: $searchName,
    actId: $documentId,
    groupId: $selectedGroupId,
    isTree: $isTree,
    actType: $actType,
  },
  filter: ({ actId, actType }, { workId }) => Boolean(actId && actType && workId),
  fn: (params, work): SendWorkVolumeFxPayload => {
    return {
      ...params,
      actId: params.actId as ActDTO['id'],
      actType: params.actType as ContractDirection,
      workId: work.workId,
      value: work.value,
    }
  },
  target: sendActWorkVolumeFx,
})

sample({
  clock: updateItemsFx.doneData,
  fn: ({ root }) => root,
  target: setRootUpdate,
})

sample({
  clock: updateItemsFx.doneData,
  filter: ({ tree }) => Boolean(tree),
  fn: ({ tree }) => tree as TreeType,
  target: setMapUpdate,
})

createToast({
  effect: sendActWorkVolumeFx,
  doneText: 'Изменения сохранены',
})

sample({
  clock: onFillByRemaining,
  source: $documentId,
  filter: Boolean,
  fn: (actId, isFill) => ({
    actId,
    isFill,
  }),
  target: fillByRemainingFx,
})

sample({
  clock: uploadTemplateFx.done,
  target: [resetFilters, getTableData],
})

sample({
  clock: uploadTemplateFx.doneData,
  fn: ({ ks }) => ks,
  target: updateKSData,
})

sample({
  clock: fillByRemainingFx.doneData,
  target: updateKSData,
})

const $hasDocumentId = $documentId.map(Boolean)

sample({
  clock: onLoadMore,
  source: {
    form: filters.$values,
    name: $searchName,
    actId: $documentId,
    groupId: $selectedGroupId,
    actType: $actType,
  },
  filter: $hasDocumentId,
  fn: mapFilters,
  target: paginateFlatTableFx,
})

condition({
  source: sample({
    clock: [
      getTableData,
      filters.$values,
      debounce({ source: $searchName, timeout: 700 }),
      onSelectedGroupIdChanged,
      $isTree.updates,
      fillByRemainingFx.done,
      sendActToApproveFx.done,
      cancelActApproveFx.done,
    ],
    source: {
      form: filters.$values,
      name: $searchName,
      actId: $documentId,
      groupId: $selectedGroupId,
      actType: $actType,
    },
    filter: $hasDocumentId,
    fn: mapFilters,
  }),
  if: $isTree,
  then: getTreeTableActWorkFx,
  else: initFlatTableFx,
})

sample({
  clock: templateSelected,
  source: $documentId,
  filter: Boolean,
  fn: (actId, file) => ({
    actId,
    file,
  }),
  target: uploadTemplateFx,
})

createToast({
  effect: uploadTemplateFx,
  doneText: 'Данные из Excel-шаблона успешно загружены',
  errorText: 'Произошла ошибка при загрузке Excel-шаблона',
})

sample({
  clock: onCloseFolder,
  source: $tableTree,
  fn: (map, parentId) => {
    const copyMap = { ...map }
    const closeChildren = (children: ActTableRow[] | null) => {
      children?.forEach((node) => {
        closeChildren(copyMap[node.id])
        delete copyMap[node.id]
      })
    }

    closeChildren(copyMap[parentId])
    delete copyMap[parentId]

    return copyMap
  },
  target: closeFolders,
})

sample({
  clock: openFolder,
  source: {
    form: filters.$values,
    name: $searchName,
    actId: $documentId,
    actType: $actType,
  },
  filter: ({ actId, actType }, groupId) => Boolean(groupId && actId && actType),
  fn: ({
    form,
    name,
    actId,
    actType,
  }, groupId): GetFolderContentParams => {
    return {
      ...form,
      name,
      actId: actId as number,
      groupId: groupId as string,
      actType: actType as ContractDirection,
    }
  },
  target: getActWorkFolderContentFx,
})
