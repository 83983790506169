import { DocumentAlias } from '@/dal'
import { RouterPaths } from '@/features/app/model'

const map = {
  [RouterPaths.ActPage]: DocumentAlias.ACT,
  [RouterPaths.Payment]: DocumentAlias.PAYMENT,
  [RouterPaths.FundingSchedule]: DocumentAlias.BUDGET,
  [RouterPaths.PlanPage]: DocumentAlias.BUDGET,
}


export const getDocumentAliasByRouterPath = (
  path: RouterPaths,
): DocumentAlias | null => map[path] ?? null
