import { useStore } from 'effector-react'
import React from 'react'
import styled, { css } from 'styled-components'

import { $summaryTotalSums } from '@/features/estimate-correction/shared-model'
import { TABLE_BOX_SHADOW, TABLE_HEADER_THICK_BORDER_STYLE } from '@/ui'

import { $hiddenCellGroups } from '../../../model/public'
import { HeaderCell, TableGridWrapper } from '../parts'
import { toggleCellVisibility } from '../../../model/private'
import {
  FIRST_GROUP_ID,
  SECOND_GROUP_ID,
  TABLE_BORDER_STYLE,
  THIRD_GROUP_ID,
} from '../../../model/const'

type Props = {
  isScrolled: boolean
}

export const TableHeader = React.memo(({ isScrolled }: Props) => {
  const hiddenCols = useStore($hiddenCellGroups)
  const summaryTotals = useStore($summaryTotalSums)

  const hiddenFirstGroup = hiddenCols.includes(FIRST_GROUP_ID)
  const hiddenSecondGroup = hiddenCols.includes(SECOND_GROUP_ID)
  const hiddenThirdGroup = hiddenCols.includes(THIRD_GROUP_ID)

  const handleExpandButton = React.useCallback((group: number) => {
    toggleCellVisibility(group)
  }, [toggleCellVisibility])

  return (
    <Wrapper>
      <Row
        hiddenFirstGroup={hiddenFirstGroup}
        hiddenSecondGroup={hiddenSecondGroup}
        hiddenThirdGroup={hiddenThirdGroup}
        as="td"
      >

        <StickyWrapper hasRightDivider={hiddenFirstGroup} hasShadow={isScrolled}>
          <HeaderCell label="Наименование услуг, работ и материалов" />
        </StickyWrapper>

        {!hiddenFirstGroup && (
          <>
            <HeaderCell isHiddenCell label="Примечание" />
            <HeaderCell isHiddenCell label="Статья оборотов" />
          </>
        )}

        <HeaderCell
          label="Ед. изм"
          groupNumber={FIRST_GROUP_ID}
          isExpanded={!hiddenFirstGroup}
          expandButtonZIndex={isScrolled ? undefined : 10}
          onExpandButton={handleExpandButton}
        />

        <HeaderCell
          label="Количество по смете"
          hasRightDivider={hiddenSecondGroup}
        />

        {!hiddenSecondGroup && (
          <>
            <HeaderCell isHiddenCell label="Принято" />
            <HeaderCell isHiddenCell label="На согласовании" />
            <HeaderCell isHiddenCell label="Остаток" />
          </>
        )}
        <HeaderCell
          label="Цена материалов"
          groupNumber={SECOND_GROUP_ID}
          onExpandButton={handleExpandButton}
          isExpanded={!hiddenSecondGroup}
        />
        <HeaderCell label="Цена работ" />
        <HeaderCell
          label="Общая цена"
          hasRightDivider={hiddenThirdGroup}
        />

        {!hiddenThirdGroup && (
          <>
            <HeaderCell
              isHiddenCell
              label="Стоимость материалов"
              totalCost={summaryTotals?.materialTotalCost}
            />
            <HeaderCell
              isHiddenCell
              label="Стоимость работ"
              totalCost={summaryTotals?.workTotalCost}
            />
          </>
        )}

        <HeaderCell
          label="Общая стоимость"
          totalCost={summaryTotals?.totalCost}
          groupNumber={THIRD_GROUP_ID}
          onExpandButton={handleExpandButton}
          isExpanded={!hiddenThirdGroup}
        />
        <HeaderCell label="Обоснование изменений" />

        <CellGroup>
          <HeaderCell label="Дополнительные реквизиты" />
          <CellRow>
            <HeaderCell label="Поставщик" />
            <HeaderCell label="Характеристики" />
            <HeaderCell label="Марка" />
          </CellRow>
        </CellGroup>
      </Row>
    </Wrapper>
  )
})

const Wrapper = styled.tr`
  display: block;
`

const Row = styled(TableGridWrapper)`
  top: 0;
  grid-template-rows: 65px;
  width: fit-content;
  padding: 0;
`

const StickyWrapper = styled.div<{ hasShadow: boolean, hasRightDivider: boolean }>`
  position: sticky;
  left: 0;
  height: 100%;
  z-index: 5;
  border-right: ${TABLE_BORDER_STYLE};
  clip-path: inset(0px -16px 0px 0px);
  transition: 0.15s linear box-shadow;

  ${({ hasShadow }) => hasShadow && css`
    box-shadow: ${TABLE_BOX_SHADOW};
  `}

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
  `}
`

const CellGroup = styled.div`
  display: grid;
  grid-auto-rows: minmax(0, 1fr);
  grid-column: span 3;

  &:not(:last-child) {
    border-right: ${TABLE_BORDER_STYLE};
  }

  & > div {
    border-right: none !important;
  } 
`

const CellRow = styled.div`
  display: flex;
  border-top: ${TABLE_BORDER_STYLE};
`
