import { sample, forward } from 'effector'

import { EstimateDoc, EstimateTableRow } from '@/dal'
import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'

import { $documentId, onUpdateSummary } from '../../shared-model'
import {
  acceptChanges,
  cancelChanges,
  editItemForm,
  updateEstimateItemFx,
  editRow,
  resetEditableState,
  $editTargetId,
  $editTarget,
} from './edit-table-item.private'
import { $isMultipleEditModeEnabled } from './edit-multiple-items.private'
import {
  $isTreeTable,
  TableGate,
  closeFolders,
  getEstimateTreeTableFx,
  initFlatTableFx,
} from './private'
import { onItemUpdate } from './public'
import { ERROR_FOR_FOLDER, ERROR_FOR_ROW } from './const'
import { getFormValuesFromRow } from './helpers/getFormValuesFromRow'

$editTargetId
  .on(editRow, (_, id) => id)
  .on(closeFolders, (id, { ids }) => (id && ids.includes(id) ? null : id))
  .reset(resetEditableState)

forward({
  from: [
    TableGate.close,
    cancelChanges,
    updateEstimateItemFx.done,
    initFlatTableFx,
    getEstimateTreeTableFx,
  ],
  to: resetEditableState,
})

forward({
  from: resetEditableState,
  to: editItemForm.reset,
})

sample({
  clock: $editTarget.updates,
  filter: Boolean,
  fn: getFormValuesFromRow,
  target: editItemForm.set,
})

createToast({
  effect: updateEstimateItemFx,
  doneText: 'Изменения сохранены',
})

forward({
  from: acceptChanges,
  to: editItemForm.submit,
})

sample({
  clock: [editItemForm.submit, editItemForm.validate],
  source: {
    isValid: editItemForm.$isValid,
    target: $editTarget,
  },
  filter: ({ isValid }) => !isValid,
  fn: ({ target }) => ({
    content: target?.is_folder ? ERROR_FOR_FOLDER : ERROR_FOR_ROW,
    icon: NotificationType.Alert,
    showClose: true,
  }),
  target: showToast,
})

sample({
  clock: editItemForm.formValidated,
  source: {
    documentId: $documentId,
    isTree: $isTreeTable,
    item: $editTarget,
    isMultipleEdit: $isMultipleEditModeEnabled,
  },
  filter: ({ documentId, item, isMultipleEdit }) => Boolean(documentId && item && !isMultipleEdit),
  fn: ({ documentId, isTree, item }, form) => ({
    documentId: documentId as number,
    itemId: item?.id as EstimateTableRow['id'],
    isFolder: Boolean(item?.is_folder),
    isTree,
    ...form,
    unit: form.unit as number,
  }),
  target: updateEstimateItemFx,
})

sample({
  clock: updateEstimateItemFx.done,
  fn: ({ params, result }) => ({
    documentId: params.documentId,
    ...result,
  }),
  target: onItemUpdate,
})

sample({
  clock: updateEstimateItemFx.done,
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({ docId: params.documentId, summary: result.summary as EstimateDoc['summary_table'] }),
  target: onUpdateSummary,
})
