import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { AdministrationAppealTypeAlias, ContractDirection } from '@/dal'
import {
  LoaderOverlay,
  NewTextButton,
  NotificationItem,
  NotificationType,
  palette,
  SimpleLoader,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { RouterPaths } from '@/features/app/model'

import {
  Cell, DiffCell, EditableCell, TableRow, TableWrapper,
} from '../parts'
import {
  $documentInfo, $editableFields, $isDraft,
} from '../../../model'
import {
  countAdvanceRemainderFx,
  onCountAdvanceRemainder,
  onSetDeductionOfPaidAdvance,
  openCompensationsModal,
  setDeductionOfPaidAdvanceFx,
} from '../../../model/info.private'
import { $isActionPending } from '../../../model/header.private'
import { CompensationsModal, OtherDeductions } from '../containers'

export const KS3Data = React.memo(() => {
  const info = useUnit($documentInfo)
  const isDraft = useUnit($isDraft)
  const editableFields = useUnit($editableFields)
  const isPending = useUnit(setDeductionOfPaidAdvanceFx.pending)
  const [isCountAdvancePending, isActionPending] = useUnit([
    countAdvanceRemainderFx.pending,
    $isActionPending,
  ])

  if (!info) return null

  const isPIR = info.act_type === ContractDirection.PDRD

  const { compensation_documents_info } = info
  const {
    guarantee_deductions, advans, advans_remainder, total,
  } = info.ks3
  const approveColor = isDraft ? 'grey70' : 'grey90'

  const isRemainderBtnDisabled = (
    !advans_remainder?.can_approve || isCountAdvancePending || isActionPending
  )

  return (
    <Container>
      <Wrapper>
        <TableWrapper>
          <TableRow type="header">
            <Cell color="grey90" isBold isUppercase isFill>
              Удержания
            </Cell>
            <Cell>% по договору</Cell>
            <Cell>Заявлено, руб</Cell>
            <Cell>Принято, руб</Cell>
          </TableRow>

          {advans_remainder && (
            <TableRow>
              <Cell isFill>Остаток незачтенного аванса</Cell>
              <Cell>
                <AdvanceRemainder>
                  <Cell color="grey90">{numberWithSpaces(advans_remainder.declared)}</Cell>
                  {advans_remainder.approving_available && (
                  <RemainderButtonWrapper>
                    <NewTextButton
                      label="Зачесть"
                      textStyle="M"
                      dataTestId="act-advance-remainder-btn"
                      tooltip="Зачесть максимально доступную сумму ранее выплаченного аванса"
                      tooltipWidth={200}
                      isDisabled={isRemainderBtnDisabled}
                      hasUnderlineOnHover
                      onClick={onCountAdvanceRemainder}
                    />
                    {isCountAdvancePending && <SimpleLoader sizePx={16} borderWidth={2} />}
                  </RemainderButtonWrapper>
                  )}
                </AdvanceRemainder>
              </Cell>
              <Cell />
            </TableRow>
          )}

          <TableRow>
            <Cell isFill>Зачёт ранее выплаченного аванса</Cell>

            {!isPIR ? (
              <Cell color="grey90">
                {advans.percent}
                %
              </Cell>
            ) : (
              <Cell />
            )}

            {editableFields?.ks3_advans_declared && !isActionPending ? (
              <EditableCell
                value={advans.declared}
                onSubmit={(sum) => onSetDeductionOfPaidAdvance(Number(sum))}
                tooltipText="Вы можете изменить сумму зачета аванса;
                    если зачет аванса не предусмотрен, то требуется поставить 0"
              />
            ) : (
              <Cell color="grey90">{numberWithSpaces(advans.declared)}</Cell>
            )}
            <Cell>
              <Cell color={approveColor}>{numberWithSpaces(advans.approved)}</Cell>
              {advans.diff && (
                <DiffCell>{advans.diff}</DiffCell>
              )}
            </Cell>
          </TableRow>
        </TableWrapper>

        <TableWrapper>
          {guarantee_deductions && (
            <TableRow>
              <Cell isFill>Гарантийные удержания</Cell>
              <Cell color="grey90">
                {guarantee_deductions.percent}
                %
              </Cell>
              <Cell color="grey90">{numberWithSpaces(guarantee_deductions.declared)}</Cell>
              <Cell>
                <Cell color={approveColor}>{numberWithSpaces(guarantee_deductions.approved)}</Cell>
                {guarantee_deductions.diff && (
                  <DiffCell>{guarantee_deductions.diff}</DiffCell>
                )}
              </Cell>
            </TableRow>
          )}

          {compensation_documents_info && (
            <TableRow>
              <Cell isFill>
                <NewTextButton
                  label="Компенсация затрат Застройщика по электроэнергии"
                  textStyle="ML"
                  dataTestId="open-compensations-modal-btn"
                  hasUnderline
                  onClick={openCompensationsModal}
                />
              </Cell>
              <Cell />
              <Cell />
              <Cell color="grey90">
                {numberWithSpaces(compensation_documents_info.total_sum)}
              </Cell>
            </TableRow>
          )}

          <OtherDeductions />

          <TableRow type="summary">
            <Cell isFill isBold isUppercase>
              {isPIR ? 'Сумма удержаний' : 'Сумма КС-3'}
            </Cell>
            <Cell />
            <Cell color="grey90" isBold>{numberWithSpaces(total.declared)}</Cell>
            <Cell isBold>
              <Cell color={approveColor} isBold>{numberWithSpaces(total.approved)}</Cell>
              {total.diff && (
              <DiffCell>{total.diff}</DiffCell>
              )}
            </Cell>
          </TableRow>
        </TableWrapper>

        {isDraft && (
          <NotificationItem
            type={NotificationType.Warning}
            body={(
              <>
                Если проценты по договору для удержаний заведены неверно, обратитесь к
                {' '}
                <NewTextButton
                  label="ответственному по договору"
                  textStyle="ML"
                  url={`${RouterPaths.NewAdministrationAppeal}/${AdministrationAppealTypeAlias.Contract}`}
                  dataTestId="act-responsible-link"
                  isNewBlank
                  isInline
                />
              </>
              )}
          />
        )}
        <LoaderOverlay show={isPending} />
      </Wrapper>

      <CompensationsModal />
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  height: fit-content;
  padding: 20px 24px;
  background-color: ${palette.white};
  border-radius: 20px;
`

const AdvanceRemainder = styled.div`
  position: relative;
`

const RemainderButtonWrapper = styled.div`
  position: absolute;
  left: calc(100% + 6px);
  top: 0;
  display: flex;
  gap: 4px;
`
