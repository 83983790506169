import { useStore, useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  ModalLayoutV2,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import {
  $documentId,
  $isSecondApproving,
  closeModal,
} from '../../model/private'
import { SecondApproving } from '../containers/SecondApproving'
import { Approving } from '../containers'

export const ApproveDocumentModal = React.memo(() => {
  const id = useStore($documentId)
  const isSecondApproving = useUnit($isSecondApproving)

  useOnChangeLocation(closeModal)

  if (id === null) return null

  return (
    <ModalLayoutV2
      closeModal={closeModal}
      dataTestId={`approve-modal-${id}`}
    >
      <Wrapper>
        {isSecondApproving ? <SecondApproving /> : <Approving />}
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
`
