import { forward } from 'effector'
import { $data, ChartGate, getChartDataFx } from './private'

$data
  .on(getChartDataFx.doneData, (_, data) => data)
  .reset(ChartGate.close)

forward({
  from: ChartGate.open,
  to: getChartDataFx,
})
