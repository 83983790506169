import { DefaultHandledError } from '@/dal'
import { AxiosError } from 'axios'
import {
  attach,
  createEffect,
  createStore,
  Effect,
  forward,
  Unit,
} from 'effector'

export function createSingleEffect<Params, Done>(
  handler: (params: Params) => Promise<Done>,
  units?: {abortEvents?: Unit<any>[]},
): {
    requestFx: Effect<Params, Done, DefaultHandledError | AxiosError>,
  abortFx: Effect<void, AbortController, Error>
} {
  const $signal = createStore(new AbortController())

  const abortFx = attach({
    source: $signal,
    effect(ctrl) {
      ctrl.abort()
      return new AbortController()
    },
  })

  const requestFx = createEffect<Params, Done, DefaultHandledError>(async (params) => {
    const { signal } = await abortFx()
    return handler({ ...params, signal })
  })

  $signal
    .on(abortFx.doneData, (_, ctrl) => ctrl)

  if (units?.abortEvents) {
    forward({
      from: units.abortEvents,
      to: abortFx,
    })
  }

  return { requestFx, abortFx }
}
