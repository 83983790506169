import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../../palette'
import { TextM } from '../../typography'

type Props = {
  label: string
  id: string | number | null
  isActive: boolean
  onClick: (val: string | number | null) => void
}

export const PillowButton = ({
  isActive,
  label,
  onClick,
  id,
}: Props) => (
  <Wrapper isActive={isActive} onClick={() => onClick(id)}>
    {label}
  </Wrapper>
)


const Wrapper = styled.div<{isActive: boolean}>`
  ${TextM}
  width: max-content;
  display: inline-flex;
  border-radius: 100px;
  color: ${palette.grey70};
  padding: 6px 13px;
  border: 1px solid ${palette.grey60};
  transition: 
    color 0.15s linear,
    background-color 0.15s linear,
    border-color 0.15s linear
  ;
  cursor: pointer;
  user-select: none;

  ${({ isActive }) => (isActive
    ? css`
      border: 1px solid ${palette.accent100};
      background-color: ${palette.accent100};
      color: ${palette.white};
    `
    : css`
      &:hover {
        border: 1px solid ${palette.accent100};
        color: ${palette.accent100};
      }
  `)}
`
