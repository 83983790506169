import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedFields } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  DefaultButton,
  DropDownList,
  FolderComponent,
  Icon2,
  NewIconButton,
  TABLE_HEADER_THICK_BORDER_STYLE,
  TextMLight,
  palette,
} from '@/ui'
import { EstimateTableRow } from '@/dal'
import { getNoun } from '@/lib/text-noun'
import { getHighlightedText } from '@/lib/getHighlightedText'
import { $documentFeatures, $hasSplittingObjects, $projectList } from '@/features/estimate-correction/shared-model'

import { ItemAction, NonFormField } from '../../../model/types'
import {
  $isTreeTable,
  onCommentButton,
  onOption,
} from '../../../model/private'
import { getActionForItem } from '../../../model/helpers'
import { TABLE_BORDER_STYLE } from '../../../model/const'
import {
  GreyText,
  NameField,
  ProjectField,
} from '../parts'
import { $searchInputValue } from '../../../model/filters.private'
import { $isResetModeEnabled } from '../../../model/reset-multiple-items.private'

type Props = {
  item: EstimateTableRow
  fields: ConnectedFields<any> | { [key: string]: NonFormField<any> }
  isExpanded: boolean
  isEditMode: boolean
  disableActionMenu: boolean
  hasRightDivider: boolean
}

const DEFAULT_ROW_PADDING = 38
const DEFAULT_FOLDER_PADDING = 20
const ROW_START_PADDING = 62
const STEP = 6
const $canCreate = $documentFeatures.map((features) => Boolean(features?.can_create_item))

export const FirstTableCell = React.memo(({
  item,
  fields,
  isExpanded,
  disableActionMenu,
  isEditMode,
  hasRightDivider,
}: Props) => {
  const isTree = useUnit($isTreeTable)
  const isResetMode = useUnit($isResetModeEnabled)
  const projects = useUnit($projectList)
  const canCreate = useUnit($canCreate)
  const hasSplittingObjects = useUnit($hasSplittingObjects)
  const searchValue = useUnit($searchInputValue)

  const {
    depth,
    id,
    is_folder,
    is_grand_tender,
    name,
    parent,
    features,
    comment_info,
  } = item

  const handleOptionClick = React.useCallback((type: ItemAction) => {
    onOption({
      id,
      type,
      parent,
      isFolder: is_folder,
    })
  }, [id, parent])

  const actions = React.useMemo(() => {
    return getActionForItem({
      canCreateRow: canCreate,
      canDelete: Boolean(features?.can_delete),
      canEdit: Boolean(features?.can_edit),
      canSplitVolume: Boolean(features?.can_split_volume),
      canReset: Boolean(features?.can_reset),
      handleOptionClick,
    })
  }, [
    handleOptionClick,
    features,
    item,
    canCreate,
  ])

  const isNew = Boolean(comment_info?.new_count)

  const commentText = React.useMemo(() => {
    if (!comment_info) return null
    const visibleCount = isNew ? comment_info.new_count : comment_info.total
    return `${isNew ? '+ ' : ''}${visibleCount} ${getNoun(visibleCount, 'комментарий', 'комментария', 'комментариев')}`
  }, [comment_info])

  const leftPadding = React.useMemo(() => {
    if (!isTree) return DEFAULT_ROW_PADDING
    let result = is_folder
      ? DEFAULT_FOLDER_PADDING : depth ? ROW_START_PADDING : DEFAULT_ROW_PADDING
    result += depth * STEP
    return result
  }, [is_folder, isTree, depth])

  const highlightedName = getHighlightedText(
    name.value,
    searchValue,
    palette.yellow30,
  )

  const highlightedProject = item.project && getHighlightedText(
    item.project.name,
    searchValue,
    palette.yellow30,
  )

  return (
    <Wrapper leftPadding={leftPadding} hasRightDivider={hasRightDivider}>
      <FolderComponent
        hasChildren={Boolean(item.children)}
        isExpanded={isExpanded}
        isFolder={is_folder}
      />

      <Info>
        {is_grand_tender && (
          <TextIconWrapper>
            <Icon2
              icon="grandTender"
              size={14}
              color="lightBlue"
            />
          </TextIconWrapper>
        )}
        <TextWrapper>

          <NameField
            id={item.id}
            isEditMode={isEditMode}
            isEditable={name.editable}
            isValid={fields.name.isValid}
            onChange={fields.name.onChange}
            value={fields.name.value}
            name={highlightedName}
          />

          <ProjectField
            id={item.id}
            isEditMode={isEditMode}
            isFolder={is_folder}
            onChange={fields.project.onChange}
            projects={projects}
            project={highlightedProject ?? ''}
            value={fields.project.value}
            isEditable={Boolean(item.project?.editable && !hasSplittingObjects)}
          />

          <GreyText>
            {item.code_1c}
          </GreyText>

          {Boolean(commentText) && (
            <CommentButton onClick={(e) => {
              e.stopPropagation()
              onCommentButton(id)
            }}
            >
              <Icon2 icon={isNew ? 'visaTask' : 'message'} size={12} />
              {commentText}
            </CommentButton>
          )}
        </TextWrapper>
      </Info>

      {!isEditMode && !isResetMode && Boolean(actions.length) && (
        <ListWrapper onClick={(e) => e.stopPropagation()}>
          <DropDownList
            options={actions}
          >
            <NewIconButton
              dataTestId={`open-menu-${id}`}
              icon="verticalDots"
              size={16}
              padding="10px"
              color="grey70"
              hoverBackgroundColor="accent20"
              hoverColor="accent100"
              borderRadius="50%"
              disabled={disableActionMenu}
            />
          </DropDownList>
        </ListWrapper>
      )}
    </Wrapper>
  )
})

type WrapperProps = {
  leftPadding: number
  hasRightDivider: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: 12px 8px 16px 38px;
  display: flex;
  gap: 10px;
  border-right: ${TABLE_BORDER_STYLE};
  height: 100%;
  ${({ leftPadding }) => css`
    padding-left: ${leftPadding}px;
  `};

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
  `}
`

const Info = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  position: relative;
  flex-grow: 1;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  flex-grow: 1;
  ${TextMLight}
`

const TextIconWrapper = styled.div`
  position: absolute;
  left: -22px;
  top: 0;
`

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CommentButton = styled(DefaultButton)`
  color: ${palette.accent80};
  transition: color 0.15s linear;
  display: flex;
  align-items: center;
  gap: 6px;

  @media(hover: hover) {
    &:hover {
      color: ${palette.accent100};
    }
  }
`
