import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import {
  HeadXL,
  Icon2,
  ModalLayoutV2,
  TextLLight,
  palette,
} from '@/ui'

import { createCommentsView } from '@/features/shared/createComments/createCommentsView'

import {
  $openedArticle,
  closeArticle,
  closeArticlesModal,
  commentsModel,
} from '../../model/private'
import { FileList } from '../containers'
import { ArticleFooter } from '../containers/ArticleFooter'

const Comments = createCommentsView(commentsModel)

export const OpenedArticle = React.memo(() => {
  const article = useStore($openedArticle)

  if (!article) return null

  return (
    <ModalLayoutV2
      closeModal={closeArticlesModal}
    >
      <Wrapper>
        <HeaderContainer>
          <Header onClick={() => closeArticle()}>
            <Icon2 size={24} icon="arrowLeft" color="grey90" />
            <Title>
              {article.title}
            </Title>
          </Header>
        </HeaderContainer>

        <Scrollbars autoHeight autoHeightMin={320} autoHeightMax="calc(70vh - 80px)">
          <FlexWrapper>

            <ContentWrapper>

              <HTMLContent dangerouslySetInnerHTML={{ __html: article.content }} />

              <FileList />

            </ContentWrapper>

            <CommentWrapper>
              <Comments />
            </CommentWrapper>

            <ArticleFooter />

          </FlexWrapper>
        </Scrollbars>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  position: relative;
  width: 700px;
  border-radius: 20px;
  color: ${palette.grey90};
`

const ContentWrapper = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HeaderContainer = styled.div`
  background-color: ${palette.white};
  padding: 32px 32px 24px;
  border-radius: 20px;
`

const Header = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

const Title = styled.h5`
  ${HeadXL}
`

const TABLE_BORDER_STYLE = `1px solid ${palette.grey40}`

const HTMLContent = styled.div`
  ${TextLLight}

  ul {
    padding-left: 1em;
  }

  ol {
    padding-left: 1.25em;
  }

  table {
    border: ${TABLE_BORDER_STYLE};
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    word-break: break-word;
  }

  td, th {
    border-left: ${TABLE_BORDER_STYLE};
    border-bottom: ${TABLE_BORDER_STYLE};
    padding: 12px;
  }

  td:first-child, th:first-child {
    border-left: none;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }
`

const CommentWrapper = styled.div`
  padding: 0 32px;  
`
