import React from 'react'
import { useStore } from 'effector-react'

import { ConfirmModalLayout } from '@/ui'

import { $documentInfo } from '../../../../shared-model'
import {
  $isResetTableConfirmOpen,
  $resetTargetItemId,
  closeResetTableConfirm,
  onConfirmReset,
  resetTableFx,
} from '../../../model/reset-table-item.private'

const $documentName = $documentInfo.map((info) => info?.name || null)

export const ResetConfirm = React.memo(() => {
  const isOpen = useStore($isResetTableConfirmOpen)
  const name = useStore($documentName)
  const isPending = useStore(resetTableFx.pending)
  const rowId = useStore($resetTargetItemId)

  const bodyText = React.useMemo(() => {
    if (rowId) return 'Внесенные изменения в строке будут сброшены, вы не сможете их вернуть. Сбросить изменения?'
    return `После сброса изменений документ ${name || ''} вернется в исходный вид. Сбросить внесенные изменения?`
  }, [name, rowId])

  return (
    <ConfirmModalLayout
      title="Сброс изменений"
      cancelLabel="Отменить"
      confirmLabel="Да, сбросить"
      dataTestId="reset-confirm"
      isPending={isPending}
      body={bodyText}
      closeModal={closeResetTableConfirm}
      isOpen={isOpen}
      onCancel={closeResetTableConfirm}
      onConfirm={onConfirmReset}
    />
  )
})
