import React from 'react'
import styled from 'styled-components'

import {
  ContentContainer,
  HeadL,
  HeadM,
  HeadXL,
  Icon2,
  ServiceLink,
  TextLLight,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { LinkCard } from '../parts'

export const HelpPage = () => (
  <ContentContainer>
    <Wrapper>
      <Title>
        Помощь и поддержка
      </Title>

      <Card>
        <CardTitle>
          Помощь
        </CardTitle>

        <Row>
          <LeftCol>
            <Cards>
              <LinkCard
                icon="supportIcon"
                link={RouterPaths.Support}
                text="Круглосуточный сервис поддержки. Вы сможете задать интересующий вас вопрос и получить ответ."
                title="Обращение в поддержку"
              />
              <LinkCard
                icon="administrative"
                link={RouterPaths.AdministrationAppeals}
                title="Обращение в администрацию проекта"
                text="Решение организационных вопросов по проекту, уточнение информации по документам и коммуникация по вопросам соблюдения сроков строительства"
              />
            </Cards>
          </LeftCol>
          <RightCol>
            <RightTop>
              <RightColText>
                Мы всегда на связи и готовы помочь вам в наших мессенджерах
              </RightColText>

              <Links>
                <Link target="_blank" href="https://telegram.me/FSK_Support_LKP">
                  <ServiceLink
                    text="Telegram"
                    icon="telegram"
                    linkStyle="secondary"
                    dataTestId="service-link-telegram"
                  />
                </Link>
                <Link target="_blank" href="https://wa.me/74956601555">
                  <ServiceLink
                    text="Whatsapp"
                    icon="whatsApp"
                    linkStyle="secondary"
                    dataTestId="service-link-whatsapp"
                  />
                </Link>
              </Links>
            </RightTop>

            <RightBottom>
              <RightColText>Телефон службы поддержки</RightColText>
              <RightInfo>Отвечаем в рабочие дни с 9:00 до 18:00</RightInfo>

              <PhoneLink href="tel:+74956601555">
                <Icon2
                  icon="phone"
                  size={20}
                  color="accent100"
                />
                +7 (495) 660-15-55, доб.: 4444,
                <br />
                нажмите 3
              </PhoneLink>
            </RightBottom>

          </RightCol>

        </Row>

      </Card>
    </Wrapper>
  </ContentContainer>
)

const Title = styled.h1`
  ${HeadXL}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Card = styled.div`
  background-color: ${palette.white};
  padding: 32px 32px 60px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CardTitle = styled.h3`
  ${HeadL}
`

const Row = styled.div`
  display: flex;
  gap: 60px;
`

const LeftCol = styled.div`
`

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  flex: 1 0 340px;
`

const RightColText = styled.div`
  ${TextLLight}
`

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const RightBottom = styled.div``

const RightInfo = styled.div`
  ${TextLLight}
  color: ${palette.grey70};
`

const PhoneLink = styled.a`
  margin-top: 16px;
  text-decoration: none;
  gap: 8px;
  display: inline-flex;
  width: max-content;
  align-content: center;
  ${HeadM}
`

const Link = styled.a`
  text-decoration: none;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Cards = styled.div`
  display: flex;
  gap: 24px;

  & > * {
    flex: 1 0;
  }
`
