import React from 'react'
import { useField } from 'effector-forms'
import { useStore } from 'effector-react'

import { NumberInputField } from '@/ui'

import { $isFormDisabled, contactForm } from '../../model/private'

export const PhoneInput = React.memo(() => {
  const { value, onChange, firstError } = useField(contactForm.fields.phone_number)
  const isDisabled = useStore($isFormDisabled)

  return (
    <NumberInputField
      dataTestId="phone-field"
      label="Телефон"
      size="L"
      placeholder="Введите данные"
      type="tel"
      required
      format="+7 ### ### ## ##"
      allowEmptyFormatting
      mask="_"
      onChange={onChange}
      value={value.replace('+7', '')}
      disabled={isDisabled}
      error={firstError?.errorText}
    />
  )
})

