import { combine } from 'effector'
import { createGate } from 'effector-react'

import {
  AdministrationAppealDocument,
  AdministrationAppealTypeAlias,
  AdministrativeRequestType,
  getAdministrativeRequestTypesReqFx,
  getAppealDocumentListReqFx,
  postAdministrativeAppealReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { statusResolve } from '@/lib/status-resolver'
import { Option, DOCUMENT_STATUS_COLOR_MAP } from '@/ui'

import { d } from './domain'
import { ResponsibleRole } from '../../shared-model/types'
import { $responsibleRoles } from '../../shared-model'
import { appealForm } from './forms'

export const NewAppealGate = createGate('NewAppeal')
export const NewAppealFormGate = createGate<AdministrationAppealTypeAlias>('NewAppealForm')

export const $selectedResponsibleAlias = d.store<ResponsibleRole['alias'] | null>(null)
export const selectResponsibleAlias = d.event<ResponsibleRole['alias']>()

export const $requestTypes = d.store<AdministrativeRequestType[] | null>(null)

export const $documentList = d.store<AdministrationAppealDocument[]>([])

export const $documentOptions = $documentList
  .map((items) => items?.map(({ id, name, status }) => {
    const statusName = statusResolve(status)
    return {
      id,
      label: name,
      subLabel: {
        text: statusName,
        color: DOCUMENT_STATUS_COLOR_MAP[status] || 'accent100',
      },
    } as Option
  }) ?? null)

export const $selectedResponsibleRole = combine(
  [$responsibleRoles, $selectedResponsibleAlias],
  ([roles, selectedAlias]) => roles.find(
    ({ alias }) => selectedAlias === alias) ?? null,
)

export const $selectedRequestType = combine(
  [$responsibleRoles, $requestTypes, $selectedResponsibleAlias],
  ([roles, requestTypes, selectedRoleAlias]) => {
    const roleRequestType = roles?.find(
      ({ alias }) => selectedRoleAlias === alias)?.alias
    return roleRequestType ?? requestTypes?.[0].name ?? null
  },
)

export const $selectedDocType = combine(
  [$documentList, appealForm.$values],
  ([documents, form]) => documents.find((doc) => doc.id === form.document)?.type ?? null,
)

export const { requestFx: sendFormFx } = createSingleEffect(postAdministrativeAppealReqFx)
export const { requestFx: getDocumentListFx } = createSingleEffect(getAppealDocumentListReqFx)
export const { requestFx: getRequestTypes } = createSingleEffect(getAdministrativeRequestTypesReqFx)
