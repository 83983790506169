import { forward, sample } from 'effector'

import { RouterPaths, replaceNavigate } from '@/features/app/model'
import { createToast, showToast } from '@/features/toast-service/model'
import { writeQueryFx } from '@/features/shared/query-string/model'
import { DocumentAlias } from '@/dal'

import { NotificationType } from '@/ui'
import {
  $documents,
  $selectedAppealType,
  FormGate,
  BlockUserGate,
  addUserForm,
  addUserFx,
  cancelButtonClicked,
  createProblemsWithApprovalFx,
  getJiraDocumentsFx,
  getMyUsersFx,
  goToTypeList,
  problemsWithApprovalForm,
  blockUserForm,
  setSelectedAppealType,
  blockUserFx,
  $createdUsers,
  otherProblemForm,
  postOtherProblemFx,
  abortGetJiraDocumentsFx,
  abortGetMyUsersFx,
  readFormQueryFx,
} from './private'
import { $appealTypes } from '../../shared-model'
import { AppealType } from '../../shared-model/types'

$selectedAppealType
  .on(setSelectedAppealType, (_, type) => type)
  .reset(FormGate.close)

$documents
  .on(getJiraDocumentsFx.doneData, (_, docs) => docs)
  .reset(FormGate.close, problemsWithApprovalForm.fields.documentType.$value)

$createdUsers
  .on(getMyUsersFx.doneData, (_, users) => users)
  .reset(BlockUserGate.close)

sample({
  clock: [
    cancelButtonClicked,
    createProblemsWithApprovalFx.doneData,
    blockUserFx.doneData,
    addUserFx.doneData,
    postOtherProblemFx.doneData,
  ],
  fn: () => RouterPaths.Support,
  target: replaceNavigate,
})

sample({
  clock: problemsWithApprovalForm.fields.documentType.$value,
  filter: Boolean,
  fn: (type) => ({ type }),
  target: getJiraDocumentsFx,
})

sample({
  clock: [FormGate.open, FormGate.state],
  source: {
    isOpen: FormGate.status,
    alias: FormGate.state,
    list: $appealTypes,
  },
  filter: ({ isOpen, alias, list }) => (isOpen && list.some((item) => item.alias === alias)),
  fn: ({ alias, list }) => list.find((item) => item.alias === alias) as AppealType,
  target: setSelectedAppealType,
})

sample({
  clock: [FormGate.open, FormGate.state],
  source: {
    isOpen: FormGate.status,
    alias: FormGate.state,
    list: $appealTypes,
  },
  filter: ({ isOpen, alias, list }) => (isOpen && !list.some((item) => item.alias === alias)),
  fn: () => ({
    content: 'Ошибка, выберите тему обращение',
    icon: NotificationType.Alert,
  }),
  target: [goToTypeList, showToast],
})

sample({
  clock: [FormGate.open],
  target: readFormQueryFx,
})

sample({
  clock: readFormQueryFx.doneData,
  filter: ({ description }) => Boolean(description),
  fn: ({ description }) => description,
  target: [otherProblemForm.fields.description.set, writeQueryFx.prepend(() => ({}))],
})

sample({
  clock: goToTypeList,
  fn: () => RouterPaths.CreateSupportAppeal,
  target: replaceNavigate,
})

forward({
  from: FormGate.close,
  to: [
    problemsWithApprovalForm.reset,
    addUserForm.reset,
    otherProblemForm.reset,
    abortGetJiraDocumentsFx,
    abortGetMyUsersFx,
  ],
})

forward({
  from: createProblemsWithApprovalFx.doneData,
  to: problemsWithApprovalForm.reset,
})

sample({
  clock: problemsWithApprovalForm.formValidated,
  fn: ({
    description,
    document,
    documentType,
    files,
  }) => ({
    doc_type: documentType as DocumentAlias,
    doc_id: document as number,
    files,
    description,
  }),
  target: createProblemsWithApprovalFx,
})

createToast({
  effect: createProblemsWithApprovalFx,
  doneText: 'Новое обращение в поддержку отправлено',
})

sample({
  clock: addUserForm.formValidated,
  fn: ({
    fio,
    email,
    phone,
    user_info,
  }) => {
    const [last_name, first_name, patronymic] = fio.split(' ')
    return {
      email,
      phone: `+7${phone}`,
      user_info,
      last_name,
      first_name,
      patronymic,
    }
  },
  target: addUserFx,
})

createToast({
  effect: addUserFx,
  doneText: 'Новое обращение в поддержку отправлено',
})

forward({
  from: BlockUserGate.open,
  to: getMyUsersFx,
})

forward({
  from: BlockUserGate.close,
  to: blockUserForm.reset,
})

sample({
  clock: blockUserForm.formValidated,
  fn: ({ userId }) => userId as number,
  target: blockUserFx,
})

createToast({
  effect: blockUserFx,
  doneText: 'Новое обращение в поддержку отправлено',
})

sample({
  clock: otherProblemForm.formValidated,
  source: $selectedAppealType,
  filter: Boolean,
  fn: (type, form) => ({
    alias: type.alias,
    ...form,
  }),
  target: postOtherProblemFx,
})

createToast({
  effect: postOtherProblemFx,
  doneText: 'Новое обращение в поддержку отправлено',
})
