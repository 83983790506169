import { DefaultTextResponseError, UserNotFoundResponseError } from './types'

export class CustomResponseError extends Error {
  data = {
    detail: '' as null | string,
    code: null as null | string,
  }

  constructor(message = '') {
    super(message)
    this.name = 'CustomResponseError'
  }
}

export class TextError extends CustomResponseError {
  data: DefaultTextResponseError

  constructor(data: DefaultTextResponseError, message = '') {
    super(message)
    this.name = 'TextError'
    this.data = data
  }
}

export class UserNotFound extends CustomResponseError {
  data: UserNotFoundResponseError

  constructor(data: UserNotFoundResponseError, message = '') {
    super(message)
    this.name = 'UserNotFound'
    this.data = data
  }
}
