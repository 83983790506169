import React from 'react'
import { useGate } from 'effector-react'
import { Route, Switch } from 'react-router-dom'

import { RouterPaths } from '@/features/app/model'

import { NewAppealWrapper } from '../parts'
import { FirstStep, SecondStep } from '../containers'
import { NewAppealGate } from '../../model/private'

export const NewAdministrativeAppeal = React.memo(() => {
  useGate(NewAppealGate)

  return (
    <NewAppealWrapper>
      <Switch>
        <Route path={RouterPaths.NewAdministrationAppeal} exact>
          <FirstStep />
        </Route>
        <Route path={`${RouterPaths.NewAdministrationAppeal}/:alias`} exact>
          <SecondStep />
        </Route>
      </Switch>
    </NewAppealWrapper>
  )
})
