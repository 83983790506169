import React from 'react'
import { useStore } from 'effector-react'

import { DocumentInfoBlock } from '@/ui'

import { $paymentInfo, onAdditionalRequestClick } from '../../../model/private'

export const DocumentApproveInfo = React.memo(() => {
  const info = useStore($paymentInfo)

  if (!info) return null

  return (
    <DocumentInfoBlock
      contract={info.contract}
      rejectionReason={info.reject_comment || info.cancel_comment || info.reason_rejection}
      progress={info.progress}
      status={info.status}
      onOpenAdditional={onAdditionalRequestClick}
      features={info.features || {}}
    />
  )
})
