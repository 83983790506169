import React from 'react'
import styled from 'styled-components'

import { palette } from '@/ui'

export const NewAppealWrapper: React.FC = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 100%;
  max-width: 700px;
  padding: 32px;
  margin-inline: auto;
  border-radius: 20px;
  background-color: ${palette.white};
`
