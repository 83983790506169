import React from 'react'
import styled from 'styled-components'

export const ButtonsContainer: React.FC = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`
