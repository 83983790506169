import queryString from 'query-string'

import { replaceNavigate } from '@/features/app/model'
import { readQueryFx, writeQueryFx } from './public'

writeQueryFx.use((source) => {
  const data = {}
  Object.keys(source).forEach((key) => {
    if (source[key]) {
      data[key] = source[key]
    }
  })
  const params = queryString.stringify(data)
  replaceNavigate(`?${params}`)
})

readQueryFx.use(() => {
  const query = window.location.href.split('?')[1]
  if (!query) return {}
  return queryString.parse(query)
})
