import { DocumentAlias } from '@/dal'

export const DEFAULT_DOCUMENT_OPTIONS = [
  {
    alias: DocumentAlias.ACT,
    name: 'Выполнение',
  },
  {
    alias: DocumentAlias.CONTRACT,
    name: 'Договор',
  },
  {
    alias: DocumentAlias.ADDITIONAL_AGREEMENT,
    name: 'Дополнительное соглашение',
  },
  {
    alias: DocumentAlias.PAYMENT,
    name: 'Платеж',
  },
  {
    alias: DocumentAlias.BUDGET,
    name: 'Планирование',
  },
]
