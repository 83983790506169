import {
  ApproveActError,
  CreateActConflictError,
  CreateActReqiredFieldError,
  TextError,
} from '@/dal'

import { d } from './domain'
import { ActModalErrorType } from './types'

export const $actModalError = d.store<ActModalErrorType | null>(null)
export const setActError = d.event<ActModalErrorType>()

export const onActTextError = d.event<TextError>()
export const onAproveActError = d.event<ApproveActError>()
export const onCreateConflictError = d.event<CreateActConflictError>()
export const onCreateActReqieredFieldError = d.event<CreateActReqiredFieldError>()
