import { createGate } from 'effector-react'

import { DocumentStatuses, EstimateLot } from '@/dal'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'

import { d } from './domain'
import { getEstimateLotFx, sendFilesFx } from './private'

export const EstimateLotGate = createGate<number>('EstimateLotGate')

export const $documentInfo = d.store<EstimateLot | null>(null)
export const $documentId = $documentInfo.map((info) => info?.id ?? null)
export const $documentStatus = $documentInfo.map((info) => info?.status ?? null)
export const $documentFeatures = $documentInfo.map((info) => info?.features ?? null)

export const $canEdit = $documentInfo.map((info) => info?.status === DocumentStatuses.Draft)
export const $isDocumentPending = getEstimateLotFx.pending

export const $canSendFiles = $documentFeatures.map((features) => (
  Boolean(features?.can_add_attachments)),
)

export const $documentAttachmentsCount = $documentInfo
  .map((info) => info?.attachments?.length ?? null)

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $documentInfo.map((info) => info?.attachments || null),
})
