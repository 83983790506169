import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton } from '@/ui'

import { StepType } from '@/dal'
import {
  $currentStepForm,
  $isLastStep,
  goToNextStep,
  goToPrevStep,
  sendSurveyResult,
} from '../../model/private'

export const StepActions = React.memo(() => {
  const [currentStepForm, isLastStep] = useUnit([$currentStepForm, $isLastStep])

  const isValid = React.useMemo(() => {
    if (currentStepForm.type === StepType.Rating) return Boolean(currentStepForm.rating)

    if (currentStepForm.type === StepType.SingleChoice) {
      return currentStepForm.choices?.some(
        ({ isChecked, can_answer_with_text, text }) => isChecked && (!can_answer_with_text || text),
      )
    }
  }, [currentStepForm])

  const onSubmitClick = () => {
    isLastStep ? sendSurveyResult() : goToNextStep()
  }

  return (
    <Wrapper>
      <NewButton label="Назад" buttonType="grey" dataTestId="survey-back-btn" isFill onClick={goToPrevStep} />
      <NewButton
        label={isLastStep ? 'Отправить ответы' : 'Далее'}
        isDisabled={!isValid}
        dataTestId="survey-next-btn"
        onClick={onSubmitClick}
        isFill
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  gap: 12px;
  padding: 24px 32px 32px;
`
