import { createGate } from 'effector-react'
import { createForm } from 'effector-forms'
import { Effect, attach, combine } from 'effector'

import {
  SupportAppealTypeAlias,
  DocumentAlias,
  JiraDocument,
  MyUsersInfo,
  addUserReqFx,
  blockUserReqFx,
  getJiraDocumentsReqFx,
  getMyUsersReqFx,
  postOtherProblemReqFx,
  postProblemsWithApprovalReqFx,
} from '@/dal'
import { rules } from '@/lib/form-validators'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { statusResolve } from '@/lib/status-resolver'
import { readQueryFx } from '@/features/shared/query-string/model'
import { Option, DOCUMENT_STATUS_COLOR_MAP } from '@/ui'

import { d } from './domain'
import { AppealType } from '../../shared-model/types'
import { DEFAULT_DOCUMENT_OPTIONS } from './consts'
import { FormQueryParams } from './types'

export const cancelButtonClicked = d.event<void>()
export const goToTypeList = d.event<void>()

export const $selectedAppealType = d.store<AppealType | null>(null)
export const setSelectedAppealType = d.event<AppealType>()

export const FormGate = createGate<SupportAppealTypeAlias>()

type DocumentsOptions = {name: string, alias: DocumentAlias}

export const $documentTypes = d.store<DocumentsOptions[]>(DEFAULT_DOCUMENT_OPTIONS)
export const $documentTypeOptions = $documentTypes
  .map((items) => items?.map((item) => ({
    label: item.name,
    id: item.alias,
  } as Option)) ?? null)

export const $documents = d.store<JiraDocument[] | null>(null)

export const $documentOptions = $documents
  .map((items) => items?.map((item) => {
    const statusName = statusResolve(item.status)

    return {
      label: item.name,
      id: item.id,
      subLabel: {
        text: statusName,
        color: DOCUMENT_STATUS_COLOR_MAP[item.status] || 'accent100',
      },
    } as Option
  }) ?? null)

export const {
  abortFx: abortGetJiraDocumentsFx,
  requestFx: getJiraDocumentsFx,
} = createSingleEffect(getJiraDocumentsReqFx)

export const createProblemsWithApprovalFx = attach({
  effect: postProblemsWithApprovalReqFx,
})

export const problemsWithApprovalForm = createForm({
  domain: d,
  fields: {
    documentType: {
      init: null as DocumentsOptions['alias'] | null,
      rules: [rules.required()],
    },
    document: {
      init: null as JiraDocument['id'] | null,
      rules: [rules.required()],
    },
    description: {
      init: '',
      rules: [rules.required()],
    },
    files: {
      init: [] as File[],
    },
  },
})

export const addUserForm = createForm({
  domain: d,
  fields: {
    fio: {
      init: '',
      rules: [rules.required(), rules.fullName()],
    },
    user_info: {
      init: '',
      rules: [rules.required()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const addUserFx = attach({
  effect: addUserReqFx,
})

export const BlockUserGate = createGate()

export const $createdUsers = d.store<MyUsersInfo[] | null>(null)
export const $usersOptions = $createdUsers.map((users) => (
  users?.map((user) => ({ label: user.fio, id: user.id })) ?? null
))
export const blockUserForm = createForm({
  fields: {
    userId: {
      init: null as number | null,
      rules: [rules.required()],
    },
  },
})

export const $selectedUser = combine({
  users: $createdUsers,
  id: blockUserForm.fields.userId.$value,
}, ({ users, id }) => users?.find((user) => user.id === id) ?? null)

export const {
  abortFx: abortGetMyUsersFx,
  requestFx: getMyUsersFx,
} = createSingleEffect(getMyUsersReqFx)

export const blockUserFx = attach({
  effect: blockUserReqFx,
})

export const otherProblemForm = createForm({
  domain: d,
  fields: {
    description: {
      init: '',
      rules: [rules.required()],
    },
    files: {
      init: [] as File[],
    },
  },
})

export const postOtherProblemFx = attach({
  effect: postOtherProblemReqFx,
})

export const readFormQueryFx: Effect<void, FormQueryParams> = attach({
  effect: readQueryFx,
})
