import React from 'react'
import styled from 'styled-components'

import { ContractItem, DocumentStatuses } from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { statusResolve } from '@/lib/status-resolver'

import { NewTextButton } from '../../buttons'
import { Icon2 } from '../../icons'
import { TextMLight } from '../../typography'

type Props = {
  id: ContractItem['id']
  name: string
  status: DocumentStatuses
}

export const AdditionalAgreementNote = ({
  id,
  name,
  status,
}: Props) => {
  return (
    <Wrapper>
      <Icon2 icon="info" color="lightBlue" />
      <Text>
        <p>
          Сформировано новое
          {' '}
          <NewTextButton
            label={name}
            textStyle="ML"
            url={`${RouterPaths.Administrative}/${id}`}
            dataTestId="additional-agreement-link"
            isInline
          />
        </p>
        <p>
          Статус — «
          {statusResolve(status)}
          »
        </p>
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`

const Text = styled.p`
  ${TextMLight}
`
