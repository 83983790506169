import { attach } from 'effector'
import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'

import {
  Plan,
  downloadFileTemplateReqFx,
  downloadPlanFilesReqFx,
  downloadPlanReportFileReqFx,
  putPlanReqFx,
  sendPlanForApproveReqFx,
  uploadTableTemplateReqFx,
} from '@/dal'
import { createFileModalModel } from '@/features/shared/filesModalFactory'
import { copyTextToClipboardFx } from '@/features/copy-text/model'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { d } from './domain'
import { $openedFundPlanFiles, $openedFundPlanId } from './public'

export const PlanGate = createGate<Plan['id']>('plan-gate')

export const contractNameClicked = d.event<void>()
export const onSendToApprove = d.event<void>()

export const onAdditionalButtonClicked = d.event<void>()

export const deletePlanClicked = d.event<void>()

export const loadTemplate = d.event<File[]>()

export const reloadButtonClicked = d.event()
export const copyMailToClipboard = d.event<string | null | undefined>()

export const onDownloadTemplate = d.event()
export const downloadFiles = d.event<void>()

export const downloadReportFile = d.event<void>()

export const onCopyPlan = d.event<void>()

export const planForm = createForm({
  fields: {
    avans_material: {
      init: '0',
    },
    comment: {
      init: '',
    },
    files: {
      init: null as File[] | null,
    },
  },
})

export const copyToClipboardInFundFx = attach({
  effect: copyTextToClipboardFx,
})

export const { requestFx: editPlanFx } = createSingleEffect(
  putPlanReqFx,
)

export const filesModalModel = createFileModalModel({
  domain: d,
  $documentId: $openedFundPlanId,
  $files: $openedFundPlanFiles,
  sendFilesFx: editPlanFx as any,
})

export const downloadPlanFilesFx = attach({
  effect: downloadPlanFilesReqFx,
})

export const downloadPlanReportFileFx = attach({
  effect: downloadPlanReportFileReqFx,
})

export const loadTemplateFx = attach({
  effect: uploadTableTemplateReqFx,
})

// @TODO Изменить endpoint
export const downloadFileTemplateFx = attach({
  effect: downloadFileTemplateReqFx,
})

export const sendPlanToApproveFx = attach({
  effect: sendPlanForApproveReqFx,
})
