import { CustomResponseError } from '../error-handler'
import { CheckInnErrorPayload, NeedChangeTableErrorPayload } from './types'

export class CheckInnNotFoundError extends CustomResponseError {
  data: CheckInnErrorPayload

  constructor(data: CheckInnErrorPayload, message = '') {
    super(message)
    this.name = 'CheckInnNotFoundError'
    this.data = data
  }
}
export class NeedChangeTableError extends CustomResponseError {
  data: NeedChangeTableErrorPayload

  constructor(data: NeedChangeTableErrorPayload, message = '') {
    super(message)
    this.name = 'NeedChangeTableError'
    this.data = data
  }
}
