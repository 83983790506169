import { DefaultErrorResponse } from '../error-handler'
import {
  CompanyItem,
  Contract,
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  FileDTO,
  RejectionReason,
} from '../interfaces'

export type PaymentDetailResponseItem = {
  status: number
  summ: number
}

export enum PaymentError {
  Not_found = 'CONTRAGENT_NOT_FOUND',
  NeedChangeTable = 'NEED_CHANGE_ERROR'
}

export type CheckInnErrorPayload = {
  code: PaymentError.Not_found,
  detail: string,
  contragent_info: {
    inn: string
    name: string
    kpp: string
    ogrn: string
  }
}
export type NeedChangeTableErrorPayload = {
  code: PaymentError.NeedChangeTable,
  detail: string,
}

export type PaymentErrorResponse =
  NeedChangeTableErrorPayload
  | CheckInnErrorPayload
  | DefaultErrorResponse

export type PaymentParamsId = string | number

export enum PaymentSubTypes {
  THIRD = 'TYPE_THIRD_PARTIES', // Оплата третьим лицам
  ACT = 'TYPE_ACT', // на основе акта
  TYPE_SMR = 'TYPE_SMR' // Оплата СМР
}

export type BillDetails = {
  number: string | null
  payment_date: string | null // YYYY-MM-DD
  avans_date: string // YYYY-MM-DD
  avans_sum: string
}

export type PaymentDocItem = {
  id: number
  number: string
  date: string // YYYY-MM-DD
  status: DocumentStatuses
  sum: string
  additional_request?: boolean
  payment_type: PaymentSubTypes
}

export type PutAvansItemParams = {
  value: string
  id: AvansTableItem['id']
}

export type GetPaymentAvansTableParams = {
  id: PaymentDocItem['id']
  showOnlyFilled: boolean
  availableAvans: boolean
}

export type AvansTableItem = {
  id: number,
  turnover_article: {
    name: string
    project: string
  },
  sum_contract: string // 48344559.38
  sum_avans: string // 29006735.63
  avans_paid: string // 13190997.17
  avans_offset: string // 0.00
  sum_avans_request: string // 4074165.00
  avans_remain: string // 13190997.17
  sum_advance_on_agreed: string // float
  advance_on_agreed: string // 123.12
}

export type Summ = {
  available_avans: string
  avans_offset: string
  avans_paid: string
  avans_remain: string
  sum_avans: string
  sum_avans_request: string
  sum_contract: string
  summa: string
  sum_advance_on_agreed: string // float
}

export type GetAvansTableResponse = {
  items: AvansTableItem[]
  sums: Summ
}

export type ThirdPersonInfo = {
  contract_id: string
  contract: string
  contragent: string
  contragent_id: string
  bankAccount: string
  bank_account_id: string
  outgoing_mail_number: string
  outgoing_mail_date: string // YYYY-MM-DD
}

export type NewContragentInfo = {
  name: string
  inn: string
  kpp: string
  ogrn: string
  biс: string
  account: string
}

export type GuaranteeContract = {
  id: number
  period: {
    date_start: string // YYYY-MM-DD
    date_end: string // YYYY-MM-DD
  }
  sum_of_security: string // float
  name: string
  is_period_expired: boolean
}

export type PaymentInfo = {
  id: PaymentDocItem['id'],
  progress: DocumentProgress | null
  features: {
    are_attachments_visible: boolean
    show_bank_warranty_splash: boolean
  } & DocumentFeatures
  type: DocumentAlias.PAYMENT
  contract: {
    id: Contract['id'],
    name: string,
    project: {
      id: number
      name: string
    }
  }
  company_id: CompanyItem['id']
  files: FileDTO[] | null
  comment: string
  name: string // Платеж ***
  last_update: string // ISODate
  pay_type: PaymentSubTypes,
  requisites: {
    payer: {
      account_number: string
      name: string
      bank_name: string
    }
    payee: {
      name: string | null
      account_number: string | null
      bank_name: string | null
    }
  }
  orders: {
    date: string // YYYY-MM-DD,
    number: string,
    sum: string,
    url: string
  }[]
  inn: string | null
  date: string // YYYY-MM-DD
  summa: string
  additional_request: boolean
  status: DocumentStatuses,
  reason_rejection: string | null
  // Заполнено по остатку или нет
  is_fill: boolean
  printable_form_link: string

  guarantee_contracts: {
    total_sum_of_security: string // float
    items: GuaranteeContract[]
  } | null

  // PaymentSubTypes.THIRD PaymentSubTypes.TYPE_SMR
  bill_details: BillDetails | null
  plan_payment_date: string | null // YYYY-MM-DD

  // PaymentSubTypes.THIRD
  third_person_info: ThirdPersonInfo | null
  contragent_request: NewContragentInfo | null

  // PaymentSubTypes.ACT
  basic_document: {
    id: number
    name: string
  } | null
  postpayment: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
}

export type PutPaymentInfoParams = {
  id: PaymentParamsId
  number: string,
  payment_date: string // YYYY-MM-DD
  avans_date: string // YYYY-MM-DD
  comment?: string | null
  isThirdPerson: boolean
} | {
  id: PaymentParamsId
  number: string,
  payment_date: string // YYYY-MM-DD
  avans_date: string // YYYY-MM-DD
  isThirdPerson: boolean
  comment: string

  inn: string
  contragent: ContragentDTO['uuid']
  current_account: ContragentDTO['bank_accounts'][0]['uuid']
  outgoing_mail_number: string
  outgoing_mail_date: string // YYYY - MM - DD
}

export type CancelPaymentApproveParams = {
  id: PaymentInfo['id']
  message: string
}

export type BankAccountDTO = {
  uuid: string
  number: string
  bik: string
  bank_name: string
}

export type ContragentContractDTO = {
  uuid: string
  name: string
}

export type ContragentDTO = {
  uuid: string
  short_name: string
  full_name: string
  inn: string
  kpp: string
  ogrn: string
  bank_accounts: BankAccountDTO[]
}

export type CreateContragentOrBankAccountParams = {
  inn: string
  bic: string
  account: string
  payment: PaymentDocItem['id']
  contragent_uuid?: string
}

export type CreateContragentResponse = NewContragentInfo

export type PostPaymentFilesParams = {
  id: PaymentParamsId
} & DefaultUpdateFileParams
