import { DefaultErrorResponse, DefaultHandledError, defaultErrorHandler } from '../error-handler'
import { ApproveActError, CreateActConflictError, CreateActReqiredFieldError } from './errors'
import {
  ActErrorResponse,
  ActErrorType,
  ActForApproveError,
  CreateActConflictErrorType,
  CreateActReqiredFieldErrorType,
} from './types'

type ActErrorHandler = ApproveActError
  | CreateActConflictError
  | CreateActReqiredFieldError
  | DefaultHandledError

export const actErrorHandler = (error: ActErrorResponse | undefined): ActErrorHandler => {
  switch (error?.code) {
    case ActErrorType.ApproveError: {
      return new ApproveActError(error as ActForApproveError)
    }
    case ActErrorType.CreateConflictError: {
      return new CreateActConflictError(error as CreateActConflictErrorType)
    }
    case ActErrorType.CreateErrorRequiredFields: {
      return new CreateActReqiredFieldError(error as CreateActReqiredFieldErrorType)
    }
    default: {
      return defaultErrorHandler(error as DefaultErrorResponse)
    }
  }
}
