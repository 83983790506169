import { sample } from 'effector'

import { DocumentAlias, EstimateLot } from '@/dal'
import {
  copyDocument,
  deleteDocument,
  onEstimateLotDeleted,
} from '@/features/document-manager/model'
import { RouterPaths, replaceNavigate } from '@/features/app/model'
import { createToast } from '@/features/toast-service/model'

import {
  onDeleteButton,
  onSendToApprove,
  onCopy,
  sendEstimateLotToApproveFx,
} from './header.private'
import { $documentId, $documentInfo, EstimateLotGate } from './public'
import { updateDocumentInfo } from './private'

sample({
  clock: onDeleteButton,
  source: $documentInfo,
  filter: (
    info: EstimateLot | null,
  ): info is EstimateLot => Boolean(info && info.features.can_delete),
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.ESTIMATE_LOT,
    documentName: info.name,
  }),
  target: deleteDocument,
})

sample({
  clock: onEstimateLotDeleted,
  source: {
    docId: $documentId,
    gateStatus: EstimateLotGate.status,
  },
  filter: ({ docId, gateStatus }, { id }) => (gateStatus && docId === id),
  fn: () => RouterPaths.EstimateLots,
  target: replaceNavigate,
})

sample({
  clock: onSendToApprove,
  source: $documentId,
  filter: Boolean,
  target: sendEstimateLotToApproveFx,
})

sample({
  clock: sendEstimateLotToApproveFx.doneData,
  target: updateDocumentInfo,
})

sample({
  clock: onCopy,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.ESTIMATE_LOT,
    documentName: info.name,
  }),
  target: copyDocument,
})

createToast({
  effect: sendEstimateLotToApproveFx,
  doneText: 'Отправлено на согласование',
})
