import React from 'react'
import styled, { css } from 'styled-components'

import { TextMLight } from '../../typography'
import { palette } from '../../palette'
import { CellInputWrapper } from './CellInputWrapper'

type Props = {
  textAlign?: 'right' | 'center' | 'left'
  value: string
  isInvalid?: boolean
  placeholder?: string
    onChange: (val: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onToggle?: (val: boolean) => void
  suffix?: string
  ref?: React.MutableRefObject<any>
}

export const CellTextInput = ({
  value,
  isInvalid,
  placeholder,
  ref,
  textAlign,
  suffix,
  onChange,
  onBlur,
  onFocus,
  onToggle,
}: Props) => (
  <CellInputWrapper
    value={value || placeholder || ''}
    suffix={suffix}
    inputRef={ref}
    textAlign={textAlign}
    isInvalid={Boolean(isInvalid)}
    onFocus={onFocus}
    onBlur={onBlur}
    onToggle={onToggle}
  >
    <Input
      onChange={(e) => onChange(e.target.value)}
      value={value}
      textAlign={textAlign}
      placeholder={placeholder}
      isInvalid={Boolean(isInvalid)}
    />
  </CellInputWrapper>
)

type InputProps = {
  textAlign?: 'right' | 'center' | 'left'
  isInvalid: boolean
}

const Input = styled.input<InputProps>`
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  transition: color 0.15s linear;
  ${TextMLight}
  width: 100%;
  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}

  ${({ isInvalid: isError }) => isError && css`
    color: ${palette.red100};
    &::placeholder {
      color: ${palette.red100};
    }
  `}
`
