import { createPollingEffect } from '@/features/factories'
import { SystemActions, getSystemActionsReqFx } from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { $userAuthorised } from '@/features/login/model'
import { d } from './domain'

export const $systemActions = d.store<SystemActions | null>(null)

export const $isReadOnlyModalOpen = d.store(false)
export const openReadOnlyModal = d.event()
export const closeReadOnlyModal = d.event()

export const onLogout = d.event()
const {
  requestFx,
} = createSingleEffect(getSystemActionsReqFx, { abortEvents: [$userAuthorised.updates] })

export const {
  effect: getSystemActionFx,
  startPolling,
  stopPolling,
} = createPollingEffect<SystemActions>({
  effect: requestFx as any,
  domain: d,
  ms: 3000,
})
