import React from 'react'
import styled, { css } from 'styled-components'

import { ResetStyleButton } from '@/ui/ResetStyleButton'

import { noop } from '@/lib/noop'
import { MediumTextCSS } from '@/ui/Typography'
import { Icon, IconName } from '../../Icon'
import { SimpleLoader } from '../../Loader'
import { themeVar } from '../../theming'
import { SmallButtonsType } from '../types'

type Props = {
  onClick: () => void
  icon?: IconName
  buttonStyle: SmallButtonsType
  dataTestId: string
  iconColor?: string
  iconHoverColor?: string
  isLoading?: boolean
  children?: React.ReactElement | string
  width?: string;
  disabled?: boolean
}

export const SmallButton = ({
  onClick,
  icon,
  buttonStyle,
  dataTestId,
  iconColor,
  isLoading,
  children,
  iconHoverColor,
  width,
  disabled,
}: Props) => (
  <Button
    disabled={disabled}
    data-testid={dataTestId}
    onClick={isLoading ? noop : onClick}
    buttonStyle={buttonStyle}
    iconHoverColor={iconHoverColor}
    iconColor={iconColor}
    width={width}
  >
    {!isLoading && icon && (
      <IconWrapper iconColor={iconColor}>
        <Icon icon={icon} />
      </IconWrapper>
    )}
    {isLoading ? <SimpleLoader sizePx={16} /> : children}
  </Button>
)

type ButtonProps = {
  buttonStyle: SmallButtonsType,
  iconHoverColor?: string,
  iconColor?: string,
  width?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = styled(ResetStyleButton)<any>`
  ${MediumTextCSS}
  line-height: 18px;
  padding: 3px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s linear;
  border-radius: 90px; 
  gap: 4px;
  height: 30px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ iconHoverColor, iconColor, width }: ButtonProps) => (
    css`
      width: ${width || '100%'};
      &:hover:not(:disabled) ${IconWrapper} {
        color: ${iconHoverColor || iconColor || 'inherit'};
      }`
  )}

  ${({ buttonStyle }: ButtonProps) => {
    if (buttonStyle === 'primary') {
      return css`
        background: ${themeVar('main')};
        color: ${themeVar('whiteText')};

        &:hover:not(:disabled) {
          opacity: 0.85;
        }
      `
    }
    if (buttonStyle === 'secondary') {
      return css`
        border: 1px solid ${themeVar('grey')};
        color: ${themeVar('textGray')};

        &:hover:not(:disabled) {
          color: ${themeVar('main')};
        }
      `
    }
    if (buttonStyle === 'third') {
      return css`
        color: ${themeVar('text')};
        border: 1px solid ${themeVar('main')};

        &:hover:not(:disabled) {
          background-color: ${themeVar('main')};
          opacity: 0.85;
          color: ${themeVar('whiteText')};
        }
      `
    }
  }}
`

const IconWrapper = styled.div<{ iconColor?: string }>`
  width: 16px;
  height: 16px;
  color: inherit;
  transition: all 0.15s linear;

  ${({ iconColor }) => iconColor && css`
    color: ${iconColor};
  `}

`
