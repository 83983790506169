import React from 'react'

export const useOverflowedContent = () => {
  const contentRef = React.useRef<any>(null)

  const [isOverflowed, setIsOverflowed] = React.useState(false)

  React.useLayoutEffect(() => {
    if (!contentRef?.current) return
    const isOverflowed = contentRef.current.clientWidth < contentRef.current.scrollWidth
    setIsOverflowed(isOverflowed)
  })

  return {
    isOverflowed,
    contentRef,
  }
}
