import { DocumentStatuses } from '@/dal'

export const statusResolve = (status: DocumentStatuses, isShort?: boolean): string => {
  const shortStatusMap = {
    [DocumentStatuses.AgreedTechSupervisor]: 'На согласовании ТН',
  }
  const statusMap = {
    [DocumentStatuses.Draft]: 'Черновик',
    [DocumentStatuses.AgreedTechSupervisor]: 'На согласовании технадзора',
    [DocumentStatuses.AgreedContractor]: 'На согласовании подрядчика',
    [DocumentStatuses.ToBeAgreed]: 'На согласовании',
    [DocumentStatuses.Approved]: 'Утвержден',
    [DocumentStatuses.Signed]: 'Подписан',
    [DocumentStatuses.Declined]: 'Отклонен',
    [DocumentStatuses.Completed]: 'Выполнен',
    [DocumentStatuses.Closed]: 'Закрыт',
    [DocumentStatuses.Sent]: 'Отправлен',
    [DocumentStatuses.WIP]: 'В работе',
    [DocumentStatuses.DocumentDemand]: 'Предоставить номера документов',
    [DocumentStatuses.SignInDiadoc]: 'Подписать документы в ДИАДОК',
    [DocumentStatuses.Payed]: 'Оплачен',
    [DocumentStatuses.Accepted]: 'Согласована',
    [DocumentStatuses.InPaying]: 'В оплате',
    [DocumentStatuses.FinalApproved]: 'Согласовано',
    [DocumentStatuses.OnCancellation]: 'На аннулировании',
    [DocumentStatuses.Cancelled]: 'Аннулирован',
    [DocumentStatuses.BudgetIsPlanned]: 'Бюджет запланирован',
    [DocumentStatuses.Irrelevant]: 'Неактуален',
    [DocumentStatuses.SignActInDiadoc]: 'Подписать АКТ в ДИАДОК',
    [DocumentStatuses.SignInClient]: 'На подписании у ЗАКАЗЧИКА',
    [DocumentStatuses.SignInSFDiadoc]: 'Подписать СФ в ДИАДОК',
    [DocumentStatuses.DocumentsWaiting]: 'Ожидание документов',
    [DocumentStatuses.ContractTerminated]: 'Расторгнут',
    [DocumentStatuses.OnSigned]: 'На подписании',
  }
  return (isShort && shortStatusMap[status]) ?? statusMap[status] ?? 'Не указан'
}
