import { CustomResponseError } from '../error-handler'
import { EstimateInvalidTableItemsError, EstimateNewConflictError } from './types'

export class HasInvalidTableItems extends CustomResponseError {
  data: EstimateInvalidTableItemsError

  constructor(data: EstimateInvalidTableItemsError, message = '') {
    super(message)
    this.name = 'EstimateInvalidTableItemsError'
    this.data = data
  }
}

export class HasNewDocConflict extends CustomResponseError {
  data: EstimateNewConflictError

  constructor(data: EstimateNewConflictError, message = '') {
    super(message)
    this.name = 'EstimateNewConflictError'
    this.data = { ...data, detail: data.detail.replace('**link**', data.name) }
  }
}
