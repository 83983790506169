import React from 'react'
import styled from 'styled-components'

import { PillowSwiper, PillowSwiperItem } from '@/ui'

type Props = {
  items: PillowSwiperItem[]
  selectedId: PillowSwiperItem['id']
  onItemClick: (id: PillowSwiperItem['id']) => void
}

export const Tabs = ({ items, selectedId, onItemClick }: Props) => {
  if (!items) return null

  return (
    <SwiperWrapper>
      <PillowSwiper
        activeId={selectedId}
        items={items}
        onItemClick={onItemClick}
      />
    </SwiperWrapper>
  )
}

const SwiperWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 32px 16px;
`
