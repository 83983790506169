
import styled from 'styled-components'
import React from 'react'
import { useStore } from 'effector-react'

import { LoaderOverlay, palette } from '@/ui'
import { updateRolesFx } from '@/features/my-company/model'

import { $roles } from '../../model/private'
import { RoleItem } from './RoleItem'

export const RoleList = React.memo(() => {
  const roles = useStore($roles)
  const isLoading = useStore(updateRolesFx.pending)

  return (
    <Wrapper>
      {roles?.map(({
        id, name, count_contacts_on_current_project, count_unique_projects_of_role_members,
      }) => (
        <ItemWrap key={id}>
          <RoleItem
            roleId={id}
            roleName={name}
            contactCount={count_contacts_on_current_project}
            projectCount={count_unique_projects_of_role_members}
          />
        </ItemWrap>
      ))}
      {isLoading && (
        <LoaderWrapper>
          <LoaderOverlay show />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.ul`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const ItemWrap = styled.li``

const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${palette.white};
`
