import { attach, Effect } from 'effector'
import { createGate } from 'effector-react'

import { $authorizedUserData } from '@/features/login/model'
import { readQueryFx } from '@/features/shared/query-string/model'

import { d } from './domain'

export const MyCompanyGate = createGate('my-company-gate')

export const $myCompanyList = $authorizedUserData.map((data) => data?.company?.map((item) => ({
  id: item.id,
  label: item.name,
})) || null)
export const selectFirstCompany = d.event<void>()

export const readMyCompanyQueryFx: Effect<void, { id?: string }> = attach({
  effect: readQueryFx,
})
