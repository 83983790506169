import { DocumentAlias, DocumentStatuses, FileDTO } from '../interfaces'

export type RequestStatuses = {
  name: string,
  count: number,
  value: string
}

export type RequestAttachment = {
  name: string,
  url: string
}

export type RequestDocument = {
  type: DocumentAlias,
  id: number,
  name: string,
  sum: string
}

export type RequestProject = {
  id: number,
  name: string
}

export type RequestStatus = {
  name: string
  value: string
}

export type AppealItemType = {
  id: number,
  can_cancel: boolean,
  request_type: {
    id: number,
    name: string
  },
  request_number: string,
  date_add: string // YYYY-MM-DD
  document: RequestDocument | null,
  project: RequestProject | null,
  status: RequestStatus,
  attachments: RequestAttachment[]
}

export type GetAppealInfoParams = {
  request_type?: string[]
  from?: string
  to?: string
  status?: string[]
}

export type GetAppealInfoResponse = {
  items: AppealItemType[],
  request_status: RequestStatuses[]
}

export type AppealComment = {
  id: number
  author: string,
  date_time: string,
  body: string,
  user_lkp: boolean
  files?: FileDTO[]
}

export type PostAppealCommentResponse = {
  item: AppealItemType
  comments: AppealComment[]
}

export type PutAppealMessageReqParams = {
  comment?: string,
  files?: File[]
  id: AppealItemType['id']
}

export type PutAppealMessageReqResponse = {
  item: AppealItemType,
  comments: AppealComment[]
}

export type GetJiraDocumentsByType = {
  type: DocumentAlias
}

export type JiraDocument = {
  status: DocumentStatuses,
  name: string,
  id: number
}

export type CreateProblemWithApproval = {
  doc_id: number
  doc_type: DocumentAlias
  description: string
  files?: File[]
}

export type AddUserParams = {
  email: string
  phone: string
  user_info: string
  first_name: string
  patronymic: string
  last_name: string
}

export type MyUsersInfo = {
  id: number,
  email: string,
  fio: string
}

export enum SupportAppealTypeAlias {
  PROBLEMS_WITH_APPROVAL = 'PROBLEMS_WITH_APPROVAL',
  ADD_NEW_USER = 'ADD_NEW_USER',
  DISABLE_USER = 'DISABLE_USER',
  LKP_ERRORS = 'LKP_ERRORS',
  CONSULTATION = 'CONSULTATION',
  SUGGEST_AN_IMPROVEMENT = 'SUGGEST_AN_IMPROVEMENT'
}

export type PostOtherProblemParams = {
  description: string
  alias: SupportAppealTypeAlias
  files?: File[]
}
