import styled, { css } from 'styled-components'
import { ContractDirection } from '@/dal'

type Props = {
  hiddenFirstGroup: boolean
  actType: ContractDirection
  isDraft?: boolean
}

export const TableGridWrapper = styled.div<Props>`
  display: grid;
  padding: 0;

  ${({ actType, hiddenFirstGroup }) => actType === ContractDirection.SMR && css`
    grid-template-columns: minmax(350px, 350fr) minmax(157px, 157fr)
      ${hiddenFirstGroup ? 'minmax(157px, 157fr)' : 'repeat(3, minmax(157px, 157fr))'}
      minmax(180px, 180fr) minmax(93px, 93fr) repeat(2, minmax(345px, 345fr));
  `}

  ${({ actType, hiddenFirstGroup, isDraft }) => actType === ContractDirection.PDRD && css`
    grid-template-columns: minmax(354px, 354fr) minmax(158px, 158fr)
      ${hiddenFirstGroup ? 'minmax(158px, 158fr)' : 'repeat(3, minmax(158px, 158fr))'}
      repeat(2, minmax(129px, 129fr))
      ${isDraft ? 'minmax(181px, 181fr)' : 'minmax(104px, 104fr)'}
      minmax(200px, 200fr) minmax(94px, 94fr) repeat(2, minmax(225px, 268fr));
  `}
`
