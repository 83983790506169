import React, { FormEvent } from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { noop } from '@/lib/noop'
import { SimpleLoader } from '@/ui/Loader'

import { ModalLayoutV2 } from '../layouts'
import { palette } from '../palette'
import { HeadXL } from '../typography'
import { NewButton } from '../buttons'

type Props = {
  closeModal: () => void
  onSubmit: () => void

  children: React.ReactElement | React.ReactElement[]
  isCloseByClickOnOverlay?: boolean
  title: string
  width?: string
  autoHeightMax?: string
  dataTestId: string
  submitLabel: string
  cancelLabel?: string

  showLoader?: boolean
  showButtons?: boolean
  isPending?: boolean
  isDisableSubmit?: boolean
}

export const FormModalWrapper = ({
  closeModal,
  onSubmit,
  isCloseByClickOnOverlay,
  showLoader,
  showButtons = true,
  title,
  children,
  dataTestId,
  autoHeightMax = '70dvh',
  width = '550px',
  isPending,
  isDisableSubmit,
  submitLabel,
  cancelLabel = 'Отменить',
}: Props) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit()
  }
  return (
    <ModalLayoutV2
      closeModal={showLoader ? noop : closeModal}
      isCloseByClickOnOverlay={isCloseByClickOnOverlay}
    >
      <Wrapper width={width}>
        {showLoader
          ? <LoaderWrapper><SimpleLoader /></LoaderWrapper>
          : (
            <>
              <Title>
                {title}
              </Title>
              <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
                <FormWrapper onSubmit={handleSubmit}>
                  {children}
                  {showButtons && (
                    <Row>
                      <NewButton
                        isFill
                        label={submitLabel}
                        dataTestId={`${dataTestId}-submit-button`}
                        type="submit"
                        isPending={isPending}
                        isDisabled={isDisableSubmit}
                      />
                      <NewButton
                        isFill
                        label={cancelLabel}
                        dataTestId={`${dataTestId}-cancel-button`}
                        onClick={closeModal}
                        isDisabled={isPending}
                        buttonType="grey"
                      />
                    </Row>
                  )}
                </FormWrapper>
              </Scrollbars>
            </>
          )}
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Title = styled.div`
  ${HeadXL}
  padding: 0 32px 12px;
`

const Wrapper = styled.div<{width: string}>`
  padding: 32px 0 20px;
  position: relative;
  width: ${({ width }) => width};
`

const LoaderWrapper = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormWrapper = styled.form`
  padding: 12px 32px 0;
`

const Row = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${palette.white};
  display: flex;
  gap: 12px;
  padding-top: 12px;
`
