import {
  ActDTO,
  ContractDirection,
  ContractItem,
  DocumentAlias,
  DocumentStatuses,
  EstimateDoc,
  ExistingDoc,
  PaymentInfo,
  Plan,
} from '@/dal'

export type TargetDocument = {
  id: number
  type: DocumentAlias
  documentName: string
  draft?: ExistingDoc
  discardedDoc?: ExistingDoc
  status?: DocumentStatuses
}

export type OnCopyDocument = {
  id: number
  type: DocumentAlias
  deletedDoc?: number | null
}

export enum DocumentManagerActions {
  Delete = 'delete',
  Copy = 'copy',
  Create = 'create'
}

export type CreateDocumentInfo = {
  contractName: string
  contractId: ContractItem['id']
  contractDirection: ContractDirection
  draft?: ExistingDoc
  discardedDoc?: ExistingDoc
}

export type CreateDocumentPayload = CreateDocumentInfo & {
  type: DocumentAlias
}

export type OnCreatedDocumentPayload = {
  id: any,
  type: DocumentAlias
} & ({
  type: DocumentAlias.KOR_DOC
} & EstimateDoc | {
  type: DocumentAlias.ACT,
  } & ActDTO | {
  type: DocumentAlias.PAYMENT
} & PaymentInfo | {
  type: DocumentAlias.BUDGET
} & Plan)
