import { attach, combine } from 'effector'

import {
  CompanyItem,
  ProjectItemType,
  RoleItemType,
  getAllContactRolesReqFx,
  getProjectsReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { $authorizedUserData } from '@/features/login/model'
import { d } from './domain'

export const $selectedCompanyId = d.store<null | CompanyItem['id']>(null)

export const $selectedCompany = combine(
  [$authorizedUserData, $selectedCompanyId],
  (([userData, selectedId]) => userData?.company?.find(({ id }) => id === selectedId)) ?? null,
)

export const $companyProjects = d.store<null|ProjectItemType[]>(null)
export const {
  abortFx: cancelGetCompanyFx,
  requestFx: getCompanyProjectFx,
} = createSingleEffect(
  attach({
    effect: getProjectsReqFx,
  }),
)

export const $allRoles = d.store<RoleItemType[]|null>(null)

export const {
  abortFx: cancelGetRolesFx,
  requestFx: getRolesFx,
} = createSingleEffect(
  attach({
    effect: getAllContactRolesReqFx,
  }),
)

export const {
  abortFx: cancelUpdateRolesFx,
  requestFx: updateRolesFx,
} = createSingleEffect(
  attach({
    effect: getAllContactRolesReqFx,
  }),
)

export const selectCompany = d.event<CompanyItem['id']>()
export const contactsUpdated = d.event<void>()
