import { attach, combine } from 'effector'

import {
  DocumentFilesInfo, DocumentFileStatus, GetDocumentFilesPayload, getDocumentFilesReqFx,
} from '@/dal'
import { createSectionTabsModel } from '@/features/factories'

import { d } from './domain'
import { DocumentFilesTab } from './types'

export const $docFilesInfo = d.store<DocumentFilesInfo | null>(null)

export const $isDocFilesModalOpen = d.store(false)
export const openDocFilesModal = d.event()
export const closeDocFilesModal = d.event()

export const getDocumentFiles = d.event<GetDocumentFilesPayload>()

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<DocumentFilesTab>({
  defaultValue: DocumentFilesTab.Templates,
  clearEvent: closeDocFilesModal,
  domain: d,
})

export const $documentFiles = combine(
  ([$docFilesInfo, $activeTabId]),
  ([info, activeTabId]) => (
    info?.document_files.filter(({ status }) => {
      const isDocSigned = status === DocumentFileStatus.Signed
      return activeTabId === DocumentFilesTab.Signed ? isDocSigned : !isDocSigned
    }) ?? []
  ),
)

export const getDocumentFilesFx = attach({
  effect: getDocumentFilesReqFx,
})
