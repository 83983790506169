import React from 'react'

import { TextSize } from '@/ui/v2/types'
import { NewTextButton } from '../ui/v2/buttons/NewTextButton'

const LINK_SLOT = '**link**'
const LINK_CURRENT_SLOT = `*${LINK_SLOT}*`

type Params = {
  text: string | undefined,
  links?: { text: string, href: string}[],
  isDownload?: boolean,
  textSize?: TextSize
}
const regex = /<b>(.*?)<\/b>|(\*\*link\*\*)/g

export const parceText = ({
  links,
  text,
  textSize,
  isDownload = false,
}: Params) => {
  const match = text?.matchAll(regex)
  let body: (string | React.ReactFragment)[] = []

  if (match) {
    let prevIndex = 0
    let linkCount = 0
    body = [...match as any].map((params: RegExpExecArray, index, arr) => {
      const before = params.input.substring(prevIndex, params.index)
      const isLink = params[0] === LINK_SLOT
      const isLast = index === (arr.length - 1)
      const after = isLast && params.input.substring(params.index + params[0].length)
      let jsx = <></>

      if (isLink) {
        if (!links) throw new Error('"links" is require parameter')

        const link = links[linkCount]

        jsx = (
          <React.Fragment key={index}>
            {before}
            <NewTextButton
              label={link.text}
              url={link.href}
              textStyle={textSize}
              dataTestId={`act-error-modal-link-${index}`}
              isDownload={isDownload}
              isNewBlank
              isInline
              onClick={(e) => e?.stopPropagation()}
            />
            {after}
          </React.Fragment>
        )
        linkCount++
      } else {
        jsx = (
          <React.Fragment key={index}>
            {before}
            <b>{params[1]}</b>
            {after}
          </React.Fragment>
        )
      }
      prevIndex = params.index + params[0].length
      return jsx
    })
    return body
  }
  return text
}

export const getTextWithLinks = ({
  isDownload = false,
  links,
  text,
  textSize = 'ML',
}: Params) => {
  const result: (string | React.ReactFragment)[] = []
  let string: string | undefined = text
  links?.forEach((link, i) => {
    if (!string) return
    const hasLinkSlot = string.indexOf(LINK_SLOT) !== -1
    if (!hasLinkSlot) return
    const arr = string.replace(LINK_SLOT, LINK_CURRENT_SLOT).split(LINK_CURRENT_SLOT)
    result.push(arr[0])
    result.push(
      <NewTextButton
        key={i}
        label={link.text}
        url={link.href}
        textStyle={textSize}
        dataTestId={`act-error-modal-link-${i}`}
        isDownload={isDownload}
        isNewBlank
        isInline
        onClick={(e) => e?.stopPropagation()}
      />,
    )
    arr.shift()
    string = arr.join()
  })

  return result
}
