import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import { Effect, Event, Store } from 'effector'
import { AxiosError } from 'axios'

import {
  Dragger,
  Button,
  UploadedFileItem,
} from '@/ui'

export type CreateUploadFiles = {
  $uploadedFiles: Store<File[]>
  sendFilesFx: Effect<any, any, AxiosError>
  onRemove: Event<string>
  onFiles: Event<File[]>
  sendFiles: Event<void>
}

export const createUploadFiles = ({
  $uploadedFiles,
  sendFilesFx,
  onRemove,
  onFiles,
  sendFiles,
}: CreateUploadFiles) => React.memo(() => {
  const files = useStore($uploadedFiles)
  const isLoading = useStore(sendFilesFx.pending)

  return (
    <Wrapper>
      {files !== null && (
        <List>
          {files?.map(({ name }) => (
            <UploadedFileItem key={name} fileName={name} onRemove={onRemove} />
          ))}
        </List>
      )}
      <Row>
        <DropArea>
          <Dragger height="100%" onFiles={onFiles} />
        </DropArea>
        <ButtonWrapper>
          <Button
            size="M"
            styleButton="primary"
            dataTestId="act-upload-files"
            disabled={!files?.length}
            isLoading={isLoading}
            onClick={sendFiles}
          >
            Загрузить
          </Button>
        </ButtonWrapper>
      </Row>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
`

const Row = styled.div`
  height: 45px;
  display: flex;
  width: 100%;
`

const DropArea = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  flex: 0 0 150px;
`

const List = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`
