export type EditingPaymentOrder = {
  number: string
  date: string // YYYY-MM-DD
  isMarkedToDelete?: boolean
  isNew?: boolean
} & (
  | {
      isNew?: false
      id: number
    }
  | {
      isNew: true
      id: string
    }
)

export enum ActTab {
  Info = 'Info',
  Works = 'Works',
  Deductions = 'Deductions',
}
