import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  DropAreaWithList,
  InputField,
  NewDropDownField,
  NewInput,
  NumberInputField,
  TextAreaField,
} from '@/ui'
import {
  $groupList, $hasSplittingObjects, $projectList, $unitList,
} from '@/features/estimate-correction/shared-model'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import { closeModal, createRowForm } from '../../../model/create-table-item.private'
import { ButtonRow } from '../parts'


export const CreateRowForm = React.memo(() => {
  const { fields, isValid, submit } = useForm(createRowForm)
  const groups = useUnit($groupList)
  const units = useUnit($unitList)
  const projects = useUnit($projectList)
  const hasSplittingObjects = useUnit($hasSplittingObjects)

  const isWorkFieldRequired = !fields.material.value || parseFloat(fields.material.value) <= 0
  const isMaterialFieldRequired = !fields.work.value || parseFloat(fields.work.value) <= 0

  const areFieldsDirty = useCheckFieldsDirty(fields, [
    'group_id',
    'justification_of_changes_current',
    'files',
    'provider',
    'specifications',
    'stamp',
    hasSplittingObjects ? 'project' : null,
    !isMaterialFieldRequired ? 'material' : null,
    !isWorkFieldRequired ? 'work' : null,
  ])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const foldersOptions = React.useMemo(() => {
    if (!groups) return []
    return [
      { label: 'Строка первого уровня (снаружи всех папок)', id: null },
      ...groups.map((item) => ({ label: `${item.code_1c} ${item.name}`, id: item.id })),
    ]
  }, [groups])

  const projectOptions = React.useMemo(() => {
    if (!projects) return []
    return projects.map((item) => ({ label: item.name, id: item.id }))
  }, [projects])

  const unitOptions = React.useMemo(() => {
    if (!units) return []
    return units.map((item) => ({ label: item.short_name, id: item.id }))
  }, [units])

  return (
    <ContentWrapper onSubmit={handleSubmit}>
      <NewDropDownField
        dataTestId="create-row-parent-folder"
        label="Папка для хранения"
        required
        options={foldersOptions}
        hasSearch
        placeholder="Выберите папку или начните вводить название..."
        size="L"
        onOptionClick={fields.group_id.onChange}
        selectedId={fields.group_id.value}
        error={fields.group_id.firstError?.errorText}
      />
      <InputField
        dataTestId="create-row-name"
        label="Название работ, услуг"
        placeholder="Введите название работ и услуг"
        isRequired
        value={fields.name.value}
        onChange={fields.name.onChange}
        onClear={fields.name.reset}
        size="L"
        error={fields.name.firstError?.errorText}
      />

      <NewDropDownField
        dataTestId="create-row-project-input"
        label="Объект строительства"
        options={projectOptions}
        hasSearch
        placeholder="Выберите объект строительства"
        size="L"
        onOptionClick={fields.project.onChange}
        selectedId={fields.project.value}
        isDisabled={hasSplittingObjects}
        required={!hasSplittingObjects}
        error={fields.project.firstError?.errorText}
      />
      <InputCell>
        <NewDropDownField
          dataTestId="create-row-unit-input"
          label="Единицы измерения"
          required
          options={unitOptions}
          hasSearch
          placeholder="Выберите единицы измерения"
          size="L"
          onOptionClick={fields.unit.onChange}
          selectedId={fields.unit.value}
          error={fields.unit.firstError?.errorText}
        />
        <NumberInputField
          dataTestId="create-row-count-in-estimate-adjusted"
          label="Кол-во по смете"
          required
          placeholder="Введите количество"
          size="L"
          onChange={fields.count_in_estimate.onChange}
          value={fields.count_in_estimate.value}
          onClear={fields.count_in_estimate.reset}
          decimalScale={4}
          decimalSeparator="."
          fixedDecimalScale
          error={fields.count_in_estimate.firstError?.errorText}
          thousandSeparator=" "
        />
        <NumberInputField
          dataTestId="create-row-material-adjusted"
          label="Цена материалов, руб"
          required={isMaterialFieldRequired}
          placeholder="Введите цену"
          size="L"
          onChange={fields.material.onChange}
          value={fields.material.value}
          onClear={fields.material.reset}
          decimalScale={2}
          decimalSeparator="."
          error={fields.material.firstError?.errorText}
          fixedDecimalScale
          thousandSeparator=" "
        />
        <NumberInputField
          dataTestId="create-row-work-adjusted"
          label="Цена работ, руб"
          required={isWorkFieldRequired}
          placeholder="Введите цену"
          size="L"
          onChange={fields.work.onChange}
          value={fields.work.value}
          onClear={fields.work.reset}
          decimalScale={2}
          fixedDecimalScale
          decimalSeparator="."
          error={fields.work.firstError?.errorText}
          thousandSeparator=" "
        />
      </InputCell>
      <TextAreaField
        dataTestId="create-row-justification_of_changes_current"
        label="Обоснование"
        placeholder="Укажите причину изменений и ссылку на РД"
        maxSymbolCount={800}
        autoGrow
        currentSymbolCount={fields.justification_of_changes_current.value.length}
        error={fields.justification_of_changes_current.firstError?.errorText}
        height={100}
        value={fields.justification_of_changes_current.value}
        onChange={fields.justification_of_changes_current.onChange}
      />

      <DropAreaWithList
        dataTestId="create-row-files"
        items={fields.files.value}
        onChange={fields.files.onChange}
      />
      <NewInput
        dataTestId="create-row-provider"
        onChange={fields.provider.onChange}
        value={fields.provider.value}
        onClear={fields.provider.reset}
        error={fields.provider.firstError?.errorText}
        size="L"
        placeholder="Укажите поставщика"
        maxLength={100}
        showMaxLengthError
      />
      <NewInput
        dataTestId="create-row-specifications"
        onChange={fields.specifications.onChange}
        value={fields.specifications.value}
        onClear={fields.specifications.reset}
        error={fields.specifications.firstError?.errorText}
        size="L"
        placeholder="Укажите характеристики материала"
        maxLength={100}
        showMaxLengthError
      />
      <NewInput
        dataTestId="create-row-stamp"
        onChange={fields.stamp.onChange}
        value={fields.stamp.value}
        onClear={fields.stamp.reset}
        error={fields.stamp.firstError?.errorText}
        size="L"
        placeholder="Укажите марку материала"
        maxLength={100}
        showMaxLengthError
      />
      <ButtonRow
        isDisableSubmit={!isValid || !areFieldsDirty}
        onCancel={closeModal}
      />
    </ContentWrapper>
  )
})

const ContentWrapper = styled.form`
  padding: 16px 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InputCell = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
