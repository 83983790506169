import React from 'react'
import { Event, Store } from 'effector'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { FileDTO } from '@/dal'
import {
  DropArea,
  HeadL,
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewFileItem,
  NotificationItem,
  TextMLight,
  palette,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { handleDeleteFileHelper } from '@/lib/handleDeleteFileHelper'
import { handleFileChangeHelper } from '@/lib/handleFileChangeHelper'
import { noop } from '@/lib/noop'

type Params = {
  close: Event<void>
  $entityId: Store<any | null>
  $isSendAttachmentsPending: Store<boolean>,
  $files: Store<File[]>
  $visibleFiles: Store<FileDTO[] | null>
  $filesCount: Store<number>
  $isNotificationShown: Store<boolean>
  $canSendFiles: Store<boolean>
  closeNotification: Event<void>
  onChangeFiles: Event<File[]>
  markToDelete: Event<FileDTO['id']>
  sendFiles: Event<void>
  $filesToDelete: Store<FileDTO['id'][]>
}

export const createAttachmentsModalView = ({
  $entityId,
  $isSendAttachmentsPending,
  $visibleFiles,
  $filesCount,
  $files,
  $filesToDelete,
  $isNotificationShown,
  $canSendFiles,
  markToDelete,
  onChangeFiles,
  close,
  closeNotification,
  sendFiles,
}: Params) => {
  return React.memo(({ notificationProps }: { notificationProps?: any }) => {
    const id = useStore($entityId)
    const files = useStore($files)
    const visibleFiles = useStore($visibleFiles)
    const isPending = useStore($isSendAttachmentsPending)
    const filesCount = useStore($filesCount)
    const filesToDelete = useStore($filesToDelete)
    const isNotificationShown = useStore($isNotificationShown)
    const canSendFiles = useStore($canSendFiles)

    useOnChangeLocation(close)

    const handleDeleteFile = (fileName: string) => {
      handleDeleteFileHelper({
        fileName,
        value: files,
        onChange: onChangeFiles,
      })
    }

    const handleUploadFile = (newFiles: File[]) => {
      handleFileChangeHelper({
        currValue: files,
        newFiles,
        onChange: onChangeFiles,
      })
    }

    if (id === null) return null

    return (
      <ModalLayoutV2 closeModal={isPending ? noop : close}>
        <Wrapper>
          <Head>
            Мои вложения
            {' '}
            <AttachmentsCount>
              {filesCount}
            </AttachmentsCount>
          </Head>

          {notificationProps && isNotificationShown && canSendFiles && (
            <NotificationItem
              {...notificationProps}
              showCloseButton
              onClose={closeNotification}
            />
          )}

          <Body>
            {visibleFiles?.length || files.length ? (
              <FileList>
                {visibleFiles?.map(({
                  url,
                  extension,
                  id,
                  name,
                  can_delete,
                }) => (
                  <NewFileItem
                    key={id}
                    isDense
                    name={name}
                    url={url}
                    extension={extension}
                    onDelete={canSendFiles && can_delete ? () => markToDelete(id) : undefined}
                  />
                ))}
                {files.map(({ name }) => (
                  <NewFileItem
                    key={name}
                    isDense
                    name={name}
                    onDelete={handleDeleteFile}
                    extension={name.split('.').pop()}
                  />
                ))}
              </FileList>
            ) : !canSendFiles && (
              <EmptyWrapper>
                У вас нет вложений
              </EmptyWrapper>
            )}

            {canSendFiles && (
              <DropArea onFiles={handleUploadFile} showInfo />
            )}
          </Body>

          {canSendFiles && (
            <Footer>
              <NewButton
                dataTestId="send-files"
                label="Сохранить"
                onClick={sendFiles}
                isPending={isPending}
                isDisabled={!files.length && !filesToDelete.length}
                isFill
              />
              <NewButton
                dataTestId="close-modal"
                label="Отменить"
                buttonType="grey"
                onClick={close}
                isDisabled={isPending}
                isFill
              />
            </Footer>
          )}
        </Wrapper>
      </ModalLayoutV2>
    )
  })
}

const Wrapper = styled.div`
  padding: 32px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Head = styled.div`
  ${HeadXL}
`

const AttachmentsCount = styled.span`
  ${HeadL}
  color: ${palette.grey60};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FileList = styled.div`
  display: grid;
  gap: 2px 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
`

const EmptyWrapper = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
  text-align: center;
`

const Footer = styled.div`
  display: flex;
  gap: 16px;
`
