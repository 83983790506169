import React from 'react'
import styled from 'styled-components'

import { HeadL, palette } from '@/ui'

type Props = {
  step: number
  title: string
}

export const StepHeader = ({ step, title }: Props) => (
  <Wrapper>
    <Step>
      {step}
      .
    </Step>
    <Title>
      {title}
    </Title>
  </Wrapper>
)

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Step = styled.div`
  ${HeadL}
  color: ${palette.grey60};
`

const Title = styled.h3`
  ${HeadL}
`
