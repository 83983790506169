import { combine, sample } from 'effector'
import { condition } from 'patronum'

import { LotTableItem } from '@/dal'
import { $selectedGroupId, loadGroupTree, onSelectedGroupIdChanged } from '@/features/group-tree/model'
import { createToast } from '@/features/toast-service/model'

import { $documentId, $documentInfo } from '../../model'
import {
  $editingWork,
  $isByConstructionElements,
  $isFirstGroupOpen,
  $isTreeTable,
  $nestedWorks,
  $openedTableItem,
  $pendingFolderIds,
  $tableRoot,
  $tableTree,
  $totalWorkCount,
  $updatingWorkIds,
  $workToDelete,
  abortPaginationFlatTabelFx,
  changeLorWorkComment,
  changeLorWorkCommentFx,
  closeFolder,
  closeFolders,
  deleteLotFolder,
  deleteLotFolderFx,
  deleteLotWork,
  deleteLotWorkFx,
  getFolderNestedWorks,
  getTableData,
  getTreeTableFx,
  getTreeTableRowsFx,
  getUnitsFx,
  initFlatTableFx,
  onLoadMore,
  openFolder,
  openTableItemFiles,
  paginateFlatTableFx,
  resetTableData,
  resetWorkToDelete,
  rowAttachmentsModel,
  setIsByConstructionElements,
  setIsFirstGroupOpen,
  setIsTreeTable,
  setTreeUpdate,
  setRootUpdate,
  setEditingWork,
  setWorkOrFolderToDelete,
  updateItemsFx,
  postLotWorkAttachmentsFx,
} from './private'
import { $units, EstimateLotTableGate, onWorkOrFolderDeleted } from './public'
import { onWorkAdded } from '../../kvr-ker-catalog/model'
import { Tree } from './types'

$tableRoot
  .on(setRootUpdate, (_, root) => root)
  .on([initFlatTableFx.doneData, getTreeTableFx.doneData], (_, { data }) => data)
  .on(paginateFlatTableFx.doneData,
    (data, { data: newPage }) => (data ? [...data, ...newPage] : newPage))
  .on(changeLorWorkCommentFx.done, (data, { params, result }) => (
    data?.map((item) => (item.id === params.id
      ? {
        ...item,
        comment: result,
      }
      : item),
    ) ?? null
  ))
  // TODO проверить с бэком
  .on(postLotWorkAttachmentsFx.done, (data, { params, result }) => (
    data?.map((item) => (item.id === params.id
      ? {
        ...item,
        attachments: result,
      }
      : item),
    ) ?? null
  ))
  .reset(resetTableData)

$tableTree
  .on(getTreeTableRowsFx.done, (map, { params, result }) => ({
    ...map,
    [params.group_id]: result,
  }))
  .on(setTreeUpdate, (map, update) => ({
    ...map,
    ...update,
  }))
  .on(closeFolders, (_, map) => map)
  .reset(EstimateLotTableGate.close, getTreeTableFx.done, initFlatTableFx)

$isTreeTable
  .on(setIsTreeTable, (_, value) => value)
  .reset(EstimateLotTableGate.close)

$isByConstructionElements
  .on(setIsByConstructionElements, (_, value) => value)
  .reset(EstimateLotTableGate.close)

$isFirstGroupOpen
  .on(setIsFirstGroupOpen, (_, value) => value)
  .reset(EstimateLotTableGate.close)

$pendingFolderIds
  .on(getTreeTableRowsFx, (arr, params) => [...arr, params.group_id])
  .on(
    getTreeTableRowsFx.finally,
    (arr, { params }) => arr.filter((id) => id !== params.group_id),
  )
  .reset(EstimateLotTableGate.close, getTreeTableFx, initFlatTableFx)

$totalWorkCount
  .on([initFlatTableFx.doneData, getTreeTableFx.doneData], (_, { total }) => total)
  .reset(resetTableData)

$workToDelete
  .on(setWorkOrFolderToDelete, (_, data) => data)
  .reset(EstimateLotTableGate.close, resetWorkToDelete)

$nestedWorks
  .on(getFolderNestedWorks.doneData, (_, data) => data)
  .reset(EstimateLotTableGate.close, resetWorkToDelete)

$editingWork
  .on(setEditingWork, (_, value) => value)
  .reset(EstimateLotTableGate.close)

$updatingWorkIds
  .on(changeLorWorkComment, (arr, { id }) => (arr.includes(id) ? arr : [...arr, id]))
  .on(changeLorWorkCommentFx.finally, (arr, { params: { id } }) => (
    arr.filter((item) => item !== id)
  ))
  .reset(EstimateLotTableGate.close, $documentId.updates)

$units
  .on(getUnitsFx.doneData, (_, data) => data)
  .reset(EstimateLotTableGate.close)

$openedTableItem
  .on(openTableItemFiles, (_, item) => item)
  .reset(rowAttachmentsModel.close)

sample({
  clock: EstimateLotTableGate.open,
  target: getUnitsFx,
})

sample({
  clock: $documentId.updates,
  source: $documentInfo,
  fn: (_, id) => ({ id }),
  // filter: (info) => !(info
  //   && info?.status === DocumentStatuses.ToBeAgreed
  //   && info.features.is_special_visa),
  target: getTableData,
})

sample({
  clock: [EstimateLotTableGate.close, initFlatTableFx, getTreeTableFx],
  target: resetTableData,
})

sample({
  clock: $isByConstructionElements.updates,
  filter: Boolean,
  fn: () => false,
  target: setIsTreeTable,
})

sample({
  clock: [
    EstimateLotTableGate.open,
    $documentId.updates,
    onWorkOrFolderDeleted,
    onWorkAdded,
  ],
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({
    documentId: id,
    // TODO ручка различается с алиасом последней буквой
    documentType: 'estimate_lots' as any,
  }),
  target: loadGroupTree,
})

// TODO дополнить
condition({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  source: sample({
    clock: [
      $isTreeTable.updates,
      getTableData,
      onSelectedGroupIdChanged,
      onWorkAdded,
      onWorkOrFolderDeleted,
    ],
    source: {
      documentId: $documentId,
      groupId: $selectedGroupId,
    },
    fn: ({ documentId, groupId, ...map }) => ({
      id: documentId,
      group_id: groupId,
      ...map,
    }),
  }),
  if: $isTreeTable,
  then: [abortPaginationFlatTabelFx, getTreeTableFx],
  else: [abortPaginationFlatTabelFx, initFlatTableFx] as any,
})

sample({
  clock: onLoadMore,
  source: {
    documentId: $documentId,
    isTree: $isTreeTable,
    workId: $selectedGroupId,
  },
  filter: ({ isTree, documentId }) => Boolean(!isTree && documentId),
  fn: ({ workId, documentId, ...map }) => ({
    ...map,
    id: documentId as number,
    // workId
  }),
  target: paginateFlatTableFx,
})

sample({
  clock: openFolder,
  filter: combine([$isTreeTable, $documentId], ([a, b]) => Boolean(a && b)),
  source: {
    // search: $searchInputValue,
    // types: $acceptedTypes,
    id: $documentId,
  },
  fn: ({ id }, group_id) => ({
    // search,
    // types,
    group_id,
    id: id as number,
  }),
  target: getTreeTableRowsFx,
})

sample({
  clock: closeFolder,
  source: $tableTree,
  fn: (map, parentId) => {
    const copyMap = { ...map }
    const closeChildren = (children: LotTableItem[] | null) => {
      children?.forEach((node) => {
        closeChildren(copyMap[node.id])
        delete copyMap[node.id]
      })
    }
    closeChildren(copyMap[parentId])
    delete copyMap[parentId]

    return copyMap
  },
  target: closeFolders,
})

sample({
  clock: updateItemsFx.doneData,
  fn: ({ root }) => root,
  target: setRootUpdate,
})

sample({
  clock: updateItemsFx.doneData,
  filter: ({ tree }) => Boolean(tree),
  fn: ({ tree }) => tree as Tree,
  target: setTreeUpdate,
})

sample({
  clock: changeLorWorkComment,
  target: changeLorWorkCommentFx,
})

sample({
  clock: openTableItemFiles,
  target: rowAttachmentsModel.open,
})

sample({
  clock: setWorkOrFolderToDelete,
  filter: ({ is_folder }) => is_folder,
  fn: ({ id }) => id,
  target: getFolderNestedWorks,
})

sample({
  clock: deleteLotWork,
  target: deleteLotWorkFx,
})

sample({
  clock: deleteLotFolder,
  target: deleteLotFolderFx,
})

sample({
  clock: [deleteLotWorkFx.done, deleteLotFolderFx.done],
  target: resetWorkToDelete,
})

sample({
  clock: [deleteLotWorkFx.done, deleteLotFolderFx.done],
  target: onWorkOrFolderDeleted,
})

createToast({
  effect: changeLorWorkCommentFx,
  doneText: 'Комментарий успешно изменен',
})

createToast({
  effect: deleteLotWorkFx,
  doneText: 'Работа успешно удалена',
})

createToast({
  effect: deleteLotFolderFx,
  doneText: 'Папка успешно удалена',
})
