import { DocumentAlias, FileDTO } from '../interfaces'

export enum DocumentFileStatus {
  Draft = 'draft',
  InWork = 'in_work',
  Signed = 'signed',
}

export type DocumentFileItem = {
  status: DocumentFileStatus
} & FileDTO

export type DocumentFilesInfo = {
  document_files: DocumentFileItem[]
  download_in_work_files_url: string
  download_signed_files_url: string | null
}

export type GetDocumentFilesPayload = {
  id: number
  docType: DocumentAlias
}
