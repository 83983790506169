import {
  Domain,
  Effect,
  attach,
  guard,
  sample,
} from 'effector'
import { root } from '@/root-domain'

type Params<R> = {
  effect: Effect<void, R, Error>
  domain?: Domain
  ms: number
}

export const createPollingEffect = <R>({ effect, domain, ms }: Params<R>) => {
  const d = domain || root.domain()
  const attachedEffect = attach({ effect })

  const $intervalId = d.store<NodeJS.Timeout | null>(null)

  const startPollingFx = d.effect<void, NodeJS.Timeout, Error>()
  const stopPollingFx = d.effect<NodeJS.Timeout, void, Error>()
  const startPolling = d.event<void>()
  const stopPolling = d.event<void>()

  $intervalId
    .on(startPollingFx.doneData, (_, id) => id)
    .reset(stopPollingFx.done)

  guard({
    clock: startPolling,
    source: $intervalId,
    filter: (id) => !id,
    target: startPollingFx,
  })

  sample({
    clock: stopPolling,
    source: $intervalId,
    filter: (id: NodeJS.Timeout | null): id is NodeJS.Timeout => Boolean(id),
    target: stopPollingFx,
  })

  startPollingFx.use(() => {
    const id = setTimeout(() => {
      attachedEffect()
      startPollingFx()
    }, ms)
    return id
  })


  stopPollingFx.use((id) => {
    clearTimeout(id)
  })

  return {
    startPolling,
    stopPolling,
    effect: attachedEffect,
  }
}
