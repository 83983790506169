import React from 'react'
import { useUnit } from 'effector-react'
import { TableVirtuoso } from 'react-virtuoso'
import styled from 'styled-components'

import { ActTableRow, isPIRActWork } from '@/dal'
import { LoaderOverlay } from '@/ui'
import { useCheckScrolled } from '@/lib/hooks'

import { TableRow } from './TableRow'
import {
  $flatItems,
  $hasMore,
  $isTableLoading,
  $isTree,
  onLoadMore,
  paginateFlatTableFx,
} from '../../model/private'
import { $isPIRAct } from '../../../model'
import { TableHeader } from './TableHeader'
import { ActTableFolder } from './TableFolder'
import { TableRowPIR } from './TableRowPIR'
import { TableHeaderPIR } from './TableHeaderPIR'

export const Table = React.memo(() => {
  const [items, isPIR] = useUnit([$flatItems, $isPIRAct])
  const isShowPendingOverlay = useUnit($isTableLoading)
  const isTree = useUnit($isTree)
  const hasMore = useUnit($hasMore)
  const isPaginationPending = useUnit(paginateFlatTableFx.pending)

  const { isScrolled, containerRef } = useCheckScrolled([items])

  const MemoHeader = React.useCallback(() => {
    if (isPIR) {
      return <TableHeaderPIR isScrolled={isScrolled.horizontal} />
    }
    return <TableHeader isScrolled={isScrolled.horizontal} />
  }, [isScrolled])

  const MemoComponent = React.useCallback((index: number, item: ActTableRow) => {
    if (item.is_folder) {
      return <ActTableFolder item={item} isScrolled={isScrolled.horizontal} />
    }
    if (isPIRActWork(item)) {
      return <TableRowPIR item={item} isScrolled={isScrolled.horizontal} />
    }
    return <TableRow item={item} isScrolled={isScrolled.horizontal} />
  }, [isScrolled])

  const handleScrollerRef = React.useCallback((ref) => {
    containerRef.current = ref
  }, [])

  if (!items) return null

  return (
    <TableWrapper>
      <TableVirtuoso
        style={{ height: '100%' }}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
        endReached={!isTree ? () => hasMore && !isPaginationPending && onLoadMore() : undefined}
        scrollerRef={handleScrollerRef}
      />
      <LoaderOverlay show={isShowPendingOverlay} />
    </TableWrapper>
  )
})

const TableWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  height: 70dvh;

  thead {
    z-index: 5 !important;
  }
`
