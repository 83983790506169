import { AdministrationAppealTypeAlias } from '@/dal'
import { ResponsibleRole } from './types'

export const appealResponsibleRoles: ResponsibleRole[] = [
  {
    alias: AdministrationAppealTypeAlias.ProjectDir,
    name: 'Директор проекта',
    dativeName: 'Директору проекта',
    description: 'Обращаться по вопросам финансирования работ по договору',
  },
  {
    alias: AdministrationAppealTypeAlias.ConstructionDir,
    name: 'Директор стройки',
    dativeName: 'Директору стройки',
    description: 'Обращаться по вопросам объема работ и сроков строительства по договору',
  },
  {
    alias: AdministrationAppealTypeAlias.ProjectCor,
    name: 'Координатор проекта',
    dativeName: 'Координатору проекта',
    description:
      'Обращаться по вопросам отсутствия согласующих и некорректно назначенных согласующих',
  },
  {
    alias: AdministrationAppealTypeAlias.Estimate,
    name: 'Сметчик',
    dativeName: 'Сметчику',
    description: 'Обращаться по вопросам текущей подписанной сметы',
  },
  {
    alias: AdministrationAppealTypeAlias.Act,
    name: 'Финансист',
    dativeName: 'Финансисту',
    description:
      'Обращаться по вопросам размера гарантийных удержаний, зачета аванса и иных удержаний',
  },
  {
    alias: AdministrationAppealTypeAlias.ActReq,
    name: 'Бухгалтер',
    dativeName: 'Бухгалтеру',
    description: 'Обращаться по вопросам первичной документации и актов сверки',
  },
  {
    alias: AdministrationAppealTypeAlias.Contract,
    name: 'Ответственный по договору',
    dativeName: 'Ответственному по договору',
    description: 'Обращаться по общим вопросам текущих условий договора',
  },
  {
    alias: AdministrationAppealTypeAlias.Contragent,
    name: 'На подрядчика',
    dativeName: '',
    description: '',
  },
]
