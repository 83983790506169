import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'

import {
  $action,
  $targetDocument,
  copyDocumentFx,
  onConfirm,
  onRejectConfirm,
} from '../../model/private'
import { DocumentManagerActions } from '../../model'
import { ConflictingDraftWarning } from '../parts'

export const CopyDocumentConfirm = React.memo(() => {
  const info = useStore($targetDocument)
  const isLoading = useStore(copyDocumentFx.pending)
  const action = useStore($action)

  useOnChangeLocation(onRejectConfirm)

  if (!info || action !== DocumentManagerActions.Copy) return null

  return (
    <ModalLayoutV2 closeModal={isLoading ? noop : onRejectConfirm}>
      <Wrapper>
        <ContentWrapper>
          <Title>Создание копии</Title>
          {info.draft && (
            <ConflictingDraftWarning
              id={info.draft.id}
              name={info.draft.name}
              docType={info.type}
            />
          )}
          <Text>
            Создать копию
            {' '}
            {info.documentName}
            ?
            Вы сразу сможете приступить к редактированию документа.
          </Text>
        </ContentWrapper>
        <Row>
          <NewButton
            dataTestId="create-copy"
            label="Создать"
            onClick={onConfirm}
            isFill
            isPending={isLoading}
          />
          <NewButton
            dataTestId="cancel-create-copy"
            label="Отменить"
            buttonType="grey"
            onClick={onRejectConfirm}
            isFill
            isDisabled={isLoading}
          />
        </Row>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 24px;
  gap: 20px;
  max-width: 500px;
`

const Title = styled.div`
  ${HeadXL}
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Text = styled.div`
  ${TextLLight}
`

const Row = styled.div`
  display: flex;
  gap: 12px;
  flex-grow: 1;
`
