import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { DocumentStatuses, SummaryFieldAlias } from '@/dal'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import { CurrencyRow } from '../parts'
import { $summary, onFieldSubmit } from '../../model/private'

export const SummaryBody = React.memo(() => {
  const summary = useStore($summary)
  const status = useStore($documentStatus)

  if (!summary) return null

  const isDraft = status === DocumentStatuses.Draft

  return (
    <Body>
      <CurrencyRow
        label="Материалы"
        {...summary.sum_material}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.MATERIAL, value })}
      />
      <CurrencyRow
        label="Работы"
        {...summary.sum_work}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.WORK, value })}
      />
      <CurrencyRow
        label="Общая"
        {...summary.sum_all}
        isDraft={isDraft}
        onSubmit={(value) => onFieldSubmit({ field: SummaryFieldAlias.TOTAL, value })}
        isTotal
      />

    </Body>
  )
})


const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
