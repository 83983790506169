import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'

import { d } from './domain'

const FILE_LIMIT = 5

export const appealForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    document: {
      init: null as number | null,
      rules: [
        rules.required(),
      ],
    },
    subject: {
      init: '',
      rules: [
        rules.required(),
      ],
    },
    description: {
      init: '',
      rules: [
        rules.required(),
      ],
    },
    files: {
      init: [] as File[],
      rules: [rules.maxFiles(FILE_LIMIT)],
    },
  },
})
