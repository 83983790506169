import {
  Contract,
  DefaultUpdateFileParams,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  FileDTO,
  PaginationRequestParams,
  PaginationType,
} from '../interfaces'
import { DocFlowDocSubtype } from '../document-flow'

export type CostObject = {
  declared: string
  approved: string
  diff: string | null
}

export enum MorphologyType {
  Object = 'object',
  Section = 'section',
  Building = 'building',
}

export type MorphologyItem = {
  id: number
  type: MorphologyType
}

export type EstimateLot = {
  id: number
  name: string
  status: DocumentStatuses
  features: DocumentFeatures
  company_id: number
  project_id: number
  direction_id: number
  is_existing_contract: boolean
  contract_id: number | null
  morphology: MorphologyItem[]
  attachments: FileDTO[] | null
  documents_link: string // url
  advance_type_id: number
  type_of_security_id: number
  advance_size: string
  guarantee_retention_size: string
  construction_start: string | null // YYYY-MM-DD
  construction_end: string | null // YYYY-MM-DD
  updated_at: string // isoDate
  comment: string | null
  is_typical_form_of_contract: boolean
  summary: {
    cost_of_work: CostObject
    cost_of_materials: {
      cost_of_material: CostObject
      grand_tender: CostObject
      nominal: CostObject
      related: CostObject
    }
    cost_of_lot: CostObject
  }
  instruction_link: string // url
}

export type LotCatalogItem = {
  id: number
  name: string
}

export type EstimateLotsFilterPayload = PaginationRequestParams & {
  document_type?: DocFlowDocSubtype
  name?: string
  date_from?: string // YYYY-MM-DD
  date_to?: string // YYYY-MM-DD
  project_name?: string
  existing_contract?: boolean
  status?: DocumentStatuses
}

export type EstimateLotListItem = {
  id: EstimateLot['id']
  name: string
  sums: string
  date: string // YYYY-MM-DD
  period: {
    date_start: string // YYYY-MM-DD
    date_stop: string // YYYY-MM-DD
  }
  status: DocumentStatuses
  answer_required: boolean
  download_link: string | null
  progress: DocumentProgress | null
  project_name: string | null
  available_actions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  // TODO уточнить enum
  document_type: string
  contract_id: Contract['id'] | null
  contract_name: string | null
}

export type UpdateEstimateLotPayload = {
  project?: number | null
  direction?: number | null
  contract?: number | null
  advance_type?: number | null
  type_of_security?: number | null
  is_existing_contract?: boolean
  is_typical_form_of_contract?: boolean
  advance_size?: string
  guarantee_retention_size?: string
  construction_start?: string
  construction_end?: string
  comment?: string
  files?: File[]
  morphology?: MorphologyItem[]
}

export type PostEstimateLotAttachmentsParams = {
  id: EstimateLot['id']
} & DefaultUpdateFileParams

export type GetEstimateLotListData = {
  items: EstimateLotListItem[]
  all_estimate_lots_count: number
  on_agreed_estimate_lots: number
}

export type GetEstimateLotListResponse = PaginationType<GetEstimateLotListData>

export type MorphologyObject = LotCatalogItem

export type MorphologyBuilding = {
  construction_object: MorphologyObject['id']
} & LotCatalogItem

export type MorphologySection = {
  building: MorphologyBuilding['id']
} & LotCatalogItem

export type GetEstimateLotMorphologyResponse = {
  construction_objects: MorphologyObject[]
  buildings: MorphologyBuilding[]
  construction_section: MorphologySection[]
}

export enum LotRowType {
  Work = 1,
  Material = 2,
  Additional = 3,
}

// TODO дополнить
export type LotTableItem = {
  id: string // uuid
  name: string
  unit_id: {
    id: number
    value: string
  } | null
  count: null
  depth: number
  price_material: string // float
  price_work: string // float
  price_all: string // float
  cost_material: string // float
  cost_work: string // float
  cost_all: string // float
  comment: string | null
  attachments: FileDTO[] | null
  description: string | null
  hierarchy_code: string
  elements: null
  has_children: boolean
  is_folder: boolean
  row_type: LotRowType | null
  // TODO актуализировать
  morphology_elements: {
    object: MorphologyObject['id']
    building: MorphologyBuilding['id'] | null
    section: MorphologySection['id'] | null
  } | null
}

export type KerElement = {
  id: number
  name: string
  unit: number | null
  description: string | null
  kvr_id: KvrElement['id']
  is_ker_in_use: boolean
}

export type KvrElement = {
  id: number
  name: string
  parent_id: KvrElement['id'] | null
  ker_elements: KerElement[] | null
  is_kvr_in_use: boolean
}

export type GetLotTreeTableParams = {
  id: EstimateLot['id']
  group_id?: LotTableItem['id']
}

export type GetLotFlatTableParams = PaginationRequestParams & GetLotTreeTableParams

export type GetLotTreeRowsParams = Required<GetLotTreeTableParams>

export type GetLotFlatTableResponse = PaginationType<LotTableItem[]>

export type GetLotTreeTableResponse = {
  data: LotTableItem[]
  total: number
}

export type UpdateLotWorkCommentPayload = {
  id: LotTableItem['id']
  comment: string
}

export type EstimateLotFiltersResponse = {
  available_statuses: DocumentStatuses[]
  available_names: string[]
  available_projects: string[]
  available_contracts: boolean[]
}

export type UnitItem = {
  id: number
  short_name: string
  full_name: string | null
}
