import {
  ActDTO, ContractDirection, GetActTableParams, RemainedFilter, RowFilterEnum,
} from '@/dal'

export const mapFilters = ({
  form,
  name,
  groupId,
  actId,
  actType,
}: {
  form: {
    article: number[];
    remained: RemainedFilter[];
    rowFilters: RowFilterEnum[]
    reportPeriod: RemainedFilter[]
  }
  name: string;
  actId: number | null;
  groupId: number | null;
  actType: ContractDirection | null;
}): GetActTableParams => {
  return {
    ...form,
    name,
    groupId: groupId || undefined,
    actId: actId as ActDTO['id'],
    actType: actType || ContractDirection.SMR,
  }
}
