import { SystemAlias } from '@/dal'

export type Subsystem = {
  link: RouterPaths | null
  subsystemId: SubSystemId
  alias?: SystemAlias
  name?: string
  isInDevelopment?: boolean
  childLinks?: {
    name: string
    link: RouterPaths
    subsystemId: SubSystemId
  }[]
}

export enum SubSystemId {
  Home = 0,
  Administrative = 1,
  DocumentFlow = 2,
  CreateEstimate = 3,
  Help = 4,
  MyCompany = 5,
  ActDoc = 6,
  PaymentDoc = 7,
  PlanDoc = 8,
  EstimateCorrectionDoc = 9,
  EditUserInfo = 10,
  Compensation = 11,
}

export enum RouterPaths {
  Home = '/',
  UserInfo = '/user-info',
  Login = '/login',
  RequestForAccess = '/request-for-access',
  RestoreAccess = '/restore-access',
  RestorePassword = '/restore-access/restore-password',
  ChangePassword = '/restore-access/change-password',
  RepeatRegistrationLetter = '/restore-access/repeat-registration-letter',
  ActPage = '/act',
  ForceAuth = '/force_auth',
  Help = '/help',
  Administrative = '/contracts',
  FundingSchedule = '/funding-schedule',
  DocumentFlow = '/document-flow',
  Payment = '/payment',
  PlanPage = '/plan',
  Maintenance = '/maintenance',
  EstimateCorrection = '/estimate-correction',
  EstimateLots = '/estimate-lots',
  Compensation = '/compensation',
  MyCompany = '/my-company',
  Contacts = '/my-company/contacts',
  Support = '/support',
  CreateSupportAppeal = '/create-appeal',
  AdministrationAppeals = '/administration-appeals',
  NewAdministrationAppeal = '/administration-appeals/create',
}
