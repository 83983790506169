import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import {
  DefaultButton,
  Icon2,
  TextL,
  palette,
} from '@/ui'

import {
  clearSelectedGroup,
  openGroupsTree,
} from '../../model/private'
import { GroupTitle } from '../parts'
import { $selectedGroupId } from '../../model'

const $hasSelectedWork = $selectedGroupId.map(Boolean)

type Props = {
  workCount: number | null
}

export const TopRowContainer = React.memo(({ workCount }: Props) => {
  const hasSelectedWork = useStore($hasSelectedWork)

  const handleClickOnReset = (e: React.MouseEvent) => {
    e.stopPropagation()
    clearSelectedGroup()
  }

  return (
    <TopRow onClick={() => openGroupsTree()}>

      <TreeIconWrapper>
        <Icon2
          icon="tree"
          size={20}
        />
      </TreeIconWrapper>

      <TopCol>
        <GroupTitle workCount={workCount} />

        {hasSelectedWork && (
          <ResetButton onClick={handleClickOnReset}>
            <Icon2
              icon="crossM"
              size={16}
            />
            Сбросить
          </ResetButton>
        )}
      </TopCol>

    </TopRow>
  )
})

const TopRow = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  width: fit-content;
`

const TopCol = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const TreeIconWrapper = styled.div`
  background-color: ${palette.accent100};
  padding: 6px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

const ResetButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  ${TextL}
  color: ${palette.grey70};
  transition: color 0.15s linear;
  height: fit-content;

  @media (hover: hover) {
    &:hover {
      color: ${palette.grey90};
    }
  }
`
