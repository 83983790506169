import React from 'react'
import styled from 'styled-components'

import { noop } from '@/lib/noop'
import { ArticleCard } from './ArticleCard'

export const ArticleSkeleton = () => (
  <Row>
    <ArticleCard
      id={0}
      onCardClick={noop}
      date=""
      description=""
      title=""
      isLoading
    />
    <ArticleCard
      id={0}
      onCardClick={noop}
      date=""
      description=""
      title=""
      isLoading
    />
    <ArticleCard
      id={0}
      onCardClick={noop}
      date=""
      description=""
      title=""
      isLoading
    />
  </Row>
)

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`
