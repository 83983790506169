import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { HeadXL } from '@/ui'
import { ServiceItem } from './ServiceItem'
import { ServicesContainer } from '../parts'

import { $isModalOpen, $services } from '../../model/private'

export const AllServices = React.memo(() => {
  const isOpen = useStore($isModalOpen)
  const services = useStore($services)

  if (!isOpen) return null

  return (
    <>
      <Title>Сервисы для работы</Title>
      <ServicesContainer>
        {services.map(({ name, alias, icon }) => (
          <ServiceItem key={alias} alias={alias} name={name} icon={icon} />
        ))}
      </ServicesContainer>
    </>
  )
})

const Title = styled.h3`
  ${HeadXL}
`
