import { createGate } from 'effector-react'

import { PaymentDocItem } from '@/dal'
import { createSectionTabsModel } from '@/features/factories'

import { $paymentInfo, getPaymentItemFx } from './private'
import { PaymentTab } from './types'
import { d } from './domain'

export const $isPaymentLoading = getPaymentItemFx.pending
export const PaymentGate = createGate<{ id: PaymentDocItem['id'] | null }>('PaymentGate')

export const $paymentRejectReason = $paymentInfo.map(
  (info) => (info?.reject_comment || info?.cancel_comment),
)

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<PaymentTab>({
  clearErrorTime: 3500,
  defaultValue: PaymentTab.Info,
  clearEvent: PaymentGate.close,
  domain: d,
})
