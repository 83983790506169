import { createSectionTabsModel } from '@/features/factories'
import { $approveTimeoutId } from './header.private'
import { ActTab } from './types'
import { d } from './domain'
import { ActGate } from './public'

export const $isEditBlocking = $approveTimeoutId.map(Boolean)
export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<ActTab>({
  defaultValue: ActTab.Info,
  clearEvent: ActGate.close,
  domain: d,
})
