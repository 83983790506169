import React from 'react'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import {
  InputNumber,
  Ruble, SimpleLoader, themeVar, WINDOW_WIDTH,
} from '@/ui'
import { $openedFundPlan, loadPlanFx } from '../../model'
import { planForm } from '../../model/private'

export const PlanAdvance = React.memo(() => {
  const isLoading = useStore(loadPlanFx.pending)
  const plan = useStore($openedFundPlan)
  const { fields } = useForm(planForm)

  if (!plan) return null

  return (
    <Wrapper>
      <Row
        isBorderBottom
        data-testid="act-info-expand-collapse"
      >
        <HeaderPeriod>
          Аванс
          {isLoading && <SimpleLoader sizePx={16} />}
        </HeaderPeriod>
        <div />
        <Cell isGrayColor>Заявлено</Cell>
        <Cell isGrayColor>Принято</Cell>
      </Row>

      <Row>
        <NamePeriod>Материалы</NamePeriod>
        <div />
        <Cell>
          {plan.avans_material_is_changeable
            ? (
              <>
                <InputNumber
                  thousandSeparator=" "
                  inputStyle="first"
                  value={fields.avans_material.value}
                  onChange={fields.avans_material.onChange}
                  decimalScale={2}
                  dataTestId="budget-advance-material-change"
                  padding="0"
                  textAlign="right"
                  hiddenError
                />
                <Ruble />
              </>
            )
            : (
              <>
                {numberWithSpaces(plan.avans_material?.toString() || '0')}
                <Ruble />
              </>
            )}
        </Cell>
        <Cell>
          {numberWithSpaces(plan.avans_material_approved?.toString() || '0')}
          <Ruble />
        </Cell>
      </Row>

      <Row>
        <NamePeriod>Работы</NamePeriod>
        <div />
        <Cell>
          {numberWithSpaces(plan.avans_work.toString())}
          <Ruble />
        </Cell>
        <Cell>
          {numberWithSpaces(plan.avans_work_approved?.toString() || '0')}
          <Ruble />
        </Cell>
      </Row>
    </Wrapper>
  )
})


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

type ItemProps = {
  isBorderBottom?: boolean
  isGrayColor?: boolean
}

const Row = styled.div<ItemProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 242fr 62fr 152fr 152fr;
  gap: 8px;

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
    `}

  ${({ isBorderBottom }) => isBorderBottom
    && css`
      border-bottom: 1px solid ${themeVar('inlineInputBorder')};
      padding-bottom: 8px;
      margin-bottom: 8px;
    `}
`

const HeaderPeriod = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${themeVar('text')};

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`

const Cell = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  color: ${themeVar('text')};

  ${({ isGrayColor }) => isGrayColor
    && css`
      color: ${themeVar('textGray')};
    `}

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`

const NamePeriod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  color: ${themeVar('textGray')};

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`
