import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import { noop } from '@/lib/noop'
import {
  Dragger,
  Icon,
  Button,
  Modal,
  SimpleLoader,
  TextArea,
  TextInput,
} from '@/ui'
import { FileList } from '../containers'
import {
  $feedbackForm,
  $modalFeedbackOpened,
  addSelectedFeedbackFiles,
  closeFeedbackModal,
  sendMailFeedbackFx,
} from '../../model/private'

export const FeedbackModal = () => {
  const modalFeedbackOpened = useStore($modalFeedbackOpened)
  const uploading = useStore(sendMailFeedbackFx.pending)
  const { fields, submit } = useForm($feedbackForm)

  return (
    <Modal
      visible={modalFeedbackOpened}
      onClose={!uploading ? closeFeedbackModal : noop}
      fillWidthPx={612}
      fillW
      dataTestId="feedback-modal"
    >
      <Header>
        <div>Обращение за помощью</div>
        {!uploading && (
          <IconWrapper
            onClick={() => closeFeedbackModal()}
            data-testid="modal-cross-close"
          >
            <Icon icon="close" />
          </IconWrapper>
        )}
        {uploading && <SimpleLoader />}
      </Header>
      <Content>
        <TextInput
          label="Заголовок"
          inputStyle="second"
          placeholder="Введите тему обращения"
          value={fields.theme.value}
          onChange={fields.theme.onChange}
          error={fields.theme.hasError()}
          errorText={fields.theme.errorText()}
          onClear={fields.theme.reset}
          dataTestId="input-feedback-topic"
        />
        <TextArea
          rows={8}
          label="Сообщение"
          placeholder="Введите ваше сообщение"
          value={fields.message.value}
          onChange={fields.message.onChange}
          error={fields.message.hasError()}
          errorText={fields.message.errorText()}
          dataTestId="textarea-feedback-message"
        />
        <Dragger onFiles={addSelectedFeedbackFiles} />
        <FileList />
        <Button
          styleButton="primary"
          size="L"
          disabled={uploading}
          onClick={submit}
          dataTestId="button-feedback-submit"
        >
          Отправить
        </Button>
      </Content>
    </Modal>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 24px;
`

const IconWrapper = styled.div`
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 16px;
`
