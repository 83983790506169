import { CustomResponseError, TextError, UserNotFound } from './errors'

export const isTextError = (error: Error):
  error is TextError => error instanceof TextError

export const isCustomError = (error: Error):
  error is CustomResponseError => error instanceof CustomResponseError

export const isUserNotFoundError = (error: Error):
  error is UserNotFound => error instanceof UserNotFound
