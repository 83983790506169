import React from 'react'
import styled from 'styled-components'

import {
  NewDateInput, NumberInput, SquareButton,
} from '@/ui'

import { EditingPaymentOrder } from '../../../model'

type Props = {
  id: EditingPaymentOrder['id']
  numberValue: string
  dateValue: string
  canDelete: boolean
  onChange: (value: Pick<EditingPaymentOrder, 'id' | 'number' | 'date'>) => void
  onDelete: (id: EditingPaymentOrder['id']) => void
}

export const EditPaymentOrder = ({
  id, numberValue, dateValue, canDelete, onChange, onDelete,
}: Props) => {
  const handleChangeNumber = (number: string) => {
    onChange({ id, number, date: dateValue })
  }

  const handleChangeDate = (date: string) => {
    onChange({ id, date, number: numberValue })
  }

  return (
    <Wrapper>
      <NumberInput
        value={numberValue}
        placeholder="Номер п/п"
        size="L"
        dataTestId="new-payment-order-number-input"
        onChange={handleChangeNumber}
      />
      <NewDateInput
        inputValue={dateValue}
        size="L"
        dataTestId="new-payment-order-date-input"
        onChange={handleChangeDate}
      />
      {canDelete && (
        <SquareButton
          icon="delete"
          buttonType="grey"
          tooltip="Удалить документ"
          dataTestId="new-payment-order-delete-btn"
          onClick={() => onDelete(id)}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px auto;
  gap: 8px;
`
