import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import {
  TextArea,
  Button,
  IconButton,
  H1TitleCSS,
  ModalLayout,
  themeVar,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import {
  $reasonPlanAgreedContractorCancelText,
  $showPlanAgreedContractorCancel,
  agreedPlanContractorCancelFx,
  closePlanAgreedContractorCancel,
  sendPlanAgreedContractorCancel,
  setPlanAgreedContractorCancelText,
} from '../../model/private'

export const CancelAgreedContractor = () => {
  const showPlanAgreedContractorCancel = useStore($showPlanAgreedContractorCancel)
  const reasonPlanAgreedContractorCancelText = useStore($reasonPlanAgreedContractorCancelText)
  const isCanceling = useStore(agreedPlanContractorCancelFx.pending)

  useOnChangeLocation(closePlanAgreedContractorCancel)

  if (!showPlanAgreedContractorCancel) {
    return null
  }

  return (
    <ModalLayout
      closeModal={closePlanAgreedContractorCancel}
      dataTestId="fs-agreed-to-cancel-modal"
    >
      <Container>
        <Header>
          <Title>Вы уверены что желаете прервать процесс согласования плана?</Title>
          <IconButton
            size={30}
            dataTestId="modal-cross-close"
            icon="cross"
            onClick={() => closePlanAgreedContractorCancel()}
          />
        </Header>
        <TextArea
          label="Комментарий"
          value={reasonPlanAgreedContractorCancelText}
          onChange={setPlanAgreedContractorCancelText}
          rows={7}
          resizeBlock
          dataTestId="textarea-funding-message"
        />

        <ButtonRow>

          <Button
            styleButton="third"
            dataTestId="close-cancel-agreed-contractor-modal"
            size="L"
            onClick={closePlanAgreedContractorCancel}
          >
            Отмена
          </Button>

          <Button
            styleButton="primary"
            size="L"
            dataTestId="sumbit-cancel-agreed-contractor"
            onClick={sendPlanAgreedContractorCancel}
            disabled={reasonPlanAgreedContractorCancelText.length === 0}
            isLoading={isCanceling}
          >
            Отклонить
          </Button>

        </ButtonRow>

      </Container>
    </ModalLayout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeVar('lightBackground')};
  padding: 24px;
  max-width: 680px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const Title = styled.div`
  ${H1TitleCSS}
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`

