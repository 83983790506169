import { Effect, sample } from 'effector'

import { UNAUTHORIZED_STATUS_CODE } from '@/dal/request'

import { replaceNavigate } from './public'

type Params = {
  effect: Effect<any, any, any>
  route: string
}

export const redirectOnFail = ({ effect, route }: Params) => {
  sample({
    clock: effect.failData,
    // @TODO решить проблему с типами при использовании isCancelError
    filter: (error) => error && error.request?.status !== UNAUTHORIZED_STATUS_CODE,
    fn: () => route,
    target: replaceNavigate,
  })
}
