import { DocumentActions } from '@/dal'
import { IconName2 } from '../../ui/v2/icons'
import { IconName } from '../../ui/Icon'

type ResolvedAction = {
  text: string,
  icon: IconName
}

// @TODO remove "new" prefix after update icon component
type NewResolvedAction = {
  text: string,
  icon: IconName2
}
export const newGetDocumentActionByAlias = (
  action: DocumentActions | undefined | null,
): NewResolvedAction | null => {
  switch (action) {
    case DocumentActions.answer_on_additional_request: {
      return {
        text: 'Ответить на доп. запрос',
        icon: 'visaTask',
      }
    }
    case DocumentActions.sign_documents: {
      return {
        text: 'Подписать документы',
        icon: 'signInDiadoc',
      }
    }
    default: return null
  }
}

export const getDocumentActionByAlias = (
  action: DocumentActions | undefined | null,
): ResolvedAction | null => {
  switch (action) {
    case DocumentActions.answer_on_additional_request: {
      return {
        text: 'Ответить на доп. запрос',
        icon: 'dialog-With-Label',
      }
    }
    case DocumentActions.sign_documents: {
      return {
        text: 'Подписать документы',
        icon: 'sign-in-diadoc',
      }
    }
    case DocumentActions.accept_solution: {
      return {
        text: 'Принять решение по выполнению',
        icon: 'approve-act-icon',
      }
    }
    default: return null
  }
}
