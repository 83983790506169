import { ContractDirection } from '../administrative'
import { DefaultErrorResponse } from '../error-handler'
import { Group } from '../group-tree'
import {
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  DocumentTracking,
  FileDTO,
  RejectionReason,
} from '../interfaces'

export type KS2Data = {
  material: {
    declared: string
    approved: string
    diff: string | null
  }
  work: {
    declared: string
    approved: string
    diff: string | null
  }
  total: {
    declared: string
    approved: string
    diff: string | null
  }
}

export type KS3Data = {
  guarantee_deductions?: {
    percent: string
    declared: string
    approved: string
    diff: string | null
  }
  advans: {
    percent: string
    declared: string
    approved: string
    can_edit: boolean
    diff: string | null
  }
  advans_remainder?: {
    declared: string
    can_approve: boolean
    diff: string | null
    approving_available: boolean
  }
  deductions?: {
    items: {
      name: string
      percent?: string
      declared: string
      approved: string
    }[]
    declared: string
    approved: string
    diff: string | null
  }
  total: {
    declared: string
    approved: string
    diff: string | null
  }
  total_cost?: {
    declared: string
    approved: string
    diff: string | null
  }
  sum_total?: {
    declared: string
    approved: string
    diff: string | null
  }
}

export type PaymentOrder = {
  id: number
  number: string
  date: string // YYYY-MM-DD
}

export type NewPaymentOrder = Omit<PaymentOrder, 'id'> & {
  id: null
}

export enum ActWorkStatus {
  NotCompleted = 'not_completed', // Не выполнено
  InterimAcceptance = 'interim_acceptance', // Промежуточная приемка
  Declared = 'declared', // Заявлено
  Completed = 'completed', // Выполнено
}

export type ReportPeriod = {
  date_start: string // YYYY-MM-DD
  date_end: string // YYYY-MM-DD
}

export type ActDTO = {
  id: number
  name: string
  number: string | null
  type: DocumentAlias.ACT
  act_type: ContractDirection
  last_update: string // isoDate
  status: DocumentStatuses
  progress: DocumentProgress
  comment: string | null
  ks2: KS2Data | null
  ks3: KS3Data
  attachments: FileDTO[] | null
  documents_link: string
  payment_orders: PaymentOrder[] | null
  instruction_link?: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
  approve_comment: string | null
  cancellation_comment: {
    description: string
    help_description: string
  } | null
  report_period: ReportPeriod | null
  contract: {
    id: number
    name: string
    project: {
      id: number
      name: string
    }
  }
  reject_time: string | null // isoDate
  features: DocumentFeatures & {
    can_process_templates: boolean
    can_edit_payment_orders: boolean
    can_count_advance: boolean
    has_negative_completions_counts: boolean
    can_delete_last_payment_order: boolean
    editable_fields: {
      comment: boolean
      reporting_period__date_end: boolean
      ks3_advans_declared: boolean
    }
  }
  document_tracking: DocumentTracking
  related_payment_document: {
    id: PaymentOrder['id']
    name: string
    status: DocumentStatuses
    progress: DocumentProgress
  } | null
  draft?: {
    id: number
    name: string
  }
  compensation_documents_info: {
    items: {
      id: number
      name: string
      status: DocumentStatuses
      consumption_period: string | null
      sum: string // float number
      signed_docs_link: string // url
    }[]
    total_sum: string
  } | null
}

type BaseActTableFolder = {
  is_folder: true
  name: string
  id: string // uuid
  note: string
  number_1c: string
  article: string
  group_id: string | null
  depth: number,
  has_children: boolean,
}

export type ActTableFolderSMR = BaseActTableFolder & {
  period_cost: {
    total: string
    material: string
    work: string
  }
}

export type ActTableFolderPIR = BaseActTableFolder & {
  total_cost: string
  period_cost: string
}

export type ActTableFolder = ActTableFolderSMR | ActTableFolderPIR

type BaseActTableItem = {
  is_folder: false
  name: string
  id: string // uuid
  number_1c: string
  project: string
  article: string
  note: string
  group_id: string | null
  depth: number,
  estimate: {
    current: string
  }
  remainder: {
    current: string
  }
  unit: string
}

export type ActWorkSMR = BaseActTableItem & {
  completed: {
    current: string
    diff: string
    corrected: {
      value: string
      comment: string
    }
    filled_value: string // для заполнения по остатку
    can_edit: boolean
  }
  total_cost: {
    total: string
    material: string
    work: string
  }
  period_cost: {
    total: string
    material: string
    work: string
  }
}

export type ActWorkPIR = BaseActTableItem & {
  status: ActWorkStatus
  start_date: string // YYYY-MM-DD
  end_date: string // YYYY-MM-DD
  is_completed: {
    value: boolean
    can_change: boolean
  }
  total_cost: string
  period_cost: string
}

export type ActTableItem = ActWorkSMR | ActWorkPIR

export enum RowFilterEnum {
  IsCorrected = 'corrected_by_technical_supervision',
  HideDeleted = 'hide_deleted'
}

export enum RemainedFilter {
  FilledJobs = 'filled_jobs',
  UnFilledJobs = 'unfilled_jobs',
  CompletedJobs = 'previously_completed_jobs'
}

export type GetActTableParams = {
  actType: ContractDirection
  actId: ActDTO['id']
  groupId?: Group['id']
  name?: string
  article?: number[];
  remained?: RemainedFilter[];
  rowFilters?: RowFilterEnum[];
  // для СМР
  reportPeriod?: RemainedFilter[]
  // для ПИР
  acceptanceStatus?: ActWorkStatus | null
  dateFrom?: string
  dateTo?: string
}

export type ActTableRow = {
  is_folder: true
} & ActTableFolder | {
  is_folder: false
} & ActTableItem

export type GetWorkFields = {
  fill_by_remaining: boolean
  can_fill_by_remaining: boolean
  total_volume: string
  download_template_link: string
  all_period_cost?: string // стоимость за отчётный период в ПИР
}

export type GetTreeTableResponse = GetWorkFields & {
  root: ActTableRow[]
  total: number
}

export type GetFlatTableResponse = { items: ActTableItem[] } & GetWorkFields

export type GetFolderContentParams = Omit<GetActTableParams, 'groupId'> & {
  groupId: ActTableFolder['id']
}

export type FillByRemainingParams = {
  isFill: boolean
  actId: ActDTO['id']
}

export type UploadActTemplateResponse = {
  ks: FillByRemainingResponse
}

export type FillByRemainingResponse = {
  ks2: KS2Data
  ks3: KS3Data
  is_fill_by_remaining: boolean
}

export type SendWorkVolumeFxPayload = {
  actId: number
  actType: ContractDirection
  workId: string
  value: string | boolean
  isTree: boolean
}

export type UpdateTableResponse = GetWorkFields & {
  ks2: KS2Data | null
  ks3: KS3Data
  total_volume: string
  // updated root item, for update a root array
  root?: ActTableRow
  // tree updated items
  tree?: { [key in ActTableFolder['id']]: ActTableRow[] }
  // target item
  target?: ActTableRow
}

export type ArticleOutlay = {
  name: string,
  number_1c: string,
  id: number
}

export type UploadActTableTemplate = {
  actId: ActDTO['id'],
  file: File
}

export type PostPaymentOrdersParams = {
  id: ActDTO['id']
  to_delete?: PaymentOrder['id'][]
  to_update?: (PaymentOrder | NewPaymentOrder)[]
}

export type ResponseWithKSData = {
  ks2: KS2Data
  ks3: KS3Data
  is_fill_by_remaining: boolean
}

export type PostActAttachmentsParams = {
  id: ActDTO['id']
} & DefaultUpdateFileParams

// @TODO актуализировать позже
export enum ActErrorType {
  ApproveError = 'ACT_APPROVING_CONFLICT_WITH_OTHER',
  CreateConflictError = 'ACT_NEW_CONFLICT',
  CreateErrorRequiredFields = 'CONTRACT_EMPTY_REQUIRED_FIELDS_CONFLICT'
}

export type CreateActReqiredFieldErrorType = {
  code: ActErrorType.CreateErrorRequiredFields
  detail: {
    contragent_id: string
    message: string
  }
}

export type ActForApproveError = {
  code: ActErrorType.ApproveError
  detail: string
  id?: ActDTO['id']
  number?: string
}

export type CreateActConflictErrorType = {
  detail: string,
  code: ActErrorType.CreateConflictError
  id: ActDTO['id'],
  number: string
}

export type ActErrorResponse =
  ActForApproveError
  | CreateActConflictErrorType
  | CreateActReqiredFieldErrorType
  | DefaultErrorResponse

export type CreateActFxPayload = {
    contractId: number
    // не нужно, если создаётся выполнение ПИР
    reporting_period?: {
      date_start: string // YYYY-MM-DD
      date_stop: string // YYYY-MM-DD
    }
  }
