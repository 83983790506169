import React from 'react'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import { NewButton } from '@/ui'

import {
  $isFormDisabled,
  closeModal,
  contactForm,
} from '../../model/private'

export const Buttons = React.memo(() => {
  const { isValid, isTouched } = useForm(contactForm)
  const isDisabled = useStore($isFormDisabled)

  return (
    <ButtonRow>
      <NewButton
        dataTestId="submit-contact-form"
        label="Сохранить"
        isFill
        isDisabled={!isValid || !isTouched}
        isPending={isDisabled}
        type="submit"
      />
      <NewButton
        dataTestId="close-contact-modal"
        label="Отменить"
        isFill
        buttonType="grey"
        onClick={closeModal}
      />
    </ButtonRow>
  )
})

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`
