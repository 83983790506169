import { AxiosError } from 'axios'

export enum DefaultErrorEnum {
  TextError = 'ERROR',
  UserNotFound = 'NO_EMAIL_FOUND',
}

export type DefaultTextResponseError = {
  code: DefaultErrorEnum.TextError
  detail: string
}

export type UserNotFoundResponseError = {
  code: DefaultErrorEnum.UserNotFound
  detail: string
}

export type DefaultErrorResponse =
  DefaultTextResponseError
  | UserNotFoundResponseError
  | AxiosError<any>
