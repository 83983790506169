import React from 'react'
import styled, { css } from 'styled-components'

import { HeadL, HeadM, TextL } from '../../typography'
import { palette } from '../../palette'
import { Item, OnTabClick, TabSize } from './types'

type Props = {
  onItemClick: OnTabClick
  item: Item
  activeId: Item['id'] | null
  size: TabSize
  count?: number
}

export const TabItem = ({
  activeId,
  item,
  onItemClick,
  size,
  count,
}: Props) => (
  <ItemWrapper
    key={item.id}
    size={size}
    onClick={() => onItemClick(item.id)}
    isActive={item.id === activeId}
  >
    {item.label}
    {count !== undefined && (
      <TabLabel size={size}>
        {count}
      </TabLabel>
    )}
  </ItemWrapper>
)

const ItemWrapper = styled.div<{
  isActive: boolean
  size: 'M' | 'L'
}>`
  ${({ size }) => (size === 'M' ? TextL : HeadL)}
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ size }) => (size === 'M' ? 6 : 8)}px;

  ${({ isActive }) => (!isActive && css`
    @media (hover: hover) {
      &:hover::before {
        background-color: ${palette.accent20};
      }
    }
  `)}

  &::before {
    content: '';
    background-color: transparent;
    border-radius: 4px;
    height: 2px;
    bottom: 0;
    width: 100%;
    transition: background-color 0.15s linear;
    position: absolute;

    ${({ isActive }) => (isActive && css`
      background-color: ${palette.accent100};
    `)}
  }
`

const TabLabel = styled.span<{ size: TabSize }>`
  ${({ size }) => (size === 'M' ? TextL : HeadM)}
  color: ${palette.grey60};
`
