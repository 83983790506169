import React from 'react'
import styled from 'styled-components'

import {
  Icon2,
  NOTIFICATION_ICON_COLOR,
  NewIconButton,
  NotificationType,
  TextL,
  palette,
} from '@/ui'

import { setToastTimeout } from '../../model/private'

type Props = {
  content: string | React.ReactNode
  icon: NotificationType
  id: string
  onClose?: (id: string) => void
}

export const ToastItem = ({
  content,
  icon,
  id,
  onClose,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!ref.current) return

    // Пока убираем рассчёт высоты и просто отображаем ошибки 15 сек, а остальное по 3 сек
    // const height = ref.current.scrollHeight
    // const { lineHeight } = getComputedStyle(ref.current)
    // const lineCount = Math.round(height / (parseFloat(lineHeight) || 20))

    setToastTimeout(id)
  }, [ref, content, onClose])

  return (
    <Item>
      <Wrapper>
        <Icon2
          icon={icon}
          size={20}
          color={NOTIFICATION_ICON_COLOR[icon]}
        />
        <Content ref={ref}>
          {content}
        </Content>
      </Wrapper>

      {onClose && (
        <NewIconButton
          dataTestId={`close-toast-${id}`}
          icon="cross"
          size={20}
          padding="12px"
          color="grey60"
          hoverColor="grey30"
          activeColor="white"
          onClick={() => onClose(id)}
        />
      )}
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  border-radius: 8px;
  align-items: center;
  background-color: ${palette.grey100};
  margin: 0 auto;
  height: max-content;
  width: max-content;
  max-width: 550px;
  pointer-events: all;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 11px 20px 11px 16px;
`

const Content = styled.div`
  ${TextL}
  padding-top: 2px;
  color: ${palette.white};
`
