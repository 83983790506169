import { forward, sample } from 'effector'
import { v4 as uuid4 } from 'uuid'

import {
  CompanyItem,
  ContactItemType,
  ProjectsDTO,
} from '@/dal'

import { createToast } from '@/features/toast-service/model'
import {
  $isEdit,
  $isOpen,
  addProjectField,
  closeModal,
  contactForm,
  createContactFx,
  editContactFx,
} from './private'
import {
  onContactCreated,
  onContactEdited,
  openCreateContact,
  openEditeContact,
} from './public'

$isOpen
  .on([openCreateContact, openEditeContact], () => true)
  .reset(closeModal)

$isEdit
  .on(openEditeContact, () => true)
  .reset(closeModal)

sample({
  clock: openCreateContact,
  fn: ({ company, projects }) => ({
    company: company as number,
    projects: projects?.map((item) => ({
      ...item,
      projectId: item.projectId,
      id: uuid4(),
    })),
  }),
  target: contactForm.set,
})

sample({
  clock: openEditeContact,
  fn: (params) => ({
    ...params,
    phone_number: params.phone_number.replace('+7', ''),
    projects: params.projects.map((item) => ({
      id: uuid4(),
      projectId: item.id,
      roles: item.roles.map((role) => role.id),
    })),
  }),
  target: contactForm.set,
})

forward({
  from: closeModal,
  to: contactForm.reset,
})

forward({
  from: [
    editContactFx.done,
    createContactFx.done,
  ],
  to: closeModal,
})

forward({
  from: editContactFx.doneData,
  to: onContactEdited,
})

forward({
  from: createContactFx.doneData,
  to: onContactCreated,
})

sample({
  clock: addProjectField,
  source: contactForm.fields.projects.$value,
  fn: (value) => [
    ...value,
    { id: uuid4(), roles: [], projectId: null },
  ],
  target: contactForm.fields.projects.onChange,
})

sample({
  clock: contactForm.formValidated,
  filter: $isEdit,
  fn: (form) => ({
    ...form,
    company: undefined,
    id: form.id as ContactItemType['id'],
    phone_number: `+7${form.phone_number}`,
    projects: form.projects.map(({ projectId, roles }) => ({
      id: projectId,
      roles,
    })) as ProjectsDTO[],
  }),
  target: editContactFx,
})

sample({
  clock: contactForm.formValidated,
  filter: $isEdit.map((val) => !val),
  fn: (form) => ({
    ...form,
    company: form.company as CompanyItem['id'],
    id: undefined,
    phone_number: `+7${form.phone_number}`,
    projects: form.projects.map(({ projectId, roles }) => ({
      id: projectId,
      roles,
    })) as ProjectsDTO[],
  }),
  target: createContactFx,
})

createToast({
  effect: editContactFx,
  doneText: 'Контакт отредактирован успешно',
})

createToast({
  effect: createContactFx,
  doneText: 'Контакт создан успешно',
})
