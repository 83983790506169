import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

import { RequestHistory } from '@/features/support/appeal-history/view'
import { ContentContainer } from '@/ui'

export const SupportPage = () => (
  <Scrollbars>
    <ContentContainer isAutoHeight>
      <RequestHistory />
    </ContentContainer>
  </Scrollbars>
)
