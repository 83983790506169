import React from 'react'
import { useStore } from 'effector-react'

import { DocumentStatuses, Work } from '@/dal'
import { WorkTableInput } from '@/ui'

import { onWorkInputError, setPlanWorkVolume, setPlanWorkVolumeFx } from '../../model/private'
import { DECIMAL_COUNT } from '../../model/const'
import { $openedFundPlanStatus } from '../../../plan-info/model'

type Props = {
  actualPlanCountDeclarated: string
  forecast: number
  isSelected: boolean
  id: Work['id']
  onInputFocus: () => void
}

const ALLOWED_SEPARATORS = [',']

export const WorkListItemInput = React.memo(({
  actualPlanCountDeclarated,
  isSelected,
  forecast,
  id,
  onInputFocus,
}: Props) => {
  const planStatus = useStore($openedFundPlanStatus)
  const [inputVal, setInputVal] = React.useState<string>(actualPlanCountDeclarated)
  const isLoading = useStore(setPlanWorkVolumeFx.pending)

  React.useEffect(() => {
    setInputVal(actualPlanCountDeclarated)
  }, [actualPlanCountDeclarated])

  const onSubmitHandler = (val: string) => {
    setPlanWorkVolume({
      workId: id,
      count: parseFloat(val),
    })
  }

  return (
    <WorkTableInput
      dataTestId={`input-funding-plan-count-${id}`}
      disabled={planStatus !== DocumentStatuses.Draft || isLoading}
      maxValue={forecast.toString()}
      value={inputVal}
      onChange={setInputVal}
      isSelect={isSelected}
      onFocus={onInputFocus}
      onSendValue={onSubmitHandler}
      decimalScale={DECIMAL_COUNT}
      initialValue={actualPlanCountDeclarated}
      onMaxValueError={onWorkInputError}
      allowedDecimalSeparators={ALLOWED_SEPARATORS}
    />

  )
})
