import { forward, sample } from 'effector'
import { $documentId } from '../../shared-model'
import {
  $commentsList,
  getEstimateItemCommentsFx,
  closeCommentsModal,
  $openedCommentsRowId,
  openCommentsModal,
  resetComments,
} from './comments-item.private'
import { TableGate } from './private'
import { getTableData } from './public'

$commentsList
  .on(getEstimateItemCommentsFx.doneData, (_, comments) => comments)
  .reset(resetComments)

$openedCommentsRowId
  .on(openCommentsModal, (_, id) => id)
  .reset(resetComments)

sample({
  clock: openCommentsModal,
  source: $documentId,
  filter: Boolean,
  fn: (documentId, itemId) => ({ documentId, itemId }),
  target: getEstimateItemCommentsFx,
})

forward({
  from: [closeCommentsModal, TableGate.close, getTableData],
  to: resetComments,
})
