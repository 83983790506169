import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'

import { ContentContainer } from '@/ui'
import { RequestHistory } from '@/features/administrative-appeals/request-history'
import { NewAdministrativeAppeal } from '@/features/administrative-appeals/new-appeal'
import { RouterPaths } from '@/features/app/model'

export const AdministrativeAppealsPage = () => (
  <Scrollbars>
    <ContentContainer isAutoHeight>
      <Switch>
        <Route exact path={RouterPaths.AdministrationAppeals}>
          <RequestHistory />
        </Route>
        <Route path={RouterPaths.NewAdministrationAppeal}>
          <NewAdministrativeAppeal />
        </Route>
      </Switch>
    </ContentContainer>
  </Scrollbars>
)

