import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled, { css } from 'styled-components'

import { PaletteColor, palette } from '../palette'
import { TextS } from '../typography'

type Props = {
  stroke: PaletteColor
  filledPercent: number
  fixedSize?: number
  strokeWidth?: number
  showPercent?: boolean
  duration?: number
}

export const CircleProgress = ({
  stroke,
  filledPercent,
  fixedSize,
  strokeWidth = 4,
  showPercent,
  duration = 1000,
}: Props) => {
  const [sizes, setSizes] = React.useState(0)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (!containerRef.current) return
    const { offsetWidth } = containerRef.current
    if (sizes !== offsetWidth) {
      setSizes(offsetWidth)
    }
  })

  const [radius, circumference] = React.useMemo(() => {
    if (!sizes || sizes < 0) return [0, 0]
    const radius = sizes / 2 - (strokeWidth / 2)
    const circumference = 2 * Math.PI * radius
    return [radius, circumference]
  }, [sizes])

  const dashoffset = React.useMemo(() => {
    if (!circumference) return 0
    return circumference * (1 - filledPercent / 100)
  }, [filledPercent, circumference])

  const animatedTrack = useSpring({
    from: { dashoffset: circumference },
    to: { dashoffset: 0 },
    config: {
      duration,
    },
  })

  const animatedProgress = useSpring({
    from: { dashoffset: circumference },
    to: { dashoffset },
    config: {
      duration,
    },
  })

  const animatedValue = useSpring({
    from: { value: 0 },
    to: { value: filledPercent },
    config: {
      duration,
    },
  })

  return (
    <Wrapper ref={containerRef} fixedSize={fixedSize}>
      <SVG viewBox={`0 0 ${sizes} ${sizes}`}>
        <CircleTrack
          stroke={stroke}
          cx={sizes / 2}
          cy={sizes / 2}
          r={radius}
          strokeDasharray={circumference}
          strokeWidth={strokeWidth}
          style={{ strokeDashoffset: animatedTrack.dashoffset }}
        />
        <Circle
          stroke={stroke}
          cx={sizes / 2}
          cy={sizes / 2}
          r={radius}
          strokeDasharray={circumference}
          style={{ strokeDashoffset: animatedProgress.dashoffset }}
          strokeWidth={strokeWidth}
        />
      </SVG>
      {showPercent && (
        <PercentLabel>
          {animatedValue.value.to((x) => `${x.toFixed(0)}%`)}
        </PercentLabel>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ fixedSize?: number }>`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ fixedSize }) => fixedSize && css`
    width: ${fixedSize}px;
    height: ${fixedSize}px;
  `}

  aspect-ratio: 1 / 1 ;
  transform: rotate(-90deg);
`

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`

const CircleTrack = styled(animated.circle) <Pick<Props, 'stroke' | 'strokeWidth'>>`
  fill: none;
  stroke-width: ${({ strokeWidth }) => strokeWidth}px;
  stroke: ${palette.grey30};
  stroke-linecap: round;
`

const Circle = styled(animated.circle) <Pick<Props, 'stroke' | 'strokeWidth'>>`
  fill: none;
  stroke-width: ${({ strokeWidth }) => strokeWidth}px;
  stroke: ${({ stroke }) => palette[stroke]};
  stroke-linecap: round;
`

const PercentLabel = styled(animated.div)`
  position: absolute;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);

  ${TextS}
`
