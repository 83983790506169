import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { Link, useHistory } from 'react-router-dom'

import { $accessToken } from '@/dal/auth'
import {
  Button,
  themeVar,
  WINDOW_WIDTH,
} from '@/ui'
import Logo from '@/ui/assets/LogoWhite.svg'
import { RouterPaths } from '@/features/app/model'

import {
  $checkedRequest,
  $isSubscribe,
  getSubscribe,
  postMaintenanceFx,
  resetCheckedRequest,
} from '../../model/private'

export const MaintenancePage = () => {
  const isSubscribe = useStore($isSubscribe)
  const loading = useStore(postMaintenanceFx.pending)
  const accessToken = useStore($accessToken)

  const checkedRequest = useStore($checkedRequest)
  const history = useHistory()

  React.useEffect(() => {
    if (checkedRequest) {
      history.replace(RouterPaths.Home)
      return resetCheckedRequest()
    }
  }, [history, checkedRequest])

  return (
    <>
      <NavBarContainer to={RouterPaths.Login}>
        <LogoLink to={RouterPaths.Home}>
          <Logo />
        </LogoLink>
      </NavBarContainer>
      <Container>
        <Window>
          <Title>Технические работы</Title>
          <Text>
            В настоящее время на сайте проводятся технические работы. Попробуйте
            зайти позже.
          </Text>
          <Button
            dataTestId="maintenance-button"
            size="L"
            styleButton="primary"
            onClick={getSubscribe}
            disabled={isSubscribe || loading || !accessToken}
          >
            <ButtonLabel>Оповестить меня об окончании работ</ButtonLabel>
          </Button>
        </Window>
      </Container>
    </>
  )
}

const NavBarContainer = styled(Link)`
  position: absolute;
  top: 20px;
  left: 40px;
`

const LogoLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  height: 22px;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Window = styled.div`
  max-width: 572px;
  padding: 40px;
  text-align: center;
  background: ${themeVar('lightBackground')};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin: 0px 10px;

  @media (max-width: ${WINDOW_WIDTH.tablet - 1}px) {
    padding: 20px;
  }
`

const Title = styled.div`
  font-size: 32px;
  line-height: 1.2;
  color: ${themeVar('text')};
  margin-bottom: 14px;

  @media (max-width: ${WINDOW_WIDTH.tablet - 1}px) {
    font-size: 24px;
  }

  @media (max-width: ${WINDOW_WIDTH.mobile - 1}px) {
    font-size: 20px;
  }
`

const Text = styled.div`
  color: ${themeVar('text')};
  font-size: 24px;
  line-height: 1.4;
  font-weight: 300;
  max-width: 492px;
  margin-bottom: 24px;

  @media (max-width: ${WINDOW_WIDTH.tablet - 1}px) {
    font-size: 18px;
  }

  @media (max-width: ${WINDOW_WIDTH.mobile - 1}px) {
    font-size: 16px;
  }
`

const ButtonLabel = styled.div`
  font-size: 20px;
  line-height: 1.1;

  @media (max-width: ${WINDOW_WIDTH.tablet - 1}px) {
    font-size: 18px;
  }

  @media (max-width: ${WINDOW_WIDTH.mobile - 1}px) {
    font-size: 14px;
  }
`
