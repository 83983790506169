import React from 'react'
import { useStore } from 'effector-react'

import { noop } from '@/lib/noop'
import { DocumentStatuses, SummaryFieldAlias } from '@/dal'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import {
  AvansCol,
  CurrencyRow,
  GridWrapper,
  Label,
} from '../parts'
import { $summary, onFieldSubmit } from '../../model/private'

export const Avans = React.memo(() => {
  const summary = useStore($summary)
  const status = useStore($documentStatus)

  const memoHandleAvansSubmit = React.useCallback((val: string) => {
    onFieldSubmit({ field: SummaryFieldAlias.AVANS, value: val })
  }, [onFieldSubmit])

  if (!summary) return null

  const isDraft = status === DocumentStatuses.Draft
  const { avans, nds } = summary

  return (
    <>
      <CurrencyRow
        {...nds}
        onSubmit={nds.is_changeable ? noop : undefined}
        label="НДС в т.ч."
        isDraft={isDraft}
        type="percent"
      />
      <GridWrapper>
        <Label>Аванс</Label>
        <AvansCol
          value={avans.percentage_current}
          currencyValue={avans.current}
        />
        <AvansCol
          value={avans.percentage_adjusted ?? '0.00'}
          currencyValue={avans.adjusted}
          isChangeable={avans.is_changeable}
          isEdited={avans.is_edited}
          onSubmit={memoHandleAvansSubmit}
        />
        <AvansCol
          value={avans.percentage_approved}
          currencyValue={isDraft ? undefined : avans.approved}
          isEdited={avans.is_edited && !isDraft}
          isGrey={isDraft}
          isApproved
        />
      </GridWrapper>
    </>
  )
})
