import React from 'react'
import Scrollbar from 'react-scrollbars-custom'
import styled, { css } from 'styled-components'
import { usePopper } from 'react-popper'
import { IconButton } from './buttons/iconButton'

import { Icon } from './Icon'
import { TextInput } from './inputs'
import { ThemedStyledProps, themeVar } from './theming'

const ITEM_HEIGHT_PX = 43
const DROPDOWN_MAX_HEIGHT_PX = 500
const SEARCH_MIN_HEIGHT_PX = 130
const SEARCH_HEIGHT_PX = 92

type Props<T> = {
  options: {
    value: T
    text: string
  }[]
  enableSearch?: boolean
  placeholder?: string
  selected?: T
  onOptionChange: (optionValue: T) => void
  onClearSelected?: () => void
  popupWidth?: number
  label?: string
  exactWidth?: number
  dataTestId: string
  disabled?: boolean
}

export const Dropdown = <T, >({
  options,
  onOptionChange,
  selected,
  enableSearch = false,
  placeholder = 'Не выбрано',
  onClearSelected,
  popupWidth,
  label,
  exactWidth,
  dataTestId,
  disabled,
}: Props<T>) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = React.useState(false)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)
  const [searchText, setSearchText] = React.useState('')

  const toggleList = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const selectedText = React.useMemo(
    // @TODO Сейчас при сохранении в query все приводится к строке,
    // убрать приведение типов после фикса
    // eslint-disable-next-line
    () => options.find(({ value }) => value == selected)?.text,
    [options, selected],
  )

  const optionsFiltered = React.useMemo(
    () => options.filter((item) => item.text
      .toUpperCase().includes(searchText.toUpperCase())),
    [options, searchText],
  )

  const popper = usePopper(ref.current, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const dropdownContainerHeight = React.useMemo(() => {
    let height = ITEM_HEIGHT_PX * optionsFiltered.length + 1

    if (height > DROPDOWN_MAX_HEIGHT_PX) {
      height = DROPDOWN_MAX_HEIGHT_PX
    }

    if (enableSearch) {
      if (optionsFiltered.length === 0) {
        height = SEARCH_MIN_HEIGHT_PX
      } else {
        height += SEARCH_HEIGHT_PX
      }
    }

    return height
  }, [enableSearch, optionsFiltered.length])

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (isOpen) {
          setIsOpen(false)
        }
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const onOptionClicked = (item: T) => {
    onOptionChange(item)
    toggleList()
  }

  return (
    <DropDownContainer
      ref={ref}
      exactWidth={exactWidth}
      disabled={Boolean(disabled)}
    >
      {label && <Label>{label}</Label>}
      <DropDownHeader
        onClick={toggleList}
        data-testid={dataTestId}
        disabled={Boolean(disabled)}
      >
        <SelectedText>
          {selectedText && selectedText}
          {!selectedText && <div>{placeholder}</div>}
        </SelectedText>
        <Icons>
          {((typeof selected === 'number' && selected !== -1)
            || (typeof selected === 'string' && selected !== ''))
            && onClearSelected
            && optionsFiltered.length > 0 && (
            <IconButton
              size={39}
              onClick={onClearSelected}
              dataTestId={`${dataTestId}-button-dropdown-clear`}
              icon="cross"
            />
          )}
          {Boolean(optionsFiltered.length) && <Icon icon="arrow-down" />}
        </Icons>
      </DropDownHeader>
      {isOpen && (
        <PopperWrapper
          tabIndex={-1}
          style={{
            ...popper.styles.popper,
            width: ref.current?.offsetWidth,
          }}
          {...popper.attributes.popper}
          ref={setPopperElement}
        >
          <DropDownListContainer
            height={dropdownContainerHeight}
            width={popupWidth}
          >
            <Scrollbar>
              <DropDownList>
                {enableSearch && (
                  <SearchItem>
                    <TextInput
                      value={searchText}
                      onChange={setSearchText}
                      onClear={() => setSearchText('')}
                      icon="search"
                      placeholder="Поиск"
                      dataTestId={`${dataTestId}-input-dropdown-search`}
                      inputStyle="second"
                      disabled={disabled}
                      hiddenError
                    />
                  </SearchItem>
                )}
                {!optionsFiltered.length ? (
                  <NotFoundText>Результаты не найдены</NotFoundText>
                ) : (
                  optionsFiltered.map((item, index) => (
                    <ListItem
                      active={item.value === selected}
                      key={index}
                      onClick={() => onOptionClicked(item.value)}
                      data-testid={`${dataTestId}-dropdown-option-${item.value}`}
                    >
                      <ItemWrapper>{item.text}</ItemWrapper>
                    </ListItem>
                  ))
                )}
              </DropDownList>
            </Scrollbar>
          </DropDownListContainer>
        </PopperWrapper>
      )}
    </DropDownContainer>
  )
}

const PopperWrapper = styled.div`
  margin-top: -1px;
  width: 257px;
  z-index: 5;
`

type DropDownContainerProps = {
  exactWidth?: number
  disabled: boolean
}

const DropDownContainer = styled.div<DropDownContainerProps>`
  width: 100%;
  overflow: hidden;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}

  ${({ exactWidth }) => (exactWidth
    ? css`
          width: ${exactWidth}px;
        `
    : css`
          flex: 1;
        `)}
`

const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${themeVar('text')};
`

const NotFoundText = styled.div`
  width: 100%;
  text-align: center;
  margin: 10px 0;
`

const DropDownHeader = styled.div<{disabled: boolean}>`
  border: 1px solid ${themeVar('line')};
  padding: 14px;
  box-sizing: border-box;
  color: ${themeVar('text')};
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  height: 45px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}
`

const SelectedText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

type DropdownListContainerProps = {
  height: number
  width?: number
}

const DropDownListContainer = styled.div<DropdownListContainerProps>`
  position: absolute;
  min-height: 45px;
  height: ${({ height }) => height}px;
  border: 1px solid ${themeVar('line')};
  width: ${({ width }) => (width ? `max(${width}px, 100%)` : '100%')};
  margin-top: -1px;
  margin-right: 6px;
  box-sizing: border-box;
  background: ${themeVar('lightBackground')};

  .ScrollbarsCustom-Track .ScrollbarsCustom-TrackY {
    background-color: ${themeVar('gray4')};
  }

  .ScrollbarsCustom-Thumb .ScrollbarsCustom-ThumbY {
    background-color: ${themeVar('main')};
  }

  .ScrollbarsCustom-Wrapper {
    inset: 0px 0px 0px 0px !important;
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;

  color: ${themeVar('fontColor')};
  box-sizing: border-box;
  font-size: 14px;
  animation: fadeout 0.5s;
`

type ListItemProps = {
  active?: boolean
}

const SearchItem = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${themeVar('line')};
`

const ListItem = styled.li<ListItemProps & ThemedStyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 8px;
  min-height: 43px;
  animation: fadeout 0.5s;
  user-select: none;
  cursor: pointer;
  border-bottom: 1px solid ${themeVar('line')};

  ${({ active }) => Boolean(active)
    && css`
      color: ${themeVar('main')};
    `}

  &:hover {
    background-color: ${themeVar('mainBackground')};

    .item-hint {
      display: block;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`

const Icons = styled.div`
  display: flex;
  align-items: center;
`

const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
`
