import React from 'react'
import styled, { css } from 'styled-components'

import { ActionOption, Option, OptionSize } from './types'
import { palette } from '../palette'
import { Icon2 } from '../icons'
import { InputStyle } from './styles'

import { useDropDownInput } from './useDropDownInput'
import { DropDownInputList } from './DropDownInputList'

type Props = {
  options: Option[] | null
  selectedId: Option['id'] | null
  hasSearch?: boolean
  placeholder?: string
  optionSize?: OptionSize
  actionOptions?: ActionOption[] | null
  dataTestId: string,
  isDisabled?: boolean
  isLoading?: boolean
  isInvalid?: boolean
  sameComponentWidth?: boolean
  listWidth?: string
  onToggle?: (val: boolean) => void
  onOptionClick: (id: Option['id'] | null) => void
}
// @TODO Отрефакторить, убрать повторения кода, вынести в хуки.
export const InlineInputDropDown = ({
  options,
  selectedId,
  hasSearch,
  placeholder,
  optionSize,
  dataTestId,
  isDisabled,
  isLoading,
  isInvalid,
  sameComponentWidth = true,
  listWidth,
  actionOptions,
  onOptionClick,
  onToggle,
}: Props) => {
  const {
    activeLabel,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    handleInputChange,
    handleOptionClick,
    isFocus,
    isOpen,
    refs,
    value,
    sortedItems,
    closeDropDown,
    handleClear,
  } = useDropDownInput({
    sameComponentWidth,
    onOptionClick,
    options,
    selectedId,
    hasSearch,
    onToggle,
  })

  return (
    <Wrapper
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <InputWrapper isInvalid={Boolean(isInvalid)}>
        <Input
          type="text"
          data-testid={dataTestId}
          onChange={(e) => handleInputChange(e.target.value)}
          value={isFocus && hasSearch ? value : activeLabel}
          placeholder={placeholder}
          readOnly={!hasSearch}
          disabled={isDisabled}
        />
        <Icon2
          icon={isOpen ? 'arrowUp' : 'arrowDown'}
        />
      </InputWrapper>

      <DropDownInputList
        isDisabled={Boolean(isDisabled)}
        isLoading={Boolean(isLoading)}
        isOpen={isOpen}
        listWidth={listWidth}
        items={sortedItems}
        searchValue={value}
        actionOptions={actionOptions}
        size={optionSize}
        dataTestId={dataTestId}
        selectedId={selectedId}
        handleOptionClick={handleOptionClick}
        floatingStyles={floatingStyles}
        onCloseDropDown={closeDropDown}
        getFloatingProps={getFloatingProps}
        refs={refs}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const InputWrapper = styled.div<{isInvalid: boolean}>`
  display: flex;
  gap: 4px;
  color: ${palette.grey90};
  transition: color 0.15s linear;
  cursor: pointer;
  position: relative;
  z-index: 1;
  ${({ isInvalid }) => isInvalid && css`
    color: ${palette.red100};
  `}
`

const Input = styled.input`
  ${InputStyle}
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
`
