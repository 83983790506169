
import { forward, sample } from 'effector'
import { debounce } from 'patronum'

import { DocumentAlias, AdditionalRequestModalInfo, Plan } from '@/dal'
import { downloadBlobFileFx } from '@/features/download-files'
import { RouterPaths, pushNavigate, replaceNavigate } from '@/features/app/model'


import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import { onAdditionalRequestUpdated, openAdditionalRequestModal } from '@/features/additional-request/model'
import { copyDocument, deleteDocument, onPlanDeleted } from '@/features/document-manager/model'
import { CONTRACT_ID_KEY, CONTRACT_NAME_KEY } from '@/features/document-flow/filters/model'

import {
  $openedFundPlan,
  $openedFundPlanId,
  clearPlan,
  loadPlan,
  loadPlanFx,
  updateSums,
  updateOpenedPlan,
} from './public'
import {
  PlanGate,
  onAdditionalButtonClicked,
  contractNameClicked,
  copyMailToClipboard,
  copyToClipboardInFundFx,
  deletePlanClicked,
  downloadFileTemplateFx,
  downloadFiles,
  downloadPlanFilesFx,
  downloadPlanReportFileFx,
  downloadReportFile,
  editPlanFx,
  loadTemplate,
  loadTemplateFx,
  onCopyPlan,
  onDownloadTemplate,
  onSendToApprove,
  planForm,
  reloadButtonClicked,
  sendPlanToApproveFx,
} from './private'

$openedFundPlan
  .on(loadPlanFx.doneData, (_, plan) => plan)
  .on([
    updateOpenedPlan,
    editPlanFx.doneData,
    sendPlanToApproveFx.doneData,
  ], (prevPlan, plan) => prevPlan && plan)
  .on(updateSums, (plan, costs) => {
    if (!plan) return plan
    return {
      ...plan,
      ...costs,
    }
  })
  .reset(clearPlan)

sample({
  clock: onSendToApprove,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => ({ planId: plan.id }),
  target: sendPlanToApproveFx,
})

forward({
  from: loadPlan,
  to: loadPlanFx,
})

forward({
  from: PlanGate.close,
  to: clearPlan,
})

sample({
  clock: [PlanGate.state, PlanGate.open, onAdditionalRequestUpdated],
  source: PlanGate.state,
  filter: PlanGate.status,
  target: loadPlan,
})

sample({
  clock: reloadButtonClicked,
  source: $openedFundPlanId,
  filter: Boolean,
  fn: (id) => id,
  target: loadPlanFx,
})

sample({
  clock: copyMailToClipboard,
  filter: Boolean,
  target: copyToClipboardInFundFx,
})

sample({
  clock: copyToClipboardInFundFx.done,
  fn: () => ({
    content: 'электронная почта скопирована в буфер обмена',
    icon: NotificationType.Success,
  }),
  target: showToast,
})

sample({
  clock: onAdditionalButtonClicked,
  source: $openedFundPlan,
  filter: (
    details: Plan | null,
  ): details is Plan => Boolean(details),
  fn: (openedFundPlan): AdditionalRequestModalInfo => ({
    docId: openedFundPlan.id,
    docType: DocumentAlias.BUDGET,
    status: openedFundPlan.status,
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: contractNameClicked,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => (
    `${RouterPaths.DocumentFlow}?${CONTRACT_ID_KEY}=${plan.contract.contract_id}&${CONTRACT_NAME_KEY}=${plan.contract.contract_name}`
  ),
  target: pushNavigate,
})

sample({
  clock: loadTemplate,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan, files) => ({
    files,
    id: plan.id,
  }),
  target: loadTemplateFx,
})

sample({
  clock: $openedFundPlan.updates,
  filter: Boolean,
  fn: ({ comment, avans_material }) => ({ comment: comment ?? '', avans_material: avans_material ?? '0' }),
  target: planForm.set,
})

sample({
  clock: onDownloadTemplate,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => plan.id,
  target: downloadFileTemplateFx,
})

sample({
  clock: downloadFileTemplateFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'template.xlsx',
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: downloadReportFile,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => plan.id,
  target: downloadPlanReportFileFx,
})

sample({
  clock: downloadPlanReportFileFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'attachment.zip',
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: downloadFiles,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => plan.id,
  target: downloadPlanFilesFx,
})

sample({
  clock: downloadPlanFilesFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'attachments.zip',
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: onCopyPlan,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => ({
    id: plan.id,
    type: DocumentAlias.BUDGET,
    documentName: plan?.number_1c,
  }),
  target: copyDocument,
})

sample({
  clock: onPlanDeleted,
  source: {
    documentId: PlanGate.state,
    status: PlanGate.status,
  },
  filter: ({ documentId, status }, { id }) => (status && documentId === id),
  fn: () => RouterPaths.Administrative,
  target: replaceNavigate,
})

sample({
  clock: deletePlanClicked,
  source: $openedFundPlan,
  filter: Boolean,
  fn: ({ id, number_1c }) => ({
    id,
    type: DocumentAlias.BUDGET,
    documentName: number_1c,
  }),
  target: deleteDocument,
})

sample({
  clock: debounce({
    source: planForm.$values,
    timeout: 700,
  }),
  source: $openedFundPlan,
  filter: (plan, form) => {
    if (!plan) return false
    if (plan.comment === form.comment
        && plan.avans_material === form.avans_material
        && !form.files) {
      return false
    }
    return true
  },
  fn: (plan, { comment, files, avans_material }) => ({
    id: (plan as Plan).id,
    comment,
    files: files ?? undefined,
    avans_material,
  }),
  target: editPlanFx,
})

forward({
  from: editPlanFx.finally,
  to: planForm.fields.files.reset,
})


createToast({
  effect: sendPlanToApproveFx,
})

createToast({
  effect: loadTemplateFx,
})

createToast({
  effect: editPlanFx,
})
