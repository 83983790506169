import { CustomResponseError } from '../error-handler'
import {
  ActForApproveError,
  CreateActConflictErrorType,
  CreateActReqiredFieldErrorType,
} from './types'

export class ApproveActError extends CustomResponseError {
  data: ActForApproveError

  constructor(data: ActForApproveError, message = '') {
    super(message)
    this.name = 'ApproveActError'
    this.data = data
  }
}
export class CreateActConflictError extends CustomResponseError {
  data: CreateActConflictErrorType

  constructor(data: CreateActConflictErrorType, message = '') {
    super(message)
    this.name = 'CreateActError'
    this.data = data
  }
}
export class CreateActReqiredFieldError extends Error {
  data: CreateActReqiredFieldErrorType

  constructor(data: CreateActReqiredFieldErrorType, message = '') {
    super(message)
    this.name = 'CreateActError'
    this.data = data
  }
}
