import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { RouterPaths } from '@/features/app/model'
import { palette } from '@/ui'

import { Forms, SelectAppealType } from '../containers'

export const CreateSupportAppeal = React.memo(() => (
  <Content>
    <Switch>
      <Route path={RouterPaths.CreateSupportAppeal} exact>
        <SelectAppealType />
      </Route>
      <Route path={`${RouterPaths.CreateSupportAppeal}/:alias`} exact>
        <Forms />
      </Route>
    </Switch>
  </Content>

))

const Content = styled.div`
  width: 700px;
  padding: 32px;
  margin: 0 auto;
  background-color: ${palette.white};
  border-radius: 20px;
  height: max-content;
`
