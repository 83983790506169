import { attach, forward, sample } from 'effector'
import { createForm } from 'effector-forms'

import { root } from '@/root-domain'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { AppealComment, CreateAppealCommentsParams, RequiredAppealItemFields } from '../types'

export const createAppealComments = <T extends RequiredAppealItemFields, >({
  domain,
  $selectedItemId,
  resetSelectedItem,
  $selectedItem,
  getCommentsFx,
  postCommentFx,
  updateAppealItem,
}: CreateAppealCommentsParams<T>) => {
  const d = domain || root.domain()
  const $comments = d.store<AppealComment[] | null>(null)

  const { abortFx, requestFx: innerGetCommentsFx } = createSingleEffect(getCommentsFx)

  const innerPostCommentsFx = attach({
    effect: postCommentFx,
  })

  const commentForm = createForm({
    fields: {
      comment: {
        init: '',
      },
      files: {
        init: [] as File[],
      },
    },
  })

  $comments
    .on(innerGetCommentsFx.doneData, (_, comments) => comments)
    .on(innerPostCommentsFx.doneData, (_, { comments }) => comments)
    .reset($selectedItemId.updates, innerGetCommentsFx)

  sample({
    clock: innerPostCommentsFx.doneData,
    fn: ({ item }) => item,
    target: updateAppealItem,
  })

  sample({
    clock: $selectedItemId,
    filter: (id) => !id,
    target: abortFx,
  })

  sample({
    clock: $selectedItemId.updates,
    source: $selectedItemId,
    filter: (id: T['id'] | null): id is T['id'] => Boolean(id),
    fn: (id) => ({ id }),
    target: innerGetCommentsFx,
  })

  sample({
    clock: commentForm.formValidated,
    source: {
      id: $selectedItemId,
      form: commentForm.$values,
    },
    filter: ({ id, form }) => Boolean(id && (form.comment.trim() || form.files.length)),
    fn: ({ form, id }) => ({
      id: id as T['id'],
      ...form,
    }),
    target: innerPostCommentsFx,
  })

  forward({
    from: innerPostCommentsFx.doneData,
    to: commentForm.reset,
  })

  return {
    $selectedItem,
    $comments,
    $isPending: innerGetCommentsFx.pending,
    commentForm,
    $isMessageSending: innerPostCommentsFx.pending,
    resetSelectedItem,
  }
}
