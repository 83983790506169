import { TreeType } from './types'

export type GetFlatTreeParams<T extends { id: any }> = {
  isTree: boolean,
  root: T[] | null
  tree: TreeType<T>
}

export const getFlatTree = <T extends { id: any }>({
  root,
  tree,
  isTree,
}: GetFlatTreeParams<T>) => {
  if (!root || !isTree) return root
  const result = [] as T[]

  const mapFunc = (item: T) => {
    result.push(item)

    if (!tree[item.id]) return
    const children = tree[item.id]
    children.forEach(mapFunc)
  }

  root.forEach(mapFunc)
  return result
}
