import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { Link } from 'react-router-dom'

import { AdministrationAppealTypeAlias } from '@/dal'
import { SelectItemButton, NewButton } from '@/ui'
import { $responsibleRoles } from '@/features/administrative-appeals/shared-model'
import { RouterPaths } from '@/features/app/model'

import { StepHeader, ButtonsContainer } from '../parts'

export const FirstStep = React.memo(() => {
  const roles = useStore($responsibleRoles)

  const filteredRoles = roles.filter(
    (role) => role.alias !== AdministrationAppealTypeAlias.Contragent,
  )

  return (
    <>
      <Container>
        <StepHeader step={1} title="Выберите, кому направить обращение" />
        <Options>
          {filteredRoles.map(({ alias, dativeName, description }) => (
            <Link key={alias} to={`${RouterPaths.NewAdministrationAppeal}/${alias}`}>
              <SelectItemButton
                icon="arrowRight"
                text={dativeName}
                additionalText={description}
                buttonStyle="multiline"
                dataTestId="administrative-appeal-responsible-btn"
              />
            </Link>
          ),
          )}
        </Options>
      </Container>
      <ButtonsContainer>
        <Link to={RouterPaths.AdministrationAppeals}>
          <NewButton
            buttonType="grey"
            label="Отменить"
            dataTestId="appeal-select-responsible-cancel-btn"
          />
        </Link>
      </ButtonsContainer>
    </>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
