import { sample } from 'effector'
import { markAppealAsRead, putMarkAppealAsReadFx } from './private'
import { listModel } from '../../shared-model'

sample({
  clock: markAppealAsRead,
  fn: (id) => ({ id }),
  target: putMarkAppealAsReadFx,
})

sample({
  clock: putMarkAppealAsReadFx.doneData,
  target: listModel.onMarkAsRead,
})
