import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { combine } from 'effector'

import { NewButton, MultiSelectPopup } from '@/ui'
import { DocumentStatuses, EstimateCorrectionType } from '@/dal'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import {
  $types,
  $acceptedTypesCount,
  setAcceptedTypes,
  $acceptedTypes,
} from '../../../model/filters.private'

const $filteredTypes = combine(
  [$types, $documentStatus],
  ([types, status]) => (status === DocumentStatuses.Draft
    ? types.filter(({ id }) => id !== EstimateCorrectionType.NotApproved)
    : types),
)

type Props = {
  isDisabled: boolean
}

export const FilterDropdown = React.memo(({ isDisabled }: Props) => {
  const selectedTypes = useStore($acceptedTypes)
  const types = useStore($filteredTypes)
  const acceptedTypesCount = useStore($acceptedTypesCount)

  const triggerComponent = React.useCallback(() => {
    return (
      <NewButton
        size="S"
        dataTestId=""
        label={!acceptedTypesCount ? 'Показать все строки' : `Выбрано: ${acceptedTypesCount}`}
        buttonType="secondary"
        prefixIcon="filter"
        isDisabled={isDisabled}
      />
    )
  }, [acceptedTypesCount, isDisabled])

  return (
    <MultiSelectPopup
      triggerComponent={triggerComponent}
      testId="estimate-filters"
      items={types}
      selectedIds={selectedTypes}
      disabled={isDisabled}
      onAcceptClick={setAcceptedTypes}
    />

  )
})

export const Buttons = styled.div`
  gap: 10px;
  display: flex;
  padding: 12px 12px 8px;
`
