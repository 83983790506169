import React from 'react'
import styled from 'styled-components'

import { NewButton } from '@/ui'

import { cancelButtonClicked, goToTypeList } from '../../model/private'

type Props = {
  isDisabled: boolean
  isPending: boolean
}

export const ButtonRow = ({ isDisabled, isPending }: Props) => (
  <ButtonWrapper>
    <NewButton
      dataTestId="go-back-button"
      label="Назад"
      buttonType="grey"
      onClick={goToTypeList}
    />
    <Row>
      <NewButton
        onClick={cancelButtonClicked}
        dataTestId="cancel-create-butotn"
        label="Отменить"
        buttonType="grey"
      />
      <NewButton
        type="submit"
        dataTestId="submit-butotn"
        label="Отправить"
        buttonType="primary"
        isDisabled={isDisabled}
        isPending={isPending}
      />
    </Row>
  </ButtonWrapper>
)


const Row = styled.div`
  display: flex;
  gap: 16px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
