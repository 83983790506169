import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { DocumentStatuses } from '@/dal'
import {
  FullTime, Icon2, IconName2, TextMLight, palette,
} from '@/ui'
import { statusResolve } from '@/lib/status-resolver'

const getTypeInfo = (
  status: DocumentStatuses | 'visa' | 'requestToContractor',
): {
  color: (typeof palette)[keyof typeof palette]
  icon: IconName2
} => {
  switch (status) {
    case DocumentStatuses.AgreedContractor: {
      return {
        color: palette.accent100,
        icon: 'agreedContractor',
      }
    }
    case DocumentStatuses.DocumentsWaiting: {
      return {
        color: palette.accent100,
        icon: 'agreedContractor',
      }
    }
    case DocumentStatuses.SignActInDiadoc:
    case DocumentStatuses.SignInSFDiadoc:
    case DocumentStatuses.SignInDiadoc: {
      return {
        color: palette.green,
        icon: 'signInDiadoc',
      }
    }
    case 'requestToContractor': {
      return {
        color: palette.violet100,
        icon: 'builder',
      }
    }
    case 'visa':
    default: {
      return {
        color: palette.blue,
        icon: 'visaTask',
      }
    }
  }
}

type Props = {
  docStatus: DocumentStatuses | null
  date: string
  isRequestToContractor?: boolean
  isVisa?: boolean
}

export const TaskTypeLabel = ({
  docStatus, date, isRequestToContractor, isVisa,
}: Props) => {
  const typeInfo = useMemo(() => {
    if (isVisa) {
      return getTypeInfo('visa')
    }
    if (isRequestToContractor) {
      return getTypeInfo('requestToContractor')
    }
    return docStatus && getTypeInfo(docStatus)
  }, [docStatus, isVisa, isRequestToContractor])

  const title = useMemo(() => {
    if (isVisa) {
      return 'Ответить на доп. запрос'
    }
    if (isRequestToContractor) {
      return 'Задача на подрядчика'
    }
    return docStatus && statusResolve(docStatus)
  }, [docStatus, isVisa, isRequestToContractor])

  if (!typeInfo || !title) return null

  return (
    <Wrapper>
      {typeInfo && (
        <HeadTitle color={typeInfo.color}>
          <Icon2 size={16} icon={typeInfo.icon} />
          <HeadTextWrapper color={typeInfo.color}>{title}</HeadTextWrapper>
        </HeadTitle>
      )}
      <TimeWrapper>
        <FullTime date={date} />
      </TimeWrapper>
    </Wrapper>
  )
}

const OneTextLineCSS = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`

const HeadTitle = styled.div<{ color: string }>`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
  color: ${({ color }) => color};
`

const HeadTextWrapper = styled.p`
  ${OneTextLineCSS}
  ${TextMLight}
  color: inherit;
`

const TimeWrapper = styled.div`
  text-align: right;
  width: 100%;
`
