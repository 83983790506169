import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  $currentSubsystemId,
  Subsystem,
  SubSystemId,
} from '@/features/app/model'
import { CONTRACT_SUBSYSTEMS, subSystem } from '@/features/app/model/const'

import { SystemItem } from '../parts'
import { $systemActions } from '../../model/private'

export const NavMenu = React.memo(() => {
  const activeId = useUnit($currentSubsystemId)
  const actions = useUnit($systemActions)

  const systemList = React.useMemo(() => {
    return Object.keys(subSystem).map((key) => ({
      ...subSystem[key] as Subsystem,
      name: subSystem[key].name || '',
    }))
      .filter((item: Subsystem) => Boolean(item.name))
  }, [])

  return (
    <Wrapper>
      {systemList.map((item) => (
        <SystemItem
          key={item.link}
          {...item}
          isInDevelopment={Boolean(item.isInDevelopment)}
          hasAction={Boolean(item.alias && actions?.[item.alias])}
          isActive={
            item.subsystemId === activeId
            || (item.subsystemId === SubSystemId.Administrative
              && CONTRACT_SUBSYSTEMS.includes(activeId))
          }
        />
      ))}
    </Wrapper>
  )
},
)
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`
