export enum DataSetType {
  History = 'history',
  Current = 'current',
  Predict = 'predict'
}

export type DataContractItem = {
  id: number
  name: string,
} & ({
  days_to_paying: number
} | {
  days_to_approving: number
})

export type DataItem = {
  quarter: number,
  year: number,
  type: DataSetType,
  data: {
    days_to_paying: number | null,
    days_to_approving: number | null,
    approving_documents: DataContractItem[],
    paying_documents: DataContractItem[],
  },
}

export type ChartData = {
  dataset: DataItem[]
  'days_to_paying': number | null,
  'days_to_approving': number | null,
}
