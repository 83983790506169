import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2 } from '../icons'
import { TextMLight } from '../typography'
import { palette } from '../palette'

type Props = {
  label?: string
  checkPosition?: 'left' | 'right'
  value?: any
  isBoolean?: boolean
  isChecked?: boolean
  isError?: boolean
  disabled?: boolean
  dataTestId?: string
  isIndeterminate?: boolean
  onChange: (id: Props['value']) => void
}

export const NewCheckbox = ({
  isChecked,
  label,
  value,
  disabled,
  isError,
  isBoolean,
  dataTestId,
  checkPosition = 'right',
  isIndeterminate,
  onChange,
}: Props) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = Boolean(isIndeterminate)
    }
  }, [checkboxRef, isIndeterminate])

  return (
    <Wrapper isError={isError}>
      <HiddenCheckbox
        ref={checkboxRef}
        onChange={() => (isBoolean ? onChange(!isChecked) : onChange(value))}
        disabled={disabled}
        checked={Boolean(isChecked)}
        value={value}
        data-test-id={dataTestId}
      />
      {label && checkPosition === 'right' && (
        <Text>
          {label}
        </Text>
      )}
      <CustomCheckbox>
        {isChecked && <Icon2 icon="checkboxMark" />}
        {isIndeterminate && <IndeterminateLine />}
      </CustomCheckbox>
      {label && checkPosition === 'left' && (
        <Text>
          {label}
        </Text>
      )}
    </Wrapper>
  )
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

const Text = styled.div`
  ${TextMLight}
`

const CustomCheckbox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid ${palette.grey50};
  background-color: ${palette.white};
  color: transparent;
  transition: 0.15s linear;
  transition-property: background-color, border-color, color;
  display: flex;
  justify-content: center;
  align-items: center;

  ${HiddenCheckbox}:checked ~ &,
  ${HiddenCheckbox}:indeterminate ~ & {
    background-color: ${palette.accent100};
    border-color: ${palette.accent100};
    color: ${palette.white};
  }

  ${HiddenCheckbox}:focus-visible ~ & {
    border: 2px solid ${palette.grey90};
  }

  ${HiddenCheckbox}:disabled ~ & {
    background-color: ${palette.grey30};
    border-color: ${palette.grey40};
    color: transparent;

    & ~ ${Text} {
      color: ${palette.grey60};
    }
  }

  ${HiddenCheckbox}:checked:disabled ~ &,
  ${HiddenCheckbox}:indeterminate:disabled ~ & {
    background-color: ${palette.grey30};
    border-color: ${palette.grey30};
    color: ${palette.grey60};
  }
`

const IndeterminateLine = styled.div`
  width: 8px;
  height: 2px;
  border-radius: 99px;
  background-color: currentColor;
`

const Wrapper = styled.label<{ isError?: boolean, isDisabled?: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  user-select: none;

  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
  `}

  @media (hover: hover) {
    &:hover {
      ${HiddenCheckbox}:not(:disabled) ~ ${CustomCheckbox} {
        border-color: ${palette.grey60};
      }

      ${HiddenCheckbox}:not(:disabled):checked ~ ${CustomCheckbox},
      ${HiddenCheckbox}:not(:disabled):indeterminate ~ ${CustomCheckbox} {
        border-color: ${palette.accent90};
        background-color: ${palette.accent90};
      }
    }
  }

  &:active {
    ${HiddenCheckbox}:not(:disabled):not(:checked) ~ ${CustomCheckbox},
    ${HiddenCheckbox}:not(:disabled):not(:indeterminate) ~ ${CustomCheckbox} {
      background-color: ${palette.grey20};
    }

    ${HiddenCheckbox}:not(:disabled):checked ~ ${CustomCheckbox},
    ${HiddenCheckbox}:not(:disabled):indeterminate ~ ${CustomCheckbox} {
      border-color: ${palette.accent80};
      background-color: ${palette.accent80};
    }
  }

  ${({ isError }) => isError && css`
    ${CustomCheckbox} {
      border-color: ${palette.red100} !important;
    }
  `}
`
