import React from 'react'

import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { AdministrationAppealTypeAlias } from '@/dal'

import { onGetRetention } from '../../model'

export const GetRetensionModal = React.memo(() => {
  // @TODO Костыли
  React.useEffect(() => {
    onGetRetention.watch(() => {
      const info = {
        content: (
          <>
            Функционал в разработке, обратитесь к
            {' '}
            <a
              href={`${RouterPaths.NewAdministrationAppeal}/${AdministrationAppealTypeAlias.Contract}`}
              target="_blank"
              rel="noreferrer"
            >
              ответственному по договору
            </a>
          </>
        ),
        icon: NotificationType.Alert,
      }
      showToast(info)
    })
  }, [])
  return null
})

