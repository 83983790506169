import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  NewTextButton,
  NotificationItem,
  NotificationType,
  TextMLight,
  palette,
} from '@/ui'

import { RouterPaths } from '@/features/app/model'
import { downloadApprovingDocuments } from '@/features/estimate-correction/estimate-info/model'
import { $isShowBusinessOffer, getComercialOfferFx } from '../../model/private'

export const CommercialOffer = React.memo(() => {
  const isShowBusinessOffer = useStore($isShowBusinessOffer)
  const isPedning = useStore(getComercialOfferFx.pending)

  if (!isShowBusinessOffer) return null

  return (
    <NotificationItem
      type={NotificationType.Warning}
      title={(
        <>
          <span>Проверьте сформированное </span>
          <ButtonWrapper>
            <NewTextButton
              textStyle="M"
              isDisabled={isPedning}
              label="коммерческое предложение"
              dataTestId="download-comercial-offer"
              onClick={downloadApprovingDocuments}
            />
          </ButtonWrapper>
        </>
      )}
      body={(
        <>
          Если в КП указаны неверные данные ген. директора, то перейдите в раздел
          {' '}
          <InfoLink to={RouterPaths.MyCompany}>«Моя компания»</InfoLink>
          {' '}
          и измените их. После нажатия на кнопку
          «Отправить на согласование» документ будет недоступен для редактирования и отмены.
        </>
      )}
    />
  )
})

const InfoLink = styled(Link)`
  ${TextMLight}
  color: ${palette.accent100};
`

const ButtonWrapper = styled.span`
  display: inline-block;
`
