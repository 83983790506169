import { ContractType } from '@/dal'
import { IconName2 } from '../../ui/v2/icons'
import { IconName } from '../../ui/Icon'

// @TODO remove 'new' after delete old icon
export const newGetContractNameAndIconByType = (
  typeId: ContractType,
): { icon: IconName2, name: string } | null => {
  switch (typeId) {
    case ContractType.ChangeConditions: return {
      icon: 'change-conditions',
      name: 'Изменение текущих условий',
    }
    case ContractType.ExtraWork: return {
      icon: 'extra-job',
      name: 'Дополнительные работы',
    }
    case ContractType.NotFinance: return {
      icon: 'not-financial',
      name: 'Нефинансовое доп. соглашение',
    }
    default: return null
  }
}

export const getContractNameAndIconByType = (
  typeId: ContractType,
): {icon: IconName, name: string} | null => {
  switch (typeId) {
    case ContractType.ChangeConditions: return {
      icon: 'change-conditions',
      name: 'Изменение текущих условий',
    }
    case ContractType.ExtraWork: return {
      icon: 'extra-job',
      name: 'Дополнительные работы',
    }
    case ContractType.NotFinance: return {
      icon: 'not-financial',
      name: 'Нефинансовое доп. соглашение',
    }
    default: return null
  }
}
