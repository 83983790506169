import { createGate } from 'effector-react'

import {
  EstimateDoc,
  EstimateDocId,
  GroupListItem,
  ProjectListItem,
  UnitListItem,
} from '@/dal'

import { d } from './domain'
import { UpdateSummaryParams } from './types'

export const $allDocumentInfo = d.store<EstimateDoc | null>(null)
export const $documentId = $allDocumentInfo.map((info) => info?.id ?? null)
export const $documentTable = $allDocumentInfo.map((info) => info?.summary_table ?? null)
export const $documentInfo = $allDocumentInfo.map((info) => (info ? ({
  ...info,
  summary_table: undefined,
}) : null))
export const $documentStatus = $documentInfo.map((info) => info?.status ?? null)
export const $documentFeatures = $documentInfo.map((info) => info?.features ?? null)
export const $hasSplittingObjects = $documentInfo.map(
  (info) => info?.has_splitting_objects ?? false,
)
export const updateDocumentInfo = d.event<EstimateDoc>()

export const $summaryTotalSums = $documentTable.map((summary) => (summary ? ({
  totalCost: summary.sum_all.approved || summary.sum_all.adjusted || summary.sum_all.current,
  materialTotalCost: summary.sum_material.approved
    || summary.sum_material.adjusted
    || summary.sum_material.current,
  workTotalCost: summary.sum_work.approved || summary.sum_work.adjusted || summary.sum_work.current,
}) : null))

export const EstimateCorrectionGate = createGate<EstimateDocId>()
export const onUpdateSummary = d.event<UpdateSummaryParams>()
export const $unitList = d.store<UnitListItem[] | null>(null)
export const $groupList = d.store<GroupListItem[] | null>(null)
export const $projectList = d.store<ProjectListItem[] | null>(null)

export const updateEstimateDirectories = d.event<void>()
export const refetchEstimateDocument = d.event<void>()

export const getUpdatedFeatures = d.event<EstimateDocId>()
export const updateFeatures = d.event<EstimateDoc['features']>()
export const updateAttachments = d.event<EstimateDoc['attachments']>()

export const $isDocumentPending = d.store(false)
