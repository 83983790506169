import {
  RequestForAccessError,
  RequestForAccessErrorType,
  RequestForAccessResponseError,
} from './types'
import {
  AccreditationFailedError,
  AccreditationTimeOutError,
  AccreditationInProccessError,
  CompanyNotFoundError,
  CompanyAlreadyExistsInLKPError,
} from './errors'
import {
  DefaultErrorResponse,
  DefaultHandledError,
  defaultErrorHandler,
} from '../error-handler'

export type RequestForAccessHandledError =
  AccreditationTimeOutError
  | CompanyNotFoundError
  | AccreditationInProccessError
  | AccreditationFailedError
  | DefaultHandledError

export const requestForAccessErrorHandler = (error: RequestForAccessResponseError):
  RequestForAccessHandledError => {
  switch (error?.code) {
    case RequestForAccessErrorType.CompanyNotFound: {
      return new CompanyNotFoundError(error as RequestForAccessError)
    }
    case RequestForAccessErrorType.AccreditationTimeOutError: {
      return new AccreditationTimeOutError(error as RequestForAccessError)
    }
    case RequestForAccessErrorType.AccreditationInProccess: {
      return new AccreditationInProccessError(error as RequestForAccessError)
    }
    case RequestForAccessErrorType.AccreditationFailed: {
      return new AccreditationFailedError(error as RequestForAccessError)
    }
    case RequestForAccessErrorType.AlreadyExistsInLKP: {
      return new CompanyAlreadyExistsInLKPError(error as RequestForAccessError)
    }
    default: return defaultErrorHandler(error as DefaultErrorResponse)
  }
}
