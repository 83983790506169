import React from 'react'
import { useField } from 'effector-forms'
import { useStore } from 'effector-react'

import { InputField } from '@/ui'

import { $isFormDisabled, contactForm } from '../../model/private'

export const Emailnput = React.memo(() => {
  const { value, onChange, firstError } = useField(contactForm.fields.email)
  const isDisabled = useStore($isFormDisabled)

  return (
    <InputField
      dataTestId="email-field"
      label="E-mail"
      size="L"
      isRequired
      type="email"
      placeholder="Введите данные"
      onChange={onChange}
      value={value}
      disabled={isDisabled}
      error={firstError?.errorText}
    />
  )
})

