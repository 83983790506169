import React from 'react'
import styled from 'styled-components'
import { ResetStyleButton } from './ResetStyleButton'
import { MediumTextCSS } from './Typography'
import { themeVar } from './theming'

type Props = {
  text: string
  onClick: () => void
  dataTestId: string
  disabled?: boolean
  // @TODO подумать, как лучше менять стили текста
  fontWeight?: number
}

export const TextButton = ({
  onClick,
  text,
  dataTestId,
  disabled,
  fontWeight,
}: Props) => (
  <Button
    type="button"
    fontWeight={fontWeight}
    disabled={disabled}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {text}
  </Button>
)

const Button = styled(ResetStyleButton)<{fontWeight?: number}>`
  ${MediumTextCSS}
  color: ${themeVar('main')};
  text-decoration: underline;
  height: max-content;
  width: max-content;
  font-weight: ${({ fontWeight }) => fontWeight || 400};

  &:disabled {
    color: ${themeVar('textGray')};
  }
`
