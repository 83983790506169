import {
  sample,
  split,
} from 'effector'
import { debounce } from 'patronum'

import {
  AdditionalRequestModalInfo,
  ContractItem,
  ContractVisibility,
  DocumentActions,
  DocumentAlias,
  VisasDocType,
  isCancelError,
} from '@/dal'

import { fetchAuthDataFx } from '@/features/login/model'
import { appInit } from '@/features/app/model'
import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { createToast, showToast } from '@/features/toast-service/model'
import { openCreateDocumentModal } from '@/features/document-manager/model'
import { NotificationType } from '@/ui'

import {
  $pendingIds,
  $companyContractList,
  $openedContractIds,
  $selectedCompany,
  ContractListGate,
  onChangeVisibility,
  getCompanyContractFx,
  onClickActionButton,
  openAdditionalModal,
  changeContractVisiblityFx,
  resetSelectedCompanyContract,
  selectCompany,
  setCompany,
  toggleOpenContract,
  $reportsContractId,
  openContractReports,
  closeContractReports,
  onCloseButton,
  updateContractItem,
  onGetRetentionButton,
  onCreateDocument,
  updateClosurePercentageFx,
  $updatedClosureIds,
  $pendingClosureUpdateIds,
  onUpdateClosurePercentage,
  getContractDirectionsFx,
  openAllContracts,
} from './private'
import { $companyList, $contractDirections } from './public'

import './filters.init'
import { $visibility, changeVisibility, filterForm } from './filters.private'
import { onGetRetention, onCloseContract, onUpdatedContractItem } from '../../close-contract/model'

// выбранная компания
$selectedCompany
  .on(setCompany, (_, s) => s)

// список договоров
$companyContractList
  .on(getCompanyContractFx.doneData, (_, s) => s)
  .on(changeContractVisiblityFx.doneData, (list, contract) => (
    list.filter((item) => item.id !== contract.id)),
  )
  .on(updateContractItem, (list, item) => list
    .map((currItem) => (currItem.id === item.id ? item : currItem)),
  )
  .on(updateClosurePercentageFx.done, (list, { params: id, result: data }) => list
    .map((currItem) => (currItem.id === id ? {
      ...currItem,
      closure_percentage: data,
    } : currItem)),
  )

$pendingIds
  .on(onChangeVisibility, (list, c) => list.concat(c.id))
  .on(changeContractVisiblityFx.finally, (list, { params }) => (
    list.filter((id) => id !== params.id)),
  )

$openedContractIds
  .on(toggleOpenContract, (ids, id) => {
    if (ids.includes(id)) {
      return ids.filter((curr) => curr !== id)
    }
    return [...ids, id]
  })
  .on(openAllContracts, (_, ids) => ids)
  .reset(ContractListGate.close, $selectedCompany.updates, getCompanyContractFx)

$visibility
  .on(changeVisibility, (_, visibility) => visibility)
  .reset(ContractListGate.close)

$reportsContractId
  .on(openContractReports, (_, id) => id)
  .reset(closeContractReports, ContractListGate.close)

$pendingClosureUpdateIds
  .on(updateClosurePercentageFx, (list, id) => [...list, id])
  .on(updateClosurePercentageFx.finally, (list, { params }) => list.filter((id) => id !== params))
  .reset(ContractListGate.close)

$updatedClosureIds
  .on(updateClosurePercentageFx.done, (list, { params }) => [...list, params])
  .reset(ContractListGate.close)

$contractDirections
  .on(getContractDirectionsFx.doneData, (_, directions) => directions)

// выбранная компания
sample({
  clock: fetchAuthDataFx.doneData,
  fn: (user) => user.company[0],
  target: setCompany,
})

sample({
  clock: appInit,
  target: getContractDirectionsFx,
})

sample({
  clock: selectCompany,
  source: $companyList,
  filter: Boolean,
  fn: (list, id) => list.find((item) => item.id === id) || list[0],
  target: setCompany,
})

createToast({
  effect: changeContractVisiblityFx,
  doneText: (result: ContractItem) => (
    `${result.name} перемещен в ${result.visibility === ContractVisibility.hidden ? 'скрытые' : 'активные'}`
  ),
})

sample({
  clock: $selectedCompany,
  filter: Boolean,
  fn: ({ id }) => ({ id }),
  target: [getCompanyContractFx, filterForm.resetValues],
})

sample({
  clock: [
    debounce({
      source: filterForm.submit,
      timeout: 500,
    }),
    $visibility.updates,
  ],
  source: {
    org: $selectedCompany,
    filters: filterForm.$values,
    visibility: $visibility,
  },
  filter: ContractListGate.status,
  fn: ({ org, filters, visibility }) => ({ id: org?.id || 0, ...filters, visibility }),
  target: getCompanyContractFx,
})

createToast({
  effect: getCompanyContractFx,
})

sample({
  clock: getCompanyContractFx.failData,
  filter: (error) => !isCancelError(error),
  target: resetSelectedCompanyContract,
})

sample({
  clock: onChangeVisibility,
  fn: (data) => ({
    id: data.id,
    visibility: data.currentVal === ContractVisibility.visible
      ? ContractVisibility.hidden
      : ContractVisibility.visible,
  }),
  target: changeContractVisiblityFx,
})

sample({
  clock: $companyContractList,
  source: filterForm.fields.direction.$value,
  filter: Boolean,
  fn: (_, contracts) => contracts.filter(({ child }) => child).map(({ id }) => id),
  target: openAllContracts,
})

// при новых значениях action синхронизировать с features/contracts/model/init.ts
split({
  source: onClickActionButton,
  match: {
    openAdditionalModal: ({ action }) => action === DocumentActions.answer_on_additional_request,
  },
  cases: {
    openAdditionalModal,
  },
})

sample({
  clock: openAdditionalModal,
  filter: (contract) => Boolean(contract.status && contract.id),
  fn: ({ id, status }) => ({
    docType: DocumentAlias.CONTRACT as VisasDocType,
    docId: id,
    status,
  } as AdditionalRequestModalInfo),
  target: openAdditionalRequestModal,
})

sample({
  clock: onCloseButton,
  target: onCloseContract,
})

sample({
  clock: onUpdatedContractItem,
  filter: ContractListGate.status,
  target: updateContractItem,
})

sample({
  clock: onGetRetentionButton,
  target: onGetRetention,
})

sample({
  clock: onCreateDocument,
  target: openCreateDocumentModal,
})

sample({
  clock: onUpdateClosurePercentage,
  target: updateClosurePercentageFx,
})

sample({
  clock: updateClosurePercentageFx.done,
  source: $companyContractList,
  filter: (list, { params }) => list.some(({ id }) => id === params),
  fn: (list, { params }) => {
    const contract = list.find(({ id }) => id === params)
    return {
      content: `Данные по ${contract?.name} успешно обновлены`,
      icon: NotificationType.Success,
    }
  },
  target: showToast,
})
