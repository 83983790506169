import React from 'react'
import styled, { css } from 'styled-components'

import {
  HeadXXL,
  PaletteColor,
  TextMLight,
  palette,
} from '@/ui'
import { getNoun } from '@/lib/text-noun'

type Props = {
  count: number | null,
  isApprove: boolean
}

export const Legent = ({
  count,
  isApprove,
}: Props) => {
  const textMap = React.useMemo(() => {
    const noun = getNoun(count ?? 0, 'день', 'дня', 'дней')
    return {
      approving: {
        false: 'документы на согласование выполнений не подавались',
        true: `${noun} на согласование выполнений`,
      },
      paing: {
        false: 'документы на оплату авансов и выполнений не подавались',
        true: `${noun} на оплату авансов и выполнений`,
      },
    }
  }, [count])

  return (
    <Item>
      <Square color={isApprove ? 'accent100' : 'violet100'} />
      <Col>
        {count && (
          <ColNumber color={isApprove ? 'accent100' : 'violet100'}>
            {count}
          </ColNumber>
        )}

        <Text disabled={!count}>
          {isApprove
            ? textMap.approving[Boolean(count).toString()]
            : textMap.paing[Boolean(count).toString()]}
        </Text>
      </Col>
    </Item>
  )
}

export const Item = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`

const Square = styled.div<{color: PaletteColor}>`
  width: 20px;
  height: 10px;
  background-color: ${({ color }) => palette[color]};
  opacity: 0.3;
  border-radius: 2px;
`

const Col = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const ColNumber = styled.div<{color: PaletteColor}>`
  ${HeadXXL}
  color: ${({ color }) => palette[color]};
`

const Text = styled.div<{disabled: boolean}>`
  ${TextMLight}
  max-width: 175px;

  ${({ disabled }) => disabled && css`
    color: ${palette.grey60};
  `}
`
