import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  TextLLight,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { parceText } from '@/lib/textParcers'

import { $actModalError } from '../../model/private'
import { clearActError } from '../../model/public'

export const ActErrorModal = React.memo(() => {
  const error = useStore($actModalError)

  useOnChangeLocation(clearActError)

  if (!error) return null

  const getBody = () => {
    return parceText({
      links: error.link,
      text: error.body,
      textSize: 'LL',
    })
  }

  return (
    <ModalLayoutV2
      closeModal={clearActError}
      dataTestId="act-error-close-modal"
    >
      <Container>
        {error.title && <Title data-testid="act-error-modal-title">{error.title}</Title>}

        {error.body && (
          <Body>{getBody()}</Body>
        )}

        <NewButton
          label="Ок"
          onClick={clearActError}
          dataTestId="act-error-close-modal-button"
          isFill
        />
      </Container>

    </ModalLayoutV2>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  max-width: 500px;
`

const Title = styled.div`
  ${HeadXL}
`

const Body = styled.div`
  ${TextLLight}
  white-space: pre-line;
`
