export type AvansInputChangeParams = {
  id: number
  value: string
}

export enum PaymentTab {
  Info,
  Table
}

export type ContragentInfo = {
  inn: string
  name: string
  kpp: string
  ogrn: string
  bic?: string
  account?: string
}
