import { sample } from 'effector'

import { RouterPaths, pushNavigate } from '@/features/app/model'

import { createButtonClicked } from './private'

sample({
  clock: createButtonClicked,
  fn: () => RouterPaths.CreateSupportAppeal,
  target: pushNavigate,
})
