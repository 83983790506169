import React from 'react'
import { useStore } from 'effector-react'

import { DocumentInfoBlock } from '@/ui'

import { $documentInfo } from '../../../model'
import { onOpenAdditionalModal } from '../../../model/info.private'

export const ActInfo = React.memo(() => {
  const info = useStore($documentInfo)

  if (!info) return null

  const {
    status,
    contract,
    progress,
    reject_comment,
    cancel_comment,
    cancellation_comment,
    features,
  } = info

  return (
    <DocumentInfoBlock
      contract={contract}
      features={features}
      progress={progress}
      status={status}
      rejectionReason={reject_comment || cancel_comment}
      cancelComment={cancellation_comment}
      connectedPayment={info.related_payment_document}
      onOpenAdditional={onOpenAdditionalModal}
    />
  )
})

