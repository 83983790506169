import React from 'react'
import styled from 'styled-components'

import { SimpleLoader } from '../../Loader'

type Props = {
  sizePx?: number
  show: boolean
  position?: 'absolute' | 'fixed'
}

export const LoaderOverlay = ({ show, sizePx = 64, position = 'absolute' }: Props) => {
  if (!show) return null

  return (
    <Overlay position={position}>
      <SimpleLoader sizePx={sizePx} />
    </Overlay>
  )
}

const Overlay = styled.div<{ position: 'absolute' | 'fixed' }>`
  position: ${({ position }) => position};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  background: rgba(255,255,255, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`
