import { forward } from 'effector'
import { appInit } from '@/features/app/model'
import { readUserAgentInfoFx, sendMetricsFx } from './private'
import { UserAgentInfo } from './types'

forward({
  from: appInit,
  to: readUserAgentInfoFx,
})

forward({
  from: readUserAgentInfoFx.doneData,
  to: sendMetricsFx,
})

readUserAgentInfoFx.use(async () => {
  const { userAgent } = window.navigator
  const canvas = document.createElement('canvas')

  const gl: any = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
  let gpu: any

  if (gl) {
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
    gpu = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  }

  let isChargeable = false
  if ('getBattery' in window.navigator) {
    isChargeable = Boolean(await (window.navigator as any).getBattery())
  }
  const { width, height } = window.screen

  return {
    userAgent,
    userGpu: gpu || '',
    isChargeable,
    isTouchable: window.matchMedia('(pointer: coarse)').matches,
    screenHeight: height,
    screenWidth: width,
  } as UserAgentInfo
})

