import { forward, sample } from 'effector'

import { ContactItemType } from '@/dal'

import { createToast } from '@/features/toast-service/model'
import {
  $contacts,
  $selectedRoleId,
  ContactsGate,
  cancelGetContactsFx,
  getContactsFx,
  selectProject,
  setSelectedRole,
  onAddContact,
  onEditContact,
  $deleteTargetContactId,
  openDeleteContactConfirm,
  rejectConfirm,
  deleteContact,
  deleteContactFx,
  $selectedProject,
} from './private'
import { $selectedCompanyId, contactsUpdated, getCompanyProjectFx } from '../../model'
import {
  OpenContactModalProjectItem,
  onContactCreated,
  onContactEdited,
  openCreateContact,
  openEditeContact,
} from '../../create-edit-contact/model'
import { $selectedProjectId } from './public'

$selectedProjectId
  .on(selectProject, (_, id) => id)
  .reset(ContactsGate.close, $selectedCompanyId.updates)

$selectedRoleId
  .on(setSelectedRole, (_, roleId) => roleId)
  .reset(ContactsGate.close, $selectedCompanyId.updates, $selectedProjectId.updates)

$contacts
  .on(getContactsFx.doneData, (_, contacts) => contacts)
  .on(onContactCreated, (contacts, item) => (contacts ? [...contacts, item] : [item]))
  .on(onContactEdited, (contacts, item) => contacts?.map(
    (contact) => (contact.id === item.id ? item : contact)),
  )
  .on(deleteContactFx.done, (contacts, { params }) => contacts?.filter(
    (item) => item.id !== params) ?? null,
  )
  .reset(ContactsGate.close, getContactsFx)

$deleteTargetContactId
  .on(openDeleteContactConfirm, (_, id) => id)
  .reset(ContactsGate.close, rejectConfirm, deleteContactFx.doneData)

sample({
  clock: [onContactCreated, onContactEdited, deleteContactFx.done],
  target: contactsUpdated,
})

forward({
  from: ContactsGate.close,
  to: cancelGetContactsFx,
})

forward({
  from: getCompanyProjectFx,
  to: cancelGetContactsFx,
})

sample({
  clock: deleteContact,
  source: $deleteTargetContactId,
  filter: (id: number | null): id is number => Boolean(id),
  target: deleteContactFx,
})

sample({
  clock: onAddContact,
  source: {
    company: $selectedCompanyId,
    project: $selectedProject,
  },
  filter: $selectedCompanyId.map(Boolean),
  fn: ({ company, project }, role) => {
    const newProject = {} as OpenContactModalProjectItem
    if (project) {
      newProject.projectId = project.id
      if (role) {
        newProject.roles = [role]
      }
    }
    return {
      company: company as number,
      projects: project ? [newProject] : undefined,
    }
  },
  target: openCreateContact,
})

sample({
  clock: onEditContact,
  source: $contacts,
  filter: (contacts, id) => contacts?.some((item) => item.id === id) || false,
  fn: (contacts, id) => contacts?.find((item) => item.id === id) as ContactItemType,
  target: openEditeContact,
})

sample({
  clock: $selectedCompanyId.updates,
  source: ContactsGate.status,
  filter: (gate, id) => Boolean(gate && id),
  fn: (_, id) => ({ contragent: id as number }),
  target: getCompanyProjectFx,
})

sample({
  clock: ContactsGate.open,
  source: $selectedCompanyId,
  fn: (id) => ({ contragent: id as number }),
  target: getCompanyProjectFx,
})

sample({
  clock: [ContactsGate.open, $selectedCompanyId.updates, $selectedProjectId.updates],
  source: {
    companyId: $selectedCompanyId,
    selectedProjectId: $selectedProjectId,
    gate: ContactsGate.status,
  },
  filter: ({ companyId, gate }) => Boolean(companyId && gate),
  fn: ({ companyId, selectedProjectId }) => ({
    company: companyId || undefined,
    project: selectedProjectId || undefined,
  }),
  target: getContactsFx,
})

createToast({
  effect: deleteContactFx,
  doneText: 'Контакт удален',
  errorText: 'Что-то пошло не так, попробуйте позже',
})
