import { combine, forward, sample } from 'effector'

import { createToast, showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'

import { PaymentDocItem } from '@/dal'
import {
  $avansTableItems,
  $disabledRowIds,
  $isFilled,
  $sums,
  fillTableFx,
  getAvansSumsFx,
  getPaymentAvansTableFx,
  onAvansInputChanged,
  onFillTable,
  onMaxValueError,
  putAvansItemFx,
  updateTableItem,
  workFilters,
} from './table.private'
import {
  $paymentDocumentId,
  $paymentInfo,
} from './private'
import { PaymentGate } from './public'

$avansTableItems
  .on([
    getPaymentAvansTableFx.doneData,
    fillTableFx.doneData,
  ], (_, { items }) => items)
  .on(updateTableItem, (items, item) => {
    if (!items) return null
    return items.map((el) => {
      if (el.id === item.id) {
        return {
          ...item,
        }
      }
      return el
    })
  })
  .reset(PaymentGate.close)

$disabledRowIds
  .on(putAvansItemFx, (ids, { id }) => [...ids, id])
  .on(putAvansItemFx.finally, (ids, { params }) => ids.filter((id) => id !== params.id))
  .reset(PaymentGate.close)

$isFilled
  .on(onFillTable, (_, newVal) => newVal)
  .on($paymentInfo.updates, (_, info) => Boolean(info?.is_fill))
  .on(fillTableFx.fail, (val) => !val)
  .reset(PaymentGate.close)

$sums
  .on([
    getPaymentAvansTableFx.doneData,
    fillTableFx.doneData,
  ], (_, { sums }) => sums)
  .on(getAvansSumsFx.doneData, (_, sums) => sums)
  .reset(PaymentGate.close)

forward({
  from: onAvansInputChanged,
  to: putAvansItemFx,
})

forward({
  from: [PaymentGate.state, fillTableFx],
  to: workFilters.reset,
})

sample({
  clock: putAvansItemFx.done,
  source: $paymentDocumentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getAvansSumsFx,
})

sample({
  clock: onFillTable,
  source: $paymentDocumentId,
  filter: Boolean,
  fn: (id, isFill) => ({
    id,
    isFill,
  }),
  target: fillTableFx,
})

sample({
  clock: PaymentGate.state.map(({ id }) => id),
  filter: Boolean,
  fn: (id) => ({
    showOnlyFilled: false,
    availableAvans: false,
    id,
  }),
  target: getPaymentAvansTableFx,
})

sample({
  clock: workFilters.$values,
  source: $paymentDocumentId,
  filter: combine(
    fillTableFx.pending,
    $paymentDocumentId,
    (pending, id) => Boolean(!pending && id),
  ),
  fn: (id, { availableAvans, showOnlyFilled }) => ({
    showOnlyFilled,
    availableAvans,
    id: id as PaymentDocItem['id'],
  }),
  target: getPaymentAvansTableFx,
})

sample({
  clock: putAvansItemFx.doneData,
  target: updateTableItem,
})

sample({
  clock: putAvansItemFx.failData,
  filter: (response) => Boolean(response?.item),
  fn: (response) => (response as any).item,
  target: updateTableItem,
})

sample({
  clock: putAvansItemFx.failData,
  filter: Boolean,
  fn: (response) => ({
    icon: NotificationType.Alert,
    content: response.detail,
  }),
  target: showToast,
})

sample({
  clock: onMaxValueError,
  fn: () => ({
    content: 'Нельзя вводить сумму больше, чем сумма остатка аванса',
    icon: NotificationType.Warning,
  }),
  target: showToast,
})

createToast({
  effect: putAvansItemFx,
  doneText: 'Изменения сохранены',
})
