import React from 'react'
import styled from 'styled-components'

import { VERTICAL_CONTENT_WRAPPER_PADDING } from '../const'

type Props = {
  children: React.ReactNode
  isAutoHeight?: boolean
  gap?: number
}

export const ContentContainer = ({ children, isAutoHeight = false, gap }: Props) => (
  <Wrapper isAutoHeight={isAutoHeight} gap={gap}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ isAutoHeight: boolean, gap?: number }>`
  padding: ${VERTICAL_CONTENT_WRAPPER_PADDING}px 32px;
  position: relative;
  height: ${({ isAutoHeight }) => (isAutoHeight ? 'auto' : '100%')};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap || 0}px`};
`
