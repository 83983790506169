import { sample } from 'effector'

import { setCurrentSubsystem } from '@/features/app/model'
import { $userAuthorised, logoutByUser } from '@/features/login/model'

import {
  $isReadOnlyModalOpen,
  $systemActions,
  closeReadOnlyModal,
  getSystemActionFx,
  onLogout,
  openReadOnlyModal,
  startPolling,
  stopPolling,
} from './private'
import { onClickNavItem } from './public'

$systemActions
  .on(getSystemActionFx.doneData, (_, actions) => actions)
  .reset(onLogout)

$isReadOnlyModalOpen
  .on(openReadOnlyModal, () => true)
  .reset(closeReadOnlyModal)

sample({
  clock: onClickNavItem,
  fn: ({ subsystemId }) => subsystemId,
  target: setCurrentSubsystem,
})

sample({
  clock: onLogout,
  target: logoutByUser,
})

sample({
  clock: $userAuthorised.updates,
  filter: $userAuthorised,
  target: startPolling,
})

sample({
  clock: $userAuthorised.updates,
  filter: $userAuthorised.map((isAuth) => !isAuth),
  target: stopPolling,
})
