import React from 'react'
import { useStore } from 'effector-react'

import { DocumentStatuses, SummaryFieldAlias } from '@/dal'
import { $documentStatus } from '@/features/estimate-correction/shared-model'

import { $summary, onFieldSubmit } from '../../model/private'
import {
  Col, DateCol, GridWrapper, Label, Value,
} from '../parts'

export const DateRow = React.memo(() => {
  const summary = useStore($summary)
  const status = useStore($documentStatus)

  const memoHandleAvansSubmit = React.useCallback((val: string) => {
    onFieldSubmit({ field: SummaryFieldAlias.DATE, value: val })
  }, [onFieldSubmit])

  if (!summary) return null

  const isDraft = status === DocumentStatuses.Draft

  const { date_end_work } = summary

  return (
    <GridWrapper>
      <Label>Срок окончания работ</Label>
      {date_end_work.current ? (
        <DateCol date={date_end_work.current} />
      ) : (
        <Col>
          <Value>
            Бессрочный
          </Value>
        </Col>
      )}
      <DateCol
        date={date_end_work.adjusted}
        isChangeble={date_end_work.is_changeable}
        onSubmit={memoHandleAvansSubmit}
      />
      {date_end_work.approved ? (
        <DateCol
          isEdited={date_end_work.is_edited}
          date={date_end_work.approved}
          isGrey={isDraft}
        />
      ) : (
        <Col />
      )}
    </GridWrapper>
  )
})
