import React from 'react'

import { IconName2 } from '../icons'
import { InputSize, MaskInputProps } from './types'
import { Field } from './Field'
import { NumberInput } from './NumberInput'

type Props = MaskInputProps & {
  label: string
  error?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  value: string
  size?: InputSize
  placeholder?: string
  disabled?: boolean,
  name?: string
  postfixIcon?: IconName2
  required?: boolean
  dataTestId: string,
  letterSpacing?: string,
  isInvalid?: boolean
  onPostfixIconClick?: () => void
  onChange: (val: string) => void
  onClear?: () => void
}

export const NumberInputField = ({
  label,
  onChange,
  placeholder,
  value,
  currentSymbolCount,
  error,
  disabled,
  maxSymbolCount,
  dataTestId,
  onClear,
  postfixIcon,
  size,
  name,
  type,
  required,
  format,
  mask,
  allowEmptyFormatting,
  letterSpacing,
  allowLeadingZeros,
  decimalScale,
  decimalSeparator,
  fixedDecimalScale,
  allowNegative,
  thousandSeparator,
  isInvalid,
  suffix,
  onPostfixIconClick,
}: Props) => (
  <Field
    label={label}
    currentSymbolCount={currentSymbolCount}
    maxSymbolCount={maxSymbolCount}
    required={required}
  >
    <NumberInput
      dataTestId={dataTestId}
      maxLength={maxSymbolCount}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      isInvalid={isInvalid || Boolean(error)}
      disabled={disabled}
      onClear={onClear}
      onPostfixIconClick={onPostfixIconClick}
      postfixIcon={postfixIcon}
      name={name}
      error={error}
      size={size}
      type={type}
      format={format}
      mask={mask}
      allowEmptyFormatting={allowEmptyFormatting}
      letterSpacing={letterSpacing}
      allowLeadingZeros={allowLeadingZeros}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      allowNegative={allowNegative}
      thousandSeparator={thousandSeparator}
      suffix={suffix}
    />
  </Field>
)
