import { DocFlowDocSubtype, DocumentAlias, DocumentStatuses } from '@/dal'
import { statusResolve } from '@/lib/status-resolver'

import { textValueItem } from './types'
import { docSubtypeMapArray } from './const'

export const statusArray = () => {
  const result: textValueItem[] = []

  for (const key in DocumentStatuses) {
    const index = Number(key)
    if (!Number.isNaN(index)) {
      result.push({
        text: statusResolve(index),
        value: index.toString(),
      })
    }
  }

  return result
}

export const getDocSubtypesByDocType = (docType?: DocumentAlias | null) => {
  if (!docType) {
    return Object.values(DocFlowDocSubtype)
  }
  return docSubtypeMapArray.find(({ type }) => type === docType)?.subtypes ?? []
}

export const getDocTypesByDocSubtype = (subtype?: DocFlowDocSubtype | null) => {
  if (!subtype) {
    return Object.values(DocumentAlias)
  }
  const result: DocumentAlias[] = []
  docSubtypeMapArray.forEach((item) => {
    if (item.subtypes.includes(subtype)) {
      result.push(item.type)
    }
  })
  return result
}
