import React from 'react'
import { useGate, useStore } from 'effector-react'
import styled from 'styled-components'

import {
  HeadL,
  Icon2,
  Loader,
  TabSwiper,
  TextL,
  palette,
} from '@/ui'

import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { CreateAppealsListViewParams, RenderFunc, RequiredAppealItemFields } from '../../types'
import { CancelConfirm } from '../parts'

type ComponentProps<T extends RequiredAppealItemFields> = {
  renderFunc: RenderFunc<T>
}

export const createAppealsListView = <T extends RequiredAppealItemFields, S = string>({
  $appealTypes,
  $isPending,
  $listItems,
  $selectedItemId,
  $selectedTabId,
  selectItem,
  onOpenCancelAppeal,
  selectTab,
  gate,
  onConfirm,
  onReject,
  $cancelAppealInfo,
  $isCancelPending,
}: CreateAppealsListViewParams<T, S>) => (
    React.memo(({ renderFunc }: ComponentProps<T>) => {
      const items = useStore($listItems)
      const tabs = useStore($appealTypes)
      const activeTabId = useStore($selectedTabId)
      const activeItemId = useStore($selectedItemId)
      const isPending = useStore($isPending)
      const appealInfo = useStore($cancelAppealInfo)
      const isCancelPending = useStore($isCancelPending)

      const isEmpty = items?.length === 0

      useGate(gate)
      useOnChangeLocation(onReject)

      const mappedTabs = React.useMemo(() => tabs.map((item) => ({
        id: item.value,
        label: item.name,
        count: item.count,
      })), [tabs])

      const memoRenderFunc = React.useCallback(
        (item) => (
          <React.Fragment key={item.id}>
            {renderFunc({
              item,
              onOpenCancelAppeal,
              selectItem,
              isSelect: activeItemId === item.id,
            })}
          </React.Fragment>
        ),
        [renderFunc, onOpenCancelAppeal, selectItem, activeItemId])

      return (
        <>
          <Wrapper>
            <Title>
              История обращений
            </Title>

            <TabsWrapper>
              <TabSwiper
                activeId={activeTabId}
                onItemClick={selectTab}
                items={mappedTabs}
                size="M"
              />
            </TabsWrapper>

            {isPending
              ? <Loader />
              : (
                <>
                  {isEmpty
                    ? (
                      <EmptyList>
                        <Icon2 icon="emptyTasks" size={170} />
                        <EmptySubtitle>
                          У вас нет обращений
                        </EmptySubtitle>
                      </EmptyList>
                    )
                    : (
                      <List>
                        {items?.map(memoRenderFunc)}
                      </List>
                    )}
                </>
              )}
          </Wrapper>
          {appealInfo && (
            <CancelConfirm
              documentName={appealInfo.request_number}
              isPending={isCancelPending}
              onCancel={onReject}
              onConfirm={onConfirm}
            />
          )}
        </>
      )
    })
  )

const HORIZONTAL_PADDING = '32px'

const Wrapper = styled.div`
  padding: 28px 0 32px;
  height: 100%;
  position: relative;
`

const TabsWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  padding: 16px ${HORIZONTAL_PADDING} 0;
  background-color: ${palette.white};
`

const Title = styled.h3`
  ${HeadL}
  padding: 0 ${HORIZONTAL_PADDING};
  margin-bottom: 8px;
`

const EmptyList = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const EmptySubtitle = styled.p`
  ${TextL}
  color: ${palette.grey60};
`

const List = styled.div``
