import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { AppealItemType } from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { createAppealsPage } from '@/features/create-appeals-list/view/factories/createAppealsPage'
import { RenderFunc } from '@/features/create-appeals-list/types'

import {
  HeadM,
  Icon2,
  NewButton,
  palette,
} from '@/ui'

import {
  $appealTypes,
  commentsModel,
  filterModel,
  listModel,
} from '../../../shared-model'
import { AppealItem } from '../parts'
import { createButtonClicked } from '../../model/private'

const PageView = createAppealsPage({
  ...commentsModel,
  ...filterModel,
  ...listModel,
  $isCommentsPending: commentsModel.$isPending,
  $filterItems: $appealTypes,
  filtersLabel: 'Тип обращения',
})

export const RequestHistory = React.memo(() => {
  const memoRenderFunc = React.useCallback<RenderFunc<AppealItemType>>((
    {
      item,
      isSelect,
      selectItem,
      onOpenCancelAppeal,
    }) => (
      <AppealItem
        attachmentsCount={item.attachments.length}
        dateTime={item.date_add}
        document={item.document}
        id={item.id}
        isSelect={isSelect}
        onSelect={selectItem}
        onCancel={onOpenCancelAppeal}
        project={item.project}
        status={item.status}
        theme={item.request_type.name}
        title={item.request_number}
        canCancel={item.can_cancel}
      />
  ), [])

  return (
    <>
      <PageHeader>
        <Link to={RouterPaths.Help}>
          <Left>
            <Icon2
              icon="arrowLeft"
              size={24}
            />
            Обращение в поддержку
          </Left>
        </Link>

        <NewButton
          dataTestId="create-new-appeal"
          label="Создать новое обращение"
          prefixIcon="plusButton"
          onClick={createButtonClicked}
          size="S"
        />
      </PageHeader>
      <PageView
        renderFunc={memoRenderFunc}
      />
    </>
  )
})

const PageHeader = styled.div`
  background-color: ${palette.white};
  padding: 14px 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  ${HeadM}
  gap: 6px;
`
