import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)

/**
 * Get YYYY-MM-DD return dayJs object
 * @param {string} date - support only YYYY-MM-DD format or isoDate
 * @param {Date} date - Js Date object
 */
export const getDayJsObject = (date: string | Date) => {
  if (typeof date === 'string' && date.includes('-') && date.length === 10) {
    return dayjs(date, 'YYYY-MM-DD')
  }
  const d = dayjs(date)
  if (!d.isValid()) {
    console.warn('invalid Date format ', date)
  }
  return d
}

/**
 * Get JsDate object and return format string YYYY-MM-DD
 * @param {Date} date - Js Date
 */
export const formatDateForBackend = (date: Date) => dayjs(date).format('YYYY-MM-DD')

/**
 * Get YYYY-MM-DD return format string DD.MM.YYYY
 * @param {string} dateStr - support only isoDate or YYYY-MM-DD format
 * @param {Date} dateStr - js Date object
 */
export const formatDateString = (dateStr: string | Date) => {
  const date = getDayJsObject(dateStr)

  if (!date.isValid()) {
    console.warn('invalid date format ', dateStr)
  }

  return date.format('DD.MM.YYYY')
}

/**
 * Get isoDate and return format string DD.MM.YYYY HH:MM
 * @param {string} date - isoDate
 */
export const formatDateToDateTimeString = (date: string) => {
  const dateFormat = getDayJsObject(date)
  return dateFormat.format('DD.MM.YYYY, HH:mm')
}
/**
 * @example
 * // return 21:00
 * formatTimeString('2023-06-07T21:00:00.000Z')
 * @param {string} dateStr - isoDate string
 * @param {Date} dateStr - Date object
 */
export const formatTimeString = (dateStr: string | Date) => {
  const date = getDayJsObject(dateStr)
  return date.format('HH:mm')
}

export const formatDateWordMonth = (
  dateStr: string | Date | null | undefined,
  previous = false,
) => {
  const date = previous ? dayjs(dateStr).subtract(1, 'month') : dayjs(dateStr)

  if (!date.isValid()) {
    return ''
  }

  return `${getRuMonthNameByMonthNumber(Number(date.format('MM')) - 1)} ${date.format('YYYY')}`
}

export const getRuMonthNameByMonthNumber = (month: number) => {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ]

  return months[month]
}

export const getNounsMonthByNumber = (month: number) => {
  const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ]
  return months[month]
}

export const compareTwoRuDates = (
  firstOperand: string,
  secondOperand: string,
  op: '>' | '<',
) => {
  const firstDate = getDayJsObject(firstOperand)
  const secondDate = getDayJsObject(secondOperand)
  if (!firstDate.isValid() || !secondDate.isValid()) {
    console.warn(`invalid date formats first ${firstOperand} second ${secondOperand}`)
    return false
  }
  if (op === '>') {
    return (
      firstDate.toDate()
      > secondDate.toDate()
    )
  }

  return (
    firstDate.toDate()
    < secondDate.toDate()
  )
}

/**
 * Get YYYY-MM-DD return dayJs object with reseted timeStamp
 * @param {string} date - support only YYYY-MM-DD format or isoDate
 * @param {Date} date - Js Date object
 */
export const getDayJsObjectWithResetTimeStamp = (date: string | Date) => {
  const dayJs = getDayJsObject(date)
  return dayJs.set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0)
}

const values = ['Сегодня', 'Вчера', '2 дня назад']

/**
 * if diff is 0 day return string + hh:ss
 * if diff is 1 day return string + hh:ss
 * if diff is 2 day return string + hh:ss
 * else return full date
 * @param {string} date - isoDate
 */

export const getFullTime = (date: string) => {
  const obj = getDayJsObjectWithResetTimeStamp(date)
  if (!obj.isValid()) return 'invalid time'
  const currDate = getDayJsObjectWithResetTimeStamp(new Date())
  const diff = currDate.diff(obj, 'd')
  return values[diff]
    ? `${values[diff]}, ${formatTimeString(date)}`
    : formatDateToDateTimeString(date)
}

export const getDateWithMonth = (date: string) => {
  const obj = getDayJsObjectWithResetTimeStamp(date)
  if (!obj.isValid()) return 'invalid time'
  const currDate = getDayJsObjectWithResetTimeStamp(new Date())
  const diff = currDate.diff(obj, 'd')

  return values[diff]
    ? `${values[diff]}`
    : `${obj.get('D')} ${getNounsMonthByNumber(obj.get('month'))}`
}
