import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { InputField, NumberInputField } from '@/ui'

import { addUserForm, addUserFx } from '../../model/private'
import { ButtonRow, Title } from '../parts'

export const AddUserForm = React.memo(() => {
  const { fields, isValid, submit } = useForm(addUserForm)
  const isPending = useStore(addUserFx.pending)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title
        step={2}
        title="Заполните данные формы – добавить пользователя в ЛКП"
      />
      <Content>
        <Row>
          <InputField
            dataTestId="fio-input"
            error={fields.fio.firstError?.errorText}
            onChange={fields.fio.onChange}
            value={fields.fio.value}
            label="ФИО"
            isRequired
            placeholder="Введите данные"
            disabled={isPending}
          />

          <InputField
            dataTestId="user-info-input"
            error={fields.user_info.firstError?.errorText}
            onChange={fields.user_info.onChange}
            value={fields.user_info.value}
            label="Должность"
            isRequired
            placeholder="Введите название должности"
            disabled={isPending}
          />
        </Row>

        <Row>
          <NumberInputField
            dataTestId="phone-input"
            error={fields.phone.firstError?.errorText}
            onChange={fields.phone.onChange}
            value={fields.phone.value}
            label="Телефон"
            format="+7 ### ### ## ##"
            allowEmptyFormatting
            mask="_"
            required
            disabled={isPending}
          />

          <InputField
            dataTestId="email-input"
            error={fields.email.firstError?.errorText}
            onChange={fields.email.onChange}
            value={fields.email.value}
            label="E-mail"
            isRequired
            placeholder="Введите почту"
            disabled={isPending}
          />
        </Row>

      </Content>
      <ButtonRow
        isDisabled={!isValid || isPending}
        isPending={isPending}
      />
    </Wrapper>

  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`


const Row = styled.div`
  display: flex;
  gap: 16px;
`
