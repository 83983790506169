import {
  Domain,
  Effect,
  Store,
  attach,
  sample,
} from 'effector'
import { AxiosError } from 'axios'
import { root } from '@/root-domain'
import { createToast } from '@/features/toast-service/model'

type CreateFileModalParams = {
  domain?: Domain
  sendFilesFx: Effect<{id: string|number, files?: File[], [key: string]: any}, any, AxiosError>
  $documentId: Store<null|number> | Store<null|string>
  $files: Store<{file: string, name: string}[]>
}

export const createFileModalModel = ({
  domain,
  sendFilesFx,
  $documentId,
  $files,
}: CreateFileModalParams) => {
  const d = domain || root.domain()

  const $isOpen = d.store<boolean>(false)
  const openModal = d.event<void>()
  const closeModal = d.event<void>()

  const $uploadedFiles = d.store<File[]>([])
  const onRemove = d.event<string>()
  const onFiles = d.event<File[]>()
  const sendFiles = d.event<void>()

  const innerSendFilesFx = attach({
    effect: sendFilesFx,
  })

  $isOpen
    .on(openModal, () => true)
    .reset(closeModal)

  $uploadedFiles
    .on(onFiles, (prevFiles, file) => (prevFiles ? [...prevFiles, ...file] : file))
    .on(onRemove, (files, fileName) => files.filter((file) => file.name !== fileName))
    .reset(closeModal, innerSendFilesFx.doneData)

  sample({
    clock: sendFiles,
    source: {
      files: $uploadedFiles,
      id: $documentId,
    },
    filter: (
      params: {id: number|string|null, files: File[]},
    ): params is {id: string|number, files: File[]} => Boolean(params.id),
    target: innerSendFilesFx,
  })

  createToast({
    effect: innerSendFilesFx,
    doneText: 'Файлы отправлены',
  })

  return {
    sendFiles,
    openModal,
    closeModal,
    onFiles,
    onRemove,
    sendFilesFx: innerSendFilesFx,

    $uploadedFiles,
    $isOpen,
    $files,
  }
}
