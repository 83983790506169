import React from 'react'
import styled from 'styled-components'

import { WINDOW_WIDTH } from '@/ui'

import {
  Attachments, BankWarrantySplash, DocsForPayment, DocumentApproveInfo, GuaranteeContracts,
} from '../containers'

export const DocumentStatusAndAttacments = React.memo(() => {
  return (
    <Wrapper>
      <DocumentApproveInfo />
      <BankWarrantySplash />
      <GuaranteeContracts />
      <AttachmentsContainer>
        <Attachments />
        <DocsForPayment />
      </AttachmentsContainer>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const AttachmentsContainer = styled.div`
  display: flex;
  gap: 16px;

  & > * {
    flex: 0 1 50%;
  }

  @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
    flex-direction: column;
  }
`
