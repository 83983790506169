import { sample } from 'effector'

import { isUserNotFoundError } from '@/dal'

import './restore-init'
import './change-init'
import './repeat-registration-letter.init'

import { $isSuccess, $userNotFoundError, setUserNotFoundError } from './private'
import { restorePasswordForm, restorePasswordFx } from './restore-private'
import { repeatForm, repeatRegistrationLetterFx } from './repeat-registration-letter.private'
import { RestoreAccessGate } from './public'

$userNotFoundError
  .on(setUserNotFoundError, () => true)
  .reset(
    RestoreAccessGate.close,
    restorePasswordForm.$values,
    repeatForm.$values,
  )

$isSuccess
  .on([
    restorePasswordFx.doneData,
    repeatRegistrationLetterFx.done,
  ], () => true)
  .reset(RestoreAccessGate.close)

sample({
  clock: [
    restorePasswordFx.failData,
    repeatRegistrationLetterFx.failData,
  ],
  filter: (error) => isUserNotFoundError(error as any),
  target: setUserNotFoundError,
})
