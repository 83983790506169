import {
  forward,
} from 'effector'
import { createForm } from 'effector-forms'

import { root } from '@/root-domain'

import { FilterItem, CreateFiltersParams } from '../types'


export const createFiltersModel = ({
  domain,
  $filterItems,
}: CreateFiltersParams) => {
  const d = domain || root.createDomain()
  const onFiltersRefresh = d.event<void>()
  const refreshFilters = d.event<void>()
  const onFilters = d.event()

  const filterForm = createForm({
    domain: d,
    fields: {
      types: {
        init: [] as FilterItem['alias'][],
      },
      from: {
        init: '',
      },
      to: {
        init: '',
      },
    },
  })

  forward({
    from: filterForm.formValidated,
    to: onFilters,
  })

  forward({
    from: refreshFilters,
    to: filterForm.reset,
  })

  forward({
    from: filterForm.reset,
    to: onFiltersRefresh,
  })

  return {
    filterForm,
    $filterItems,
    onFilters,
    refreshFilters,
    onFiltersRefresh,
  }
}
