import { LotCatalogItem, MorphologyType } from '@/dal'
import { MorphologyRow } from './types'

export const mapCatalogItemsToOptions = (items: LotCatalogItem[]) => (items
  ? items.map(({ id, name }) => ({ id, label: name }))
  : [])

export const trimTrailingZeros = (number?: string) => number?.replace(/(\.|,)?0*$/, '')

export const getMorphologyRowDeepestNonNullField = (row: MorphologyRow) => {
  if (!row.object) return null
  if (!row.building) return MorphologyType.Object
  if (!row.section.length) return MorphologyType.Building
  return MorphologyType.Section
}
