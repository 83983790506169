import React, { FormEvent } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import {
  DropArea,
  NewDropDownField,
  NewFileItem,
  TextAreaField,
} from '@/ui'

import {
  $documentOptions,
  $documentTypeOptions,
  createProblemsWithApprovalFx,
  getJiraDocumentsFx,
  problemsWithApprovalForm,
} from '../../model/private'
import { ButtonRow, Title } from '../parts'

export const ProblemWithApproval = React.memo(() => {
  const { fields, isValid, submit } = useForm(problemsWithApprovalForm)
  const docOptions = useStore($documentOptions)
  const docTypeOptions = useStore($documentTypeOptions)

  const isPending = useStore(createProblemsWithApprovalFx.pending)
  const isDocumentPending = useStore(getJiraDocumentsFx.pending)

  const handleFileChange = (files: File[]) => {
    const newVal = [
      ...fields.files.value,
      ...Array.from(files)
        .filter((item) => !fields.files.value.some((cur) => cur.name === item.name)),
    ]
    fields.files.onChange(newVal)
  }

  const handleDeleteFile = (fileName: File['name']) => {
    const newVal = [
      ...fields.files.value.filter((item) => item.name !== fileName),
    ]
    fields.files.onChange(newVal)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title
        step={2}
        title="Заполните данные формы – проблемы с согласованием"
      />
      <Content>
        <Row>
          <NewDropDownField
            dataTestId="document-type-input"
            error={fields.documentType.firstError?.errorText}
            options={docTypeOptions}
            onOptionClick={fields.documentType.onChange}
            label="Тип документа"
            required
            placeholder="Выберите тип документа из списка"
            selectedId={fields.documentType.value}
            isLoading={isPending}
          />

          <NewDropDownField
            dataTestId="document-input"
            error={fields.document.firstError?.errorText}
            options={docOptions}
            onOptionClick={fields.document.onChange}
            label="Документы"
            required
            placeholder="Выберите документ из списка"
            selectedId={fields.document.value}
            hasSearch
            isDisabled={!fields.documentType.value}
            isLoading={isPending || isDocumentPending}
          />
        </Row>

        <TextAreaField
          dataTestId="text-area"
          error={fields.description.firstError?.errorText}
          onChange={fields.description.onChange}
          value={fields.description.value}
          height={100}
          autoGrow
          required
          label="Описание проблемы"
          placeholder="Введите текст..."
          disabled={isPending}
        />

        <DropArea
          onFiles={handleFileChange}
          height={92}
          isLoading={isPending}
        />

        {fields.files.value.length && (
          <FileList>
            {fields.files.value.map((file) => (
              <NewFileItem
                key={file.name}
                name={file.name}
                onDelete={isPending ? undefined : handleDeleteFile}
              />
            ))}
          </FileList>
        )}

      </Content>
      <ButtonRow
        isDisabled={!isValid || isPending}
        isPending={isPending}
      />
    </Wrapper>

  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const FileList = styled.div`
  
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
