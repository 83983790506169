import React from 'react'
import styled from 'styled-components'

import { RouterPaths } from '@/features/app/model'
import { statusResolve } from '@/lib/status-resolver'

import { NewTextButton } from '../../buttons'
import { Icon2 } from '../../icons'
import { TextMLight } from '../../typography'
import { ConnectedPaymentType } from '../../types'

type Props = ConnectedPaymentType

export const ConnectedPayment = ({
  id,
  name,
  status,
  progress,
}: Props) => {
  const progressString = progress ? `${progress?.agreed_by_default}/${progress?.total_visas_default}` : ''

  return (
    <Wrapper>
      <Icon2 icon="info" color="lightBlue" />
      <Text>
        Запущен процесс согласования
        {' '}
        <NewTextButton
          label={name}
          textStyle="ML"
          url={`${RouterPaths.Payment}/${id}`}
          dataTestId="connected-payment-link"
          isInline
        />
        .
        Статус документа –
        {' '}
        {progressString}
        {' '}
        {statusResolve(status)}
        .
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`

const Text = styled.p`
  ${TextMLight}
`
