import { sample } from 'effector'

import { $documentId } from '@/features/estimate-lot/model'

import { EstimateLotTableGate, onWorkOrFolderDeleted } from '../../table/model'
import {
  $isCatalogOpen,
  $searchValue,
  changeSearchValue,
  closeCatalog,
  getKvrAndKerFx,
  $openedKvrId,
  setOpenedKvrId,
  $catalogMap,
  $areDescriptionsExpanded,
  setAreDescriptionsExpanded,
  addKvrToLot,
  addKerToLotFx,
  addKerToLot,
  selectKvrId,
  $selectedKvrId,
  addKvrToLotFx,
  acceptSearch,
  selectKerId,
  $selectedKerId,
  getOpenedKvr,
  addKvrWithLoadedChildren,
  $kvrsWithLoadedChildren,
} from './private'
import { onWorkAdded, openCatalog } from './public'

$isCatalogOpen
  .on(openCatalog, () => true)
  .reset(EstimateLotTableGate.close, closeCatalog)

$catalogMap
  .on(getKvrAndKerFx.doneData, (map, data) => ({
    ...map,
    ...data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
  }))
  .on(addKerToLotFx.done, (map, { params: { kvrId, kerId } }) => {
    const targetKvr = map[kvrId]
    if (!targetKvr) return map
    return {
      ...map,
      [kvrId]: {
        ...targetKvr,
        ker_elements: targetKvr.ker_elements?.map((item) => (
          item.id !== kerId ? item : { ...item, is_ker_in_use: true }
        )) ?? null,
      },
    }
  })
  .reset(EstimateLotTableGate.close, onWorkOrFolderDeleted)

$kvrsWithLoadedChildren
  .on(addKvrWithLoadedChildren, (arr, id) => (arr.includes(id) ? arr : [...arr, id]))
  .reset(EstimateLotTableGate.close, onWorkOrFolderDeleted)

$searchValue
  .on(changeSearchValue, (_, value) => value)
  .reset(EstimateLotTableGate.close, closeCatalog)

$areDescriptionsExpanded
  .on(setAreDescriptionsExpanded, (_, value) => value)
  .reset(EstimateLotTableGate.close, closeCatalog)

$openedKvrId
  .on(setOpenedKvrId, (_, value) => value)
  .reset(EstimateLotTableGate.close, closeCatalog)

$selectedKvrId
  .on(selectKvrId, (_, value) => value)
  .reset(EstimateLotTableGate.close, closeCatalog, $openedKvrId.updates)

$selectedKerId
  .on(selectKerId, (_, value) => value)
  .reset(EstimateLotTableGate.close, closeCatalog, $openedKvrId.updates)

sample({
  clock: setOpenedKvrId,
  source: $kvrsWithLoadedChildren,
  filter: (loadedKvrs, kvrId) => Boolean(kvrId && !loadedKvrs.includes(kvrId)),
  fn: (_, kvrId) => kvrId,
  target: getOpenedKvr,
})

sample({
  clock: [
    EstimateLotTableGate.open,
    getOpenedKvr,
    acceptSearch,
    addKvrToLotFx.done,
    onWorkOrFolderDeleted,
  ],
  source: {
    id: $documentId,
    kvrId: $openedKvrId,
    search: $searchValue,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, kvrId, search }) => ({
    search,
    id: id as number,
    parentId: kvrId,
  }),
  target: getKvrAndKerFx,
})

sample({
  clock: getKvrAndKerFx.done,
  filter: ({ params }) => Boolean(params.parentId),
  fn: ({ params }) => params.parentId as number,
  target: addKvrWithLoadedChildren,
})

sample({
  clock: addKvrToLot,
  source: $documentId,
  filter: Boolean,
  fn: (id, kvrId) => ({ id, kvrId }),
  target: addKvrToLotFx,
})

sample({
  clock: addKerToLot,
  source: $documentId,
  filter: Boolean,
  fn: (id, { kerId, kvrId }) => ({ id, kerId, kvrId }),
  target: addKerToLotFx,
})

sample({
  clock: [addKerToLotFx.done, addKvrToLotFx.done],
  target: onWorkAdded,
})
