import React from 'react'
import { Link } from 'react-router-dom'

import { SelectItemButton } from '@/ui'

type Props = {
  alias: string
  text: string
  link: string
}

export const TypeItem = ({ alias, text, link }: Props) => (
  <Link to={link}>
    <SelectItemButton
      text={text}
      icon="arrowRight"
      dataTestId={`item-${alias}`}
    />
  </Link>
)
