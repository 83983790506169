import React from 'react'
import styled, { css } from 'styled-components'

import { noop } from '@/lib/noop'
import { useTooltip } from '@/lib/hooks'
import { mergeRefs } from '@/lib/mergeRefs'

import { TextL } from '../../typography'
import { Icon2 } from '../../icons'
import { DefaultButton } from '../DefaultButton'
import { styles } from './styles'
import { AnimationWrapper } from './wrappers'
import { ButtonSize, ButtonType } from './types'
import { ToolTipV2 } from '../../components/tooltip'
import { ButtonLoader } from './ButtonLoader'

type HandleClick = ((e: React.MouseEvent) => void) | (() => void)

type Props = {
  label: string
  size?: ButtonSize
  isPending?: boolean
  buttonType?: ButtonType
  tooltip?: string
  onClick?: HandleClick
  onClose: HandleClick
  dataTestId: string
  isFill?: boolean
}

// TODO доработать с созданием сметы
export const TagButton = React.forwardRef(({
  isPending,
  label,
  size = 'M',
  buttonType = 'primary',
  tooltip,
  onClick,
  onClose,
  dataTestId,
  isFill,
}: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({ tooltipOffset: 4 })

  const handleClick = isPending ? noop : onClick

  return (
    <AnimationWrapper
      isFill={isFill}
      {...getReferenceProps()}
      ref={mergeRefs([ref ?? null, refs.setReference])}
    >
      <Wrapper
        onClick={(e) => handleClick?.(e)}
        size={size}
        buttonType={buttonType}
        data-testid={dataTestId}
        isFill={isFill}
        data-pending={isPending || undefined}
      >
        {label}
        <IconWrapper onClick={onClose}>
          <Icon2 size={16} icon="cross" color="accent100" />
        </IconWrapper>
      </Wrapper>
      {isPending ? <ButtonLoader buttonType={buttonType} /> : null}

      {tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltip}
        </ToolTipV2>
      )}
    </AnimationWrapper>
  )
})

type WrapperProps = {
  size: ButtonSize
  buttonType: ButtonType
  isFill?: boolean
}

const Wrapper = styled.div`
  ${TextL}
  transition: color 0.15s linear, background-color 0.15s linear, border 0.15s linear;
  border-radius: 8px;
  gap: 8px;
  display: inline-flex;
  align-items: center;
  width: max-content;
  height: max-content;
  justify-content: center;
  border: 1px solid transparent;
  padding: ${({ size }: WrapperProps) => (size === 'M' ? '9px 23px' : '5px 19px')};
  cursor: default;
  ${({ buttonType: type }: WrapperProps) => styles[type]}
  ${({ isFill }: WrapperProps) => isFill && css`
    width: 100%;
  `}
`

const IconWrapper = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`
