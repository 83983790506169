import { forward } from 'effector'

import { loadAccessTokenFx } from '@/dal/auth'

import {
  $appLoaded,
  $currentRoute,
  $currentSubsystemId,
  $previousRoute,
  appInit,
  resetCurrentSubsystem,
  setCurrentRoute,
  setCurrentSubsystem,
  setPreviousRoute,
} from './public'

$appLoaded
  .on(loadAccessTokenFx.done, () => true)

forward({
  from: appInit,
  to: loadAccessTokenFx,
})

$currentSubsystemId
  .on(setCurrentSubsystem, (_, s) => s)
  .reset(resetCurrentSubsystem)

$currentRoute
  .on(setCurrentRoute, (_, route) => route)

$previousRoute
  .on(setPreviousRoute, (_, route) => route)
