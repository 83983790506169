import React from 'react'
import styled, { css } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useGate, useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  $activeTabId,
  $isPaymentLoading,
  $paymentRejectReason,
  PaymentGate,
} from '@/features/payment/single-payment/model'
import { CancelPaymentApproveModal } from '@/features/payment/single-payment/cancel-approve-modal/view'
import {
  CreateBankAccountModal,
  CreateContragentModal,
  DocumentStatusAndAttacments,
  PaymentAvansTable,
  PaymentHeader,
  DocumentInfo,
} from '@/features/payment/single-payment/view'
import { PaymentTab } from '@/features/payment/single-payment/model/types'

import {
  ContentContainer,
  LayoutGrid,
  Loader,
  RejectReasonNotification,
  ScrollTopButton,
} from '@/ui'

export const PaymentPage = () => {
  const { id } = useParams<{ id: string }>()
  useGate(PaymentGate, { id: parseInt(id, 10) || null })
  const ref = React.useRef<Scrollbars>(null)
  const rejectionReason = useUnit($paymentRejectReason)
  const isPending = useUnit($isPaymentLoading)
  const activeTab = useUnit($activeTabId)

  if (isPending) return <Loader />

  return (
    <>
      <Scrollbars ref={ref}>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <PaymentHeader />

            <RejectReasonNotification rejectReason={rejectionReason} />

            <TabWrapper isActive={activeTab === PaymentTab.Info}>
              <LayoutGrid>
                <LeftBlock>
                  <DocumentStatusAndAttacments />
                </LeftBlock>
                <RightBlock>
                  <DocumentInfo />
                </RightBlock>
              </LayoutGrid>
            </TabWrapper>

            <TabWrapper isActive={activeTab === PaymentTab.Table}>
              <LayoutGrid>
                <TableWrapper>
                  <PaymentAvansTable />
                </TableWrapper>
              </LayoutGrid>
              <ScrollTopButton
                scrollContainerRef={ref}
                bgColor="grey100"
                iconColor="grey60"
                hoverIconColor="white"
              />
            </TabWrapper>

          </Wrapper>

        </ContentContainer>
      </Scrollbars>
      <CancelPaymentApproveModal />
      <CreateContragentModal />
      <CreateBankAccountModal />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const TabWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isActive }) => !isActive && css`
    display: none;
  `}
`

const LeftBlock = styled.div`
  grid-column: span 5;
`

const RightBlock = styled.div`
  grid-column: span 7;
`

const TableWrapper = styled.div`
  grid-column: span 12;
`
