import React from 'react'
import { Link } from 'react-router-dom'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { RouterPaths } from '@/features/app/model'
import { ConfirmModalLayout, palette } from '@/ui'
import { noop } from '@/lib/noop'
import { usePendingDocument } from '@/features/document-manager/hooks'
import { DocumentAlias, SupportAppealTypeAlias } from '@/dal'

import {
  $paymentDocumentId,
  $paymentInfo,
  $showConfirm,
  closeConfirm,
  onConfirm,
} from '../../../model/private'

const $docName = $paymentInfo.map((info) => info?.name || null)

export const ChangeCompanyRequisitesConfirm = React.memo(() => {
  const [isOpen, docName, docId] = useUnit([
    $showConfirm,
    $docName,
    $paymentDocumentId,
  ])

  const {
    isDeleting,
  } = usePendingDocument({
    id: docId,
    type: DocumentAlias.PAYMENT,
  })

  const appealLink = React.useMemo(() => {
    const appealDescription = `Я изменил реквизиты по своей организации и создал новый документ ${
      docName || ''
    }, но в документе новые банковские реквизиты не отобразились.`
    return `${RouterPaths.CreateSupportAppeal}/${SupportAppealTypeAlias.LKP_ERRORS}?description=${appealDescription}`
  }, [docName])

  return (
    <ConfirmModalLayout
      body={(
        <>
          Прежде чем приступить к изменению реквизитов,
          необходимо удалить созданный платеж.
          Обновленные реквизиты подтянутся в новый документ автоматически.
          Если этого не произошло, обратитесь в
          {' '}
          {isDeleting
            ? <Span>службу поддержки</Span>
            : (
              <Link to={appealLink} target="_blank">
                службу поддержки
              </Link>
            )}
          .
        </>
      )}
      cancelLabel="Отменить"
      confirmLabel="Удалить платеж и изменить реквизиты"
      title={docName || ''}
      dataTestId="change-company-info"
      isOpen={isOpen}
      isPending={isDeleting}
      areButtonsFill={false}
      closeModal={!isDeleting ? closeConfirm : noop}
      onCancel={closeConfirm}
      onConfirm={onConfirm}
    />
  )
})

const Span = styled.span`
  color: ${palette.accent100};
`
