import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { palette } from '@/ui'

import { CreateAppealsPageView, RenderFunc, RequiredAppealItemFields } from '../../types'
import { createAppealsListView } from './createAppealsListView'
import { createAppealsFilterView } from './createAppealsFilterView'
import { createAppealCommentsView } from './createAppealCommentsView'

type Props<T extends RequiredAppealItemFields> = {
  renderFunc: RenderFunc<T>
}

export const createAppealsPage = <T extends RequiredAppealItemFields, S = string>({
  $appealTypes,
  $filterItems,
  $isPending,
  $listItems,
  $selectedItemId,
  $selectedTabId,
  onOpenCancelAppeal,
  filterForm,
  gate,
  refreshFilters,
  selectItem,
  selectTab,
  $comments,
  $isCommentsPending,
  $selectedItem,
  commentForm,
  $isMessageSending,
  onConfirm,
  onReject,
  $cancelAppealInfo,
  $isCancelPending,
  resetSelectedItem,
  filtersLabel,
}: CreateAppealsPageView<T, S>) => {
  const ListView = createAppealsListView<T, S>({
    $appealTypes,
    $isPending,
    $listItems,
    $selectedItemId,
    $selectedTabId,
    onOpenCancelAppeal,
    gate,
    selectItem,
    selectTab,
    onConfirm,
    onReject,
    $cancelAppealInfo,
    $isCancelPending,
  })

  const FilterView = createAppealsFilterView({
    filtersLabel,
    $filterItems,
    $isPending,
    filterForm,
    refreshFilters,
  })

  const CommentsView = createAppealCommentsView<T>({
    $comments,
    $isCommentsPending,
    $selectedItem,
    commentForm,
    $isMessageSending,
    resetSelectedItem,
  })

  return React.memo(({ renderFunc }: Props<T>) => {
    const memoFunc = React.useCallback(renderFunc, [renderFunc])
    const selectedItemId = useStore($selectedItemId)

    return (
      <Wrapper>
        <List>
          <ListView renderFunc={memoFunc} />
        </List>
        {
          selectedItemId
            ? (
              <MessageCol>
                <CommentsView />
              </MessageCol>
            ) : (
              <FilterCol>
                <FilterView />
              </FilterCol>
            )
        }
      </Wrapper>
    )
  })
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

const List = styled.div`
  flex-grow: 1;
  border-radius: 20px;
  background-color: ${palette.white};
`

const FilterCol = styled.div`
  position: sticky;
  top: 24px;
  border-radius: 20px;
  flex: 0 0 375px;
  background-color: ${palette.white};
  height: max-content;
`

const MessageCol = styled.div`
  position: sticky;
  top: 24px;
  border-radius: 20px;
  flex: 0 0 375px;
  background-color: ${palette.white};
  height: calc(100dvh - 170px); 
`
