import React from 'react'
import { useUnit } from 'effector-react'

import { TabSwiper } from '@/ui'

import {
  $companyItems,
  $selectedCompany,
  selectCompany,
} from '../../model/private'

export const CompanyList = React.memo(() => {
  const {
    list,
    selectedCompany,
  } = useUnit({
    selectedCompany: $selectedCompany,
    list: $companyItems,
  })

  if (!list) return null

  return (
    <TabSwiper
      activeId={selectedCompany?.id}
      items={list}
      onItemClick={selectCompany}
      disableArrow
      size="M"
    />
  )
})
