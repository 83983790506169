import React, { FormEvent } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  HeadL,
  ModalLayoutV2,
  NewTextButton,
  NotificationItem,
  NotificationType,
  palette,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import {
  $hasProjectsToAdd,
  $isEdit,
  $isOpen,
  addProjectField,
  closeModal,
  contactForm,
} from '../../model/private'
import {
  Buttons,
  Emailnput,
  NameInput,
  PhoneInput,
  ProjectSelector,
} from '../containers'

export const EditCreateContactModal = React.memo(() => {
  const isOpen = useStore($isOpen)
  const isEdit = useStore($isEdit)
  const hasProjectsToAdd = useStore($hasProjectsToAdd)
  const { values, submit } = useForm(contactForm)

  useOnChangeLocation(closeModal)

  if (!isOpen) return null

  const title = isEdit
    ? 'Редактирование контакта'
    : 'Добавление контакта'

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <ModalLayoutV2
      closeModal={closeModal}
      dataTestId="edit-create-contact-modal"
    >
      <Wrapper>
        <Head>
          <Title>
            {title}
          </Title>
        </Head>
        <Scrollbars autoHeight autoHeightMax="70dvh">

          <ContentWrapper>
            <Form onSubmit={handleSubmit}>
              <NameInput />
              <PhoneInput />
              <Emailnput />

              <NotificationItem
                type={NotificationType.Info}
                body="Поля «Роль» и «Проект» содержат множественный выбор. Если человек отвечает за несколько ролей или проектов, вы можете выбрать их из списка. "
              />

              {values.projects.map(({ id }, index) => (
                <ProjectSelector fieldId={id} index={index} key={id} />
              ))}

              {hasProjectsToAdd && (
                <NewTextButton
                  dataTestId="added-new-project"
                  label="Добавить проект"
                  iconSize={16}
                  prefixIcon="plusButton"
                  onClick={addProjectField}
                />
              )}
              <Buttons />
            </Form>
          </ContentWrapper>

        </Scrollbars>
      </Wrapper>

    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  width: 400px;
  border-radius: 12px;
`

const ContentWrapper = styled.div`
  padding: 0px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Head = styled.div`
  padding: 24px 32px;
`

export const Title = styled.h4`
  ${HeadL}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
