import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useStore } from 'effector-react'

import {
  DropAreaWithList,
  NumberInputField,
  TextAreaField,
  TextM,
  TextMLight,
} from '@/ui'
import { FileDTO } from '@/dal'

import {
  $splitTarget,
  splitVolumeForm,
} from '../../../model/split-volume.private'

export const OldFields = React.memo(() => {
  const { fields } = useForm(splitVolumeForm)
  const info = useStore($splitTarget)

  const uploadedFilesToKeep = React.useMemo(() => {
    const filesToDelete = fields.old_files_to_delete.value
    return info?.attachments?.filter(({ id }) => !filesToDelete.includes(id)) ?? null
  }, [info, fields])

  const onMarkFileToDelete = (id: FileDTO['id']) => {
    fields.old_files_to_delete.set([...fields.old_files_to_delete.value, id])
  }

  return (
    <>
      <Field>
        <FieldLabel>
          Название услуг, работ
        </FieldLabel>
        <FieldValue>
          {info?.code_1c}
          {' '}
          {info?.name.value}
        </FieldValue>
      </Field>
      <FieldRow>
        <Field>
          <FieldLabel>
            Объект строительства
          </FieldLabel>
          <FieldValue>
            {info?.project?.name || 'Не указано'}
          </FieldValue>
        </Field>
        <Field>
          <FieldLabel>
            Единицы измерения
          </FieldLabel>
          <FieldValue>
            {info?.unit?.value || 'Не указано'}
          </FieldValue>
        </Field>
      </FieldRow>
      <NumberInputField
        dataTestId="old-estimate-count"
        label="Кол-во по смете"
        required
        onChange={fields.old_count_in_estimate.onChange}
        value={fields.old_count_in_estimate.value}
        decimalScale={4}
        fixedDecimalScale
        error={fields.old_count_in_estimate.firstError?.errorText}
        onClear={fields.old_count_in_estimate.reset}
        size="L"
        thousandSeparator=" "
      />
      <TextAreaField
        dataTestId="old-text-area"
        label="Обоснование"
        onChange={fields.old_justification_of_changes_current.onChange}
        placeholder="Укажите причину изменений и ссылку на РД"
        value={fields.old_justification_of_changes_current.value}
        autoGrow
        currentSymbolCount={fields.old_justification_of_changes_current.value.length}
        maxSymbolCount={800}
        height={100}
      />
      <DropAreaWithList
        items={fields.old_files_to_save.value}
        uploadedFiles={uploadedFilesToKeep}
        onChange={fields.old_files_to_save.onChange}
        markUploadedFileToDelete={onMarkFileToDelete}
        dataTestId="old-file-input"
      />
    </>
  )
})

const FieldLabel = styled.div`
  ${TextM}
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  overflow: hidden;
`

const FieldValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${TextMLight}
`

const FieldRow = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`
