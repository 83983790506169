import {
  ActTableFolder, ActTableFolderPIR, ActTableItem, ActWorkPIR,
} from './types'
import {
  ApproveActError,
  CreateActConflictError,
  CreateActReqiredFieldError,
} from './errors'

export const isApproveError = (error: Error):
  error is ApproveActError => error instanceof ApproveActError

export const isCreateActConflictError = (error: Error):
  error is CreateActConflictError => error instanceof CreateActConflictError

export const isCreateActReqieredFieldError = (error: Error):
  error is CreateActReqiredFieldError => error instanceof CreateActReqiredFieldError

export const isPIRActWork = (work: ActTableItem): work is ActWorkPIR => 'status' in work

export const isPIRTableFolder = (work: ActTableFolder): work is ActTableFolderPIR => (
  typeof work.period_cost === 'string'
)
