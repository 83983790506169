import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { DocumentAlias, ExistingDoc } from '@/dal'
import { RouterPaths } from '@/features/app/model'
import { NewButton, TextMLight } from '@/ui'

import { ConflictingDraftWarning } from './ConflictingDraftWarning'

type Props = {
  name: string
  id: number
  draft?: ExistingDoc
  onCopy: () => void
  onConfirmCreateKorDoc: () => void
  onClose: () => void
  isCreatePending: boolean
  isCopyPending: boolean
}

export const EstimateCreateOrCopyConfirm = ({
  id,
  name,
  draft,
  onCopy,
  onConfirmCreateKorDoc,
  onClose,
  isCreatePending,
  isCopyPending,
}: Props) => {
  return (
    <Wrapper>
      <ContentWrapper>
        {draft && (
          <ConflictingDraftWarning
            id={draft.id}
            name={draft.name}
            docType={DocumentAlias.KOR_DOC}
          />
        )}

        <Text>
          У вас есть отклоненная/неактуальная
          {' '}
          <Link target="_blank" to={`${RouterPaths.EstimateCorrection}/${id}`}>
            {name}
          </Link>
          , вы можете создать копию ПС и продолжить работу с документом
          или создать новую ПС.
        </Text>
      </ContentWrapper>

      <ButtonsWrapper>
        <NewButton
          isFill
          dataTestId="copy-old"
          label="Создать копию отклоненной ПС"
          buttonType="line-primary"
          onClick={onCopy}
          isPending={isCopyPending}
          isDisabled={isCreatePending}
        />
        <NewButton
          isFill
          dataTestId="create-new"
          label="Создать новую ПС"
          onClick={onConfirmCreateKorDoc}
          isPending={isCreatePending}
          isDisabled={isCopyPending}
        />
        <NewButton
          isFill
          dataTestId="cancel"
          label="Отменить"
          onClick={onClose}
          buttonType="grey"
          isDisabled={isCreatePending || isCopyPending}
        />
      </ButtonsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Text = styled.div`
  ${TextMLight}
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
