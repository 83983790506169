import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { TextM, TextMLight } from '../../typography'
import { palette } from '../../palette'
import { NewIconButton } from '../../buttons/NewIconButton'
import { Icon2 } from '../../icons'
import { NewCheckbox } from '../NewCheckbox'
import { TABLE_HEADER_BORDER_STYLE, TABLE_HEADER_THICK_BORDER_STYLE } from './const'

type Props = {
  isHiddenCell?: boolean
  hasRightDivider?: boolean
  textAlign?: 'left' | 'center' | 'right'
  label: string
  totalCost?: string
  groupNumber?: number
  isExpanded?: boolean
  info?: string
  checkboxLabel?: string
  checkboxPosition?: 'left' | 'right'
  isChecked?: boolean
  isDisableCheckbox?: boolean
  isFirst?: boolean
  expandButtonZIndex?: number
  onToggle?: (isChecked: boolean) => void
  onExpandButton?: (groupNumber: number) => void
}

export const TableHeaderCell = ({
  hasRightDivider,
  isHiddenCell,
  label,
  textAlign = 'center',
  groupNumber,
  isExpanded,
  info,
  totalCost,
  checkboxLabel,
  checkboxPosition = 'right',
  isChecked,
  isDisableCheckbox,
  isFirst,
  expandButtonZIndex,
  onToggle,
  onExpandButton,
}: Props) => {
  return (
    <Cell
      isHiddenCell={Boolean(isHiddenCell)}
      hasRightDivider={Boolean(hasRightDivider)}
      isFirst={Boolean(isFirst)}
    >
      {groupNumber !== undefined && onExpandButton && (
        <ButtonWrapper isExpanded={Boolean(isExpanded)} zIndex={expandButtonZIndex}>
          <NewIconButton
            padding="4px"
            dataTestId={`expand-group-${groupNumber}`}
            icon={isExpanded ? 'arrowLeft' : 'arrowRight'}
            size={12}
            borderRadius="50%"
            color="white"
            backgroundColor={isExpanded ? 'grey80' : 'grey50'}
            hoverBackgroundColor={isExpanded ? 'grey80' : 'grey50'}
            onClick={() => onExpandButton(groupNumber)}
          />
        </ButtonWrapper>
      )}

      <LabelWrapper textAlign={textAlign}>
        {label}
        {info && <Icon2 icon="info" color="accent80" size={20} tooltipText={info} />}
      </LabelWrapper>

      {totalCost && (
        <SubLabel textAlign={textAlign}>
          {numberWithSpaces(totalCost)}
        </SubLabel>
      )}

      {onToggle && checkboxLabel && (
        <CheckboxWrapper>
          <NewCheckbox
            label={checkboxLabel}
            onChange={onToggle}
            isBoolean
            isChecked={isChecked}
            disabled={isDisableCheckbox}
            checkPosition={checkboxPosition}
          />
        </CheckboxWrapper>
      )}

    </Cell>
  )
}

const Cell = styled.div<{ isHiddenCell: boolean, hasRightDivider: boolean, isFirst: boolean }>`
  position: relative;
  padding: ${({ isFirst }) => (isFirst ? '20px' : '9px 12px')};
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ isHiddenCell }) => (isHiddenCell ? palette.grey30 : palette.grey10)};

  &:not(:last-child) {
    border-right: ${TABLE_HEADER_BORDER_STYLE};
  
    ${({ hasRightDivider }) => hasRightDivider && css`
      border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
    `}
  }
`

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const LabelWrapper = styled.div<Required<Pick<Props, 'textAlign'>>>`
  display: flex;
  width: 100%;
  justify-content: ${({ textAlign }) => alignments[textAlign]};
  gap: 6px;
  text-align: ${({ textAlign }) => textAlign};
  ${TextM};
`

const SubLabel = styled.div<Pick<Props, 'textAlign'>>`
  ${TextMLight}
  color: ${palette.grey70};
  text-align: ${({ textAlign }) => textAlign};
  margin-top: 2px;
`

const ButtonWrapper = styled.div<{ isExpanded: boolean, zIndex?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  transform: ${({ isExpanded }) => (isExpanded ? 'translateX(-50%)' : 'translateX(calc(-50% - 2px))')};
  align-items: center;

  ${({ zIndex }) => zIndex && css`
    z-index: ${zIndex};
  `}
`

const CheckboxWrapper = styled.div`
  width: max-content;
  margin-left: auto;
`
