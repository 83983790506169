import React from 'react'

import { FeedbackModal } from './features/feedback/view'
import {
  CopyDocumentConfirm,
  CreateDocumentModal,
  DeleteDocumentConfirm,
} from './features/document-manager/view'
import { ActErrorModal } from './features/act-constructor'
import { CloseContractModal, GetRetensionModal } from './features/administrative/close-contract/view'
import { AdditionalModal } from './features/additional-request'


export const ModalProvider = () => (
  <>
    <CopyDocumentConfirm />
    <FeedbackModal />
    <ActErrorModal />
    <DeleteDocumentConfirm />
    <CloseContractModal />
    <GetRetensionModal />
    <CreateDocumentModal />
    <AdditionalModal />
  </>
)
