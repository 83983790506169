import React from 'react'
import { Field } from './Field'
import { NewInput } from './NewInput'
import { IconName2 } from '../icons'
import { HTMLInputType, InputSize } from './types'

type Props = {
  label?: string
  error?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  value: string
  size?: InputSize
  placeholder?: string
  disabled?: boolean,
  type?: HTMLInputType
  name?: string
  postfixIcon?: IconName2
  dataTestId: string,
  isRequired?: boolean
  isLabel?: boolean
  readOnly?: boolean
  isInvalid?: boolean
  onPostfixIconClick?: () => void
  onChange: (val: string) => void
  onClear?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export const InputField = React.forwardRef(({
  label,
  onChange,
  onBlur,
  onFocus,
  isInvalid,
  placeholder,
  value,
  currentSymbolCount,
  error,
  disabled,
  maxSymbolCount,
  dataTestId,
  onClear,
  postfixIcon,
  size,
  name,
  type,
  isRequired,
  isLabel,
  readOnly,
  onPostfixIconClick,
}: Props, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Field
      label={label}
      currentSymbolCount={currentSymbolCount}
      maxSymbolCount={maxSymbolCount}
      required={isRequired}
      isLabel={isLabel}
    >
      <NewInput
        dataTestId={dataTestId}
        maxLength={maxSymbolCount}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid || Boolean(error)}
        disabled={disabled}
        onClear={onClear}
        onPostfixIconClick={onPostfixIconClick}
        postfixIcon={postfixIcon}
        name={name}
        error={error}
        size={size}
        type={type}
        ref={ref}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </Field>
  )
})
