import { EstimateDoc, EstimateDocId } from '@/dal'

export enum KorDocTab {
  Info,
  Works,
}

export type UpdateSummaryParams = {
  docId: EstimateDocId,
  summary: EstimateDoc['summary_table']
}
