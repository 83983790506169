import { combine } from 'effector'

import { EstimateTableRow, updateMultipleKorDocItemsReqFx } from '@/dal'
import { createChunkRequest } from '@/features/factories'

import { d } from './domain'
import { NonFormField } from './types'

export type RowsFormDataMap = {
  [key: EstimateTableRow['id']]: {
    [key: string]: Omit<NonFormField<unknown>, 'onChange'>
  }
}

export type SpecificFieldPayload = {
  id: EstimateTableRow['id']
  value: unknown
  name: string
}

export const $isMultipleEditModeEnabled = d.store(false)
export const enableMultipleEditMode = d.event()
export const exitMultipleEditMode = d.event()

export const $rowsFormDataMap = d.store<RowsFormDataMap>({})
export const updateRowsFormDataMap = d.event<EstimateTableRow[]>()
export const changeSpecificField = d.event<SpecificFieldPayload>()

export const $changedRowIds = d.store<EstimateTableRow['id'][]>([])
export const addChangedRowId = d.event<EstimateTableRow['id']>()

export const $invalidRowIds = d.store<EstimateTableRow['id'][]>([])
export const setInvalidRowId = d.event<EstimateTableRow['id']>()
export const removeInvalidRowId = d.event<EstimateTableRow['id']>()

export const $canSendEditedRows = combine(
  [$invalidRowIds, $changedRowIds],
  ([invalidRows, changedRows]) => Boolean(!invalidRows.length && changedRows.length),
)

export const $currentFormId = d.store<EstimateTableRow['id'] | null>(null)
export const setCurrentFormId = d.event<EstimateTableRow['id']>()
export const validationChecked = d.event<EstimateTableRow['id']>()

export const $lastChangedField = d.store<SpecificFieldPayload | null>(null)
export const setLastChangedField = d.event<SpecificFieldPayload>()

export const updateMultipleKorDocItems = d.event<void>()

export const {
  effectFx: updateMultipleKorDocItemsFx,
  requestEndedWithErrors,
  $counter,
  $totalItems,
} = createChunkRequest({
  fetchEffect: updateMultipleKorDocItemsReqFx,
  domain: d,
})
