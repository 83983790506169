import React from 'react'
import styled from 'styled-components'

import { HeadL, palette } from '@/ui'

type Props = {
  title: string
  step: number
}

export const Title = ({ title, step }: Props) => (
  <Text>
    <TextSpan>
      {step}
      .
    </TextSpan>
    {title}
  </Text>
)


const Text = styled.h1`
  ${HeadL}
  display: flex;
  gap: 6px;
`

const TextSpan = styled.span`
  color: ${palette.grey60};
`
