import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useGate, useUnit } from 'effector-react'

import {
  LoaderOverlay,
  MonthInput,
  TextLLight,
} from '@/ui'

import {
  $availableMonths,
  CreatePlanGate,
  createPlanForm,
  createPlanFx,
  getSubmissionMonthsFx,
} from '../../model/create-plan.private'
import { $createDocumentInfo, closeCreateDocumentModal } from '../../model/private'
import { getFormattedContractName } from '../mappers'
import { DocumentCreationButtons } from '../parts'

export const CreatePlan = React.memo(() => {
  const {
    fields, submit, isValid, isTouched,
  } = useForm(createPlanForm)
  const isLoading = useUnit(createPlanFx.pending)
  const availableMonths = useUnit($availableMonths)
  const isLoadingSubmissionMonths = useUnit(getSubmissionMonthsFx.pending)
  const info = useUnit($createDocumentInfo)

  useGate(CreatePlanGate)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper>
      <Description>
        Чтобы создать планирование по
        {' '}
        {getFormattedContractName(info?.contractName)}
        , выберите период для формирования бюджета.
      </Description>

      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <MonthInput
            dataTestId="create-plan-date"
            label="Выберите месяц и год"
            fullWidth
            inputValue={fields.dateStart.value}
            onChange={fields.dateStart.onChange}
            errorText={fields.dateStart.errorText()}
            disabled={isLoading || isLoadingSubmissionMonths}
            includeDates={availableMonths ?? []}
            required
          />
          <LoaderOverlay sizePx={40} show={isLoadingSubmissionMonths} />
        </InputWrapper>

        <DocumentCreationButtons
          isDisabled={!isValid || !isTouched || isLoadingSubmissionMonths}
          isPending={isLoading}
          onClose={closeCreateDocumentModal}
        />
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Description = styled.div`
  ${TextLLight}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const InputWrapper = styled.div`
  position: relative;
`
