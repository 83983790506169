import { sample } from 'effector'
import { debounce } from 'patronum'

import { ContractDirection, DocumentStatuses } from '@/dal'
import { $documentId } from '@/features/estimate-lot/model'
import { createToast } from '@/features/toast-service/model'
import { replaceNavigate, RouterPaths } from '@/features/app/model'

import { $documentInfo, attachmentsModel, EstimateLotGate } from './public'
import {
  $advanceTypes,
  $contracts,
  $directions,
  $projects,
  $typesOfSecurity,
  getContractsFx,
  getDirectionsFx,
  getEstimateLotAdvanceTypesFx,
  getEstimateLotFx,
  getMorphologyFx,
  getProjectsFx,
  getTypesOfSecurityFx,
  onLotInfoChanged,
  openAttachmentsModal,
  sendFilesFx,
  updateDocumentInfo,
  updateEstimateLotFx,
} from './private'
import { lotInfoForm } from './forms'
import { mapCatalogItemsToOptions, trimTrailingZeros } from './helpers'
import { cancelEstimateLotApprovingFx } from './header.private'
import { $selectedMorphology, changeMorphologyRow, setMorphologyRows } from './morphology.private'

import './header.init'
import './morphology.init'

$documentInfo
  .on([getEstimateLotFx.doneData, updateDocumentInfo], (_, data) => data)
  .on(sendFilesFx.doneData, (data, attachments) => (data ? { ...data, attachments } : null))
  .reset(EstimateLotGate.close)

$projects
  .on(getProjectsFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$contracts
  .on(getContractsFx.doneData, (_, data) => {
    const contracts = data.filter(({ status, direction }) => (
      status === DocumentStatuses.Signed && direction === ContractDirection.SMR
    ))
    return mapCatalogItemsToOptions(contracts)
  })
  .reset(EstimateLotGate.close)

$directions
  .on(getDirectionsFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$advanceTypes
  .on(getEstimateLotAdvanceTypesFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

$typesOfSecurity
  .on(getTypesOfSecurityFx.doneData, (_, data) => mapCatalogItemsToOptions(data))
  .reset(EstimateLotGate.close)

sample({
  clock: EstimateLotGate.open,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getEstimateLotFx,
})

sample({
  clock: getEstimateLotFx.doneData,
  fn: (info) => ({ id: info.company_id }),
  target: [
    getContractsFx,
    getDirectionsFx,
    getTypesOfSecurityFx,
    getProjectsFx,
    getEstimateLotAdvanceTypesFx,
    getMorphologyFx,
  ],
})

sample({
  clock: getEstimateLotFx.fail,
  fn: () => RouterPaths.EstimateLots,
  target: replaceNavigate,
})

sample({
  clock: [updateEstimateLotFx.doneData, cancelEstimateLotApprovingFx.doneData],
  target: updateDocumentInfo,
})

sample({
  clock: $documentInfo,
  filter: Boolean,
  fn: ({ morphology }) => morphology,
  target: setMorphologyRows,
})

sample({
  clock: $documentInfo,
  filter: Boolean,
  fn: (info) => ({
    ...info,
    project: info.project_id,
    direction: info.direction_id,
    contract: info.contract_id,
    advance_type: info.advance_type_id,
    type_of_security: info.type_of_security_id,
    advance_size: trimTrailingZeros(info.advance_size) ?? '',
    guarantee_retention_size: trimTrailingZeros(info.guarantee_retention_size) ?? '',
    is_existing_contract: info.is_existing_contract,
    is_typical_form_of_contract: info.is_typical_form_of_contract,
    comment: info.comment ?? '',
    construction_start: info.construction_start ?? '',
    construction_end: info.construction_end ?? '',
  }),
  target: lotInfoForm.setInitialForm,
})

sample({
  clock: changeMorphologyRow,
  target: onLotInfoChanged,
})

sample({
  clock: lotInfoForm.$values,
  filter: lotInfoForm.$touched,
  target: onLotInfoChanged,
})

sample({
  clock: debounce({
    source: onLotInfoChanged,
    timeout: 1000,
  }),
  source: {
    id: $documentId,
    form: lotInfoForm.$values,
    morphology: $selectedMorphology,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, form, morphology }) => ({
    ...form,
    morphology,
    id: id as number,
    construction_start: form.construction_start || undefined,
    construction_end: form.construction_end || undefined,
  }),
  target: updateEstimateLotFx,
})

sample({
  clock: openAttachmentsModal,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: attachmentsModel.open,
})

createToast({
  effect: updateEstimateLotFx,
  doneText: 'Изменения успешно сохранены',
})
