import { Effect, sample } from 'effector'
import { AxiosError } from 'axios'

import {
  CustomResponseError,
  isCancelError,
  isCustomError,
} from '@/dal'
import { NotificationType } from '@/ui'

import { showToast } from './public'

type Params = {
  effect: Effect<any, any, AxiosError | CustomResponseError | any>
  doneText?: string | ((value: any) => string)
  errorText?: string
}

export const createToast = ({
  doneText,
  effect,
  errorText,
}: Params) => {
  sample({
    clock: (effect as Effect<any, any, AxiosError>).failData,
    filter: (error): error is AxiosError => !isCustomError(error) && !isCancelError(error),
    fn: (payload: AxiosError) => ({
      content: errorText || payload.message || 'Произошла ошибка',
      icon: NotificationType.Alert,
    }),
    target: showToast,
  })

  sample({
    clock: (effect as Effect<any, any, CustomResponseError>).failData,
    filter: (error): error is CustomResponseError => isCustomError(error),
    fn: (payload) => ({
      content: errorText || payload.data.detail,
      icon: NotificationType.Alert,
    }),
    target: showToast,
  })

  sample({
    clock: effect.doneData,
    filter: () => Boolean(doneText),
    fn: (data) => ({
      content: typeof doneText === 'function' ? doneText(data) : doneText,
      icon: NotificationType.Success,
    }),
    target: showToast,
  })
}
