import dayjs from 'dayjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { FileDTO } from '@/dal'
import { formatTimeString, getDateWithMonth, getDayJsObjectWithResetTimeStamp } from '@/lib/date'

import { TextMLight, TextSLight } from '../typography'
import { palette } from '../palette'
import { NewFileItem } from './NewFileItem'
import { Icon2, IconName2 } from '../icons'


type Props = {
  author: string,
  date_time: string,
  body: string,
  isRight?: boolean
  prevMessageDate?: string | null
  files?: FileDTO[]
  avatarIcon?: IconName2
  isFill?: boolean
}

export const MessageItem = ({
  author,
  body,
  date_time,
  isRight,
  prevMessageDate,
  files,
  avatarIcon,
  isFill,
}: Props) => {
  const currentDate = React.useMemo(() => {
    if (!prevMessageDate) {
      return getDateWithMonth(date_time)
    }
    const prevDate = getDayJsObjectWithResetTimeStamp(prevMessageDate)
    const currDate = getDayJsObjectWithResetTimeStamp(date_time)

    const diff = dayjs(currDate).diff(prevDate, 'day')
    return diff ? getDateWithMonth(date_time) : null
  }, [prevMessageDate, date_time])


  return (
    <Wrapper>
      {Boolean(currentDate) && (
        <CurrentDate>
          {currentDate}
        </CurrentDate>
      )}

      <ItemWrapper isRight={isRight}>
        <Item isRight={isRight} isFill={isFill}>
          <Text>
            {body}
          </Text>

          {Boolean(files?.length) && (
            <FileList>
              {files?.map(({ size, ...item }) => (
                <NewFileItem key={item.id} {...item} />
              ))}
            </FileList>
          )}

          <Author>
            {author}
          </Author>
          <TimeStamp>{formatTimeString(date_time)}</TimeStamp>
        </Item>

        {avatarIcon && (
          <Avatar isLeftSide={!isRight}>
            <Icon2 icon={avatarIcon} size={30} />
          </Avatar>
        )}
      </ItemWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

const CurrentDate = styled.div`
  ${TextSLight}
  color: ${palette.grey70};
  text-align: center;
`

const ItemWrapper = styled.div<{ isRight?: boolean }>`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  ${({ isRight }) => isRight && css`
    align-self: flex-end;
  `}
`

const Item = styled.div<{ isRight?: boolean, isFill?: boolean }>`
  position: relative;
  padding: 16px 16px 20px;
  background-color: ${palette.accent10};
  border-radius: 12px 12px 12px 0px;
  align-self: flex-start;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ isRight }) => isRight && css`
    border-radius: 12px 12px 0px 12px;
    background-color: ${palette.grey10};
  `}

  ${({ isFill }) => isFill && css`
    max-width: unset;
    width: 100%;
  `}
`

const Text = styled.div`
  ${TextMLight}
  white-space: pre-line;
  word-break: break-word;
`

const Author = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const FileList = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 0 16px;
`

const TimeStamp = styled.div`
  ${TextSLight}
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: ${palette.grey70};
`

const Avatar = styled.div<{ isLeftSide: boolean }>`
  ${({ isLeftSide }) => isLeftSide && css`
    order: -1;
  `}
`
