import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { DocumentAlias } from '@/dal'
import {
  Icon2,
  TextM,
  TextMLight,
  TextS,
  palette,
} from '@/ui'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'
import { numberWithSpaces } from '@/lib/number-represent'
import { getFullTime } from '@/lib/date'

type Props = {
  id: number
  onSelect: (id: number) => void
  onCancel: (id: number) => void
  status: {
    name: string
    value: string
  }
  title: string
  dateTime: string
  theme: string
  document: {
    type: DocumentAlias;
    id: number;
    name: string;
    sum: string;
  } | null
  project: {
    id: number
    name: string
  } | null
  attachmentsCount: number
  isSelect: boolean
  canCancel?: boolean
}

const statusColorMap = {
  WAIT_FOR_SUPPORT: {
    text: palette.violet100,
    bgc: palette.violet10,
  },
  IN_WORK: {
    text: palette.accent100,
    bgc: palette.accent10,
  },
  CLOSED: {
    text: palette.grey90,
    bgc: palette.grey20,
  },
  CANCELED: {
    text: palette.red100,
    bgc: palette.red10,
  },
}

export const AppealItem = ({
  attachmentsCount,
  dateTime,
  document,
  id,
  onSelect,
  onCancel,
  project,
  status,
  theme,
  isSelect,
  title,
  canCancel,
}: Props) => {
  const DocumentItem = React.useMemo(() => {
    if (!document) return null
    const link = getRouterPathByDocumentAlias(document.type)

    if (link) {
      return (
        <Link
          onClick={(e) => {
            e.stopPropagation()
          }}
          to={`${link}/${document?.id}`}
        >
          <DocumentName>
            {document?.name}
          </DocumentName>
        </Link>
      )
    }
    return null
  }, [document])

  return (
    <ItemWrapper
      onClick={() => onSelect(id)}
      isSelect={isSelect}
    >
      <Top>
        <Status colors={statusColorMap[status.value]}>
          {status.name}
        </Status>
        <Row>
          <TitleWrap>
            <Title>
              {title}
            </Title>
            {Boolean(canCancel) && (
              <TextButton onClick={(e) => {
                e.stopPropagation()
                onCancel(id)
              }}
              >
                Отменить
              </TextButton>
            )}
          </TitleWrap>
          <Time>
            {getFullTime(dateTime)}
          </Time>
        </Row>
        <Row>
          <ThemeWrapper>
            <ThemeSpan>
              Тема
            </ThemeSpan>
            <ThemeText>
              {theme}
            </ThemeText>
          </ThemeWrapper>
          <AttachWrapper>
            <Icon2
              icon="attach"
              color="grey60"
            />
            <AttachSpan>
              {attachmentsCount}
            </AttachSpan>
          </AttachWrapper>
        </Row>
      </Top>

      {document && (
        <Bottom>
          <Col>
            <ProjectName>
              {project?.name}
            </ProjectName>
            {DocumentItem}
          </Col>

          <Col>
            <DocumentSumm>
              СУММА ДОКУМЕНТА, РУБ
            </DocumentSumm>
            <DocumentTotal>
              {numberWithSpaces(document?.sum.toString() || '')}
            </DocumentTotal>
          </Col>

        </Bottom>
      )}

    </ItemWrapper>
  )
}

const TitleWrap = styled.div`
  display: flex;
  gap: 12px;
`

const Title = styled.h5`
  ${TextM}
  transition: color 0.15s linear;

`

const TextButton = styled.div`
  ${TextM}
  display: none;
  cursor: pointer;
  transition: color 0.15s linear;
  @media (hover: hover) {
    &:hover { 
      color: ${palette.accent100};
    }
  }
`

const ItemWrapper = styled.div<{isSelect: boolean}>`
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: background-color 0.15s linear;
  cursor: pointer;

  @media (hover: hover) {
      &:hover {
        background-color: ${palette.grey10};

        & ${Title} {
          color: ${palette.accent100};
        }

        & ${TextButton} {
          display: block;
        }
      }
    }

  ${({ isSelect }) => isSelect && css`
    background-color: ${palette.yellow10};

    & ${Title} {
      color: ${palette.accent100};
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${palette.yellow10};
      }
    }
  `}

`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Status = styled.div<{colors?: {text: string, bgc: string}}>`
  ${TextS}
  border-radius: 2px;
  width: max-content;
  padding: 0 2px;
  color: ${palette.accent100};
  background: ${palette.accent10};

  ${({ colors }) => colors && css`
    color: ${colors.text};
    background: ${colors.bgc};
  `}

`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Time = styled.time`
  ${TextMLight}
  color: ${palette.grey70};
`

const ThemeWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const ThemeSpan = styled.span`
  ${TextMLight}
  color: ${palette.grey70};
`

const ThemeText = styled.p`
  ${TextMLight}
`

const AttachWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: ${palette.grey60};
`

const AttachSpan = styled.span`
  ${TextMLight}
  color: ${palette.grey60};
`

const Bottom = styled(Row)``

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const ProjectName = styled.p`
  ${TextMLight}
`

const DocumentName = styled.p`
  ${TextMLight};
  color: ${palette.accent100};
  cursor: pointer;
`

const DocumentSumm = styled.p`
  ${TextS}
  color: ${palette.grey60};
`

const DocumentTotal = styled.p`
  ${TextMLight}
  text-align: right;
`
