import React from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'effector-react'

import { Summ } from '@/dal'
import { TextM, TextMLight, palette } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { TableTemplate } from '../parts'
import { $sums, getAvansSumsFx } from '../../../model/table.private'

export const TableHeader = React.memo(() => {
  const sums = useStore($sums)
  const isLoadingSums = useStore(getAvansSumsFx.pending)

  const getCol = (key: keyof Summ, isHighlighted?: boolean) => {
    if (!sums?.[key] || isLoadingSums) return <ColSpan>—</ColSpan>
    return <ColSpan isHighlight={isHighlighted}>{numberWithSpaces(sums[key])}</ColSpan>
  }

  return (
    <Header>
      <Col>Статья оборотов</Col>
      <Col>
        Сумма договора
        {getCol('sum_contract')}
      </Col>
      <Col>
        Лимит аванса
        {getCol('sum_avans')}
      </Col>
      <Col>
        Выплачено аванса
        {getCol('avans_paid')}
      </Col>
      <Col>
        Аванс на согласовании
        {getCol('sum_advance_on_agreed')}
      </Col>
      <Col>
        Зачтено аванса
        {getCol('avans_offset')}
      </Col>
      <Col>
        Остаток лимита
        {getCol('avans_remain')}
      </Col>
      <Col>
        Запрос аванса
        {getCol('sum_avans_request', true)}
      </Col>
    </Header>
  )
})

const Header = styled.div`
  ${TableTemplate}
`

const Col = styled.div`
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  background-color: ${palette.grey10};
  ${TextM}

  &:first-child {
    padding-left: 20px;
    align-items: flex-start;
    justify-content: center;
  }

  &:not(:first-child) {
    text-align: right;
  }

  &:last-child {
    padding-right: 20px;
  }

  &:not(:last-child) {
    border-right: 1px solid ${palette.grey40};
  }
`

const ColSpan = styled.span<{ isHighlight?: boolean }>`
  ${TextMLight}
  color: ${palette.grey70};

  ${({ isHighlight }) => isHighlight && css`
    ${TextM}
    color: ${palette.accent100}
  `}
`
