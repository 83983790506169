import { sample, forward } from 'effector'
import { condition } from 'patronum'

import { createToast } from '@/features/toast-service/model'
import { DeleteResetTableItemParams, EstimateDoc } from '@/dal'

import { $documentId, onUpdateSummary, refetchEstimateDocument } from '../../shared-model'
import { $isTreeTable, TableGate } from './private'
import { getTableData, onItemUpdate } from './public'
import {
  $isResetTableConfirmOpen,
  onResetTable,
  closeResetTableConfirm,
  resetTableFx,
  $resetTargetItemId,
  onConfirmReset,
  resetItem,
  resetTable,
  resetTableItemFx,
} from './reset-table-item.private'

$isResetTableConfirmOpen
  .on(onResetTable, () => true)
  .reset(TableGate.close, closeResetTableConfirm, resetTableFx.finally)

$resetTargetItemId
  .on(onResetTable, (_, id) => id || null)
  .reset(TableGate.close, closeResetTableConfirm)

condition({
  source: onConfirmReset,
  if: $resetTargetItemId.map((id) => Boolean(id)),
  then: resetItem,
  else: resetTable,
})

sample({
  clock: resetTable,
  source: $documentId,
  filter: Boolean,
  fn: (documentId) => documentId,
  target: resetTableFx,
})

sample({
  clock: resetItem,
  source: {
    documentId: $documentId,
    itemId: $resetTargetItemId,
    isTree: $isTreeTable,
  },
  filter: ({ documentId, itemId }) => Boolean(documentId && itemId),
  fn: (params) => params as DeleteResetTableItemParams,
  target: resetTableItemFx,
})

forward({
  from: [resetTableFx.done, resetTableItemFx.done],
  to: [refetchEstimateDocument, closeResetTableConfirm],
})

forward({
  from: resetTableFx.done,
  to: getTableData,
})

sample({
  clock: resetTableItemFx.done,
  fn: ({ params, result }) => ({
    documentId: params.documentId,
    ...result,
  }),
  target: onItemUpdate,
})

sample({
  clock: resetTableItemFx.done,
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({ docId: params.documentId, summary: result.summary as EstimateDoc['summary_table'] }),
  target: onUpdateSummary,
})

createToast({
  effect: resetTableFx,
  doneText: 'Изменения в документе сброшены',
})

createToast({
  effect: resetTableItemFx,
  doneText: 'Изменения успешно сброшены',
})
