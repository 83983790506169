import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { NewButton, NewFileItem } from '@/ui'
import { useShortItems } from '@/lib/hooks/useShortItems'

import { $openedArticle } from '../../model/private'

const $openedArticleFiles = $openedArticle
  .map((item) => (item?.files?.length ? item?.files : null))

export const FileList = React.memo(() => {
  const files = useStore($openedArticleFiles)

  const {
    setIsExpand,
    visibleItems,
    isExpand,
  } = useShortItems({ source: files, shortCount: 4 })

  return (
    <Wrapper>

      <List>
        {visibleItems?.map(({ file, name }) => {
          const url = file.split('/media')[1]
          return (
            <NewFileItem url={`/media${url}`} name={name} size="L" />
          )
        })}
      </List>

      {!isExpand && (files?.length ?? 0) > 4 && (
        <NewButton
          isFill
          dataTestId="show-article-all-files"
          label="Показать все файлы"
          buttonType="line-grey"
          onClick={() => setIsExpand(true)}
        />
      )}

    </Wrapper>
  )
})

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 16px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

