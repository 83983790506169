import { defaultErrorHandler } from '../error-handler'
import { HasInvalidTableItems, HasNewDocConflict } from './errors'
import { EstimateCorrectionErrorType, EstimateErrorsType } from './types'

export const estimateCorrectionErrorHandler = (error: EstimateErrorsType | undefined):
  EstimateErrorsType | ReturnType<typeof defaultErrorHandler> => {
  switch (error?.code) {
    case EstimateCorrectionErrorType.VALIDATION_ERROR: {
      return new HasInvalidTableItems(error)
    }
    case EstimateCorrectionErrorType.KOR_DOC_NEW_CONFLICT: {
      return new HasNewDocConflict(error)
    }
    default: {
      return defaultErrorHandler(error)
    }
  }
}
