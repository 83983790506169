import {
  AdministrativeAppealItemType,
  getAdministrativeAppealInfoReqFx,
  getAdministrationCommentsReqFx,
  AdministrationAppealStatus,
} from '@/dal'
import { createAppealPageModel } from '@/features/create-appeals-list/model'

import { d } from './domain'
import { ResponsibleRole } from './types'
import { appealResponsibleRoles } from './const'

export const $responsibleRoles = d.store<ResponsibleRole[]>(appealResponsibleRoles)

export const $appealTypes = $responsibleRoles.map((roles) => (
  roles.map(({ alias, name }) => ({ name, alias }))),
)

export const {
  filterModel,
  listModel,
  commentsModel,
} = createAppealPageModel<AdministrativeAppealItemType, AdministrationAppealStatus>({
  domain: d,
  getCommentsFx: getAdministrationCommentsReqFx,
  $filterItems: $appealTypes,
  getItemsFx: getAdministrativeAppealInfoReqFx,
  postCommentFx: d.effect(),
})
