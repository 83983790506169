import { useStore } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { Button, IconButton } from '@/ui'
import { DocumentStatuses } from '@/dal'
import { openPlanAgreedContractorAccept } from '@/features/funding-schedule/plan-page/agreed-contractor-to-accept/model'
import { openPlanAgreedContractorCancel } from '@/features/funding-schedule/plan-page/agreed-contractor-to-cancel/model'

import { $openedFundPlan, $openedFundPlanStatus } from '../../model'
import {
  downloadPlanReportFileFx,
  downloadReportFile,
  filesModalModel,
  loadTemplate,
  loadTemplateFx,
  onCopyPlan,
  onDownloadTemplate,
  onSendToApprove,
  sendPlanToApproveFx,
} from '../../model/private'

export const HeaderButtons = React.memo(() => {
  const plan = useStore($openedFundPlan)
  const status = useStore($openedFundPlanStatus)
  const downloading = useStore(downloadPlanReportFileFx.pending)
  const isApprovePending = useStore(sendPlanToApproveFx.pending)
  const fileInputRef = React.createRef<HTMLInputElement>()
  const isUploading = useStore(loadTemplateFx.pending)

  if (!plan || !status) return null

  const { features } = plan
  const isDraft = status === DocumentStatuses.Draft

  const openFileInput = () => {
    fileInputRef.current?.click()
  }

  return (
    <>
      <IconButton
        onClick={() => filesModalModel.openModal()}
        size={45}
        iconSize={28}
        borderColor="grey"
        dataTestId="button-download-funding"
        icon="folder-icon"
      />
      {isDraft && (
        <>
          <InputFile
            ref={fileInputRef}
            onInput={(e: any) => loadTemplate(e.target.files)}
            accept=".xlsx"
            multiple={false}
            data-testid="input-act-add-pattern-file"
          />

          <IconButton
            onClick={openFileInput}
            size={45}
            dataTestId="button-template-upload"
            tooltip="Загрузить шаблон"
            icon="template-upload"
            borderColor="grey"
            iconSize={20}
            isLoading={isUploading}
          />

          <IconButton
            onClick={onDownloadTemplate}
            size={45}
            dataTestId="button-template-download"
            tooltip="Скачать шаблон"
            icon="template-download"
            borderColor="grey"
            iconSize={20}
            isLoading={isUploading}
          />
        </>
      )}

      <IconButton
        onClick={downloadReportFile}
        size={45}
        iconSize={30}
        borderColor="grey"
        tooltip="Скачать документы"
        dataTestId="button-download-funding"
        isLoading={downloading}
        icon="download"
      />

      {features.can_create_copy && (
        <Button
          size="M"
          styleButton="primary"
          onClick={onCopyPlan}
          dataTestId="button-plan-to-copy"
        >
          Создать копию
        </Button>
      )}

      {isDraft && (
        <Button
          styleButton="primary"
          width="210px"
          size="M"
          onClick={onSendToApprove}
          isLoading={isApprovePending}
          dataTestId="button-funding-send-to-agreed"
        >
          Отправить на согласование
        </Button>
      )}

      {[DocumentStatuses.AgreedContractor, DocumentStatuses.DocumentsWaiting].includes(status) && (
        <Button
          styleButton="primary"
          size="M"
          onClick={openPlanAgreedContractorAccept}
          dataTestId="button-funding-to-agreed"
        >
          Согласовать
        </Button>
      )}

      {![
        DocumentStatuses.Approved,
        DocumentStatuses.Draft,
        DocumentStatuses.Completed,
        DocumentStatuses.Declined,
        DocumentStatuses.OnCancellation,
        DocumentStatuses.BudgetIsPlanned,
      ].includes(status) && (
        <Button
          width="180px"
          styleButton="secondary"
          size="M"
          onClick={openPlanAgreedContractorCancel}
          dataTestId="button-funding-to-cancel"
        >
          Отменить согласование
        </Button>
      )}
    </>
  )
})

const InputFile = styled.input.attrs({
  type: 'file',
})`
  width: 0px;
  height: 0px;
  position: absolute;
`
