import React from 'react'

import { NewTextButton, NotificationItem, NotificationType } from '@/ui'

type Props = {
  templateLink: string
  isThirdParties?: boolean
}

const thirdPartiesText = 'Приложите счёт. Распорядительное письмо будет сформировано электронно. Для проверки шаблона распорядительного письма можете его скачать'
const smrText = 'Счёт на оплату будет сформирован автоматически. Дата счёта будет сформирована автоматически. Номер счёта укажет ваш бухгалтер при подписании в ДИАДОК. Для проверки шаблона счёта можете его скачать'

export const PaymentDocsNotification = ({ templateLink, isThirdParties = false }: Props) => {
  return (
    <NotificationItem
      body={(
        <>
          {isThirdParties ? thirdPartiesText : smrText}
          {' '}
          <NewTextButton
            label="по ссылке"
            url={templateLink}
            dataTestId="payment-template-link"
            textStyle="M"
            isInline
            isDownload
            hasUnderline
          />
          .
        </>
      )}
      type={NotificationType.Info}
    />
  )
}
