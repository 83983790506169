import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Scrollbar } from 'react-scrollbars-custom'
import { useGate, useStore } from 'effector-react'

import {
  Icon2,
  Loader,
  TextL,
  palette,
  AppealItem,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { noop } from '@/lib/noop'
import { getAppealDocInfo } from '@/lib/getAppealDocInfo'

import {
  $appealItems,
  $appealsFilters,
  $selectedAppealFilter,
  AppealResponsesGate,
  getAppealResponsesFx,
  selectAppealFilter,
} from '../../model/private'
import { Tabs } from '../parts'

const $mappedTabs = $appealsFilters.map(
  (filters) => filters.map(({ value, label }) => ({ label, id: value })),
)

export const AppealResponses = React.memo(() => {
  const items = useStore($appealItems)
  const tabs = useStore($mappedTabs)
  const selectedFilter = useStore($selectedAppealFilter)
  const isPending = useStore(getAppealResponsesFx.pending)

  const isEmpty = items?.length === 0

  useGate(AppealResponsesGate)

  return (
    <Wrapper>
      <Tabs
        selectedId={selectedFilter}
        items={tabs}
        onItemClick={selectAppealFilter as any}
      />
      {isPending ? (
        <Loader />
      ) : (
        <>
          {isEmpty ? (
            <EmptyList>
              <Icon2 icon="emptyTasks" size={170} />
              <EmptySubtitle>У вас нет обращений</EmptySubtitle>
            </EmptyList>
          ) : (
            <List>
              <Scrollbar>
                {items?.map((item) => (
                  <Link to={RouterPaths.AdministrationAppeals} target="_blank" key={item.id}>
                    <AppealItem
                      attachmentsCount={item.attachments.length}
                      dateTime={item.created_date}
                      id={item.id}
                      docInfo={getAppealDocInfo(item)}
                      responsible={item.executor}
                      onMarkAsRead={noop}
                      onSelect={noop}
                      title={item.request_number}
                      description={item.description}
                      isRead={item.is_read}
                      hasAnswer={item.is_found_answer}
                      type="secondary"
                    />
                  </Link>
                ),
                )}
              </Scrollbar>
            </List>
          )}
        </>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const EmptyList = styled.div`
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const EmptySubtitle = styled.p`
  ${TextL}
  color: ${palette.grey60};
`

const List = styled.div`
  flex-grow: 1;
`
