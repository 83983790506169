import { useGate, useStore } from 'effector-react'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { SupportAppealTypeAlias } from '@/dal'
import {
  $selectedAppealType,
  FormGate,
} from '../../model/private'
import { ProblemWithApproval } from './ProblemWithApproval'
import { AddUserForm } from './AddUserForm'
import { BlockUserForm } from './BlockUserForm'
import { OtherProblemForm } from './OtherProblemForm'

export const Forms = React.memo(() => {
  const router = useRouteMatch<{alias: SupportAppealTypeAlias}>()
  useGate(FormGate, router.params.alias)

  const type = useStore($selectedAppealType)

  const Form = React.useMemo(() => {
    if (type?.alias === SupportAppealTypeAlias.PROBLEMS_WITH_APPROVAL) {
      return <ProblemWithApproval />
    }
    if (type?.alias === SupportAppealTypeAlias.ADD_NEW_USER) {
      return <AddUserForm />
    }
    if (type?.alias === SupportAppealTypeAlias.DISABLE_USER) {
      return <BlockUserForm />
    }
    return <OtherProblemForm />
  }, [type])

  return Form
})
