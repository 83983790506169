import React from 'react'

import { noop } from '@/lib/noop'

import { NewInput } from './NewInput'
import { MultiSelectPopup } from '../components/popup-components/MultiSelectPopup'

type Props = {
  onAcceptClick: (ids: any[]) => void
  disabled?: boolean
  items: {
    id: any,
    label: string
  }[]
  testId: string
  selectedIds: any[]
  placeholder: string
  hasAcceptOnClear?: boolean
}

export const MultiSelectInput = ({
  items,
  testId,
  selectedIds,
  disabled,
  placeholder,
  hasAcceptOnClear,

  onAcceptClick,
}: Props) => {
  const label = React.useMemo(() => {
    return items.filter((item) => selectedIds.includes(item.id))
      .map((item) => item.label).join(', ')
  }, [selectedIds, items])

  const TriggerComponent = React.useCallback(({ isOpen }) => (
    <NewInput
      onChange={noop}
      readOnly
      value={label}
      postfixIcon={isOpen ? 'arrowUp' : 'arrowDown'}
      dataTestId=""
      selectedItemsCount={selectedIds.length || undefined}
      placeholder={placeholder}
      disabled={disabled}
    />
  ), [label, selectedIds, disabled])

  return (
    <MultiSelectPopup
      items={items}
      onAcceptClick={onAcceptClick}
      selectedIds={selectedIds}
      testId={testId}
      triggerComponent={TriggerComponent}
      disabled={disabled}
      hasAcceptOnClear={hasAcceptOnClear}
    />
  )
}
