import { createForm } from 'effector-forms'
import { attach, combine } from 'effector'

import { rules, withDisableCondition } from '@/lib/form-validators'
import { sendEstimateToApproveReqFx } from '@/dal'

import { d } from './domain'
import { WithComment } from './types'

export const MAX_COMMENT_LENGHT = 1000

export const $documentId = d.store<number|null>(null)
export const closeModal = d.event<void>()

export const $isSecondApproving = d.store(false)

export const $tabItems = d.store([{ label: 'Без комментария', value: WithComment.No }, { label: 'С комментарием', value: WithComment.Yes }])
export const $isWithComment = d.store<WithComment>(WithComment.No)
export const changeWithComment = d.event<WithComment>()

const isCommentRequired = () => {
  const isWithComment = $isWithComment.getState()
  return isWithComment === WithComment.Yes
}

export const approveForm = createForm({
  fields: {
    comment: {
      init: '',
      rules: [
        withDisableCondition(isCommentRequired, rules.required()),
        rules.maxLength(MAX_COMMENT_LENGHT),
      ],
    },
  },
})

export const secondApprovingForm = createForm({
  fields: {
    dsFile: {
      init: null as null | File,
    },
    gfprFile: {
      init: null as null | File,
    },
    gprFile: {
      init: null as null | File,
    },
  },
})

export const approveDocumentFx = attach({
  effect: sendEstimateToApproveReqFx,
})

export const $canSendSecondApprove = combine(
  secondApprovingForm.$values,
  (fields) => Object.keys(fields).some((key) => Boolean(fields[key])),
)
