import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { useTooltip } from '@/lib/hooks'

import { PaletteColor, palette } from '../palette'
import { Icon2, IconName2 } from '../icons'
import { DefaultButton } from './DefaultButton'
import { ToolTipV2 } from '../components/tooltip/ToolTipV2'

type Props = {
  color?: PaletteColor | 'inherit'
  hoverColor?: PaletteColor
  activeColor?: PaletteColor
  disabledColor?: PaletteColor
  icon: IconName2,
  size: number,
  padding?: string;
  backgroundColor?: PaletteColor | 'transparent'
  hoverBackgroundColor?: PaletteColor | 'transparent'
  borderRadius?: string;
  disabled?: boolean
  borderColor?: PaletteColor
  hoverBorderColor?: PaletteColor
  dataTestId: string
  hasShadow?: boolean
  tooltipText?: string,
  onClick?: (e: MouseEvent) => void
}

export const NewIconButton = ({
  color = 'inherit',
  hoverColor,
  activeColor,
  icon,
  size,
  padding,
  backgroundColor = 'transparent',
  hoverBackgroundColor = 'transparent',
  borderRadius,
  disabled,
  disabledColor = 'grey50',
  borderColor,
  hoverBorderColor,
  dataTestId,
  hasShadow,
  tooltipText,
  onClick,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({})

  return (
    <>
      <Wrapper
        {...getReferenceProps()}
        ref={refs.setReference}
        hasShadow={hasShadow}
        type="button"
        hoverColor={hoverColor}
        activeColor={activeColor}
        color={color}
        padding={padding}
        backgroundColor={backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor}
        borderRadius={borderRadius}
        disabled={disabled}
        disabledColor={disabledColor}
        borderColor={borderColor}
        hoverBorderColor={hoverBorderColor}
        data-testid={dataTestId}
        onClick={onClick}
      >
        <Icon2 color="inherit" size={size} icon={icon} />
      </Wrapper>
      {tooltipText && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltipText}
        </ToolTipV2>
      )}
    </>
  )
}

const Wrapper = styled(DefaultButton)<{
  hoverColor?: PaletteColor
  color: PaletteColor | 'inherit'
  activeColor?: PaletteColor
  padding?: string;
  backgroundColor: PaletteColor | 'transparent'
  hoverBackgroundColor: PaletteColor | 'transparent'
  borderRadius?: string;
  disabledColor: PaletteColor
  borderColor?: PaletteColor
  hoverBorderColor?: PaletteColor
  hasShadow?: boolean
}>`
  width: max-content;
  height: max-content;
  color: inherit;
  display: inline-flex;
  cursor: pointer;
  transition: 0.15s linear;
  transition-property: color, background-color, border-color;
  box-shadow: ${({ hasShadow }) => (hasShadow ? `0px 3px 10px 0px ${palette.shadow}` : 'none')};
  color: ${({ color }) => (color !== 'inherit' ? palette[color] : color)};
  padding: ${({ padding }) => padding || '0px'};
  background-color: ${({ backgroundColor }) => (
    backgroundColor === 'transparent' ? backgroundColor : palette[backgroundColor]
  )};

  ${({ borderColor, hoverBorderColor }) => (borderColor || hoverBorderColor) && css`
    border: 1px solid ${borderColor ? palette[borderColor] : 'transparent'};
  `}

  ${({ borderRadius }) => borderRadius && css`
    border-radius: ${borderRadius};
  `}

  &:disabled {
    cursor: not-allowed;
    color: ${({ disabledColor }) => palette[disabledColor]};
  }

  &:not(:disabled):hover {
    color:  ${({ hoverColor }) => hoverColor && palette[hoverColor]};
    background-color:  ${({ hoverBackgroundColor }) => (
    hoverBackgroundColor === 'transparent' ? hoverBackgroundColor : palette[hoverBackgroundColor]
  )};
    border-color: ${({ hoverBorderColor }) => (hoverBorderColor ? palette[hoverBorderColor] : 'transparent')};
  }

  &:not(:disabled):active {
    ${({ activeColor }) => activeColor && css`
      color: ${palette[activeColor]};
    `}
  }
`
