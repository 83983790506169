import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  Dropdown,
  IconButton,
  UiSearchInput,
} from '@/ui'
import {
  clearPlanFilter,
  $planSearchText,
  planSearchWorks,
  setPlanWorkListSearchText,
  resetSearchText,
  filterWorkForm,
  $analystArticleItems,
} from '../../model/private'

export const WorkFilter = () => {
  const planSearchText = useStore($planSearchText)
  const { fields } = useForm(filterWorkForm)
  const analystItems = useStore($analystArticleItems)

  const handleSearch = () => {
    planSearchWorks()
  }

  const analystOptions = React.useMemo(() => {
    const options = analystItems?.map(({ id, name }) => ({
      value: id.toString(),
      text: name,
    }))
    return [
      { value: null, text: 'Статья аналитики: не выбрано' },
      ...(options || []),
    ]
  }, [analystItems])

  return (
    <Row>
      <UiSearchInput
        value={planSearchText}
        onChange={setPlanWorkListSearchText}
        placeholder="Поиск"
        onClickSearchButton={handleSearch}
        dataTestId="input-funding-search"
        onClear={resetSearchText}
      />

      <Dropdown
        options={analystOptions}
        onOptionChange={fields.analystArticle.onChange}
        selected={fields.analystArticle.value}
        placeholder="Статья аналитики: не выбрано"
        dataTestId="fs-works-filter-select-analyst-article"
      />

      <Dropdown
        options={[
          { value: null, text: 'Прогноз месяца: не выбрано' },
          { value: 'is_not_zero', text: 'Не нулевые значения' },
        ]}
        onOptionChange={fields.forecast.onChange}
        selected={fields.forecast.value}
        placeholder="Прогноз месяца: не выбрано"
        dataTestId="fs-works-filter-select-forecast"
      />

      <Dropdown
        options={[
          { value: null, text: 'Остаток (начало мес.): не выбрано' },
          { value: 'not_null', text: 'Не пустые' },
          { value: 'positive', text: 'Только положительные' },
          { value: 'negative', text: 'Только отрицательные' },
          { value: 'is_zero', text: 'Равные нулю' },
        ]}
        onOptionChange={fields.residue.onChange}
        selected={fields.residue.value}
        placeholder="Остаток (начало мес.): не выбрано"
        dataTestId="fs-works-filter-select-residue"
      />

      <Dropdown
        options={[
          { value: null, text: 'Плановый месяц: не выбрано' },
          { value: 'is_filled', text: 'Заполненные' },
          { value: 'positive', text: 'Только положительные' },
          { value: 'negative', text: 'Только отрицательные' },
          { value: 'is_zero', text: 'Равные нулю' },
        ]}
        onOptionChange={fields.plannedMonth.onChange}
        selected={fields.plannedMonth.value}
        placeholder="Плановый месяц: не выбрано"
        dataTestId="fs-works-filter-select-planned_month"
      />

      <IconButton
        size={45}
        onClick={() => clearPlanFilter()}
        borderColor="gray4"
        dataTestId="button-funding-clear-filter"
        icon="cross"
      />
    </Row>
  )
}

const Row = styled.div`
  min-height: 45px;
  margin: 16px 0;
  gap: 16px;
  width: 100%;

  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 45px;
`
