import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import {
  HeadXL,
  InputField,
  ModalLayout,
  NewButton,
  NewIconButton,
  palette,
} from '@/ui'

import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import {
  $isVisibleShareModal,
  closeShareModal,
  sendArticleForm,
  sendArticleToEmailFx,
} from '../../model/private'

export const ShareArticleModal = React.memo(() => {
  const isVisible = useStore($isVisibleShareModal)
  const { fields, submit, isValid } = useForm(sendArticleForm)
  const isPending = useStore(sendArticleToEmailFx.pending)

  useOnChangeLocation(closeShareModal)

  if (!isVisible) return null

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <ModalLayout closeModal={closeShareModal}>
      <Wrapper onSubmit={handleSubmit}>
        <Title>Поделиться новостью</Title>

        <CloseWrapper>
          <NewIconButton
            icon="cross"
            dataTestId="close-article-modal-button"
            color="grey60"
            hoverColor="grey90"
            size={20}
            padding="6px"
            onClick={() => closeShareModal()}
          />
        </CloseWrapper>

        <InputField
          label=""
          placeholder="Введите почту"
          dataTestId="send-article-input"
          onChange={fields.email.onChange}
          value={fields.email.value}
          size="L"
          error={fields.email.firstError?.errorText}
        />

        <Row>
          <NewButton
            isDisabled={!isValid}
            isPending={isPending}
            dataTestId="submit-share-article-button"
            buttonType="primary"
            label="Отправить"
            type="submit"
          />

          <NewButton
            dataTestId="cancel-share-article-button"
            buttonType="grey"
            label="Отменить"
            onClick={closeShareModal}
          />

        </Row>

      </Wrapper>
    </ModalLayout>
  )
})

const Wrapper = styled.form`
  position: relative;
  background-color: ${palette.white};
  padding: 32px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  border-radius: 20px;
  width: 450px;
`

const Title = styled.h5`
  ${HeadXL}
`

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-end;
`

const CloseWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`
