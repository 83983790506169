import React from 'react'
import styled from 'styled-components'
import { useGate, useStore } from 'effector-react'

import {
  HeadL,
  NewTextButton,
  TextL,
  palette,
} from '@/ui'
import {
  $articleTotal,
  ArticleGate,
  openArticlesModal,
} from '../../model/private'
import { ArticlesSlider } from '../containers'
import { $newArticleCount } from '../../model'

export const Articles = React.memo(() => {
  const articleTotal = useStore($articleTotal)
  const notificationCount = useStore($newArticleCount)

  const unreadArticlesCount = React.useMemo(() => {
    if (!articleTotal) return 0
    return articleTotal - (notificationCount || 0)
  }, [notificationCount, articleTotal])

  useGate(ArticleGate)

  return (
    <Wrapper>
      <Row>
        <Title>
          Новости
          <ArticleCount>
            {unreadArticlesCount}
            {Boolean(notificationCount) && (
              <>
                /
                <UnreadArticleCount>
                  +
                  {notificationCount}
                </UnreadArticleCount>
              </>
            )}
          </ArticleCount>
        </Title>
        <NewTextButton
          label="Все новости"
          postfixIcon="arrowRightS"
          onClick={openArticlesModal}
          dataTestId="open-articles-btn"
        />
      </Row>
      <ArticlesSlider />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`

const Title = styled.h3`
  ${HeadL}
`

const ArticleCount = styled.span`
  margin-left: 8px;
  ${TextL}
  color: ${palette.grey60};
`

const UnreadArticleCount = styled.span`
  color: ${palette.accent100};
`
