
import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedField } from 'effector-forms'

import { numberWithSpaces } from '@/lib/number-represent'
import {
  CellNumberInput,
  CellTextArea,
  Icon2,
  InlineInputDropDown,
  Option,
  OverflowText,
  TextMLight,
  palette,
} from '@/ui'
import { EstimateNumberCell, EstimateStringCell, EstimateTableRow } from '@/dal'
import { getNoun } from '@/lib/text-noun'

import { TABLE_BORDER_STYLE } from '../../../model/const'
import { NonFormField } from '../../../model'

type Props = {
  isEmpty?: boolean
  isEditMode?: boolean
  isGray?: boolean
  isActive?: boolean
  isNumber?: boolean
  decimalScale?: number
  field?: ConnectedField<any> | NonFormField<unknown>
  options?: Option[]
  placeholder?: string
  itemId: EstimateTableRow['id']
  cellColor: string | null
  hasRightDivider?: boolean
} & ({
  isNumber?: false | undefined
  cellInfo?: EstimateStringCell | null
  hasFileAttach?: boolean
  fileCount?: number
  onOpenAttacments?: () => void
} | {
  isNumber?: true
  cellInfo?: EstimateNumberCell | null
  hasFileAttach?: false
  fileCount?: number
  onOpenAttacments?: () => void
})

export const TableCell = ({
  isActive,
  isEditMode = false,
  isEmpty,
  isGray,
  isNumber,
  cellInfo,
  fileCount,
  hasFileAttach,
  options,
  field,
  decimalScale = 2,
  placeholder,
  itemId,
  cellColor,
  hasRightDivider,
  onOpenAttacments,
}: Props) => {
  if (isEmpty || !cellInfo) {
    return (
      <Wrapper
        isGray={isGray}
        cellColor={cellColor}
        className="cell"
        hasRightDivider={Boolean(hasRightDivider)}
      />
    )
  }

  const align = isNumber ? 'right' : options ? 'center' : 'left'

  const isEdit = Boolean(isEditMode && cellInfo.editable)

  if (!isNumber) {
    const { value } = cellInfo as EstimateStringCell

    const getValue = () => {
      return (
        <OverflowText maxLines={4}>
          {value}
        </OverflowText>
      )
    }

    const getField = () => {
      if (!field) return getValue()
      if (options) {
        return (
          <InlineInputDropDown
            dataTestId={`${field.name}-input`}
            onOptionClick={field.onChange}
            options={options}
            selectedId={field.value}
            isInvalid={!field.isValid}
            placeholder={placeholder}
            sameComponentWidth={false}
            listWidth="150px"
          />
        )
      }
      return (
        <CellTextArea
          dataTestId={`${field.name}-input`}
          onChange={field.onChange}
          value={field.value}
          autoGrow
          isInvalid={!field.isValid}
          placeholder={placeholder}
          maxHeight={650}
          maxLines={4}
        />
      )
    }
    return (
      <Wrapper
        isGray={isGray}
        isActive={Boolean(isActive)}
        align={align}
        data-testid={field ? `${field.name}-${itemId}` : undefined}
        cellColor={cellColor}
        isEditMode={isEdit}
        className="cell"
        hasRightDivider={Boolean(hasRightDivider)}
      >
        <TextWrapper>
          {isEdit ? getField() : getValue()}

          {hasFileAttach && fileCount !== undefined && (
            <FileRow hasMargin={isEditMode} onClick={onOpenAttacments}>
              <FileInfo>
                {`${fileCount} ${getNoun(fileCount, 'файл', 'файла', 'файлов')}`}
              </FileInfo>
              <Icon2
                icon="attach"
                size={16}
              />
            </FileRow>
          )}
        </TextWrapper>
      </Wrapper>
    )
  }
  const {
    value_adjusted,
    value_approved,
    value_current,
    value_delta,
  } = cellInfo
  const isEquals = value_current === value_adjusted
  const isPositiveDelta = !isEquals && parseFloat(value_adjusted || '0.00') > parseFloat(value_current || '0.00')

  const currentValue = value_adjusted || value_current
  const oldValue = value_adjusted ? value_current : value_approved

  return (
    <Wrapper
      isGray={isGray}
      isActive={Boolean(isActive)}
      align={align}
      data-testid={field ? `${field.name}-${itemId}` : undefined}
      cellColor={cellColor}
      isEditMode={isEdit}
      hasRightDivider={Boolean(hasRightDivider)}
      className="cell"
    >

      {isEdit && field
        ? (
          <CellNumberInput
            dataTestId={`${field.name}-input`}
            onChange={field.onChange}
            inputValue={field.value}
            displayValue={field.value}
            isInvalid={!field.isValid}
            decimalScale={decimalScale}
            fixedDecimalScale
            textAlign={align}
            allowLeadingZeros={false}
            placeholder={placeholder}
            padding="12px"
            popupZIndex={3}
          />
        ) : (
          <>
            <TopWrapper>
              {currentValue && (
                <CurrentValue>
                  {numberWithSpaces(currentValue, { decimalScale })}
                </CurrentValue>
              )}
              {oldValue && (
                <OldValue>
                  {numberWithSpaces(oldValue, { decimalScale })}
                </OldValue>
              )}
              {value_delta && (
                <Delta>
                  {`${isEquals ? '' : isPositiveDelta ? '+' : '-'} ${numberWithSpaces(value_delta, { decimalScale })}`}
                </Delta>
              )}
            </TopWrapper>

            {value_approved && (
              <ApproveValue>
                {numberWithSpaces(value_approved, { decimalScale })}
              </ApproveValue>
            )}
          </>
        )}
    </Wrapper>
  )
}

type WrapperProps = {
  isActive?: boolean
  align?: 'center' | 'right' | 'left'
  isGray?: boolean
  cellColor: string | null
  isEditMode?: boolean
  hasRightDivider: boolean
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  padding: 12px;
  ${TextMLight}
  color: ${({ isActive }) => (isActive ? palette.grey90 : palette.grey70)};
  text-align: ${({ align }) => align || 'inherit'};
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: ${TABLE_BORDER_STYLE};
  ${({ hasRightDivider }) => hasRightDivider && css`
    border-width: 4px;
  `}
  transition: 0.15s linear;
  transition-property: background-color, border-color;
  border-bottom: 1px solid transparent;

  &:last-child:not(:first-child) {
    border-right: none;
  }

  ${({ isGray }) => isGray && css`
    background-color: ${palette.grey10};
  `}

  ${({ cellColor }) => cellColor && css`
    background-color: ${cellColor};
  `}
  ${({ isEditMode }) => isEditMode && css`
    padding: 0;
    background-color: ${palette.white};
    border-bottom-color: ${palette.accent100};
  `}
`

const TopWrapper = styled.div``

const CurrentValue = styled.div``

const OldValue = styled.div`
  color: ${palette.grey70};
  text-decoration: line-through;
`

const Delta = styled.div`
  color: ${palette.accent100};
`

const ApproveValue = styled.div`
  padding: 0px 2px;
  background-color: ${palette.accent100};
  border-radius: 2px;
  color: ${palette.white};
  margin-left: auto;
  margin-right: 0;
  width: max-content;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const FileRow = styled.div<{ hasMargin: boolean }>`
  display: flex;
  justify-content: space-between;
  ${TextMLight}
  color: ${palette.accent100};
  cursor: pointer;

  ${({ hasMargin }) => hasMargin && css`
    margin: 0 12px 12px;
  `}
`

const FileInfo = styled.div``
