import React from 'react'
import styled from 'styled-components'

import { Item, OnTabClick, TabSize } from './types'
import { SwiperWrapper } from '../SwiperWrapper'
import { TabItem } from './TabItem'
import { palette } from '../../palette'

type Props = {
  onItemClick: OnTabClick
  items: Item[] | null
  activeId: Item['id'] | null
  disableArrow?: boolean
  size?: TabSize
}

export const TabSwiper = ({
  activeId,
  items,
  onItemClick,
  size = 'M',
  disableArrow,
}: Props) => {
  const renderFunc = React.useCallback((item) => (
    <TabItem
      item={item}
      activeId={activeId}
      onItemClick={onItemClick}
      size={size}
      count={item.count}
    />
  ), [items, activeId, size, onItemClick])

  if (!items) return null

  return (
    <Wrapper>
      <SwiperWrapper
        itemRenderFn={renderFunc}
        items={items}
        spaceBetween={24}
        disableArrow={disableArrow}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${palette.grey10};
  }
`
